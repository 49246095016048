import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { BUTTON, COMMON_TEXT } from '../../../../app/const/text-common';
import { ButtonType, SaveType } from '../../../../app/enum/common-enum';
import { ModalTemplate } from '../../../../app/models/common-model';

@Component({
  selector: 'pivot-confirm-unsaved-data-dialog',
  templateUrl: './confirm-unsaved-data-dialog.component.html',
  styleUrls: ['./confirm-unsaved-data-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class ConfirmUnsavedDataDialogComponent implements OnInit, OnChanges {
  buttonType = ButtonType;
  saveType = SaveType;
  BUTTON = BUTTON;
  MESSAGE_TEXT = MESSAGE_TEXT;
  modalData: ModalTemplate = new ModalTemplate();

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.config.showHeader = true;
      this.config.style = { 
        width: '35%',
        header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA
      };
     }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
  }

  onSubmit(data: number) {
    this.ref.close(data);
  }
}
