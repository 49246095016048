<pivot-modal-template [modalTemplate]="modalData" (closeModalHandler)="onClose()">
  <div class="formula-container" modal-body>
    <pivot-input-formula #inputFormula [displayCols] = "displayCols" [selected] = "selected" [hasTargetValue] = "true" [isTarget] = "true">
    </pivot-input-formula>
  </div>
  <div modal-bottom class="bottom-button">
    <div class="btn-grp">
        <pivot-text-button (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
        </pivot-text-button>
        <pivot-text-button (btnClick)="onSubmit()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.ADD">
        </pivot-text-button>
    </div>
</div>

</pivot-modal-template>