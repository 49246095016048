export class ZaitakuSyncItem {
    countflg: boolean = true;     
    hasSocialLicense: boolean = false;         
    date: string = "";                // 識別1
    dayInMonth: number = 0;           // 識別2
    cumulativeDays: number = 0;       // 識別3
    inmates: number = 0;              // 識別4
    cumulativeNew: number = 0;        // 識別5
    newLeavers: number = 0;           // 識別6
    peopleLeaving: number = 0;        // 識別7
    death: number = 0;                // 識別8
    admission: number = 0;            // 識別9
    discharge: number = 0;            // 識別10
    serviceType: number = 0;          // 識別11
    healthFacility: number = 0;       // 識別12
    physicalTherapists: number = 0;   // 識別13
    careHealthFacility: number = 0;   // 識別14
    coumselors: number = 0;           // 識別15
    aspiration: number = 0;           // 識別16
    feeding: number = 0;              // 識別17
    sameDayDischarge: number = 0;
    [key: string]: any; 
  };