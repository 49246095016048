import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { orderBy } from 'lodash';
import { DATATYPE, DSTARGET, FooterName, InvisibleColumn, InvisibleInCustom, SummaryColumnName } from 'src/app/const/const';
import { MESSAGE_TEXT } from 'src/app/const/message';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING } from 'src/app/const/text-common';
import { ButtonType, ColumnType, SearchType } from 'src/app/enum/common-enum';
import { ModalTemplate, operator, SearchParams } from 'src/app/models/common-model';
import { WidgetDetail } from 'src/app/models/request/widget.dto';
import { createXNode, formulaToOperator } from 'src/app/_helper/helper';
import { InputFormulaComponent } from 'src/app/component/common/input-formula/input-formula.component';
import { getOperatorString } from 'src/app/_helper/operator-custom-helper';
import { OPERATOR_TYPE } from 'src/app/const/operator-custom';

@Component({
  selector: 'pivot-target-setting-formula',
  templateUrl: './target-setting-formula.component.html',
  styleUrls: ['./target-setting-formula.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TargetSettingFormulaComponent implements OnChanges {
    
  @ViewChild('inputFormula') inputFormula: InputFormulaComponent;
  @Input() displayCols: Array<WidgetDetail>;
  @Input() selected: WidgetDetail | null
  @Input() usedColumns: Array<WidgetDetail> = [];
  @Output() onSubmitData: any = new EventEmitter<any>();
  COMMON_TEXT = COMMON_TEXT;
  WIDGET_SETTING = WIDGET_SETTING;
  BUTTON = BUTTON;
  selectedColIndex: number = 0;
  usingDataType: any;
  operators: Array<operator> = []
  operatorHtml: SafeHtml;
  _sanitizer: DomSanitizer;
  cursorIndex: number | undefined;
  calculatorStyle: any = { 'margin-left.px': 10, 'width.px': 250, 'height.px': 30 }
  prevValue: any;
  buttonType = ButtonType;
 
  modalData: ModalTemplate = new ModalTemplate();
  DATATYPE = DATATYPE;
  options: any[] = [ {name: '小数点第一を', value: 0}, {name: '小数点第二を', value: 1}, {name: '一の位を', value: 2}, {name: '十の位を', value: 3}, {name: '百の位を', value: 4}];
  optionParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: '小数点以下を',
    cssStyle: { height: '32px', width: 'calc(70% - 5px)', 'font-size': '14px', float: 'right', 'margin-right' : '10px' },
    readonly: false,
    disabled: false,
    dataSource: this.options,
    comboDisplayText: 'name'
  };
  // 切り捨てる
  rbtKirisuteAll: boolean = true;
  // 切り上げる
  rbtKiriageAll: boolean = false;
  // 四捨五入する
  rbtRoundAll: boolean = false;
  item: number = 0;
  option: number = 0;

  constructor(sanitizer: DomSanitizer) {
    this._sanitizer = sanitizer;
    this.modalData.header = "計算式";
    this.modalData.style = { 'width': '50%' };
    this.modalData.breakpoints = { '500px': '75vw', '640px': '100vw' };
  }

  ngOnChanges(): void {
    let displayCols = this.displayCols.filter(x => 
     ![DSTARGET, FooterName, SummaryColumnName].includes(x.datasourcecd) &&   
     !InvisibleColumn[x.columnname]
    )
    this.displayCols = orderBy(displayCols, ["utcsortno"]);
    if(this.selected) {
      this.operators = formulaToOperator(this.selected, 'targetValue' , this.displayCols)
      if(this.selected?.rounding) {
        let rounding = this.selected.rounding?.split(',') || [];
        this.item = parseInt(rounding[0]?.toString());
        this.option = parseInt(rounding[1]?.toString());
        this.rbtKirisuteAll = this.item == 0 ? true: false;
        this.rbtKiriageAll = this.item == 1 ? true: false;
        this.rbtRoundAll = this.item == 2 ? true: false;
        this.optionParams.defaultValue = this.options.find(s => s.value === this.option)?.name;
      }
      else {
        this.rbtKirisuteAll = true;
        this.rbtKiriageAll = false;
        this.rbtRoundAll = false;
        this.optionParams.defaultValue = this.options[0]?.name;
      }
      this.setDataType();
      this.onGenerateHtml();
    }
  }

  // close dialog
  onClose() {
    this.onSubmitData.emit(null);
  }

  onSubmit() { 

    let dataFormula = this.inputFormula.getData();
    let operator = getOperatorString(dataFormula.operators);
    dataFormula.operators.forEach((op: any) => {
      if(op.value != OPERATOR_TYPE.OPERATOR_CUSOR){
        if(typeof op.value == 'object') {
          op.value.columntype = ColumnType.Hidden_Value;
          this.usedColumns.push(op.value);
        }
      }
     
    });
    this.onSubmitData.emit({operator: operator, usedColumnsInTarget: this.usedColumns, rounding: dataFormula.rounding, operators: dataFormula.operators})
  }


  onClickArrowBtn(event: any) {
    let { columnname, displayname, datatype, ...abc } = this.displayCols[this.selectedColIndex];
    let item: operator = { type: 'column', label: displayname, value: { columnname, displayname, datatype, ...abc } }
    this.pushToFormula(item)
    this.usingDataType = datatype;
  }

  onClickColumn(i: number) {
    this.selectedColIndex = i;
    this.onGenerateHtml()
  }

  pushToFormula(item: operator) {
    if (this.cursorIndex !== undefined) {
      this.operators.splice(this.cursorIndex, 0, item)
      this.cursorIndex++
    } else {
      this.operators.push(item)
    }
    this.onGenerateHtml();
  }

  onGenerateHtml() {
    let html = createXNode();
    this.operators.forEach((item: any, i: number) => {
      var span = createXNode(item.label)
      var hidden = createXNode(JSON.stringify(item), 'hidden')
      span.setAttribute('x-index', i.toString())
      span.appendChild(hidden)
      html.appendChild(span)
    });
    this.operatorHtml = this._sanitizer.bypassSecurityTrustHtml(html.innerHTML);
  }

  setCursorIndex() {
    const selection = document.getSelection() as Selection;
    const range = selection.getRangeAt(0);
    if (range.startContainer.nodeName === '#text') {
      let node = range.startContainer.previousSibling as HTMLElement
      if (node) {
        this.cursorIndex = Number(node.getAttribute('x-index')) + 2
      } else {
        this.cursorIndex = 1
      }
    } else {
      this.cursorIndex = range.startOffset
    }
  }

  onClickCalculator(event: any) {
    let item: operator = { type: 'operator', label: event, value: event, }
    this.pushToFormula(item)
  }

  onFocusinEditor(event: any) {
    this.prevValue = event.currentTarget.innerHTML;
  }

  onFocusoutEditor(event: any) {
    let check = true;
    let validData = ["0","1","2","3","4","5","6","7","8","9","."];
    if (event.target.childNodes && event.target.childNodes.length > 0) {
      event.target.childNodes.forEach((element: any, index: number) => {
        if (element.nodeName === '#text') {
          let chars = [...element.nodeValue];
          chars.forEach(char => {
            if (!validData.includes(char)) check = false;
          });
        }
      });
    }

    if (!check) {
      event.currentTarget.setHTML(this.prevValue);
      this.cursorIndex = event.target.childNodes.length;
    } else {
      this.setCursorIndex()
    }
    let target = event.target as HTMLElement
    this.operators = []

    target.childNodes.forEach((node, i) => {
      let item = {} as operator


      if (node.nodeName === '#text') {
        item.type = 'operator'
        item.value = item.label = node.textContent as string
      } else {
        item = JSON.parse(node.childNodes[1].textContent as string)
      }

      this.operators.push(item)
    })

    this.onGenerateHtml();
    this.setDataType();

  }

  onKeydownEditor(event: any) {
    let control = ['Backspace', 'Delete', 'Meta', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', '.']

    if (event.key === ' ') return false
    if (control.includes(event.key)) return true
    if (!isNaN(Number(event.key))) return true
    if (event.metaKey) return true

    return false
  }

  setDataType() {
    let tmp = this.operators.filter(x => x.type == "column");
    if (!tmp || tmp.length == 0) {
      this.usingDataType = null;
    } else {
      if (this.selected) {
        let val = tmp[0].value as WidgetDetail;
        if(val) this.usingDataType = val.datatype;
      }
    }
  }

  onFilterOption(data: any) {
    this.option = data[0].value;
  }

  onCheckedOptionChange(value: any) {
    this.item = parseInt(value);
    if(value === '0') {
      this.rbtKirisuteAll = true;
      this.rbtKiriageAll = false;
      this.rbtRoundAll = false;
    }
    else if(value == '1') {
      this.rbtKirisuteAll = false;
      this.rbtKiriageAll = true;
      this.rbtRoundAll = false;
    }
    else {
      this.rbtKirisuteAll = false;
      this.rbtKiriageAll = false;
      this.rbtRoundAll = true;
    }
  }

}
