<!-- <pivot-table [data]="data"></pivot-table> -->

<div class="pivot-chart-bulk" >
    <table class="pivot-table" #table>
        <thead>
            <tr>
                <ng-container *ngFor="let col of headers; let i = index">
                    <th class="first">{{col.title}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of bodySlice">
                <tr [style.height.px]="rowHeight">
                    <ng-container *ngFor="let col of headers; let i = index">
                        <td>{{formatDataDraw(row[col.field], col)}}</td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>