
<div class="def-item " [ngClass]="{'def-flex': !isDefinedColumn}">
  <div *ngIf="!isDefinedColumn" class="def-child def-column">
      <div class="def-height1">
          <label>{{COMMON_TEXT.ITEM_SELECT}}</label>
      </div>  
      <div class="def-column-child">
          <table>
              <tr *ngFor="let item of displayCols;let i = index"
                  [ngClass]="{'def-selected': selectedColIndex === i}" (click)="onClickColumn(i)">
                  <td [ngClass]="{ 'def-disabled': (usingDataType && item.datatype !== usingDataType && !isTarget)}">{{item.displayname || item.columnname}}</td>
              </tr>
          </table>
      </div>
  </div>
  <div *ngIf="!isDefinedColumn" class="def-child def-button">
      <div>
          <pivot-additional-arrow-button (onClick)="onClickArrowBtn($event)">
          </pivot-additional-arrow-button>
      </div>
  </div>
  <div class="def-child" [ngClass]="{'def-operator': !isDefinedColumn, 'def-operator-definedcolumn': isDefinedColumn}">
      <div class="def-height1">
          <label>{{labelButton}}</label>
          <pivot-calculator (onClickButton)="onClickCalculator($event)" [dataType]="usingDataType" [isDataSource]="isDataSource">
          </pivot-calculator>
      </div>
      <div id="js-operator-editor" class="def-operator-child" contenteditable="true" tabindex="-1"  #operatorEditor  [mention] = "itemsMention" [innerHTML]="operatorHtml"
          (focusin)="onFocusinEditor($event)"
          (focusout)="onFocusoutEditor($event)"
          (click)="onClickEditor($event)"
          (paste) = "onPasteEditor($event)"
          (copy) = "onCopyEditor($event)"
          (itemSelected) = "itemSelectedMetion($event)">
      </div>

      <fieldset  *ngIf="!isDefinedColumn" class="format-grb">
          <legend class="title">端数処理</legend>
          <div class="grb-item">
              <div class="lst-option" [ngClass]="( (usingDataType!= DATATYPE.Number && usingDataType != DATATYPE.Float) &&  usingDataType != undefined  &&  !isTarget)? 'fieldset-disable': ''">
                  <div class="option-grb">
                      <label class="lbl-option">結果に対して</label>
                      <pivot-search-bar id="customsearchId" [searchParams]="optionParams" (onFilterData)="onFilterOption($event)">
                      </pivot-search-bar>
                  </div>

                  <div class="cb-margin">
                      <pivot-radio-button [isChecked]="rbtKirisuteAll" (onClick)="onCheckedOptionChange('0')"></pivot-radio-button>
                      <label class="cb-item">{{WIDGET_SETTING.DEFINED_TRUNCATE}}</label>
                      <pivot-radio-button [isChecked]="rbtKiriageAll" (onClick)="onCheckedOptionChange('1')"></pivot-radio-button>
                      <label class="cb-item">{{WIDGET_SETTING.DEFINED_ROUNDUP}}</label>
                      <pivot-radio-button [isChecked]="rbtRoundAll" (onClick)="onCheckedOptionChange('2')"></pivot-radio-button>
                      <label class="cb-item">{{WIDGET_SETTING.DEFINED_ROUNDOFF}}</label>
                  </div>
              </div>
          </div>
      </fieldset>
  </div>
</div>