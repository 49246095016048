export const LocalStorageKey = {
  TOKEN: 'token',
  CORP_PREFIX: 'corp_prefix',
  ORGANIZATION_CODE: 'organization_code',
  CURRENT_USER: 'current_user',
  LOGIN_INFO: 'login_info',
  DASHBOARD_CD: 'dashboardcd',
  SELECTED_OFFICES: 'seleted_offices',
  SELECTED_FOLDER: 'seleted_folder',
  PACKCD: 'packCd',
  SYNCDATA: 'syncData',
  ALL_STAFF: 'all_staff',
  CURRENT_USER_ROLE: 'current_user_role',
  HAS_ZAITAKU: 'has_zaitaku',
  THEME: 'theme',
  USERID: 'userid',
  AUTH_CORP_LIST: 'auth_corp_list',
  COMPANYID: 'companyid',
  CORP_TEMPLATE_SETTING: 'corp_template_setting',
  OFFICE_SELECTED: 'office_selected',
};

export class LocalStorageHelper {

  public static getToken(): string {
    const currentUser = localStorage.getItem(LocalStorageKey.CURRENT_USER);
    if (!currentUser) return '';
    return JSON.parse(currentUser).token; 
  }

  public static clearAll(): void {
    localStorage.clear();
  }

  public static clearItem(key: string) {
    localStorage.removeItem(key);
  }

  public static getUrl(url: string): string {
    return url;
  }

  public static getCurrentUser(): string {
    return localStorage.getItem(LocalStorageKey.CURRENT_USER) || "";
  }

  public static getCurrentDashboard(): string {
    return localStorage.getItem(LocalStorageKey.DASHBOARD_CD) || "";
  }

  public static getBaseLocation(): string {
    const corpPrefixInLocal = localStorage.getItem(LocalStorageKey.CORP_PREFIX) || "";
    const paths: string[] = location.pathname.split('/').splice(1, 1);
    const basePath: string = (paths && paths[0]) || corpPrefixInLocal;
    if (corpPrefixInLocal !== basePath) {
      LocalStorageHelper.clearAll();
      localStorage.setItem(LocalStorageKey.CORP_PREFIX, basePath);
      return basePath;
    }
    return corpPrefixInLocal;
  }

  public static getOrganizationCode() {
    return JSON.parse(localStorage.getItem(LocalStorageKey.ORGANIZATION_CODE) || "{}");
  }

  public static setOrganizationCode(organizationCode: string) {
    localStorage.setItem(LocalStorageKey.ORGANIZATION_CODE, organizationCode);
  }

  public static getPackCd() {
    return localStorage.getItem(LocalStorageKey.PACKCD) || "";
  }

  public static setPackCd(packcd: string) {
    localStorage.setItem(LocalStorageKey.PACKCD, packcd);
  }

  public static getSyncData() {
    return JSON.parse(localStorage.getItem(LocalStorageKey.SYNCDATA) || "[]");
  }

  public static setSyncData(syncData: any) {
    localStorage.setItem(LocalStorageKey.SYNCDATA, JSON.stringify(syncData));
  }

  public static setKey(key: string, value: string) {
    localStorage.setItem(key, value);
  }


  public static getLoginInfo() {
    return JSON.parse(localStorage.getItem(LocalStorageKey.LOGIN_INFO) || "{}");
  }

  public static setLoginInfo(data: any) {
    localStorage.setItem(LocalStorageKey.LOGIN_INFO, JSON.stringify(data));
  }
}
