
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { DataType, DateFormat } from 'src/app/enum/common-enum';
import { HeaderItem, TableData } from '../../../../app/models/table-model';

@Component({
  selector: 'pivot-table-bulk-data',
  templateUrl: './table-bulk-data.component.html',
  styleUrls: ['./table-bulk-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableBulkDataComponent implements OnChanges, OnInit, AfterViewInit {

  @Input() data: TableData
  @ViewChild('table') table: ElementRef;

  headers: Array<HeaderItem>
  body: Array<any>
  bodySlice: Array<any>


  pageSize: number = 1000;
  startIndex: number = 0;
  readonly rowHeight: number = 30;
  dataType = DataType;

  constructor() { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.body = this.data.body;
    this.headers = this.data.header;
    this.bodySlice = this.body.slice(0,this.pageSize)
  }

  ngAfterViewInit() {
    this.table.nativeElement.addEventListener('scroll', this.onTableScroll.bind(this));
  }
  onTableScroll() {
    // const rowHeight = this.table.nativeElement.querySelector('tbody tr').offsetHeight;
    const scrollTop = this.table.nativeElement.scrollTop;
    const visibleStart = Math.floor(scrollTop / this.rowHeight);
    const visibleEnd = visibleStart + Math.ceil(this.table.nativeElement.offsetHeight / this.rowHeight);
    if(visibleEnd > (this.startIndex + this.pageSize)) {
      const newData = this.body.slice(this.startIndex + this.pageSize, this.startIndex + this.pageSize * 2);
      this.bodySlice = [...this.bodySlice, ...newData];
      this.startIndex += this.pageSize;
    }
  }

  formatDataDraw(value: any, col: any) {
    if(value == null) return value;
    if (typeof value == 'string') {
      value = value.replace(/\\/g, "¥");
    }
    switch (col.dataType?.toUpperCase()) {
      case "VARCHAR":
        return value;
      case "INT":
      case "FLOAT":
        if (value === 0) return 0; else if (!value) return '';
        return Number.parseFloat(value).toLocaleString();
      case "DATETIME":
        return moment(value).format(DateFormat.FULL_SHORT_DATE);
      default:
        return value;
    }
  }
}
