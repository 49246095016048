import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType, DateFormat, FilterGroup, InputType, PrimengDateFormat } from '../../../app/enum/common-enum';
import { InputParams, ModalTemplate, item } from '../../../app/models/common-model';
import { BUTTON, COMMON_TEXT } from '../../../app/const/text-common';
import { ColFilterGroup, DATATYPE, FilterGroupOptions } from '../../../app/const/const';
import { cloneDeep, isDate, isNaN } from 'lodash';
import { findMinMaxValues, getDayGroups, getFormatDate, getHourGroups, getRangesValueNumber, getValueFromFormatDate, isDecimal, minAndMaxArray } from '../../../app/_helper/helper';
import * as moment from 'moment';

@Component({
  selector: 'pivot-dialog-filter-group',
  templateUrl: './dialog-filter-group.component.html',
  styleUrls: ['./dialog-filter-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFilterGroupComponent implements OnInit {

  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() tableData: any | undefined;
  @Input() sourceTable: any | undefined;
  @Input() dataType: any = null;
  @Input() value: any = null;
  @Input() options: item[] = [];
  @Input() itemSelected: any = null;
  @Output() onSubmitData: any = new EventEmitter();
  isMinValue: boolean = true;
  isMaxValue: boolean = true;
  min: any = null;
  max: any = null;
  unitParams: InputParams = new InputParams();
  minParams: InputParams = new InputParams();
  maxParams: InputParams = new InputParams();
  filterOption: any[] = FilterGroupOptions;
  buttonType = ButtonType;
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  optionSelected: any = {};
  isDate: boolean = true;
  minValue: any = null;
  maxValue: any = null;
  unitValue: any = null;
  isRow: boolean = false;
  isColumn: boolean = false;
  isMaxValidate: boolean = false;
  isMinValidate: boolean = false;
  isUnitValidate: boolean = false;
  isMinFocus: boolean = false;
  isUnitFocus: boolean = false;
  isMaxFocus: boolean = false;
  condition: any = null;
  dateFormat: string = PrimengDateFormat.JP_FULL_SHORT_DATE;
  isTimeOnly: boolean = false;
  isShowTime: boolean = false;
  FilterGroup = FilterGroup;
  groupFilter: any = null;
  constructor() { }

  ngOnInit(): void {
    this.optionSelected = {};
    this.unitValue = 1;
    this.isDate = this.dataType == DATATYPE.Date ? true: false;
    this.isRow = this.tableData?.config?.rows?.find((s: any) =>s.columnname == this.itemSelected?.columnname)? true : false;
    this.isColumn = this.tableData?.config?.columns?.find((s: any) => s.columnname == this.itemSelected?.columnname) ? true: false;
    this.setInputType();
    this.filterOption = this.isDate?  FilterGroupOptions.filter(s=>s.value != FilterGroup.NONE) : FilterGroupOptions.filter(s=>s.value == FilterGroup.NONE);
    if(this.itemSelected && this.itemSelected.groupfilterval) {
      let filterValue = JSON.parse(this.itemSelected.groupfilterval);
      if(filterValue) {
        let item = filterValue.groupFilter;
        this.groupFilter = item;
        if(item) {
          this.condition = item.option;
          this.isTimeOnly = item.option?.value == FilterGroup.HOUR || item.option?.value == FilterGroup.HOUR_MINUTE ? true: false;
          this.optionSelected = this.filterOption.find(f=>f.value == this.condition?.value);
          this.dateFormat =  getFormatDate(this.optionSelected?.value, true);
          if(this.isDayType()) {
            this.minValue = Number(item.minValue)
            this.maxValue = Number(item.maxValue);
          } else {
            this.minValue = item.isDate? new Date(item.minValue): Number.parseFloat(item.minValue);
            this.maxValue = item.isDate? new Date(item.maxValue): Number.parseFloat(item.maxValue); 
          }
          this.isMinValue = item.isMinValue || false;
          this.isMaxValue = item.isMaxValue || false;
          if(this.isMinValue) this.min = this.minValue;
          if(this.isMaxValue) this.max = this.maxValue;
          this.unitValue = item.unitValue;
          this.setUnitValidate();
        }   
      }
      else {
        this.condition = FilterGroupOptions.find(s =>s.value == FilterGroup.YEAR_MONTH);
        this.getRangeValue();
      } 
    }
    else this.getRangeValue();
    if(this.isMinValue && this.isMaxValue) this.getRangeValue();
    this.setDisableInput();
  }

  /* 
   set disable input min and max 
  */
  setDisableInput() {
    this.minParams.disabled = this.isMinValue;
    this.maxParams.disabled = this.isMaxValue;
    this.minParams = cloneDeep(this.minParams);
    this.maxParams = cloneDeep(this.maxParams);
  }

  /* 
   event on submit data
  */
  onSubmiDataFilter(isSubmit: any) {
    let columnname = this.itemSelected?.columnname;
    if (isSubmit) {
      if(this.unitValue == null || this.isUnitValidate || this.isMinValidate || this.isMaxValidate) {
        this.validateUnitErr(true);
        return;
      }
      this.minValue = this.condition?.value == FilterGroup.DAY || !this.isDate ? Number(this.minValue) : moment(this.minValue).format('YYYY-MM-DD HH:mm').toString();
      this.maxValue = this.condition?.value == FilterGroup.DAY || !this.isDate ? Number(this.maxValue) : moment(this.maxValue).format('YYYY-MM-DD HH:mm').toString();
      this.onSubmitData.emit({ groupFilter: { minValue: this.minValue, maxValue: this.maxValue, unitValue: this.unitValue , isDate: this.isDate, 
        option: this.isDate ? this.condition : null, isMinValue: this.isMinValue, isMaxValue: this.isMaxValue }, columnname: columnname
        , columntype: this.itemSelected?.columntype})
    } else {
      this.onSubmitData.emit({ groupFilter: this.groupFilter ? this.groupFilter : null, columnname: columnname, columntype: this.itemSelected?.columntype, isCancel: true})
    }
  }

  /* 
   event set checkbox is min value
  */
  getFirstValue() {
    this.isMinValue = !this.isMinValue;
    this.minMaxValidate(false);
    this.minParams.disabled = this.isMinValue? true: false;
    if(this.isMinValue) {
      this.minParams.validate = false;
      this.minParams.isError = false;
      this.getRangeValue();
    }
    this.minParams = cloneDeep(this.minParams);
  }

  /* 
   event on inout min value
  */
  onInputMinValue(value: any) {
    this.minValue = parseInt(value);
    if(Number(this.minValue) != Number(this.min)) this.isMinValue = false;
    if(!value) this.minMaxValidate(false);
    this.setUnitValidate();
  }

  // validate input min and input max
  minMaxValidate(isMax: boolean , isFocusout: boolean = false) {
    // set validate input max
    if(isMax) {
      if(!this.min || !this.max) this.getRangeValue();
      this.isMaxValidate = this.max && (this.maxValue == null || Number(this.maxValue) < Number(this.minValue) || Number(this.maxValue) > Number(this.max)) 
      || (!this.maxValue && this.maxValue != 0)? true : false;
      this.isMaxValidate = !this.isDate && !this.isDayType() ? false : this.isMaxValidate;
      this.maxParams.isError = this.isMaxValidate
      this.maxParams.validate = this.isMaxValidate;  
      this.maxParams = cloneDeep(this.maxParams);
      if(isFocusout) this.isMaxFocus = false;
    }
    // set validate input min
    else {
      this.isMinValidate =  this.minValue == null || Number(this.minValue) > Number(this.maxValue) || Number(this.minValue) < Number(this.min) 
      || (!this.minValue && Number(this.minValue != 0))? true : false;
      this.isMinValidate = !this.isDate && !this.isDayType() ? false : this.isMinValidate;
      this.minParams.isError = this.isMinValidate;
      this.minParams.validate = this.isMinValidate;
      this.minParams = cloneDeep(this.minParams);
      if(isFocusout) this.isMinFocus = false;
    }
  }

  /* 
   event focus input 未尾の値
  */
  inputMaxFocusIn() {
    this.isMaxFocus = true;
  }

  /* 
   event forcus input 先頭の値
  */
  inputMinFocusIn() {
    this.isMinFocus = true; 
  }

  /* 
   event on input value
  */
  onInputMaxValue(value: any) {
    this.maxValue = parseInt(value);
    if(Number(this.maxValue) != Number(this.max)) this.isMaxValue = false;
    if(!value) this.minMaxValidate(true);
    this.setUnitValidate();
  }

  /* 
   check date type from condition 
  */
  isDayType = ()=> this.condition?.value == FilterGroup.DAY? true: false

  /* 
   set input type number
  */
  setInputType() {
    this.unitParams.type = InputType.number;
    this.unitParams = cloneDeep(this.unitParams);
    this.minParams = cloneDeep(this.unitParams);
    this.maxParams = cloneDeep(this.unitParams);
  }

  /* 
   check validate error for input unit
  */
  validateUnitErr(isValidate: boolean = false) {
    this.unitParams.validate = isValidate;
    this.unitParams.isError = isValidate;
    this.unitParams = cloneDeep(this.unitParams);
  }

  /* 
   switch case get validate value
  */
  getUnitValidate() {
    this.unitValue = Number(this.unitValue) || 1;
    if(this.unitValue < 0) return true;
    switch(this.optionSelected.value) {
      case FilterGroup.HOUR:
            return this.unitValue + this.minValue?.getHours() -1 > this.maxValue?.getHours();
      case FilterGroup.MONTH: 
          return  Number(this.minValue?.getMonth() + 1 || 1) + this.unitValue - 1 > Number(this.maxValue?.getMonth() + 1 || 12);
      case FilterGroup.DAY:
          return this.unitValue + Number(this.minValue) - 1 > Number(this.maxValue);
      case FilterGroup.HOUR_MINUTE:
        return new Date(new Date(this.minValue).setMinutes(this.unitValue - 1)) > new Date(this.maxValue);
      case FilterGroup.HOUR:
        return this.unitValue + Number(this.minValue?.getHours() || 0) > Number(this.maxValue?.getHours() || 0)
      case FilterGroup.YEAR_MONTH:
        this.minValue = new Date(this.minValue);
        return new Date(this.minValue?.getFullYear(),  this.minValue?.getMonth() + this.unitValue - 1, 1) > new Date(this.maxValue)
      case FilterGroup.MONTH_DAY:
        // Lấy ngày đầu tiên của năm hiện tại
        const firstDayOfYear = new Date(new Date().getFullYear(), this.minValue?.getMonth(), this.minValue?.getDate());
        // Lấy ngày cuối cùng của năm hiện tại
        const lastDayOfYear = new Date(new Date().getFullYear(), this.maxValue?.getMonth(), this.maxValue?.getDate());
        // Tính số ngày bằng cách lấy hiệu của ngày cuối và ngày đầu
        const numberOfDays = Math.ceil((lastDayOfYear.getTime() - firstDayOfYear.getTime()) / (1000 * 3600 * 24)) + 1;
        return this.unitValue > numberOfDays;
      default: 
      return false;
    }
  }


  /* 
   set is unit validate value
  */
  setUnitValidate() {
    this.isUnitFocus = false;
    // datatype is date
    if(this.isDate) {
      this.isUnitValidate = this.getUnitValidate() || false;
    }
    // datatype is number
    else {
      this.unitValue = Number(this.unitValue) || 0;
      this.minValue = Number(this.minValue) || 0;
      this.maxValue = Number(this.maxValue) || 0;
      this.isUnitValidate = ((this.minValue + (this.unitValue - 1)) > this.maxValue)  || this.unitValue <= 0? true : false;
    }
    if(this.isUnitValidate) this.validateUnitErr(true);
    else this.validateUnitErr();
  }

  /* 
   event on change value unit
  */
  onChangeUnit(value: any) {
    this.unitValue = this.isDate? parseInt(value) : parseFloat(value);
    this.isUnitFocus = true;
  }

  /* 
   unit focus in input 単位
  */
  onUnitFocus() {
    this.isUnitValidate = false;
    this.isUnitFocus = true;
    if(!this.isDate) {
      this.unitParams.isError =  this.unitValue == null || (Number(this.maxValue) - Number(this.minValue || 0)) < Number(this.unitValue)  ? true : false;
      this.unitParams.validate = this.unitParams.isError;  
      this.unitParams = cloneDeep(this.unitParams);
    }
  }
  
  /* 
  get value for checkbox 先頭の値
  */
  getLastValue() {
    this.isMaxValue = !this.isMaxValue;
    this.minMaxValidate(true);
    this.maxParams.disabled = this.isMaxValue ? true: false;
    if(this.isMaxValue)  {
      this.maxParams.validate = false;
      this.maxParams.isError = false;
      this.getRangeValue();
    }
    this.maxParams = cloneDeep(this.maxParams);
  }

  /* 
  event change option for value type Date 
  */
  onChangeDropDown(data: any) {
    if(!data) return; 
    let option = this.filterOption.find(f=>f.value == data.value?.value);
    this.dateFormat =  getFormatDate(option.value, true)?.toLocaleLowerCase();
    this.condition = option;
    if(option.value == FilterGroup.HOUR || option.value == FilterGroup.HOUR_MINUTE) {
      this.isTimeOnly = true;
    }
    else this.isTimeOnly = false;
    this.unitValue = 1;
    this.isMinValue = true;
    this.isMaxValue = true;
    this.setDisableInput();
    this.getRangeValue();
    this.isUnitValidate = false;
    this.validateUnitErr(this.isUnitValidate);
  }

  getRangeFilterData() {
   if(this.isDate) {
    switch(this.optionSelected.value) {
      case FilterGroup.HOUR:
            return getHourGroups(this.minValue, this.maxValue, this.unitValue);
      case FilterGroup.MONTH: 
        return getDayGroups(this.minValue, this.maxValue, this.unitValue);
      case FilterGroup.DAY:
          return this.unitValue + Number(this.minValue) > Number(this.maxValue);
      case FilterGroup.HOUR_MINUTE:
        return new Date(new Date(this.minValue).setMinutes(this.unitValue)) > new Date(this.maxValue);
      case FilterGroup.HOUR:
        return this.unitValue + Number(this.minValue?.getHours() || 0) > Number(this.maxValue?.getHours() || 0)
      case FilterGroup.YEAR_MONTH:
        return new Date(new Date(this.minValue).setMonth(this.unitValue)) > new Date(this.maxValue)
      default: 
      return null;
    }
   }
   else {
     return getRangesValueNumber(this.minValue, this.maxValue, this.unitValue);
   }
  }

  /* 
  get range Min and Max value
  */
  getRangeValue() {
    let data = this.sourceTable.map((s: any)=>s[this.itemSelected?.columnname?.replace(ColFilterGroup + '_', '')?.trim()]);
    let minVal: any = null;
    let maxVal: any = null;
    if(!this.condition) this.condition = FilterGroupOptions.find(s =>s.value == FilterGroup.YEAR_MONTH);
      // set range value for value type Date
      if(this.isDate) {
        data = data?.filter((value: any) => typeof value === 'string' || isDate(value)) || [];
        if(data.length > 0) {
          let minMaxCol = findMinMaxValues(data, this.isDate, this.condition );
          minVal = getValueFromFormatDate(this.condition?.value, minMaxCol.minVal);
          maxVal = getValueFromFormatDate(this.condition?.value, minMaxCol.maxVal);
        }
      }
      else {
        // set range value of value type number
        data = data?.filter((value: any) => typeof value === 'number' || isDecimal(value)) || [];
        if(data.length > 0) {
          let minMaxValue = minAndMaxArray(data);
          minVal = minMaxValue.min;
          maxVal = minMaxValue.max;
        }
      }
      // set min max value
      if(this.isMinValue) this.minValue = minVal;
      if(this.isMaxValue) this.maxValue = maxVal;
      this.min = this.minValue;
      this.max = this.maxValue;
      // set date format for calendar
      this.dateFormat = getFormatDate(this.condition?.value, true);
      this.isMinValidate = false;
      this.isMaxValidate = false;
  }

  // onchange value for mindate and max date
  changeDateValue(value: any, type: any) {
    if (!value) return;
    value = getValueFromFormatDate(this.condition?.value, value.toString());
    // set minvalue
    if(type == 'min')   {
      this.minValue = value;
      this.isMinValidate = new Date(this.minValue) > new Date(this.maxValue)? true : false;
    }
    // set max value
    else {
      this.maxValue = value;
      this.isMaxValidate = new Date(this.maxValue)  <  new Date(this.minValue)? true : false;
    } 
    this.setUnitValidate();
  }

}
