import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import Utils from 'src/app/util/utils';
import { BUTTON, COMMON_TEXT, DATASOURCE_LIST } from '../../../const/text-common';
import { ButtonType, DataType, SearchType } from '../../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../models/common-model';
import { TableData } from '../../../models/table-model';


@Component({
  selector: 'pivot-select-widget',
  templateUrl: './select-widget.component.html',
  styleUrls: ['./select-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectWidgetComponent implements OnInit {
  @Input() dataSourceCd: string  = "";
  @Input() widgetList: []  = [];
  @Input() showModal: boolean = false;
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedWidgetHandler = new EventEmitter();
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  modalTemplate: ModalTemplate = new ModalTemplate();
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  tableData: TableData = {
    config: {
      id: 'select-widget',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: true,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {}

    },
    header: [
      {
        field: 'widgetCd',
        title: "",
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'widgetname',
        title: 'ウィジェット名',
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  searchText: string = "";
  selectedWidgets = []
  loadingTable: boolean = true;
  widgetData: any[] = [];

  ngOnInit() {
    this.modalTemplate.header = DATASOURCE_LIST.SELECT_WIDGETS_TITLE;
    this.modalTemplate.style = { 'width': '550px', 'max-height': 'calc(100vh - 200px)', 'min-height': 'calc(100vh - 200px)', 'height': 'calc(100vh - 200px)' };
    this.tableData.body = this.widgetList;
    this.tableData = cloneDeep(this.tableData);
    this.widgetData = this.widgetList;
    this.loadingTable = false;
  }

  save() {
    this.selectedWidgetHandler.emit(this.selectedWidgets);
    this.closeModalHandler.emit();
  }

  cancel() {
    this.closeModalHandler.emit();
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }

  checkRowsTable(data: any) {
    if (data && Object.keys(data).length > 0) {
      this.selectedWidgets = data.data;
    }
  }

  onFilterOutData(widgetName: any) {
    let table = cloneDeep(this.tableData);
    widgetName = widgetName.trim();
    this.searchText = widgetName;
    let widgets = [];
    widgets = this.widgetData.filter(o => {
      let nameCondition = Utils.isNullOrEmpty(widgetName) ? true : o.widgetname?.includes(widgetName) ;
      return nameCondition;
    });
    table.body = widgets;
    this.tableData = table;
  }
}
