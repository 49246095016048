import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DateFormat, ScreenMode } from '../../../app/enum/common-enum';
import { ResponseData } from '../../../app/models/api-model';
import { Dashboard, DashboardList, DashboardModel, DashboardSetting } from '../../../app/models/response/dashboard.ro';
import { PivotTableConfig, Widget } from '../../../app/models/response/widget.ro';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageKey } from './../../_helper/local-storage.helper';
import {ErrorHandleService} from '../error-handle.service';

@Injectable({ providedIn: 'root' })
export class DashboardService extends ApiService {

  private dashboardItem?: DashboardList;
  private dashboardMode: ScreenMode;
  private duplicateItem: any = null;
  private publicData: any[] = [];
  private itemsRemove: any[] = [];
  private currentData: any = null;
  private currentTemplateData: any = null;
  private listNewItem: any[] = [];
  private featureChange: any = {
    listInsert: [],
    listRemove: [],
    dashboardcd: ''
  };

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService,   
    private authenticationService: AuthenticationService) {
    super(http, router, errorHandle);
  }

  setListNewItem(items: any[]) {
    this.listNewItem = items;
  }

  setFeaturePublicChange(featureChange: any) {
    this.featureChange = featureChange;
  }

  getFeaturePublicChange() {
    return this.featureChange;
  }

  getListNewItem() {
    return this.listNewItem;
  }

  setCurrentData(data: any) {
    this.currentData = data;
  }

  getCurrentData() {
    return this.currentData;
  }

  setCurrentTemplateData(data: any) {
    this.currentTemplateData = data;
  }

  getCurrentTemplateData() {
    return this.currentTemplateData;
  }

  setDashboardItem(mode: ScreenMode, value?: DashboardList) {
    this.dashboardItem = value;
    this.dashboardMode = mode;
  }

  setListPublicData(datas: any[]) {
    this.publicData = datas;
  }

  setListDataRemove(items: any[]) {
    this.itemsRemove = items;
  }

  getListDataRemove() {
    return this.itemsRemove;
  }

  getListPublicData() {
    return this.publicData;
  }

  getDashboardItem() {
    let item = {
      dashboard: this.dashboardItem || null,
      mode: this.dashboardMode
    };
    return item;
  }

  setDuplicateItem(item: any) {
    this.duplicateItem = item;
  }

  getDuplicateItem() {
    return this.duplicateItem;
  }

  async getAll() {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.ALL;
    let resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return this.mapModel(resData.data);
    }
    return [];
  }

  async getByFolder(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.FOLDER;
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModel(resData);
  }

  async mapModel(data: any) {
    const staffs = this.authenticationService.getData(LocalStorageKey.ALL_STAFF) || [];
    let dataArr = [];
      for await (const element of data.data) {
        let dash = new DashboardList();
        dash.id = element.id;
        dash.name = element.dashboardname;
        dash.dashboardCd = element.dashboardcd;
        dash.description = element.dashboarddesc;
        dash.folderCd = element.foldercd;
        dash.folderName = element.foldername;
        dash.insdate = element.insdate;
        dash.insstfcd = element.insstfcd;
        dash.insstfnm = staffs?.find((stf: any) => stf.staffCd == element.insstfcd)?.fullName || element.insstfnm;
        dash.upddate = element.upddate;
        dash.updstfnm = staffs?.find((stf: any) => stf.staffCd == element.updstfcd)?.fullName || element.updstfnm;
        dash.publicCd = element.publiccd;
        dash.publictype = element.publictype;
        if (element.updstfcd) {
          dash.displayNm = dash.updstfnm;
          dash.displayDate = (moment(element.upddate)).format(DateFormat.FULL_SHORT_DATE);
        } else {
          dash.displayNm = dash.insstfnm;
          dash.displayDate = (moment(element.insdate)).format(DateFormat.FULL_SHORT_DATE)
        }
        dataArr.push(dash);
      }
    return dataArr;
  }

  mapDashboardSetting(data: any): DashboardSetting {
    const detail = new DashboardSetting();
    detail.widgetCd = data.widgetcd;
    detail.datasourceCd = data.datasourcecd;
    detail.datasourceName = data.dsname;
    detail.period =  data.period;
    detail.widgetName = data.widgetname;
    detail.widgetConfig = data.widgetconfig;
    detail.widgetdetails = data.widgetdetails;
    detail.syncStatus = data.syncstatus;
    if (data.widgetposition) {
      detail.widgetPosition = data.widgetposition;
      try {
        const parsedPosition = JSON.parse(data.widgetposition);
        detail.x = parsedPosition.x
        detail.y = parsedPosition.y;
        detail.rows = parsedPosition.rows;
        detail.cols = parsedPosition.cols;
        detail.width = parsedPosition.width;
        detail.height = parsedPosition.height;
      } catch (e) {
      }
    }

    return detail;
  }

  async insertOrUpdate(bodyData: any, isUpdate: boolean) {
    let parameter = [
      isUpdate
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.INSERTORUPDATE;
    let resData = await this.post(apiUrl, parameter, bodyData);
    return resData;
  }

  async delete(ids: any) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DELETE;
    let resData = await this.post(apiUrl, [], ids);
    return resData;
  }

  async getListDashboardSetting(dashboardCd: string = '') {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTING;
    let resData = await this.get(apiUrl, parameter);
    return resData;
  }

  async getPublic(isAdminOrSupporter?: boolean) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.PUBLIC;
    if(isAdminOrSupporter)
      apiUrl += "/true";
    else
      apiUrl += "/false";
    const resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getDetail(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DETAIL;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getSettingDetail(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGDETAIL;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getSettingWidgetDetail(dashboardCd: string, isTemplate:boolean = false) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGWIDGETDETAIL;
    if(isTemplate) apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGWIDGETDETAILTEMPLATE;

    let resData = await this.get(apiUrl, parameter);
    if (resData.statuscode === 200) {
      let dashboard = this.mapModelDetail(resData.data);
      return dashboard
    }
    return {};
  }
  mapModelWidgetDetail(data: any) {
    const res: ResponseData<Widget[]> = new ResponseData<Widget[]>();
    const dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for (const element of data.data || []) {
        const widget = new Widget();
        widget.id = element.widgetid;
        widget.widgetCd = element.widgetcd;
        widget.name = element.widgetname;
        widget.chartType = element.charttype;
        widget.description = element.widgetdesc;
        widget.folderCd = element.foldercd;
        widget.delflg = element.delflg;
        widget.dataTableConfig = [];
        widget.pivotTableConfig = new PivotTableConfig();
        widget.insdate = element.insdate || new Date(element.insdate);
        widget.upddate = element.insdate || new Date(element.upddate);
        widget.insstfcd = element.insstfcd;
        widget.updstfcd = element.updstfcd;
        widget.insstfnm = element.insstfnm;
        widget.updstfnm = element.updstfnm;
        widget.sortcoltype = element.sortcoltype;
        dataArr.push(widget);
      }
    }
    res.data = dataArr;
    return res;
  }
  mapModelDetail(data: any) {
    if(!data) return [];
    const dataArr: Dashboard[] = [];
      for (const element of data) {
        const db = dataArr.find(d => d.id === element.id);
        if (db && element.widgetposition) {
          const detail = this.mapDashboardSetting(element);
          db.setting.push(detail);
          continue;
        }

        const dash = new Dashboard();
        dash.id = element.id;
        dash.name = element.dashboardname;
        dash.dashboardCd = element.dashboardcd;
        dash.description = element.dashboarddesc;
        dash.folderCd = element.foldercd;
        dash.folderName = element.foldername;
        dash.insdate = element.insdate;
        dash.insstfcd = element.insstfcd;
        dash.insstfnm = element.insstfnm;
        dash.upddate = element.upddate;
        dash.updstfnm = element.updstfnm;
        dash.publicCd = element.publiccd;
        dash.publicDate = element.publicdate;
        dash.publictype = element.publictype;
        dash.sortcoltype = element.sortcoltype;
        dash.setting = [];
        if (element.widgetposition) {
          const detail = this.mapDashboardSetting(element);

          dash.setting.push(detail);
        }

        if (element.updstfcd) {
          dash.displayNm = element.updstfnm;
          dash.displayDate = (moment(element.upddate)).format(DateFormat.FULL_SHORT_DATE);
        } else {
          dash.displayNm = element.insstfnm;
          dash.displayDate = (moment(element.insdate)).format(DateFormat.FULL_SHORT_DATE)
        }
        dataArr.push(dash);
      }

    return dataArr;
  }


  mapWidgetModel(data: any) {
    if(!data) return [];
    let dataArr = [];
      for  (const element of data) {
        let widget = new Widget();
        widget.id = element.id;
        widget.widgetCd = element.widgetcd;
        widget.name = element.widgetname;
        widget.description = element.widgetdesc;
        widget.chartType = element.charttype;
        widget.publicDate = element.publicdate;
        dataArr.push(widget);
      
      }
    return dataArr;
  }

  async getWidgetList(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.GETWIDGETLIST;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      return this.mapWidgetModel(resData.data);
    }
    else return [];
  }

  async insertOrUpdateDbDetail(bodyData: any) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.INSERTORUPDATEDETAIL;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  async deleteDashboardAndDetail(bodyData: any) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DELETEDASHBOARDANDDETAIL;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  mapDashboardModel(data: any) {
    let dash = new DashboardModel();
    dash.upddate = data?.upddate;
    dash.updstfcd = data?.updstfcd;
    dash.insdate = data?.insdate;
    dash.insstfcd = data?.insstfcd;
    dash.publiccd = data?.publicCd;
    dash.id = data?.id;
    dash.dashboardname = data?.name;
    dash.dashboarddesc = data?.description;
    dash.dashboardcd = data?.dashboardCd;
    dash.delflg = data?.delflg;
    dash.foldercd = data?.folderCd;
    dash.publictype = data?.publictype;
    return dash;
  }


  async getAllDashboardTemplate(isCheckingPublic: boolean) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl, [isCheckingPublic]);
    return resData;
  }

  async getWidgetTemplatesByDashboard(dashboardCd: string) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + "/" + dashboardCd + API_APP.DASHBOARD.GETWIDGETLIST + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return this.mapWidgetModel(resData.data);
    }
    return [];
  }

  async getSettingDetailTemplate(dashboardCd: string) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER+ "/" + dashboardCd  + API_APP.DASHBOARD.SETTINGDETAIL + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl);
    if (resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return []; 
  }
}
