import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, isDate } from 'lodash';
import { BUTTON, COMMON_TEXT } from '../../../app/const/text-common';
import { FilterDateCurrent, FilterValueOption, ItemNoSelect, MstFilterType, MstFilterGroup, MstFilterIntItem, NoFilter, UnChooseAble } from '../../../app/const/const';
import { ButtonType, InputType, PrimengDateFormat, SearchType, DateFormat } from '../../../app/enum/common-enum';
import { item, ModalTemplate, SearchParams } from '../../../app/models/common-model';
import * as moment from 'moment';
import { CorpMstService } from '../../../app/services/modules/corpmst.service';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { filterByFinancialYearPeriod, getDayOfWeekOrMonthCurrent, getRange } from '../../../app/_helper/helper';
import { MstCommonService } from 'src/app/services/modules/mstcommon.service';

@Component({
  selector: 'pivot-dialog-filter-condition',
  templateUrl: './dialog-filter-condition.component.html',
  styleUrls: ['./dialog-filter-condition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFilterConditionComponent implements OnInit, OnChanges {
  @Input() dataType: any = null;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() value: { filtertype: string, filtervalue: any }
  @Input() options: item[] = []
  @Input() sourceTable: any | undefined;
  @Input() periodSelected: any | undefined;
  @Input() periodList: any [] = [];
  @Input() isHaveMaxMinFilter: boolean = false;
  @Output() onSubmitData: any = new EventEmitter<any>();

  buttonType = ButtonType;
  inputValue: any = '';
  selected: item;
  dateFormat: string = PrimengDateFormat.FULL_SHORT_DATE;
  isDisableDate: boolean = true;
  isRequireInput: boolean = false;
  searchParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    comboDisplayText: 'name'
  };
  inputParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 100
  };
  inputParamsNumber: any = {
    type: InputType.number,
    validate: false,
    borderFill: true,
    maxLength: 50
  };
  MstFilterType = MstFilterType
  MstFilterGroup = MstFilterGroup;
  NoFilter = NoFilter
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  currentSelectedRange: string;   // For date type (003)
  unChooseable = UnChooseAble;
  dropdownOptions: any = [];
  selectedItemInOptions: any = [];
  isNumberType: boolean = false;
  selectedRangeDate: any = {};
  moneyMonth: number = 1;
  isloadFilter: boolean = false;
  datasourcePeriod: any = null;
  periodArr: any[] = FilterValueOption.map(s => s.value);
  constructor(private corpMstService: CorpMstService,
              private mstService: MstCommonService,
              private loadingService: ProcessLoadingService) { }

  async ngOnInit() {
    this.isloadFilter = false;
    this.modalData.header = COMMON_TEXT.FILTER_CONDITION;
    this.options = this.options.filter(x => x.type === this.dataType)
    if(this.dataType.toUpperCase() === "TOTALDAYS") {
      this.options[0].items.forEach((obj: any) => {
        this.dropdownOptions.push({ name: obj.displayname, code: obj.columnname, checked: false });
      })
    };
    this.options.unshift(ItemNoSelect);
    this.searchParams.dataSource = this.options;
    if (!this.value || !this.value.filtertype) {
      this.selected = this.options[0];
      this.value = {
        filtertype: [this.options[0].type, this.options[0].value].join('-'),
        filtervalue: null,
      }
    }
    else {
      this.selected = {
        value: this.value.filtertype,
        type: this.value.filtertype.split('-')[0]
      };
      this.selected.type = this.dataType;
      this.selectedItemInOptions = this.value.filtervalue;
    }

    this.searchParams.selected = this.selected.value;
    this.searchParams = cloneDeep(this.searchParams)
    if (this.selected.type === MstFilterGroup.DATATIME) {
      if(this.periodArr.includes(this.value.filtertype)) this.currentSelectedRange = this.value.filtervalue;
      else this.currentSelectedRange = getRange(this.value.filtertype.split('-')[1], this.periodSelected, this.periodList)
    }
    const valueInit = this.selected.value.split('-').pop()!
    if(this.selected?.type == MstFilterGroup.INT && ((valueInit == MstFilterIntItem.Max) || (valueInit == MstFilterIntItem.Min))) {
      this.inputParamsNumber.disabled = true;
    }
  }

  ngOnChanges() {
    if (this.value) {
      if (this.value.filtertype) {
        this.selected = { type: this.value.filtertype.split('-')[0], value: this.value.filtertype }
        this.searchParams.selected = this.value.filtertype;
        this.searchParams = cloneDeep(this.searchParams)
      } else {
        this.selected = this.options[0]
      }
      this.setIsNumberType(this.value.filtertype);
    }
    const value = this.selected?.value?.split('-').pop()!;
    this.isDisableDate = this.selected?.type == MstFilterGroup.DATATIME && this.unChooseable.includes(value);

    // PIVOT-3572 | Filter Max Min.
    const valueTypeMax = `${MstFilterGroup.INT}-${MstFilterIntItem.Max}`;
    const valueTypeMin = `${MstFilterGroup.INT}-${MstFilterIntItem.Min}`;

    if (!this.sourceTable) {
      this.options = this.options.filter(x => x.value !== valueTypeMax && x.value !== valueTypeMin);
    } 

    let oldFilterValue = this.value?.filtertype;
    let newFilterValue = this.selected?.value;
    let isSelectedOldValue = false;

    if (oldFilterValue == newFilterValue) {
      isSelectedOldValue = true;
    }

    this.options.map((x : any) => {
      x.disabled = false;
      // Disable filter max min, chỉ được chọn filter max min cho 1 cột duy nhất.
      if ((x.value === valueTypeMax || x.value == valueTypeMin)) {
        if (isSelectedOldValue && this.isHaveMaxMinFilter && (newFilterValue !== valueTypeMax && newFilterValue !== valueTypeMin)) {
          x.disabled = true;
        } else if (!oldFilterValue && this.isHaveMaxMinFilter) {
          x.disabled = true;
        }
      }
      return x;
    });  
  }

  setIsNumberType(value: any) {
    let filterValue = value?.split('-') || 0;
    if(filterValue.length > 1) {
      let filterType = value.split('-')[0];
      let filterCondition = value.split('-')[1];
      if(filterType == MstFilterGroup.INT) {
        let listCondition: string[] = [
          MstFilterIntItem.Contains,
          MstFilterIntItem.DoesNotContain,
          MstFilterIntItem.DoesNotEqual,
          MstFilterIntItem.Equal
        ];
        this.isNumberType = listCondition.filter((c: any) => c == filterCondition)?.length > 0 ? false : true;
      }
      else this.isNumberType = false;
    }
  }

  async onFilterCondition(data: item[]) {
    this.selected = data[0];
    const value = this.selected.value.split('-').pop()!
    this.isDisableDate = this.selected?.type == MstFilterGroup.DATATIME && this.unChooseable.includes(value)
    this.currentSelectedRange = getRange(value, this.periodSelected, this.periodList)
    this.setIsNumberType(this.selected?.value);
    if(this.isNumberType || this.selected?.type == MstFilterGroup.DATATIME) this.value.filtervalue = null;
    // "007" : 最大値 giá trị lớn nhất Max value.
    // "008" : 最小値 giá trị nhỏ nhất Min value.
    if(this.selected?.type == MstFilterGroup.INT && ((value == MstFilterIntItem.Max) || (value == MstFilterIntItem.Min))) {
      // Disable input khi chọn max hoặc min value.
      this.inputParamsNumber.disabled = true;
    } else {
      this.inputParamsNumber.disabled = false;
    }
  }

  onInputData(data: any) {
    this.value.filtervalue = data.currentTarget.value;
  }

  onInputDataDate(data: any) {
    this.isRequireInput = false;
    this.value.filtervalue = data;
  }

  onSubmiDataCondition(isSubmit: boolean) {
    if (isSubmit) {
      if( this.selected.value === "totaldays") {
        this.value.filtervalue = this.selectedItemInOptions;
      }
      if (this.selected.type === MstFilterGroup.DATATIME && (this.periodArr.includes(this.selected.value) || FilterDateCurrent?.map(s => s.value).includes(this.selected.value))) {
        this.value.filtervalue = this.currentSelectedRange;
      }
      this.value.filtertype = this.selected.value
      this.onSubmitData.emit(this.value)
    } else {
      this.onSubmitData.emit(null)
    }
  }

  onFocusTextBox() {
    this.isRequireInput = false;
  }

  clearFilter() {
    this.value.filtertype = 'no-filter'
    this.value.filtervalue = null
    this.ngOnChanges()
  }
}
