<pivot-modal-template *ngIf="isDisplayModal" [modalTemplate]="modalData" (closeModalHandler)="onClose()">
    <div modal-body>
        <div class="add-dashboard-content">
            <div class="dashboard-name-grp">
                <label>{{COMMON_TEXT.NAME}} <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                <pivot-textbox [inputParams]="inputDbNameParams" (onInput)="onInputDashboardName($event)"
                    (onFocusOut)="inputNameFocusOut()" [inputValue]="newDashboard.name"></pivot-textbox>
            </div>
            <div class="margin-grp">
                <label>{{COMMON_TEXT.DESCRIPTION}}</label>
                <pivot-textbox [inputParams]="inputPlanParams" (onInput)="onInputExplanation($event)"
                    [inputValue]="newDashboard.description"></pivot-textbox>
            </div>
            <div class="margin-grp">
                <div class="grp-name">
                    <label>{{COMMON_TEXT.FOLDER}} <span class="input-required" >{{COMMON_TEXT.ASTERISK}}</span></label>
                    <div class="folder-name">
                        <pivot-textbox [inputParams]="inputFolderParams" [inputValue]="newDashboard.folderName">
                        </pivot-textbox>
                        <div class="add-dashboard-btn">
                            <pivot-text-button class="btn-add-dashboard" [buttonType]="buttonType.SECONDARY"
                                (btnClick)="onShowFolderModal()" [buttonText]="BUTTON.FOLDER_SELECT">
                            </pivot-text-button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isShowAssign">
                <div class="margin-grp">
                  <div class="grp-name-lable">
                    <label>{{COMMON_TEXT.PUBLIC_TO}}   <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                    <div class="helpcontent" *ngIf="isDisplaySystemSetting">
                      <div class="help" (mouseover)="onTargetMouseover()"  (mouseleave)="onTargetMouseout()">?</div>
                      <div class="help-panel" *ngIf="showPanel"  (mouseenter)="onPanleMousenter()"  (mouseleave)="onPanleMouseout()">
                        <span class="ds-suggest-header"> グループ作成は <a (click)="gotoStaffInfo()">こちら</a></span> 
                      </div>
                    </div>
                  </div>
                 
                    <pivot-search-bar [searchParams]="assignTypeParams" (onFilterData)="onFilterAssignType($event)">
                    </pivot-search-bar>
                </div>
                <div class="margin-grp">
                    <label>{{COMMON_TEXT.NAME}}   <span *ngIf="!disableInputName" class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                    <pivot-auto-complete [selectedData]="assignModal" [data]="listObjectAssign" [disabled]="disableInputName"
                        [field]="objectAssignDisplay" (onUnSelectData)="onRemoveFeatureData($event)"
                        [inputParams]="autoCompleteParams"
                        (onChangeData)="onChangeFeatures($event)" [excludeSearchData]="excludeSearchData">
                    </pivot-auto-complete>
                </div>
            </ng-container>
        </div>
    </div>
    <div modal-bottom class="add-dashboard-content">
        <div class="btn-grp">
            <pivot-text-button class="btn-add-dashboard" (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button class="btn-add-dashboard" (btnClick)="onSubmitDashboardData()"
                [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CREATE">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>

<pivot-folder-modal *ngIf="isDisplayFolderModal" [selectedFolder]="folderSelected" [folderList]="folders" 
    [folderType]="folderType.Dashboard"
    (closeModalHandler)="onCloseFolderModal()" 
    (selectedFolderHandler)="onChangeFolder($event)" 
    (onChangeFolderHandler)="onChangeFolderData($event)">
</pivot-folder-modal>