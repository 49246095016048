<ng-container *ngIf="isLoggedIn && isChooseCorpScreen">
  <router-outlet></router-outlet>
</ng-container>

<img style="position: absolute; z-index: -9999999999; width: 0px; height: 0px;" src="../assets/icons/exclamation-mark-blue.svg">
<ng-container *ngIf="isLoggedIn && !isShowErrorPage && !isChooseCorpScreen">
  <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded, 'sidebar-hidden':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}">
    <pivot-page-outlet [isLoggedIn]="isLoggedIn" [sidebarExpanded]="sidebarExpanded"
      (toggleSidebar)="handleToggleSidebar($event)">
    </pivot-page-outlet>
  </div>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</ng-container>

<pivot-loading></pivot-loading>
<pivot-error-details-dialog></pivot-error-details-dialog>
<pivot-notification></pivot-notification>