import { FOLDER_TYPE } from './../../models/response/folder.ro';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, uniqBy } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { StaffService } from '../../../app/services/modules/staff.service';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { BUTTON, COMMON_TEXT, DASHBOARD_LIST } from '../../../app/const/text-common';
import { AssignFolderType, ButtonType, DialogType, InputType, ScreenMode, SearchType } from '../../../app/enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../app/models/common-model';
import { DashboardList } from '../../../app/models/response/dashboard.ro';
import { Folder } from '../../../app/models/response/folder.ro';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { DashboardService } from '../../../app/services/modules/dashboard.service';
import Utils from '../../../app/util/utils';
import { LocalStorageHelper, LocalStorageKey } from '../../../app/_helper/local-storage.helper';
import { TeamService } from '../../../app/services/modules/team.service';
import { ConfirmDialogComponent } from '../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { FeatureService } from '../../../app/services/modules/feature.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DASHBOARD_CD_NEW, SideMenu, SystemSettingPage, TEAM_EVERYONE } from 'src/app/const/const';

@Component({
  selector: 'pivot-dialog-dashboard-editor',
  templateUrl: './dialog-dashboard-editor.component.html',
  styleUrls: ['./dialog-dashboard-editor.component.scss']
})
export class DialogDashboardEditorComponent implements OnInit, OnChanges {

  // * variables
  @Input() isShowAssign: boolean = true;
  // folder List
  @Input() folders: Folder[] = [];
  // is update dashboard
  @Input() isUpdate: boolean = false;
  @Input() isDuplicate: boolean = false;
  // folder selected
  @Input() folderSelected: any = null;
  // display modal 
  @Input() isDisplayModal: boolean = false;
  // modal data
  @Input() modalData: ModalTemplate = new ModalTemplate();
  // submit data event
  @Output() onSubmitData: any = new EventEmitter<any>();
  // edit dashboard data
  @Input() data: DashboardList;
  // new dashboard variables
  newDashboard: DashboardList = new DashboardList();
  // field name staff
  fieldNameStaff: string = 'fullName';
  // field name team
  fieldNameTeam: string = 'teamName';
  // list public data
  features: any[] = [];
  // list AssignName
  listObjectAssign: any[] = [];
  // list public dashboard
  publicDasboards: any = null;
  objectAssignDisplay: string = '';
  //
  isPublic: boolean = false;
  // current mode
  currentType: any = null;
  // button type
  buttonType = ButtonType;
  folderType = FOLDER_TYPE;
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  // input params
  inputDbNameParams: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 100
  };

  inputFolderParams: any = {
    type: InputType.text,
    disabled: true,
    readonly: false,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
  };
  autoCompleteParams: any = {
    validate: false,
    isError: false,
    validateError: MESSAGE_TEXT.REQUIRE_FIELD,
    borderFill: true,
  };
  inputPlanParams: any = {
    type: InputType.text,
    borderFill: true,
    maxLength: 200
  };

  // show folder modal
  isDisplayFolderModal: boolean = false;
  mode: ScreenMode;
  _dashboardService: DashboardService;

  assignTypeParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: COMMON_TEXT.USER,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    dataSource: [],
    comboDisplayText: 'name'
  };
  disableInputName: boolean = true;
  assignModal: any[] = [];
  listAssignType: any[] = [];
  staffs: any[] = [];
  teams: any[] = [];
  assignType: AssignFolderType = AssignFolderType.STAFF;
  lstAssignRemove: any[] = [];
  lstAssignInsert: any[] = [];
  itemSelecteds: any[] = [];
  staffcd: string = '';
  listAssigns: any[] = [];
  loggedInUser: any;
  excludeSearchData: any[] = [];
  isDashboardTemplate: boolean;
  isDisplaySystemSetting = false;
  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private processLoadingService: ProcessLoadingService,
    private staffService: StaffService,
    private teamService: TeamService,
    private modalService: DialogService,
    private featureService: FeatureService,
    private authenService: AuthenticationService
  ) {
    this._dashboardService = dashboardService;
    this.isDashboardTemplate = this.router.url.includes("dashboard-template") ? true : false;
    this.authenService.currentUser.subscribe((user: any) => {
      if (!user || !user.token) {
        return;
      }
      const roles = this.authenService.getData(LocalStorageKey.CURRENT_USER_ROLE);
      if (roles && roles.length > 0 && roles[0].permissions) {
        const item = roles[0].permissions.find((s: any) => s == SideMenu.SYSTEM_SETTING);
        if (item) this.isDisplaySystemSetting = true;
      } else {

      }
    });
  }

  // on change data
  async ngOnChanges() {
    this.autoCompleteParams.isError = false;
    this.assignType = AssignFolderType.ONLY_ME;
    this.setAssignTypeParams(COMMON_TEXT.ONLY_ME);
    this.currentType = AssignFolderType.ONLY_ME;
    if (!Utils.isNullOrEmpty(this.data)) {
      this.folders = this.folders.filter(s => s.act != 1);
      this.modalData.header = DASHBOARD_LIST.DASHBOARD_DLG_EDIT;
      this.inputDbNameParams.isValidate = false;
      this.inputFolderParams.isValidate = false;
      this.newDashboard = this.data;
      this.mode = ScreenMode.EDIT;
      this.features = await this.getListPublicData(this.data?.publicCd);
      await this.getListAssign();
    }
    else {
      this.assignModal = [];
      this.modalData.header = DASHBOARD_LIST.DASHBOARD_DLG_CREATE;
      this.newDashboard = new DashboardList();
      this.mode = ScreenMode.ADD;
      this.assignType = AssignFolderType.ONLY_ME;
    }
    this.setPublicStatus();
    if (this.folderSelected != null) {
      this.newDashboard.folderId = this.folderSelected.id;
      this.newDashboard.folderCd = this.folderSelected.folderCd;
      this.newDashboard.folderName = this.folderSelected.name;
    }

    if (this.currentType == AssignFolderType.STAFF) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.STAFF;
      this.disableInputName = false;
    }
    else if (this.currentType == AssignFolderType.GROUP) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.GROUP;
      this.disableInputName = false;
    }
    else if (this.currentType == AssignFolderType.EVERYONE) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.EVERYONE;
      this.disableInputName = true;
    }
    else if (this.currentType == AssignFolderType.ONLY_ME) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
      this.disableInputName = true;
    }
    else {
      this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
    }
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
  }

  setPublicStatus() {
    if (this.lstAssignInsert?.length > 0 || this.assignModal?.length > 0) {
      this.isPublic = false;
    }
    else {
      this.isPublic = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.setAssignTypeParams(COMMON_TEXT.ONLY_ME);
    this.loggedInUser = this.authenService.getData(LocalStorageKey.CURRENT_USER);
    await this.getListStaff();
    await this.getListTeam();
    this.setAccessListOption();
  }

  async getListPublicData(publicCd: any = '') {
    let listPublic: any[] = [];
    if (!Utils.isNullOrEmpty(publicCd)) {
      await this.featureService.getByType(publicCd).then(p => {
        if (p.statuscode == 200) {
          listPublic = p.data || [];
        }
      });
    }
    return listPublic;
  }

  async getListStaff() {
    await this.staffService.getAll(this.loggedInUser.issupport).then((s: any) => {
      if (s.statuscode == 200) {
        this.staffs = s.data || [];
        this.staffs = this.staffs.filter((value, index, self) =>
          index === self.findIndex((t) => (t.staffCd === value.staffCd))
        );
        this.staffs = this.staffs.filter( e => !((e.isSupport && e.roleCd == "RL00000099") || e.roleCd == "RL00000001"));
      }
      else {
        //DO NOTHING
      }
    });
  }

  async getListTeam() {
    await this.teamService.getAll().then((t: any) => {
      if (t.statuscode == 200) {
        this.teams = t.data || [];
        this.teams = this.teams.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.teamCd === value.teamCd
          ))
        );
      }
      else {
        //DO NOTHING
      }
    });
  }

  // close dialog
  onClose() {
    this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
    this.assignType = AssignFolderType.ONLY_ME;
    this.disableInputName = true;
    this.clearActionParam();
    this.onSubmitData.emit(null);
  }

  clearActionParam() {
    this.isDisplayModal = false;
    this.assignTypeParams.defaultValue = COMMON_TEXT.STAFF;
    this.assignTypeParams.defaultValue = cloneDeep(this.assignTypeParams.defaultValue);
    this.setAccessListOption();
    this.assignType = AssignFolderType.ONLY_ME;
    this.currentType = AssignFolderType.ONLY_ME;
    this.features = [];
  }

  // on submit data event
  async onSubmitDashboardData() {
    if(!this.validateData()) return;
    this.inputDbNameParams.inputValue = '';
    this.isDisplayModal = false;
    let isSaveSuccess: any = true;
    // set Feature value
    if (this.isUpdate) {
      let reqData = [];
      this.processLoadingService.isLoading.emit(true);
      let dash = this.dashboardService.mapDashboardModel(this.newDashboard);
      dash.dashboardcd = this.data.dashboardCd;
      if (this.assignModal?.length == 0) {
        dash.publiccd = "";
      }

      this.setFeatureValue(this.data.dashboardCd || '');
      reqData.push(dash);
      isSaveSuccess = await this.dashboardService.insertOrUpdate(reqData, true);
      if (this.publicDasboards != null && (this.publicDasboards?.listInsert?.length > 0 || this.publicDasboards?.listRemove?.length)) {
        await this.featureService.insertOrUpdate(this.publicDasboards).then(request => {
          if (request.statuscode == 200) {
            this.clearData();
          }
        });
      }
      this.processLoadingService.isLoading.emit(false);
    }
    else {
      this.newDashboard.dashboardCd = DASHBOARD_CD_NEW;
      this.setFeatureValue(this.newDashboard.dashboardCd);
      this.dashboardService.setListNewItem([]);
      this.dashboardService.setListPublicData([]);
      this.dashboardService.setDashboardItem(1, this.newDashboard);
      if (!this.isDuplicate) {
        if (this.isDashboardTemplate) {
           this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE)]);
           setTimeout(() => {
            this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) + '/' + this.newDashboard.dashboardCd + '/' + ScreenMode.ADD]);
           }, 10);  
        } else {
          this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_CREATE) + '/' + this.newDashboard.dashboardCd + '/' + ScreenMode.ADD]);
        }
      }
    }
    this.clearActionParam();
    if(isSaveSuccess == false || (isSaveSuccess.statuscode && isSaveSuccess.statuscode != 200)) {
      this.onSubmitData.emit(false);
    }
    else
      this.onSubmitData.emit(this.newDashboard);
  }

  clearData() {
    this.lstAssignInsert = [];
    this.lstAssignRemove = [];
  }

  setFeatureValue(dashboardCd: string) {
    this.mapIdForListRemove();
    this.setPublicStatus();
    let listStaffCd = this.staffs.map(x=>x.staffCd);
    let assigns = this.features.filter(x=>x.staffcd  && x.staffcd !=''  && x.staffcd && !listStaffCd.includes(x.staffcd));
    let dataParam: any = {
      listInsert: this.lstAssignInsert,
      listRemove: this.lstAssignRemove,
      listFeaturesAdmin: assigns,
      dashboardcd: dashboardCd || '',
      ispublic: this.isPublic
    }
    this.publicDasboards = cloneDeep(dataParam);
    this.dashboardService.setFeaturePublicChange(dataParam);
  }

  // get list assign
  async getListAssign() {
    this.listAssigns = [];
    this.assignModal = [];
    // set list assign backup
    if (this.features?.length > 0) {
      this.features.forEach((ass: any) => {
        let feaIndex = this.listAssigns?.findIndex(s => s.id == ass.id);
        if (feaIndex == -1) {
          this.listAssigns.push(ass);
        }
      });
    }
    this.listAssigns.forEach((ass: any) => {
      let staffcd = ass.staffcd == undefined ? ass.staffCd == undefined ? '' : ass.staffCd : ass.staffcd;
      let teamcd = ass.teamcd == undefined ? ass.teamCd == undefined ? '' : ass.teamCd : ass.teamcd;
      if (!Utils.isNullOrEmpty(staffcd)) {
        this.assignType = AssignFolderType.STAFF;
        this.objectAssignDisplay = this.fieldNameStaff;
        this.excludeSearchData = [{ [this.objectAssignDisplay]: this.loggedInUser?.fullname }];

        this.listObjectAssign = this.listAssignType[0]?.data;
        let findStaff = this.staffs.find((s: any) => s.staffCd === staffcd);
        if (!Utils.isNullOrEmpty(findStaff)) {
          this.assignModal.push(findStaff);
        }
      }
      else if (!Utils.isNullOrEmpty(teamcd)&& teamcd != TEAM_EVERYONE) {
        this.assignType = AssignFolderType.GROUP;
        let findTeam = this.teams.find((s: any) => s.teamCd === teamcd);
        if (!Utils.isNullOrEmpty(findTeam)) {
          this.objectAssignDisplay = this.fieldNameTeam;
          this.excludeSearchData = [];

          this.listObjectAssign = this.listAssignType[1]?.data;
          this.assignModal.push(findTeam);
        }

      }
      else if (!Utils.isNullOrEmpty(teamcd) && teamcd == TEAM_EVERYONE) {
        this.excludeSearchData = [];
        this.assignType = AssignFolderType.EVERYONE;
      }
    });
    this.assignModal = uniqBy(this.assignModal, 'id');
    this.itemSelecteds = this.assignModal;
    this.assignType = this.data.publictype ? this.data.publictype : AssignFolderType.ONLY_ME;
    if (this.assignType == AssignFolderType.STAFF) {
      this.setAssignTypeParams(COMMON_TEXT.STAFF);
      this.currentType = AssignFolderType.STAFF;
      this.disableInputName = false;
    } else if (this.assignType == AssignFolderType.GROUP) {
      this.setAssignTypeParams(COMMON_TEXT.GROUP);
      this.currentType = AssignFolderType.GROUP;
      this.disableInputName = false;
    } else if (this.assignType == AssignFolderType.EVERYONE) {
      this.setAssignTypeParams(COMMON_TEXT.EVERYONE);
      this.currentType = AssignFolderType.EVERYONE;
      this.assignModal = [];
      this.disableInputName = true;
    }
  }


  // input dashboard name
  onInputDashboardName(data: any) {
    this.newDashboard.name = data.currentTarget?.value;
  }

  // input Explanation data
  onInputExplanation(data: any) {
    this.newDashboard.description = data.currentTarget?.value;
  }

  // set folder data for dashboard
  onShowFolderModal() {
    this.isDisplayFolderModal = true;
  }

  // event change folder data
  async onChangeFolder(folder: any) {
    if(folder) {
      this.newDashboard.folderName = folder.name;
      this.newDashboard.folderId = folder.id;
      this.newDashboard.folderCd = folder.folderCd;
      this.inputFolderParams.isValidate = false;
    }
  }

  // close modal folder
  onCloseFolderModal() {
    this.clearData();
    this.isDisplayFolderModal = false;
  }

  // input dashboard name focus out
  inputNameFocusOut() {
    this.inputDbNameParams.validate = false;
  }

  setAssignTypeParams(defaultValue: string) {
    this.assignTypeParams.defaultValue = defaultValue;
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
    if (this.features?.length == 0) {
      this.setAccessListOption();
    }
  }

  onChangeFolderData(data: any[]) {
    this.folders = data;
  }

  setAccessListOption() {
    this.listAssignType = [
      {
        name: COMMON_TEXT.ONLY_ME,
        value: AssignFolderType.ONLY_ME,
        data: this.staffs,
        field: this.fieldNameStaff,
      },
      {
        name: COMMON_TEXT.STAFF,
        value: AssignFolderType.STAFF,
        data: this.staffs,
        field: this.fieldNameStaff,
      },
      {
        name: COMMON_TEXT.GROUP,
        value: AssignFolderType.GROUP,
        data: this.teams,
        field: this.fieldNameTeam,
      },
      {
        name: COMMON_TEXT.EVERYONE,
        value: AssignFolderType.EVERYONE,
        data:  this.teams,
        field: this.fieldNameTeam,
      },
    ];
    this.assignTypeParams.dataSource = this.listAssignType;
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
    let index = 0;
    if(this.assignTypeParams.dataSource){
      let itemDefault = {}
      itemDefault = this.assignTypeParams.dataSource.find((x:any)=>{
        return (x.value == this.assignTypeParams.defaultValue)
      })
      index = this.assignTypeParams.dataSource.indexOf(itemDefault);
    }else{
      this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
       index = 0;
    }
    ///??????
    this.currentType = COMMON_TEXT.STAFF ? AssignFolderType.STAFF : AssignFolderType.GROUP;
    this.listObjectAssign = this.listAssignType[index]?.data;
    this.objectAssignDisplay = this.listAssignType[index]?.field;

    if (this.assignTypeParams.defaultValue == COMMON_TEXT.STAFF) {
      this.excludeSearchData = [{ [this.objectAssignDisplay]: this.loggedInUser?.fullname }];
    } else {
      this.excludeSearchData = [];
    }
  }

  openConfirmDialog(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  onFilterAssignType(data: any) {
    this.onSetListAssignRemove();
    this.autoCompleteParams.isError = false;
    this.assignModal = [];
    if (data[0]?.name == COMMON_TEXT.GROUP) {
      this.assignType = AssignFolderType.GROUP;
      this.excludeSearchData = [];
      this.disableInputName = false;
    }
    else if(data[0]?.name == COMMON_TEXT.STAFF){
      this.assignType = AssignFolderType.STAFF;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser.fullname }];
      this.disableInputName = false;
    }
    else if(data[0]?.name == COMMON_TEXT.ONLY_ME){
      this.assignType = AssignFolderType.ONLY_ME;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser.fullname }];
      this.disableInputName = true;
    }
    else if(data[0]?.name == COMMON_TEXT.EVERYONE){
      this.assignType = AssignFolderType.EVERYONE;
      this.excludeSearchData = [];
      this.disableInputName = true;
    }
    else {
      this.assignType = AssignFolderType.STAFF;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser.fullname }];
    }
    this.currentType = this.assignType;
    this.listObjectAssign = data[0]?.data;
    this.objectAssignDisplay = data[0]?.field;
    this.newDashboard.publictype = this.currentType;
    this.setlistAssignChange();
  }

  onSetListAssignRemove() {
    if (this.itemSelecteds.length > 0) {
      this.itemSelecteds?.forEach((asm: any) => {
        this.lstAssignRemove.push(asm);
      });
    }
  }

  onRemoveFeatureData(faeture: any) {
    this.lstAssignRemove.push(faeture);
  }

  // on change features
  onChangeFeatures(features: any) {
    this.assignModal = features;
    this.itemSelecteds = features;
    this.setlistAssignChange();
    this.setPublicStatus();
    this.autoCompleteParams.isError = false;
  }

  getPublicInfor(feaItem: any) {
    return {
      staffcd: feaItem?.staffcd == undefined ? feaItem?.staffCd == undefined ? '' : feaItem?.staffCd : feaItem?.staffcd,
      teamcd: feaItem.teamcd == undefined ? feaItem.teamCd == undefined ? '' : feaItem.teamCd : feaItem.teamcd
    }
  }
  mapIdForListRemove() {
    if (this.lstAssignRemove.length > 0) {
      this.lstAssignRemove.map((fea => {
        let publicInfor = this.getPublicInfor(fea);
        let findFeature: any = null;
        if (!Utils.isNullOrEmpty(publicInfor.staffcd)) {
          findFeature = this.features?.find(fea => fea.staffcd == publicInfor.staffcd);
        }
        else if (!Utils.isNullOrEmpty(publicInfor.teamcd)) {
          findFeature = this.features?.find(fea => fea.teamcd == publicInfor.teamcd);
        }
        if (!Utils.isNullOrEmpty(findFeature)) {
          fea.id = findFeature?.id;
        }
      }))
    }
  }

  // set list assign insert
  setlistAssignChange() {
    this.lstAssignInsert = [];
    this.lstAssignRemove = [];
    if (this.currentType  == AssignFolderType.EVERYONE) {
      let everyonTeam: any = {
        teamCd: TEAM_EVERYONE,
        teamName: 'EVERYONE'
      }
      this.itemSelecteds = [];
      this.itemSelecteds.push(everyonTeam);
    }
    if (this.currentType == this.assignType) {
      this.features?.forEach((feaItem: any) => {
        let feaIndex = -1;
        let staffcd = feaItem.staffcd == undefined ? feaItem.staffCd == undefined ? '' : feaItem.staffCd : feaItem.staffcd;
        let teamcd = feaItem.teamcd == undefined ? feaItem.teamCd == undefined ? '' : feaItem.teamCd : feaItem.teamcd;
        // check is selected
        if (!Utils.isNullOrEmpty(staffcd)) {
          feaIndex = this.itemSelecteds?.findIndex(fea => staffcd == fea.staffCd);
        }
        else if (!Utils.isNullOrEmpty(teamcd)) {
          feaIndex = this.itemSelecteds?.findIndex(fea => teamcd == fea.teamCd);
        }
        if (feaIndex == -1) {
          // set list remove
          this.lstAssignRemove.push(feaItem);
        }
      });
      // set list insert 
      this.setListNewItem();
    }
    else {
      // set list insert 
      this.setListNewItem();
      // set list Item Remove 
      this.features?.forEach((feaItem: any) => {
        this.lstAssignRemove.push(feaItem);
      });
    }
    this.mapIdForListRemove();
  }

  setListNewItem() {
    // set list insert 
    this.itemSelecteds?.forEach((item: any) => {
      let indexInsert = -1;
      item.featuretype = 1;
      let publicInfor = this.getPublicInfor(item);

      if (!Utils.isNullOrEmpty(publicInfor.staffcd)) {
        indexInsert = this.features?.findIndex(s => s.staffcd == publicInfor.staffcd);
      }
      else if (!Utils.isNullOrEmpty(publicInfor.teamcd)) {
        indexInsert = this.features?.findIndex(s => s.teamcd == publicInfor.teamcd);
      }
      if (indexInsert == -1) {
        this.lstAssignInsert.push(this.mapFeatureModal(item));
      }
    });
  }

  mapFeatureModal(data: any) {
    let publicInfor = this.getPublicInfor(data);
    let featureInsert = {
      featuretype: 1,
      teamcd: this.assignType == AssignFolderType.STAFF ? null : publicInfor.teamcd,
      staffcd: this.assignType == AssignFolderType.STAFF ? publicInfor.staffcd : null,
      insdate: new Date(),
      delflg: false,
    };
    return featureInsert;
  }

  validateData():boolean{
    if (Utils.isNullOrEmpty(this.newDashboard?.name)) {
      this.inputDbNameParams.isValidate = true;
      this.inputDbNameParams.validate = true;
      return false; 
    }
    if (Utils.isNullOrEmpty(this.newDashboard.folderName)) {
      this.inputFolderParams.isValidate = true;
      return false;
    }
    
    if(this.assignType == AssignFolderType.STAFF || this.assignType == AssignFolderType.GROUP){
      if((!this.assignModal) || (this.assignModal.length == 0)) {
        this.autoCompleteParams.isError = true;
        return false;
      }
    }
    return true;
  }
  clearValidate(){
    this.autoCompleteParams.isError = false;
    this.inputDbNameParams.isValidate = false;
  }

  isPanelEnter = false;
  showPanel  = false;
  onTargetMouseover() {
    this.showPanel = true;
  }
  onTargetMouseout() {
    setTimeout(() => {
      if (!this.isPanelEnter) {
        this.showPanel = false;
        this.isPanelEnter = false;
      }
    }, 100);
  }

  onPanleMouseout() {
    if (this.isPanelEnter) {
      this.showPanel = false;
      this.isPanelEnter = false;
    }
  }
  onPanleMousenter(){
    this.isPanelEnter = true;
  }
  gotoStaffInfo(){
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.SYSTEM_SETTING) + '/' + SystemSettingPage.STAFF_INFO]);

  }
}
