import { APP_INITIALIZER, NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CalendarModule } from 'primeng/calendar';
import { DragDropModule } from 'primeng/dragdrop';
import { TagModule } from 'primeng/tag';
import { AppComponent } from './app.component';
import { AddButtonComponent } from './component/common/add-button/add-button.component';
import { AdditionalArrowButtonComponent } from './component/common/additional-arrow-button/additional-arrow-button.component';
import { ConfirmDialogComponent } from './component/common/confirm-dialog/confirm-dialog.component';
import { HeaderComponent } from './component/common/header/header.component';
import { PaginationComponent } from './component/common/pagination/pagination.component';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InputSwitchModule } from 'primeng/inputswitch';

// service
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GridsterModule } from 'angular-gridster2';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SkeletonModule } from 'primeng/skeleton';
import { TreeModule } from 'primeng/tree';
import { AppRoutingModule } from './app-routing.module';
import { CalculatorComponent } from './component/common/calculator/calculator.component';
import { CalendarComponent } from './component/common/calendar/calendar.component';
import { InputFormulaComponent } from './component/common/input-formula/input-formula.component';
import { InputFormulaInlineComponent } from './component/common/input-formula-inline/input-formula-inline.component';
import { BarChartComponent } from './component/common/chart/bar-chart/bar-chart.component';
import { ChartComponent } from './component/common/chart/chart.component';
import { DoughnutChartComponent } from './component/common/chart/doughnut-chart/doughnut-chart.component';
import { GaugeChartComponent } from './component/common/chart/gauge-chart/gauge-chart.component';
import { LineBarCombineChartComponent } from './component/common/chart/line-bar-combine-chart/line-bar-combine-chart.component';
import { LineChartComponent } from './component/common/chart/line-chart/line-chart.component';
import { NumberChartComponent } from './component/common/chart/number-chart/number-chart.component';
import { PieChartComponent } from './component/common/chart/pie-chart/pie-chart.component';
import { CheckboxComponent } from './component/common/checkbox/checkbox.component';
import { DialogModalComponent } from './component/common/dialog-modal/dialog-modal.component';
import { DisplayGraphComponent } from './component/common/display-graph/display-graph.component';
import { MultiplePannelTemplateComponent } from './component/common/layouts/multiple-pannel-template/multiple-pannel-template.component';
import { SinglePannelTemplateComponent } from './component/common/layouts/single-pannel-template/single-pannel-template.component';
import { ListBoxComponent } from './component/common/list-box/list-box.component';
import { ListItemComponent } from './component/common/list-item/list-item.component';
import { MentionListComponent } from './component/common/mention-list/mention-list.component';
import { ModalTemplateComponent } from './component/common/modal-template/modal-template.component';
import { PageOutletComponent } from './component/common/page-outlet/page-outlet.component';
import { RadioButtonComponent } from './component/common/radio-button/radio-button.component';
import { SearchBarModule } from './component/common/search-bar/search-bar-module.module';
import { SidebarComponent } from './component/common/sidebar/sidebar.component';
import { TableComponent } from './component/common/table/table.component';
import { TextboxComponent } from './component/common/textbox/textbox.component';
import { TreeNodeComponent } from './component/common/tree-view/tree-node/tree-node.component';
import { TreeViewComponent } from './component/common/tree-view/tree-view.component';
import { BasicInfoComponent } from './module/basic-info/basic-info.component';
import { DashboardListComponent } from './module/dashboard-list/dashboard-list.component';
import { DashboardSettingComponent } from './module/dashboard/dashboard-setting/dashboard-setting.component';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { DataSourceListComponent } from './module/data-source-list/data-source-list.component';
import { DataSourceSettingComponent } from './module/data-source-setting/data-source-setting.component';
import { OfficeServiceComponent } from './module/data-source-setting/office-service/office-service.component';
import { DialogDashboardEditorComponent } from './module/dialog-dashboard-editor/dialog-dashboard-editor.component';
import { DialogFolderEditorComponent } from './module/dialog-folder-editor/dialog-folder-editor.component';
import { DialogHeaderComponent } from './module/dialog-header/dialog-header.component';
import { DialogRoleEdiorComponent } from './module/dialog-role-editor/dialog-role-editor.component';
import { RoleDetailInfComponent } from './module/role-detail-inf/role-detail-inf.component';
import { RoleSettingComponent } from './module/role-setting/role-setting.component';
import { WidgetListComponent } from './module/widget-list/widget-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { LoadingComponent } from './component/loading/loading.component';
import { FolderModalComponent } from './module/data-source-setting/folder-modal/folder-modal.component';
import { UserDefinedComponent } from './module/data-source-setting/user-defined-column/user-defined-column.component';
import { NotFoundComponent } from './module/error-page/not-found/not-found.component';
import { LoginComponent } from './module/login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { ProcessLoadingService } from './services/loading.service';
import { appInitializer, CompleteRequestsInterceptor, JwtInterceptor, filterInterceptorRequest } from './_helper';
import { DialogDatasourceSelectionComponent } from './module/dialog-datasource-selection/dialog-datasource-selection.component';

import { NotificationComponent } from './component/common/notification/notification.component';
import { ProcessNotificationService } from './services/process-notification.service';
import { WidgetSettingComponent } from './module/widget-setting/widget-setting.component';
import { SidebarExpandHandleService } from './services/sidebar-expand-handle.service';
import { RoleSettingStaffInfoComponent } from './module/role-setting/role-setting-staff-info/role-setting-staff-info.component';
import { DialogTeamCreateComponent } from './module/role-setting/dialog-team-create/dialog-team-create.component';
import { DialogStaffEditComponent } from './module/role-setting/dialog-staff-edit/dialog-staff-edit.component';

import { DefinedColumnDialogComponent } from './module/widget-setting/defined-column-dialog/defined-column-dialog.component';
import { PermissionSettingComponent } from './module/permission-setting/permission-setting.component';
import { ConfirmInviteDialogComponent } from './module/role-setting/confirm-invite-dialog/confirm-invite-dialog.component';
import { FilterModalComponent } from './component/common/filter-modal/filter-modal.component';
import { ApiService } from './services/api.service';
import { DialogFilterConditionComponent } from './module/dialog-filter-condition/dialog-filter-condition.component';
import { ConfirmDeleteDialogComponent } from './component/common/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmUnsavedDataDialogComponent } from './component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { ConfirmDeleteSelectedDialogComponent } from './component/common/confirm-del-selected-dialog/confirm-del-selected-dialog.component';
import { AutoCompleteComponent } from './component/common/auto-complete/auto-complete.component';
import { TableChartComponent } from './component/common/chart/table-chart/table-chart.component';
import { ImageCropperModule } from './component/common/image-cropper/image-cropper.module';
import { RoleService } from './services/modules/role.service';
import { ResetPasswordComponent } from './module/reset-password/reset-password.component';
import { SharedDataService } from './services/share-data.service';
import { HandleSyncDataDataService } from './services/modules/handle-sync-data.service';
import { BasicInfoCommunicatorService } from './services/modules/basic-info-communicator.service';
import { DialogAvatarUploadInformationComponent } from './module/basic-info/dialog-avatar-upload-information/dialog-avatar-upload-information.component';
import { CanDeactivateDashboard } from './services/can-deactivate/can-deactivate-dashboard.service';
import { CanDeactivateWidget } from './services/can-deactivate/can-deactivate-widget.service';
import { CanDeactivateDataSource } from './services/can-deactivate/can-deactivate-datasource.service';
import { RangeDateModalComponent } from './module/data-source-setting/range-date-modal/range-date-modal.component';
import { SyncMasterDataComponent } from './module/role-setting/sync-master-data/sync-master-data.component';
import { DialogSyncInfoComponent } from './module/role-setting/sync-master-data/dialog-sync-info/dialog-sync-info.component';
import { LazyTreeViewComponent } from './module/data-source-setting/lazy-tree-view/lazy-tree-view.component';
import { LazyTreeNodeComponent } from './module/data-source-setting/lazy-tree-view/lazy-tree-node/lazy-tree-node.component';
import { ScrollerModule } from 'primeng/scroller';
import { WidgetDetailComponent } from './module/widget-detail/widget-detail.component';
import { CkReportComponent } from './module/ck_report/ck-report.component';
import { ZaitakuItemComponent } from './module/dashboard/zaitaku-item/zaitaku-item.component';
import { HelpListComponent } from './module/help-list/help-list.component';
import { TabViewModule } from 'primeng/tabview';
import { HttpCancelService } from './services/http-cancel.service';
import { RequestService } from './services/request.service';
import { AUTH_TOKEN_INTERCEPTOR_FILTER } from "./config/auth.config";
import { DialogSelectTitleComponent } from './module/dialog-select-title/dialog-select-title.component';
import { DashboardTemplateSettingComponent } from './module/dashboard-template-setting/dashboard-template-setting.component';
import { CorpSelectionComponent } from './module/login/corp-selection/corp-selection.component';
import { DialogChartComponent } from './component/common/chart/dialog-chart/dialog-chart.component';
import { TableBulkDataComponent } from './component/common/table-bulk-data/table-bulk-data.component';
import { DialogFilterTableComponent } from './module/dialog-filter-table/dialog-filter-table.component';
import { TemplateDatasourceDialogComponent } from './component/common/template-datasource-dialog/template-datasource-dialog.component';
import { DialogSyncInfoDetailComponent } from './module/role-setting/sync-master-data/dialog-sync-info-detail/dialog-sync-info-detail.component';
import { ProcessBarComponent } from './component/common/process-bar/process-bar.component';
import { LockAccountComponent } from './module/lock-account/lock-account.component';
import { MarkdownModule } from 'ngx-markdown';
import {TargetBlankDirective} from './component/common/target-blank/target-blank.directive'
import {MentionDirective} from './component/common/mention/mention.directive'
import {HelpDetailDialogComponent} from './component/common/help-detail-dialog/help-detail-dialog.component'
import { ColorPickerModule } from 'primeng/colorpicker';
import { ComboChartSettingComponent } from './component/common/chart/line-bar-combine-chart/combo-chart-setting/combo-chart-setting.component';
import { TargetSettingFormulaComponent } from './module/widget-setting/target-setting-formula/target-setting-formula.component';
import { CanDeactivateDataSourceTemplate } from './services/can-deactivate/can-deavtivate-template.service';
import { ErrorDetailsDialogComponent } from './component/error-details-dialog/error-details-dialog.component';
import { DialogUpdateComponentComponent } from './module/dashboard/update-widget-dialog/update-widget-dialog-component.component';
import { SelectWidgetComponent } from './module/data-source-setting/select-widget/select-widget.component';
import { StackedBarChartComponent } from './component/common/chart/stacked-bar-chart/stacked-bar-chart.component';
import { IconButtonComponent } from './component/common/icon-button/icon-button.component';
import { DialogQuestionRoleNewValueEnteredComponent } from './module/role-setting/dialog-question-role-new-value-entered/dialog-question-role-new-value-entered.component';
import { DashboardFilterService } from './services/dashboard-filter.service';
import { DialogFilterGroupComponent } from './module/dialog-filter-group/dialog-filter-group.component';
import { ZaitakuSettingDialogComponent } from './module/ck_report/zaitaku-setting-dialog/zaitaku-setting-dialog.component';
import { ZaitakuConditionItemComponent } from './module/ck_report/condition-item/condition-item.component';
import { AutoCompleteItemComponent } from './module/ck_report/auto-complete-item/auto-complete-item.component';
import { ZaitakuTreenodeItemComponent } from './module/ck_report/treenode-item/treenode-item.component';
import { ZaitakuTreeNodeTitlesComponent } from './module/ck_report/treenode-titles/treenode-titles.component';
import { DialogCombineInformationComponent } from './module/dialog-combine-information/dialog-combine-information/dialog-combine-information.component';
import { DialogAddNewStaffToTeamByDragComponent } from './module/role-setting/role-setting-staff-info/dialog-add-new-staff-to-team-by-drag/dialog-add-new-staff-to-team-by-drag.component';
import { SwitchButtonComponent } from './component/common/switch-button/switchbutton/switchbutton.component';
import { SplitterModule } from 'primeng/splitter';
import { SplitterPanelTemplateComponent } from './module/splitter-panel-template/splitter-panel-template.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PaginationComponent,
    AddButtonComponent,
    ConfirmDialogComponent,
    AddButtonComponent,
    PaginationComponent,
    DialogModalComponent,
    TextboxComponent,
    RadioButtonComponent,
    CheckboxComponent,
    DisplayGraphComponent,
    AdditionalArrowButtonComponent,
    SidebarComponent,
    CalculatorComponent,
    AdditionalArrowButtonComponent,
    ModalTemplateComponent,
    SinglePannelTemplateComponent,
    TreeViewComponent,
    TreeNodeComponent,
    MultiplePannelTemplateComponent,
    PageOutletComponent,
    ListBoxComponent,
    ListItemComponent,
    MentionListComponent,
    CalendarComponent,
    InputFormulaComponent,
    InputFormulaInlineComponent,
    TableComponent,
    OfficeServiceComponent,
    TemplateDatasourceDialogComponent,
    DataSourceSettingComponent,
    DataSourceListComponent,
    DialogDashboardEditorComponent,
    DialogFolderEditorComponent,
    ChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    GaugeChartComponent,
    LineBarCombineChartComponent,
    LineChartComponent,
    PieChartComponent,
    WidgetListComponent,
    BasicInfoComponent,
    NumberChartComponent,
    DialogRoleEdiorComponent,
    DashboardComponent,
    DashboardSettingComponent,
    DashboardTemplateSettingComponent,
    DashboardListComponent,
    RoleSettingComponent,
    RoleDetailInfComponent,
    DialogHeaderComponent,
    FolderModalComponent,
    UserDefinedComponent,
    LoginComponent,
    LoadingComponent,
    NotFoundComponent,
    NotificationComponent,
    WidgetSettingComponent,
    DialogDatasourceSelectionComponent,
    DefinedColumnDialogComponent,
    RoleSettingStaffInfoComponent,
    DialogTeamCreateComponent,
    DialogStaffEditComponent,
    PermissionSettingComponent,
    ConfirmInviteDialogComponent,
    FilterModalComponent,
    DialogFilterConditionComponent,
    ConfirmDeleteDialogComponent,
    ConfirmDeleteSelectedDialogComponent,
    ConfirmUnsavedDataDialogComponent,
    DialogChartComponent,
    AutoCompleteComponent,
    TableChartComponent,
    ResetPasswordComponent,
    DialogAvatarUploadInformationComponent,
    RangeDateModalComponent,
    SyncMasterDataComponent,
    DialogSyncInfoComponent,
    DialogSyncInfoDetailComponent,
    LazyTreeViewComponent,
    LazyTreeNodeComponent,
    WidgetDetailComponent,
    CkReportComponent,
    HelpListComponent,
    HelpDetailDialogComponent,
    ZaitakuItemComponent,
    CorpSelectionComponent,
    TableBulkDataComponent,
    DialogSelectTitleComponent,
    DialogFilterTableComponent,
    ProcessBarComponent,
    LockAccountComponent,
    TargetBlankDirective,
    MentionDirective,
    ComboChartSettingComponent,
    TargetSettingFormulaComponent,
    ErrorDetailsDialogComponent,
    DialogUpdateComponentComponent,
    SelectWidgetComponent,
    StackedBarChartComponent,
    IconButtonComponent,
    DialogQuestionRoleNewValueEnteredComponent,
    DialogFilterGroupComponent,
    ZaitakuSettingDialogComponent,
    ZaitakuConditionItemComponent,
    AutoCompleteItemComponent,
    ZaitakuTreenodeItemComponent,
    ZaitakuTreeNodeTitlesComponent,
   

    DialogCombineInformationComponent,
    DialogAddNewStaffToTeamByDragComponent,
    SwitchButtonComponent,
    DialogCombineInformationComponent,
    SplitterPanelTemplateComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DialogModule,
    DynamicDialogModule,
    OverlayPanelModule,
    ListboxModule,
    SearchBarModule,
    DragDropModule,
    TableModule,
    ProgressBarModule,
    CheckboxModule,
    MultiSelectModule,
    OrderListModule,
    HttpClientModule,
    SplitterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CalendarModule,
    DropdownModule,
    TagModule,
    GridsterModule,
    TriStateCheckboxModule,
    AutoCompleteModule,
    ImageCropperModule,
    TooltipModule,
    VirtualScrollerModule,
    SkeletonModule,
    TreeModule,
    ScrollerModule,
    TabViewModule,
    ProgressSpinnerModule,
    PanelMenuModule,
    RadioButtonModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
    }),
    ColorPickerModule,
    AngularSvgIconModule.forRoot(),
    InputSwitchModule
  ],
  providers: [
    RequestService,
    HttpCancelService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [
        AuthenticationService
      ]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CompleteRequestsInterceptor, multi: true },
    { provide: AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest },
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    ProcessNotificationService,
    ProcessLoadingService,
    SidebarExpandHandleService,
    ApiService,
    SharedDataService,
    HandleSyncDataDataService,
    RoleService,
    BasicInfoCommunicatorService,
    CanDeactivateDashboard,
    CanDeactivateWidget,
    CanDeactivateDataSource,
    CanDeactivateDataSourceTemplate,
    DatePipe,
    DashboardFilterService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
