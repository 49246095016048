import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, groupBy } from 'lodash';
import { BUTTON, COMMON_TEXT } from '../../../app/const/text-common';
import { ButtonType, SearchType } from '../../enum/common-enum';
import { ListBoxParams, ModalTemplate, SearchParams, TreeNode, TreeViewInputParams } from '../../models/common-model';
import TreeUtils from '../../util/treeUtils';
import { Subscription } from 'rxjs';
import { NodeTransferringService } from '../../services/node-transferring.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FolderService } from '../../services/modules/folder.service';

@Component({
  selector: 'pivot-dialog-datasource-selection',
  templateUrl: './dialog-datasource-selection.component.html',
  styleUrls: ['./dialog-datasource-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogDatasourceSelectionComponent implements OnInit, OnDestroy {

  @Input() isTemplate: boolean = false;
  @Input() datasourceTreeData: TreeViewInputParams;
  @Output() closeModalHandler = new EventEmitter<any>();

  modalTemplate: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.DS_SEARCH,
    cssStyle: { width: '300px', height: '40px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };
  lbParams: ListBoxParams = {
    items: [],
    displayfilter: false,
    itemDisplayText: 'name',
    cssStyle: {
      width: '300px'
    },
    styleClass: 'list-box',
    disabled: true
  };
  datasourceTreeData2Level: TreeViewInputParams;

  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  nodeListener: Subscription;
  isOnFetchProcess: boolean = false;
  searchText: string;
  isSupporterAdmin: boolean = false;
  constructor(
    private nodeTrans : NodeTransferringService,
    private authenticationService: AuthenticationService
  ) { }
  ngOnDestroy(): void {
    if(this.nodeListener) this.nodeListener.unsubscribe();
  }

  async ngOnInit() {
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    this.nodeListener = this.nodeTrans.loadedNodeData.subscribe((data: TreeNode | null) => {
      if(data) {
        let handleNode = this.datasourceTreeData.nodes.find(node => node.id === data.id);
        if(handleNode) {
          handleNode.isLoaded = data.isLoaded;
          handleNode.nodes = [...data.nodes ?? []];
        }
      }
    });
    this.modalTemplate.header = BUTTON.DATASOURCE_SELECT;

    this.datasourceTreeData2Level = cloneDeep(this.datasourceTreeData);
    this.removeLv3FromTreeNodes(this.datasourceTreeData2Level);
    this.reassignIsLastNode(this.datasourceTreeData2Level);
    this.forEachLv2(this.datasourceTreeData2Level, (node: TreeNode) => {
      if (node.singleSelected) {
        this.handleNodeClick(node);
      }
    })
    this.hiddenNullVal(this.datasourceTreeData2Level);
  }

  hiddenNullVal(datasourceTreeData2Level: TreeViewInputParams) {
    for (const lv1 of datasourceTreeData2Level.nodes) {
      if (!lv1.nodes || lv1.nodes.length === 0) {
        continue;
      }

      for (const lv2 of lv1.nodes) {
        if(lv2.id == "null") {
          lv2.hidden = lv1.nodes.length > 1;
          lv2.isSpin = lv1.nodes.length == 1;
        }
        if(lv2.nodes?.length == 1 && lv2.nodes[0].id == "") {
          lv2.nodes[0].isSpin = true;
        }
      }
    }
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }

  cancel() {
    this.closeModalHandler.emit();
  }

  save() {
    for (const lv1 of this.datasourceTreeData2Level.nodes) {
      // set expand field for level 1 node
      const originLv1 = this.datasourceTreeData.nodes?.find(n => n.id === lv1.id);
      if (originLv1) {
        let isFolderSelected: boolean = !(!lv1.nodes || !lv1.nodes!.some(node => node.singleSelected));
        originLv1.expanded = isFolderSelected;
      }

      if (!lv1.nodes) {
        continue;
      }

      // set selected field for level 2 node
      for (const lv2 of lv1.nodes) {
        const originLv2 = this.findLv2(this.datasourceTreeData, lv2);
        if (originLv2) {
          originLv2.selected = lv2.selected;
          originLv2.singleSelected = lv2.singleSelected;
        }
      }
    }

    this.closeModalHandler.emit(this.datasourceTreeData);
  }

  disableSaveCheck() {
    const isHasSelected = (this.lbParams && this.lbParams.items && this.lbParams.items.some(x => x.value));
    
    return !(!this.isOnFetchProcess && isHasSelected);
  }

  handleNodeFetchProcess(status: boolean) {
    this.isOnFetchProcess = status;
    this.disableSaveCheck();
  }

  isAllFolderLoaded(): boolean {
    return Array.isArray(this.datasourceTreeData2Level?.nodes) &&
      this.datasourceTreeData2Level.nodes.every(node => node?.isLoaded === true);
  }

  async onFilterOutData(searchText: any) {
    if(this.searchText == searchText.trim().toUpperCase()) return;
    this.searchText = searchText.trim().toUpperCase();
    if (!this.searchText) {
      this.resetAllHidden();
      return;
    }

    if(!this.isAllFolderLoaded()) {
      let filterStructs = await this.nodeTrans.getFolderWithKeywork(searchText.trim().toUpperCase(), this.isSupporterAdmin, this.isTemplate);
      let uniqueFolderCds: Set<string> = new Set(filterStructs.map((n: any) => n.id));
      let notLoadedFolderCd: string[] = 
        this.datasourceTreeData.nodes.filter((e: TreeNode) => uniqueFolderCds.has(e.id ?? "") && !e.isLoaded).map(n => n.id ?? "");
  
      // Group folder
      let foldergroup = filterStructs.filter((e: any) => notLoadedFolderCd.includes(e.id));
      
      foldergroup.forEach((item: TreeNode) => {
        item.isLoaded = true;
        const sendNote = cloneDeep(item);
        this.nodeTrans.loadedNodeDataSub.next(sendNote);
        let renderedNode = this.datasourceTreeData2Level.nodes.find(e => e.id === item.id);
        if(renderedNode) {
          renderedNode.isLoaded = true;
          renderedNode.nodes = item.nodes!.map(node => ({
            ...node,
            nodes: node.isLastNode ? [] : node.nodes,
          }));
        }
      });
  
      this.datasourceTreeData2Level = cloneDeep(this.datasourceTreeData2Level);
    }

    this.datasourceTreeData2Level.nodes.forEach(lv1 => {
      lv1.expanded = false
      lv1.nodes?.forEach(lv2 => lv2.hidden = (!TreeUtils.checkTreeNodeLabel(lv2, searchText) && !TreeUtils.checkTreeNodeLabel(lv1, searchText)));

      if (!lv1.nodes || lv1.nodes.length === 0) {
        lv1.hidden = true;
      } else {
        lv1.hidden = lv1.nodes?.every(n => n.hidden);
      }
    });
    // Clear pipeline
    this.nodeTrans.loadedNodeDataSub.next(null);
  }

  resetAllHidden() {
    this.datasourceTreeData2Level.nodes.forEach(lv1 => {
      lv1.expanded = false;
      lv1.hidden = false;
      const isFolderSelected = lv1.nodes?.some(node => node.singleSelected);
      lv1.nodes?.forEach(lv2 => {
        lv2.hidden = (isFolderSelected && lv2.id === "null") ? true : false;
      });
    });
  }

  handleNodeClick(node: TreeNode) {
    const isParent = this.datasourceTreeData2Level.nodes.some(d => d === node);
    if (isParent) {
      return;
    }

    const originLv2Node = this.findLv2(this.datasourceTreeData, node);
    if (!originLv2Node || !originLv2Node.data) {
      return;
    }

    const lv3Nodes = originLv2Node.nodes?.filter(n => !n.hidden && n.label);
    const listboxItems = lv3Nodes?.map(n => {
      const nodeData = originLv2Node.data?.find(d => d.columnid === n.id);

      return { name: n.label || '', value: n.id, utcsortno: nodeData?.utcsortno };
    })

    const sortedItems = listboxItems?.sort((a, b) =>
      a.utcsortno - b.utcsortno
    );

    this.lbParams.items = sortedItems || [];
  }

  removeLv3FromTreeNodes(param: TreeViewInputParams) {
    this.forEachLv2(param, (lv2: TreeNode) => lv2.nodes = []);
  }

  forEachLv2(param: TreeViewInputParams, callback: Function) {
    for (const lv1 of param.nodes) {
      if (!lv1.nodes || lv1.nodes.length === 0) {
        continue;
      }

      for (const lv2 of lv1.nodes) {
        callback(lv2);
      }
    }
  }

  reassignIsLastNode(param: TreeViewInputParams) {
    this.forEachLv2(param, (lv2: TreeNode) => lv2.isLastNode = true);
  }

  findLv2(param: TreeViewInputParams, node: TreeNode): TreeNode | undefined {
    for (const lv1 of param.nodes) {
      if (!lv1.nodes || lv1.nodes.length === 0) {
        continue;
      }

      for (const lv2 of lv1.nodes) {
        if (lv2.id === node.id) {
          return lv2;
        }
      }
    }

    return undefined;
  }

  showNoDataText() {
    return this.datasourceTreeData2Level?.nodes?.length === 0 || this.datasourceTreeData2Level?.nodes?.every(n => n.hidden);
  }
}

