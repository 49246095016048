export const STAFF = {
    ID: 'ID',
    FULLNAME: '氏名',
    MAIL: 'メール',
    PHONE: '電話番号',
    TEAM: "グループ",
    ROLE: 'ロール',
}

export const TEAM = {
    NAME: 'グループ名一覧',
}

export const DATA = {
    DSNAME: 'データソース名',
    WGUSED: '利用ウィジェット',
    STAFF: '更新者',
    DATE: '最終更新日',
    NOTE: '進捗',
    STATUS: 'ステータス'
}

export const OFFICE = {
    OFFICECD: '事業所名',
    OFFICENM: '事業所名',
}

export const FOLDER = {
    NAME: 'データソースフォルダ一覧',
    DATANAME: 'ダッシュボードフォルダ一覧',
    WIDGETNAME: 'ウィジェットフォルダ一覧'
}
export const DATASOURCE = {
    NAME: 'データソース名',
    FOLDERNAME: 'フォルダ名',
    FOLDERID: 'フォルダ',
    DISPLAYNM: '更新者',
    DISPLAYDATE: '更新日',
}

export const DASHBOARD = {
    NAME: 'ダッシュボード名',
    DESCRIPTION: '説明',
    FOLDERID: 'フォルダ',
    FOLDERNAME: 'フォルダ名',
    DISPLAYNM: '更新者',
    DISPLAYDATE: '更新日',
}

export const WIDGET = {
    NAME: 'ウィジェット名',
    DESCRIPTION: 'ウィジェット説明',
    FOLDERNAME: 'フォルダ名',
    DISPLAYNM: '更新者',
    DISPLAYDATE: '更新日',
}

export const CK_REPORT_COLUMNS = {
    COUNTFLG: 'カウント',
    HASSOCIALLICENSE: '社会福祉士',
    DATE: '日付',
    DAYINMONTH: '月の日数',
    CUMULATIVE_DAYS: '介護度4か5に該当する入所者の延日数',
    INMATES: '延入所者日数',
    CUMULATIVE_NEW: '新規入所者の延数',
    NEW_LEAVERS: '新規の退所者の延数',
    PEOPLE_LEAVING: '居宅への退所者の延数',
    DEATH: '死亡',
    ADMISSION: '入所前後訪問指導を行った者の延数',
    DISCHARGE: '退所前後訪問指導を行った者の延数',
    SERVICE_TYPE: 'サービス種類数',
    HEALTH_FACILITY: '理学療法士等の当該介護保健施設に従事する勤務延時間数',
    PHYSICAL_THERAPISTS: '理学療法士等が勤務すべき時間',
    CARE_HEALTH_FACILITY: '支援相談員の当該介護保健施設に従事する勤務延時間数',
    COUMSELORS: '支援相談員が勤務すべき時間',
    ASPIRATION: '入所者ごとの喀痰吸引を実施した延入所者数',
    FEEDING: '入所者ごとの経管栄養を実施した延入所者数',
    SAME_DAY_DISCHARGE: '併設病院から退院日同日入所'
}

export const CK_REPORT_COLUMNCDS = {
    COUNTFLG: 'countflg',
    HASSOCIALLICENSE: 'hasSocialLicense',
    DATE: 'date',
    DAYINMONTH: 'dayInMonth',
    CUMULATIVE_DAYS: 'cumulativeDays',
    INMATES: 'inmates',
    CUMULATIVE_NEW: 'cumulativeNew',
    NEW_LEAVERS: 'newLeavers',
    PEOPLE_LEAVING: 'peopleLeaving',
    DEATH: 'death',
    ADMISSION: 'admission',
    DISCHARGE: 'discharge',
    SERVICE_TYPE: 'serviceType',
    HEALTH_FACILITY: 'healthFacility',
    PHYSICAL_THERAPISTS: 'physicalTherapists',
    CARE_HEALTH_FACILITY: 'careHealthFacility',
    COUMSELORS: 'coumselors',
    ASPIRATION: 'aspiration',
    FEEDING: 'feeding',
    SAME_DAY_DISCHARGE: 'sameDayDischarge'
}


export const ZAITAKU_COLS = [
    {
        columncd: CK_REPORT_COLUMNCDS.ADMISSION,
        name: CK_REPORT_COLUMNS.ADMISSION
    },
    {
        columncd: CK_REPORT_COLUMNCDS.DISCHARGE,
        name: CK_REPORT_COLUMNS.DISCHARGE
    },
    {
        columncd: CK_REPORT_COLUMNCDS.ASPIRATION,
        name: CK_REPORT_COLUMNS.ASPIRATION
    },
    {
        columncd: CK_REPORT_COLUMNCDS.FEEDING,
        name: CK_REPORT_COLUMNS.FEEDING
    }
]

export const FOLDER_MODAL = {
    ID: 'Id',
    NAME: 'フォルダ名',
}

export const TITLE = {
    SORT: 'NO.',
    TITLENM: 'タイトル',
}


export const LIST_COMPARISON = [
    { name: '一致', value: 'equal', type: '1' }, 
    { name: '不一致', value: 'not_equal', type: '2' },
    { name: '含む', value: 'like', type: '3' },
    { name: '含まない', value: 'not_like', type: '4' },
    { name: '以上', value: 'greater_than', type: '5' },
    { name: '以下', value: 'less_than', type: '6' },
    { name: '始まる', value: 'like_right', type: '7' },
    { name: '終わる', value: 'like_left', type: '8' },
  ]