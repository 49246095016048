import { TargetSettingType } from './../../../enum/common-enum';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { v4 } from 'uuid';
import { DSCUSTOM, FooterName, InvisibleColumn, DATATYPE, InvisibleInCustom, SummaryColumnName, DSTARGET, PivotOptions, ColFilterGroup } from '../../../../app/const/const';
import { ButtonType, ColumnType, FormatType, InputType, SearchType } from '../../../../app/enum/common-enum';
import { ModalTemplate, operator, SearchParams } from '../../../../app/models/common-model';
import { WidgetDetail, WidgetSettingRecord } from '../../../../app/models/request/widget.dto';
import Utils from '../../../../app/util/utils';
import { convertJapaneseNumber, createXNode, formatingDateTimeData, selectType } from '../../../../app/_helper/helper';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING } from '../../../../app/const/text-common';
import { bool } from 'aws-sdk/clients/signer';
import { cloneDeep, orderBy } from 'lodash';
import { Router } from '@angular/router';
import { setCaretPosition } from 'src/app/_helper/mention-helper';
import { OPERATOR_CUSTOM, OPERATOR_CUSTOM_NAME, OPERATOR_TYPE } from 'src/app/const/operator-custom';
import { OperatorCustom } from 'src/app/models/operator-custom';
import { createOperator, generateHtml, generateHtmlCusor, generateHtmlOperator, getOperatorFromString, getOperatorString} from 'src/app/_helper/operator-custom-helper';
import { InputFormulaComponent } from 'src/app/component/common/input-formula/input-formula.component';


@Component({
  selector: 'pivot-defined-column-dialog',
  templateUrl: './defined-column-dialog.component.html',
  styleUrls: ['./defined-column-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class DefinedColumnDialogComponent implements OnInit, OnChanges {
  // @ViewChild('operatorEditor') operatorEditorEmelent: ElementRef;
  
  @ViewChild('inputFormula') inputFormula: InputFormulaComponent;
  
  @Input() targetSettings: any [] = [];
  // modal data
  @Input() modalData: ModalTemplate = new ModalTemplate();
  // edit  data
  @Input() datasourceCols: Array<WidgetDetail>;
  @Input() selected: WidgetDetail | null

  @Input() columns: WidgetDetail[] = [];

  @Input() dataDS : any[] = [];

  @Output() onSubmitData: any = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<WidgetDetail>();
  @ViewChild('lstRowOption', { static: false }) listOp: any;

  column: WidgetDetail = new WidgetDetail();
  displayCols: Array<WidgetDetail>;
  // button type
  buttonType = ButtonType;
  rowFilters: any[] = [];
  targetOptions: any[] = [];
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  WIDGET_SETTING = WIDGET_SETTING;
  DATATYPE = DATATYPE;
  // input params
  inputNameParams: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 50,
  };

  selectedColIndex: number = 0;

  calculatorStyle: any = { 'margin-left.px': 10, 'width.px': 250, 'height.px': 30 }

  operators: Array<operator> = []
  operatorHtml: SafeHtml;
  _sanitizer: DomSanitizer;

  cursorIndex: number | undefined;
  prevValue: any;
  hover: bool = false;
  usingDataType: any;
  columnname: string;
  currentTargets: any[] = [];


  targetParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: { width: 'min(100%, 583px)', height: '40px' },
		readonly: false,
		disabled: false,
		maxLength: 100,
		comboDisplayText: 'name'
	};

  rowParams: SearchParams = cloneDeep(this.targetParams);

  columnOptions: any[] = [];
  rowOptions: any[] = [];
  datetimeFormatOptions: any[] = PivotOptions.filter(op => op.value != FormatType.Group);
  // input params
  inputTargetName: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 50,
  };

  DSCUSTOM = DSCUSTOM
  DSTARGET = DSTARGET
  targetTable: any[] = [];
  selectedSetting: WidgetSettingRecord = new WidgetSettingRecord();
  targetFomular: any[] = [{code: TargetSettingType.FIX_NUMBER, name: "入力"}, {code: TargetSettingType.FORMULAR, name: '計算式'}];
  showFormulaModal: boolean = false;
  isDefineNewColumnTab =  true;
  selectedRow: any = null;
  selectedColumn: any= null;
  selectedRowFormula: any;
  formatDateTime: any;
  validation: any = {};
  buttonName: string = '追加';
  key: string = '';
  selectedFormat: any;
  targetTableAll: any = []
  targetTableEach: any = [];
  isFilterAll: boolean = false;
  usedColumnsInTarget: Array<WidgetDetail> = [];
  usedColumnsInCustom: Array<WidgetDetail> = [];
  popupIcon = "../../../../assets/icons/open-popup.svg";
  isDisableSaveBtn: boolean = true;
  isDraftData: boolean = false;
  TARGET_SETTING_TYPE = TargetSettingType;

  itemsMention: any = {};
  constructor(sanitizer: DomSanitizer,  private router: Router,) {
    this._sanitizer = sanitizer;
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (isDarkMode) {
      this.popupIcon = '../../../../assets/icons/open-popup-white.svg';
    } else {
      this.popupIcon = '../../../../assets/icons/open-popup.svg';
    }
    this.itemsMention.itemsFunction = [];
    OPERATOR_CUSTOM.map((x:OperatorCustom) =>{
      this.itemsMention.itemsFunction.push(
        { label: x.name , type: OPERATOR_TYPE.OPERATOR_CUSTOM }
      )
    });
    this.itemsMention.itemsColumn = [];
  }

  // on change data
  ngOnChanges(): void {
    this.usingDataType = null;
    if (!Utils.isNullOrEmpty(this.displayCols)) {
      this.inputNameParams.isValidate = false;
    }
    else {
      this.column = new WidgetDetail()
    }

    let displayCols = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname] 
      && !x.columnname?.includes(ColFilterGroup)
      && !InvisibleInCustom[x.columnname] 
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
      && x.datatype != DATATYPE.Date
    )?.map(item => {
      if(item.formattype?.toString()?.includes(FormatType.Group)) item.datatype = item.datatype == DATATYPE.String ? DATATYPE.Float : item.datatype;
      return item;
    })
    displayCols = cloneDeep(displayCols);
    this.displayCols = displayCols
    let notTDDNodes = this.displayCols.filter(el => !el.columnname?.toUpperCase().startsWith("TTD_"));
    let TTDNodes = this.displayCols.filter(el => el.columnname?.toUpperCase().startsWith("TTD_"));
    if(TTDNodes.length) {
      let orderTTDNodes = TTDNodes.sort((a: any, b: any) => {
        const numberA = parseInt(a.columnname.split("_")[1]);
        const numberB = parseInt(b.columnname.split("_")[1]);
        return numberA - numberB;
      })
      notTDDNodes = orderBy(notTDDNodes, ["utcsortno"]);
      this.displayCols = [...orderTTDNodes, ...notTDDNodes]
    }
      
    if (this.selected) {
      if(this.selected.datasourcecd === DSCUSTOM) {
        this.column = this.selected
        this.columnname = this.column.columnname

      }
      else { //DSCUSTOMTARGET
        let exist = this.targetSettings.filter((target: WidgetSettingRecord)=> target.targetColumnName === this.selected?.columnname).pop();
        this.selectedSetting = cloneDeep(exist);
        this.buttonName = '確定';
        this.repopulateData();
        this.loadDataForDropDownTarget();
      }
    }
    
    this.displayCols = this.displayCols?.filter(
      (item, index, array) => array.findIndex((element) => element.columnname === item.columnname) === index
    );
    this.itemsMention.itemsColumn = [];

    this.onGenerateHtml();
  }

  ngOnInit(): void {
    this.targetSettings = cloneDeep(this.targetSettings);
    if(!this.targetSettings) {
      this.targetSettings = [];
    }
    if (this.selected) {
      if(this.selected.datasourcecd === DSCUSTOM) {
        this.isDefineNewColumnTab = true;
      }
      else { //DSCUSTOMTARGET
        this.isDefineNewColumnTab = false;
      }
    }
    else {
      this.isDefineNewColumnTab = true;
      this.onGenerateHtml();
    }

    if(this.targetSettings && this.targetSettings.length) {
      this.reloadLastestTargetTable()
    }
  }

  // close dialog
  onClose() {
    this.reset()
    this.onSubmitData.emit(null);
  }

  // on submit data event
  onSubmit() { 
    if(this.isDefineNewColumnTab) {
      if (Utils.isNullOrEmpty(this.columnname)) {
        this.inputNameParams.validateError = MESSAGE_TEXT.REQUIRE_INPUT;
        this.inputNameParams.isValidate = true;
        this.inputNameParams.validate = true;
        return;
      }
    }
    if (!this.selected) {
      if (this.columns.some(col => col.columnname === this.columnname && col.delflg === false)) {
        this.validateDuplicateColName();
        return;
      }

      this.column.columnid = v4()
      this.column.columntype = ColumnType.NoUse
      this.column.datasourcecd = DSCUSTOM
    } else {
      const isDuplicate = this.columns.some(col => col !== this.selected && col.columnname === this.columnname && col.delflg === false);

      if (isDuplicate) {
        this.validateDuplicateColName();
        return;
      }
    }
    this.column.columnname = this.columnname
    this.column.displayname = this.column?.columnname
    let dataFormula = this.inputFormula.getData();
    if(dataFormula.usingDataType == DATATYPE.Number || dataFormula.usingDataType == DATATYPE.Float) {
      this.column.rounding = dataFormula.rounding;
      this.column.datatype = dataFormula.usingDataType;
    }
    if(dataFormula.usingDataType == null) {
      this.column.rounding = dataFormula.rounding;
    }

    this.column.operator = getOperatorString(dataFormula.operators);
    dataFormula.operators.forEach((op: any) => {
      if(op.value != OPERATOR_TYPE.OPERATOR_CUSOR){
        if(op.type.trim() == 'column'){
          if(typeof op.value == 'object') {
            this.usedColumnsInCustom.push(op.value);
          }
        }
      }
    });

    // replace "¥" => "\" to submit data
    this.targetSettings.map((record: any) => {
      record.targetTable.map((r: any) => {
        if (r.value != null && typeof r.value == 'string') {
          r.value = r.value.replaceAll("¥", "\\");
        }
        return r;
      })
        
      return record;
    } );

    let dataOutput = {column: this.column, targetSettings: this.targetSettings, 
                      usedColumnsInTarget: this.usedColumnsInTarget, usedColumnsInCustom: this.usedColumnsInCustom}
    this.onSubmitData.emit(dataOutput);
    this.reset()
  }

  validateDuplicateColName() {
    this.inputNameParams.validateError = '既に登録されています。';
    this.inputNameParams.isValidate = true;
    this.inputNameParams.validate = true;
  }

  reset() {
    this.operators = [];
    this.cursorIndex = undefined;
    this.column = new WidgetDetail();
    this.columnname = '';
  }

  // input  name
  onInputName(data: any) {
    this.columnname = data.currentTarget?.value;
    this.inputNameParams.validate = false;
    if(this.selected)
    {
      //Update targetvalue that using operator in target table when rename DS-TARGET
      let oldName = this.selected.columnname
      let newName = data.currentTarget?.value
      if(oldName != newName) {
        let oldNameOperator = `{${oldName}}`
        let newNameOperator = `{${newName}}`
        this.targetSettings.map((record: any) => {
          let original = record;
          original.targetTable =  original.targetTable.map((r: any) => {
            if(r.targetValue && r.targetValue.toString().includes('{')) {
              let map =  { ...r , 
                targetValue : r.targetValue.replaceAll( oldNameOperator, newNameOperator),
              }       
              return map;
            }
            else return r;
          })
            
          return original ;
        } );
      }
    }
  }

  onClickCalculator(event: any) {
    if(OPERATOR_CUSTOM_NAME.includes(event)) {
      let item: operator  = { type: OPERATOR_TYPE.OPERATOR_CUSTOM, label: event, value: event, }
      this.pushToFormula(item);
      let itemEpan: operator = { type:  OPERATOR_TYPE.OPERATOR, label: "(", value: "(", }
      this.pushToFormula(itemEpan);
    }else{
      let item: operator = { type: OPERATOR_TYPE.OPERATOR, label: event, value: event, }
      this.pushToFormula(item)
    }
  }

  onClickArrowBtn(event: any) {
    let { columnname, displayname, datatype, ...abc } = this.displayCols[this.selectedColIndex];
    let item: operator = { type: 'column', label: displayname, value: { columnname, displayname, datatype, ...abc } }
    this.pushToFormula(item)
    this.usingDataType = datatype;
  }

  onClickColumn(i: number) {
    this.selectedColIndex = i;
    this.onGenerateHtml()
  }

  onFocusoutEditor(event: any) {
    let check = true;
    //insertCusor(this.operatorEditorEmelent);

    let target = event.target as HTMLElement

    this.operators = []
    target.childNodes.forEach((node, i) => {
      let item = {} as operator
      let nodeLE =  node as HTMLElement;
      if (node.nodeName === '#text') {
        var listOperator = getOperatorFromString(node.textContent);
        this.operators = [...this.operators, ...listOperator]
      } else if(nodeLE.id == OPERATOR_TYPE.OPERATOR_CUSOR || nodeLE.nodeValue == OPERATOR_TYPE.OPERATOR_CUSOR ){
        item.label = "";
        item.value = OPERATOR_TYPE.OPERATOR_CUSOR;
        item.type = OPERATOR_TYPE.OPERATOR_CUSOR;
        this.operators.push(item);
      }
      else {
        item = JSON.parse(node.childNodes[1].textContent as string)
        this.operators.push(item)
      }
    })
    this.onGenerateHtml();
    this.setDataType();
  }

  onGenerateHtml() {
    let html = createXNode();
    this.operators.forEach((item: any, i: number) => {
      if(item.type == OPERATOR_TYPE.OPERATOR_CUSOR) {
        generateHtmlCusor(html, i);

      } else if(item.type == OPERATOR_TYPE.OPERATOR) {
        let nextItem;
        if((i + 1) < this.operators.length){ nextItem = this.operators[i+1];}
        generateHtmlOperator(html,item , i, nextItem);

      }
      else {
        generateHtml(html, item, i);
      }
    });
    this.operatorHtml = this._sanitizer.bypassSecurityTrustHtml(html.innerHTML);
  }

  onKeydownEditor(event: any) {

  }

  onFocusinEditor(event: any) {
    this.prevValue = event.currentTarget.innerHTML;
    //removeAllCusor(this.operatorEditorEmelent)
  }

  pushToFormula(item: operator) {
    let cursorIndex = this.operators.find((x:any) => x.type == OPERATOR_TYPE.OPERATOR_CUSOR);
    if(cursorIndex){
      this.cursorIndex = this.operators.indexOf(cursorIndex);
    } 

    if (this.cursorIndex !== undefined) {
      this.operators.splice(this.cursorIndex, 0, item)
      this.cursorIndex++
    } else {
      this.operators.push(item)
    }
    this.onGenerateHtml();
  }
  focusoutInput() {
    this.inputNameParams.validate = false;
  }
  remove() {
    this.hover = false;
    this.reset();
    this.onDelete.emit(this.selected!);
  }
  
  setDataType() {
    let opratorCustom = this.operators.filter(x => x.type == OPERATOR_TYPE.OPERATOR_CUSTOM);
    if(opratorCustom.length > 0 ) {
      this.usingDataType = DATATYPE.String;
      return;
    }
    let tmp = this.operators.filter(x => x.type == OPERATOR_TYPE.COLUMN);
    if (!tmp || tmp.length == 0) {
      this.usingDataType = null;
    } else {
      if (this.selected) {
        let val = tmp[0].value as WidgetDetail;
        this.usingDataType = val.datatype;
      }
    }
  }


  onTabChange(value: boolean) {
    this.isDefineNewColumnTab = value;
    if( !this.isDefineNewColumnTab) {
      if(this.selectedSetting && this.selectedSetting.id == "") {
        this.selectedSetting = new WidgetSettingRecord();
      }

      this.loadDataForDropDownTarget();
    }
  }

  loadDataForDropDownTarget() {
    this.datasourceCols = orderBy(this.datasourceCols, ["utcsortno"]);
    this.columnOptions = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname]
      && !x.columnname?.includes(ColFilterGroup)
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any) => {
      let col =  {code: item.columnname, name: item.displayname, datatype: item.datatype, value: item.columnname}
      return col;
    });
    this.targetParams.dataSource = this.columnOptions;
    this.targetParams = cloneDeep(this.targetParams);
    this.rowOptions = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname]
      && !x.columnname?.includes(ColFilterGroup)
      && ![DSCUSTOM, DSTARGET,  FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any) => {
      let row = {code: item.columnname, name: item.displayname, datatype: item.datatype, value: item.columnname}
      return row;
    });
    this.rowParams.dataSource = this.rowOptions;
    this.rowParams = cloneDeep(this.rowParams);
    let emptyItem ={ code: null,  name: "", datatype: null }

    if(this.rowOptions.length) this.rowOptions.unshift(emptyItem);
    if(this.columnOptions.length) this.columnOptions.unshift(emptyItem);
  }

  onChangeApplyStyle(value: boolean) {
    this.isFilterAll = value;
    this.selectedSetting.isApplyAll = value;
    if(value) {
      this.addApplyAllRecordToTable()
    }
    else {
      this.loadTargetTableForRow(this.selectedSetting.row.code, "row")
      if(this.rowFilters.length > 0) {
        let targets: any[] = [];
        this.targetTable.forEach(t => {
          let findTarget = this.rowFilters?.find(f => f.value == t.value);
          if(findTarget){
            targets.push(t);
          }
        })
        this.targetTable = cloneDeep(targets);
      }
    }
  }

  addApplyAllRecordToTable() {
    let record = {value: "目標値", type: "0", targetValue: ""};
    this.targetTable = [];
    this.targetTable.push(record)
  }

  changeRowItem(event: any) {
    let selectedRow= event[0];
    if(selectedRow) {
      this.validation.row = false;
      this.formatDateTime = null;
      this.selectedFormat = null;
      this.selectedSetting.row = selectedRow
      this.selectedRow = selectedRow;
      this.rowParams.defaultValue = selectedRow.name || '';
      this.rowParams = cloneDeep(this.rowParams);
      this.rowFilters = [];
      if(this.selectedSetting.isApplyAll) {
        this.addApplyAllRecordToTable()
      }
      else {
        this.loadTargetTableForRow(selectedRow.code, "row");
      }
    }
  }

  loadTargetTableForRow(colName: string, type: "col" | "row") {
    if(type =="col") {
      if(this.selectedSetting.column) {
        let dataRow = this.dataDS.map((item: any) => item[this.selectedSetting.column.code.toUpperCase()])
        const uniqueDataRow = new Set(dataRow);
        this.targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
      }
    }
    else {
      if(this.selectedSetting.row) {
        if(this.selectedFormat) {
          this.targetTable = [];
          let dataRow = this.dataDS.map((item: any) => item[this.selectedSetting.row.code.toUpperCase()])
          let formatDateTime = formatingDateTimeData(dataRow, this.selectedFormat);
          const uniqueDataRow = new Set(formatDateTime);
          this.targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
          this.targetTable = orderBy(this.targetTable, ["value"]);
        }
        else {
          let dataRow = this.dataDS.map((item: any) => item[colName.toUpperCase()])
          const uniqueDataRow = new Set(dataRow);
          this.targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
        }
      }
    
    }
    this.targetTable = orderBy(this.targetTable, ["value"]);

    // replace Yen character when select Target
    this.targetTable.map((x: any)=> {
      if (typeof x.value == 'string' && x.value != null) {
        x.value = x.value.replace(/\\/g, "¥");
      }
      return x;
    });
    
    this.mapTargetTable();
    this.currentTargets = cloneDeep(this.targetTable);
  }

  changeColumnItem(event: any) {
    if(event) {
      this.validation.column = false;
      let column = event[0];
      this.targetParams.defaultValue = column.name || '';
      this.targetParams = cloneDeep(this.targetParams);
      this.selectedColumn = column;
    }
  }

  mapTargetTable() {
    this.targetOptions = cloneDeep(this.targetTable)?.map((s: any) => {
      s.name = s.value,
      s.checked = false
      return s;
    });
  }

  addRecord() {
    this.buttonName = '追加';
    let isValidForm = true;
    if(!this.selectedSetting.targetColumnName){
      this.inputTargetName.validateError = MESSAGE_TEXT.REQUIRE_INPUT;
      this.inputTargetName.isValidate = true;
      this.inputTargetName.validate = true;
      isValidForm = false;
    } 
    else {
      let existTargetColumn = this.targetSettings.filter((x: WidgetSettingRecord) => 
                        x.targetColumnName == this.selectedSetting.targetColumnName && this.selectedSetting.id != x.id);
      if(existTargetColumn && existTargetColumn.length) {
        this.inputTargetName.validateError = MESSAGE_TEXT.DUPLICATE_NAME;
        this.inputTargetName.isValidate = true;
        this.inputTargetName.validate = true;
        isValidForm = false;
      }
    }

    if(!this.selectedRow) {
      this.validation.row = true;
      isValidForm = false;
    }
    else {
      this.validation.row = false;
    }

    if(!this.selectedColumn) {
      this.validation.column = true;
      isValidForm = false;
    }
    else {
      this.validation.column = false;
    }
    if(!isValidForm) return;

    let selectedRow = this.findColumnDataInDataSource(this.selectedRow.code, ColumnType.Row);

    if(selectedRow) {
      if(this.selectedRow.data ) {
        selectedRow.data.formattype = this.selectedFormat ? this.selectedFormat.formattype: "";
      }
      else {
        selectedRow.data.formattype = this.selectedFormat ? this.selectedFormat.formattype: "";
      }
      this.selectedSetting.row = selectedRow;
    }

    let selectedColumn = this.findColumnDataInDataSource(this.selectedColumn.code, ColumnType.Value);
    if(selectedColumn) {
      this.selectedSetting.column = selectedColumn;
    }
    
    if(!this.isFilterAll) {
      let targets: any[] = cloneDeep(this.targetTable);
      this.targetTable = cloneDeep(this.currentTargets).map(item => {
          let findTarget = targets?.find(t => t.value == item.value);
          if(findTarget) {
            item = findTarget
          }
          return item;
      });
    }
    this.selectedSetting.targetTable = this.targetTable;

    if(!this.selectedSetting.id) {
      this.selectedSetting.id = v4();
      this.targetSettings.push(cloneDeep(this.selectedSetting));
    }
    else {
      this.targetSettings = this.targetSettings.map((x: WidgetSettingRecord) => x.id == this.selectedSetting.id ? ({...this.selectedSetting}): ({...x}));
    }
    this.resetSettingTargetForm();
    this.isDisableSaveBtn = false;
    this.isDraftData = false;
  }

  resetRecord(isResetBtn = false) {
    this.isDisableSaveBtn = false;
    this.isDraftData = false;
    this.resetSettingTargetForm(false, isResetBtn);
  }

  findColumnDataInDataSource(columnCode: string, columnType: ColumnType) {
    return this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname]
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any, type) => {
      let col =  {code: item.columnname, name: item.displayname, data: {...item}}
      col.data.columntype = columnType;

      return col;
    }).filter((x:any) => x.code === columnCode).pop();
  }

  resetSettingTargetForm(isDelete: boolean = false, isResetBtn = false) {
    this.resetValidationForm();
    this.selectedRow = null;
    this.rowParams.defaultValue = '';
    this.rowParams = cloneDeep(this.rowParams);
    this.selectedColumn = null;
    this.targetParams.defaultValue = '';
    this.targetParams = cloneDeep(this.targetParams);
    this.targetTable = [];
    this.formatDateTime = null;
    this.selectedFormat = null;
    this.rowFilters = [];
    if(this.selectedSetting && (this.buttonName == '追加' || isDelete)) {
      this.selectedSetting = new WidgetSettingRecord();
    }
    else if(isResetBtn)
    {
      this.selectedSetting = new WidgetSettingRecord();
    }
  }

  resetValidationForm() {
    this.validation = {};
    this.inputTargetName.validateError = "";
    this.inputTargetName.isValidate = false;
    this.inputTargetName.validate = false;
  }

  setTargetName(data: any) {
    let targetColumn: any = {}; 
    if(this.selectedSetting && this.selectedSetting.id === undefined)
    {
      targetColumn.columnid = v4();
      targetColumn.columntype = ColumnType.Value;
      targetColumn.datasourcecd = DSTARGET
      targetColumn.columnname = data.currentTarget?.value;
      targetColumn.displayname = targetColumn.columnname;
      targetColumn.datatype = "INT";
    }
    else 
    {
      //Update operator in columns with type DS-CUSTOM when rename DS-TARGET
      let oldName = this.selectedSetting.targetColumn.columnname
      let newName = data.currentTarget?.value
      if(oldName != newName) {
        let oldNameOperator = `{${oldName}}`
        let newNameOperator = `{${newName}}`
        this.columns.map(w=> w.operator = w.operator?.replaceAll(oldNameOperator, newNameOperator) as string);
      }
      //update targetSettings when renaming target column
      let filter = this.targetSettings.filter((t: any) => t.column?.data?.columnid == this.selectedSetting?.targetColumn?.columnid).pop() as WidgetSettingRecord;
      if(filter) {
        filter.column.code = newName;
        filter.column.name = newName;
        if(filter.column.data) {
          filter.column.data.columnname = newName;
          filter.column.data.displayname = newName;
        }
      }
      targetColumn = {...this.selectedSetting.targetColumn} ;
      targetColumn.columnname = data.currentTarget?.value;
      targetColumn.displayname = targetColumn.columnname;
    }
    let column = cloneDeep(targetColumn)
    this.selectedSetting.targetColumn = column;
    this.selectedSetting.targetColumnName = column.columnname;
  }

  editTarget(id: string) {
    this.resetValidationForm();
    this.buttonName = '確定';
    let selectedSetting = this.targetSettings.filter((item:WidgetSettingRecord )=> item.id === id).pop();
    this.selectedSetting = cloneDeep(selectedSetting);
    this.repopulateData();
    this.isDisableSaveBtn = true;
  }

  repopulateData() {
    if(this.selectedSetting) {
      let selectedRow =  cloneDeep(this.selectedSetting.row)
      if(selectedRow) {  
        this.selectedFormat = selectedRow.data; 
        this.formatDateTime = PivotOptions.filter(y => y.value === selectType(this.selectedFormat.formattype, 'A')).pop();
        let datatypeRow = selectedRow.data?.datatype;
        delete selectedRow.data;
        this.selectedRow = selectedRow
        this.selectedRow.value = this.selectedRow.code;
        this.selectedRow.datatype = datatypeRow;
        this.rowParams.defaultValue = this.selectedRow.name;
        this.rowParams = cloneDeep(this.rowParams);
      }
      let selectedColumn =  cloneDeep(this.selectedSetting.column)
      if(selectedColumn) {
        let datatypeColumn= selectedColumn.data?.datatype;
        delete selectedColumn.data;
        this.selectedColumn = selectedColumn
        this.targetParams.defaultValue = selectedColumn.name;
        this.selectedColumn.value = selectedColumn.code;
        this.targetParams = cloneDeep(this.targetParams);
        this.selectedColumn.datatype = datatypeColumn;
      }

      if(this.selectedSetting.isApplyAll) {
        this.targetTable = this.selectedSetting.targetTable;
        this.targetTable = orderBy(this.targetTable, ["value"]);
      }
      else {
        let dataRow = this.dataDS.map((item: any) => item[this.selectedSetting.row.code.toUpperCase()])
        let formatDateTime = formatingDateTimeData(dataRow, this.selectedSetting.row.data);
        const uniqueDataRow = new Set(formatDateTime);
        let allRows = Array.from(uniqueDataRow).map((item:string) => {
          let exist = this.selectedSetting.targetTable.filter((data: any) => data.value ? data.value == item: "" == item);
          if(exist.length > 0 ){
            return {...exist[0]}
          }
          return {value: item, type: "", targetValue: ""}
        })

        this.targetTable = orderBy(allRows, ["value"]);
      }
    }
    this.mapTargetTable();

    // replace Yen character when Edit Target
    this.targetTable.map((x: any) => {
      if (typeof x.value == 'string' && x.value != null) {
        x.value = x.value.replace(/\\/g, "¥");
      }
      return x;
    });

    this.currentTargets = cloneDeep(this.targetTable);
    this.rowFilters = [];
  }

  deleteTarget(id: string) {
    this.isDisableSaveBtn = false;
    this.targetSettings = this.targetSettings.filter((item:WidgetSettingRecord )=> item.id !== id);
    this.resetSettingTargetForm(true);
  } 

  showTargetFormulaModal(selectedRow: any) {
    this.selectedRowFormula = selectedRow;
    this.showFormulaModal = true;
  }
  changeValueInline(selectedRow: any) {
    this.selectedRowFormula = selectedRow;
    this.showFormulaModal = true;
  }
  onSelectedOption(data: any) {
    if(data) {
      this.rowFilters = data.value || [];
      let targets: any[] = cloneDeep(this.targetTable);
      this.currentTargets = this.currentTargets.map(o => {
          let findItem = this.rowFilters.find(s=> s.value == o.value);
          let findTarget = targets?.find(t => t.value == o.value);
          if(findTarget) o = findTarget;
          o.checked = findItem? true: false;
          o.name = o.value
          return o;
      });
      this.targetTable = data.value?.length > 0? cloneDeep(this.currentTargets).filter(s=>s.checked): cloneDeep(this.currentTargets);
    }
  }

  submitFormula(event: any) {
    this.showFormulaModal = false;
    if(!event) return;
    if(!this.isDraftData) {
      this.isDraftData = true;
    }
    let {operator, displayText,  usedColumnsInTarget, rounding} = event;
    this.selectedRowFormula.targetValue = operator;
    this.selectedRowFormula.displayText = displayText;

    this.selectedRowFormula.rounding = rounding;
    let uniq = new Set([...this.usedColumnsInTarget, ...usedColumnsInTarget]);
    this.usedColumnsInTarget = [...uniq];
  }

  filterData(event: any) {
    if(this.targetTable.length > 0) {
      this.listOp?.toggle(event);
    }
  }

  changeDateFormat(event: any) {
    let option = event.value;
    let selectedRow = this.findColumnDataInDataSource(this.selectedRow.code, ColumnType.Row);
    if(selectedRow) {
      if(selectedRow.data.datatype === "DATETIME") {
        selectedRow.data.formattype = option.value;
        let data = cloneDeep(this.selectedRow);
        data.data = selectedRow.data;
        data.data.formattype = option.value;
        this.selectedFormat = data.data;
        if(this.selectedSetting && !this.selectedSetting.isApplyAll) {
          this.targetTable = [];
          let dataRow = this.dataDS.map((item: any) => item[this.selectedSetting.row.code.toUpperCase()])
          let formatDateTime = formatingDateTimeData(dataRow, selectedRow.data);
          const uniqueDataRow = new Set(formatDateTime);
          let targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
          this.targetTable = orderBy(targetTable, ["value"]);
          this.currentTargets = cloneDeep(this.targetTable);
          this.mapTargetTable();
        }
      }
    }
  }

  reloadLastestTargetTable() {
    let settings: WidgetSettingRecord[] =[];
    //Modify target table to lastest record
    if(this.targetSettings && this.targetSettings && this.targetSettings.length) {
      if(this.dataDS && this.dataDS.length) {
        this.targetSettings.forEach((target: WidgetSettingRecord) => {
          let setting:WidgetSettingRecord = new WidgetSettingRecord();

          let dataRow = this.dataDS.map((item: any) => item[target.row?.code.toUpperCase()])
          const uniqueDataRow = new Set(dataRow);
          let newRecords = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
          let lastestTargetTable = newRecords.map((item: any)=> 
          {
            let existRecord =  target.targetTable.filter((target: any) => target.value === item.value).pop();
            if(existRecord) {
              item = existRecord;
            }
            return item;
          })
          lastestTargetTable = orderBy(lastestTargetTable, ["value"]);
          setting = {...target, targetTable: lastestTargetTable}
          settings.push(setting);
        })
      }
    }
  }

  enableDropDownDateTime() {
    if(this.selectedRow && this.selectedRow.datatype === 'DATETIME')
      return false;
    return  true
  }

  formatNumber(event: any, data: any) {
    const tabindex = event.tabIndex;
    let value = convertJapaneseNumber(event.value);
    value = this.cellValueChange(value);
    if(value && !this.isDraftData) {
      this.isDraftData = true;
    }
    data.targetValue =  value;
    if(this.key == 'Tab') {
      if (!isNaN(tabindex)) {
        setTimeout(() => {
          const nextElement: any = document.querySelector(`[tabindex="${tabindex + 1}"]`);
          if (nextElement) {
            nextElement.focus();
          }
        }, 100);
      }
      this.key = '';
    }
  }

  cellValueChange(data: any) {
    let numericString = data.toString()?.replace(/[^\d.]/g, '');
    let hasDecimal = numericString.includes('.');
    if(hasDecimal) {
      let [thousand, decimal] = numericString.split('.');
      if(decimal) {
        return thousand + '.' + decimal.slice(0, 2);
      }
      else return thousand;
    }
    return data;
  }

  inputKeyDown(event: any) {
    this.key = event.key
  }

  changeFormulaType(event: any, data: any) {
    data.targetValue = "";
    data.displayText = "";
    if(event) {
      data.type = event.value.code;
    }
  }

  itemSelectedMetion(event:any){
    let selObj =  window.getSelection();
    if (selObj && selObj.rangeCount > 0) {
      var anchorNode = selObj.anchorNode;
      let item:any = {};
      item.label = event.label;
      item.type = event.type;
      item.value = event.label;
      var span = createOperator(item);
      var empty = document.createTextNode('\u00A0'); 
      var spanEpmty = document.createTextNode(' '); 

      if( event.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        spanEpmty = document.createTextNode('('); 
      }
      var nexNode =  anchorNode?.nextSibling;
      if(anchorNode?.nodeName == "#text"){
        let searchStringLength = event.pos - event.startPos;
        let nodeTextValue = anchorNode.nodeValue ?? "";
        nodeTextValue = nodeTextValue?.substring(0, nodeTextValue?.length - searchStringLength);
        anchorNode.nodeValue = nodeTextValue;
      }
      if(nexNode) {
        nexNode?.parentNode?.insertBefore(span, nexNode);
        nexNode?.parentNode?.insertBefore(empty, nexNode);
        nexNode?.parentNode?.insertBefore(spanEpmty, nexNode);

      }else{
        anchorNode?.parentNode?.appendChild(span);
        anchorNode?.parentNode?.appendChild(empty);
        anchorNode?.parentNode?.appendChild(spanEpmty);
     
      } 
      let spanEpmtyHTML = (<HTMLInputElement><unknown>spanEpmty);
      if( item.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        setCaretPosition(spanEpmtyHTML,1,null);
      }else{
        setCaretPosition(spanEpmtyHTML,0,null);
      }
    }
  }

  isDisableSaveButton() {
    if(!this.isDefineNewColumnTab && (this.isDisableSaveBtn || this.isDraftData) ){
      return true;
    }
    return false;
  }


  onPasteEditor(event: any) {
    event.preventDefault();
    let data = (event.clipboardData.getData("text").replace(/\n/g, ''));
    //insertValuePaste(data, this.operatorEditorEmelent);
  }
}
