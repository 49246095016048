import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ChartData } from 'src/app/models/common-model';
import { ZaitakuSyncItem } from 'src/app/models/response/zaitaku.ro';
import { ProcessLoadingService } from 'src/app/services/loading.service';
import { calculaTotalByPreviousMonth, convertToDate, formatValueNumber, getServiceTypeByPreviousMonth, seidokaiseiAfter202404 } from 'src/app/_helper/helper';
import { SERVICE_TYPE } from '../../../const/zaitaku-table-define'
@Component({
  selector: 'pivot-zaitaku-item',
  templateUrl: './zaitaku-item.component.html',
  styleUrls: ['./zaitaku-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZaitakuItemComponent implements OnInit, OnChanges {
  @Input() dataZaitaku: ZaitakuSyncItem[];
  @Input() zaitakuId: string | undefined;
  @Input() itemWidth: number | undefined;
  @Input() hasHospitalNearby: boolean = false;
  dataTblRate: any[] = [];
  currentData: any[] = [];
  dataTblIndex: any[] = [];
  dataTblIndexSum: any[] = [];
  dataTblA: any[] = [];
  dataTblB: any[] = [];
  dataTblC: any[] = [];
  dataTblD: any[] = [];
  dataTblE: any[] = [];
  dataTblF: any[] = [];
  dataTblG: any[] = [];
  dataTblH: any[] = [];
  dataTblI: any[] = [];
  dataTblJ: any[] = [];
  dataTblMonthA: any[] = [];
  dataTblMonthB: any[] = [];
  dataTblMonthC: any[] = [];
  dataTblMonthD: any[] = [];
  dataTblMonthE: any[] = [];
  dataTblMonthF: any[] = [];
  dataTblMonthG: any[] = [];
  dataTblMonthH: any[] = [];
  dataTblMonthI: any[] = [];
  dataTblMonthJ: any[] = [];
  dataChart: ChartData = new ChartData();
  width: number = 0;
  constructor(private el: ElementRef,  private loadingService: ProcessLoadingService, private renderer: Renderer2) { 
    this.width = this.itemWidth && this.itemWidth <= 1300 ? this.itemWidth - 20 : 1300;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName === 'itemWidth' && changes[propName].firstChange === false) {
        if(changes[propName] && changes[propName].currentValue) {
          this.width = changes[propName].currentValue <= 1300 ? changes[propName].currentValue - 20 : 1300;
        }
      }
    }
  }

  ngOnInit(): void {
    this.handleCaseSameDayDischarge();
    this.currentData = cloneDeep(this.dataZaitaku);
    this.dataZaitaku = cloneDeep(this.currentData.filter((z: any) => !z.hide))
    if (this.zaitakuId === "ZD00000001") {
      // ■数値
      this.dataTblRate.push({ date: "", rateA: "在宅復帰率(%)", rateB: "ベッド回転率(%)", rateC: "入所前後訪問指導割合(%)", rateD: "退所前後訪問指導割合(%)", rateE: "居宅サービス実施数", rateF: "リハ専門職配置割合", rateG: "支援相談員配置割合", rateH: "要介護4·5割合", rateI: "喀痰吸引実施割合", rateJ: "経管栄蓑実施割合" })
      this.dataZaitaku.forEach(element => {
        let rateA;
        let peopleLeavingA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'peopleLeaving', this.currentData);
        let newLeaversA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'newLeavers', this.currentData);
        let deathA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'death', this.currentData);
        rateA = peopleLeavingA / (newLeaversA - deathA) * 100;
        rateA = (isNaN(rateA) || !isFinite(rateA)) ? '' : rateA.toFixed(1) + '%';

        let rateB;
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let cumulativeNew = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeNew', this.currentData);
        let newLeavers = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'newLeavers', this.currentData);
        rateB = 30.4 / inmates * (cumulativeNew + newLeavers) / 2 * 100;
        rateB = (isNaN(rateB) || !isFinite(rateB)) ? '' : rateB.toFixed(1) + '%';

        let rateC;
        let admission = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'admission', this.currentData);
        rateC = admission / cumulativeNew * 100;
        rateC = (isNaN(rateC) || !isFinite(rateC)) ? '' : rateC.toFixed(1) + '%';

        let rateD;
        let discharge = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'discharge', this.currentData);
        let peopleLeavingD = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'peopleLeaving', this.currentData);
        rateD = discharge / peopleLeavingD * 100;
        rateD = (isNaN(rateD) || !isFinite(rateD)) ? '' : rateD.toFixed(1) + '%';

        let serviceType = '1';
        var dataService = getServiceTypeByPreviousMonth(3, convertToDate(element.date), 'serviceType', this.currentData);
        if(dataService) {
         var servetype = SERVICE_TYPE.filter(x=>x.value ==  (dataService.serviceType??0));
          if( servetype.length >0 ){
            serviceType = servetype[0].title;
          }
        } 
        let rateF;
        let healthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'healthFacility', this.currentData);
        let physicalTherapists = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'physicalTherapists', this.currentData);
        let dayInMonth = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'dayInMonth', this.currentData);
        rateF = healthFacility / physicalTherapists / inmates * dayInMonth * 100;
        rateF = (isNaN(rateF) || !isFinite(rateF)) ? '' : rateF.toFixed(1) + '%';

        let rateG;
        let careHealthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'careHealthFacility', this.currentData);
        let coumselors = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'coumselors', this.currentData);
        rateG = careHealthFacility / coumselors / inmates * dayInMonth * 100;
        rateG = (isNaN(rateG) || !isFinite(rateG)) ? '' : rateG.toFixed(1) + '%';

        let rateH;
        let cumulativeDays = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeDays', this.currentData);
        rateH = cumulativeDays / inmates * 100;
        rateH = (isNaN(rateH) || !isFinite(rateH)) ? '' : rateH.toFixed(1) + '%';

        let rateI;
        let aspiration = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'aspiration', this.currentData);
        rateI = aspiration / inmates * 100;
        rateI = (isNaN(rateI) || !isFinite(rateI)) ? '' : rateI.toFixed(1) + '%';

        let rateJ;
        let feeding = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'feeding', this.currentData);
        rateJ = feeding / inmates * 100;
        rateJ = (isNaN(rateJ) || !isFinite(rateJ)) ? '' : rateJ.toFixed(1) + '%';
        this.dataTblRate.push({ date: element.date, rateA: rateA, rateB: rateB, rateC: rateC, rateD: rateD, rateE: serviceType, rateF: rateF, rateG: rateG, rateH: rateH, rateI: rateI, rateJ: rateJ })
      });
  
      // ■指標
      this.dataTblIndex.push({ date: "", idxA: "在宅復帰率", idxB: "ベッド回転率", idxC: "入所前後訪問指導割合", idxD: "退所前後訪問指導割合", idxE: "居宅サービス実施数", idxF: "リハ専門職配置割合", idxG: "支援相談員配置割合", idxH: "要介護4·5割合", idxI: "喀痰吸引実施割合", idxJ: "経管栄蓑実施割合" })
      this.dataZaitaku.forEach(element => {
        let after202404 = seidokaiseiAfter202404(3, convertToDate(element.date), this.currentData);
        let rateA;
        let peopleLeavingA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'peopleLeaving', this.currentData);
        let newLeaversA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'newLeavers', this.currentData);
        let deathA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'death', this.currentData);
        rateA = peopleLeavingA / (newLeaversA - deathA) * 100;
        rateA = (isNaN(rateA) || !isFinite(rateA)) ? '' : rateA.toFixed(1);
        let idxA = (rateA == '') ? '' : (Number(rateA) > 50) ? 20 : (30 < Number(rateA) && Number(rateA) <= 50) ? 10 : 0;

        let rateB;
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let cumulativeNew = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeNew', this.currentData);
        let newLeavers = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'newLeavers', this.currentData);
        rateB = 30.4 / inmates * (cumulativeNew + newLeavers) / 2 * 100;
        rateB = (isNaN(rateB) || !isFinite(rateB)) ? '' : rateB.toFixed(1);
        let idxB = (rateB == '') ? '' : (Number(rateB) > 10) ? 20 : (5 <= Number(rateB) && Number(rateB) < 10) ? 10 : 0;

        let rateC;
        let admission = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'admission', this.currentData);
        rateC = admission / cumulativeNew * 100;
        rateC = (isNaN(rateC) || !isFinite(rateC)) ? '' : rateC.toFixed(1);
        let idxC;
        if (after202404) {
          idxC = (rateC == '') ? '' : (Number(rateC) >= 35) ? 10 : (15 <= Number(rateC) && Number(rateC) < 35) ? 5 : 0;
        } else {
          idxC = (rateC == '') ? '' : (Number(rateC) >= 30) ? 10 : (10 <= Number(rateC) && Number(rateC) < 30) ? 5 : 0;
        }

        let rateD;
        let discharge = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'discharge', this.currentData);
        let peopleLeavingD = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'peopleLeaving', this.currentData);
        rateD = discharge / peopleLeavingD * 100;
        rateD = (isNaN(rateD) || !isFinite(rateD)) ? '' : rateD.toFixed(1);

        let idxD;
        if (after202404) {
          idxD = (rateD == '') ? '' : (Number(rateD) >= 35) ? 10 : (15 <= Number(rateD) && Number(rateD) < 35) ? 5 : 0;
        } else {
          idxD = (rateD == '') ? '' : (Number(rateD) >= 30) ? 10 : (10 <= Number(rateD) && Number(rateD) < 30) ? 5 : 0;
        }

        //let serviceType = calculaTotalByPreviousMonth(3, new Date(element.date), 'serviceType', this.currentData);
        //let idxE = (serviceType == 3) ? 5 : (serviceType == 2) ? 3 : (serviceType == 1) ? 2 : 0;
        let idxE = 0;
        var dataService = getServiceTypeByPreviousMonth(3, convertToDate(element.date), 'serviceType', this.currentData);
        if(dataService) {
          idxE = dataService.serviceType;
        } 

        let rateF;
        let healthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'healthFacility', this.currentData);
        let physicalTherapists = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'physicalTherapists', this.currentData);
        let dayInMonth = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'dayInMonth', this.currentData);
        rateF = healthFacility / physicalTherapists / inmates * dayInMonth * 100;
        rateF = (isNaN(rateF) || !isFinite(rateF)) ? '' : rateF.toFixed(1);
        let idxF = (rateF == '') ? '' : (Number(rateF) >= 5) ? 5 : (3 <= Number(rateF) && Number(rateF) < 5) ? 3 : 0;

        let rateG;
        let careHealthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'careHealthFacility', this.currentData);
        let coumselors = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'coumselors', this.currentData);
        rateG = careHealthFacility / coumselors / inmates * dayInMonth * 100;
        rateG = (isNaN(rateG) || !isFinite(rateG)) ? '' : rateG.toFixed(1);

        let idxG;
        if (after202404) {
          let hasSocialLicense = element.hasSocialLicense;
          if (rateG == '') idxG = ''
          else if (Number(rateG) >= 3 && hasSocialLicense) idxG = 5
          else if (Number(rateG) >= 3 && !hasSocialLicense) idxG = 3
          else if (Number(rateG) >= 2 && Number(rateG) < 3) idxG = 1
          else idxG = 0
        } else {
          idxG = (rateG == '') ? '' : (Number(rateG) >= 3) ? 5 : (2 <= Number(rateG) && Number(rateG) < 3) ? 3 : 0;
        }

        let rateH;
        let cumulativeDays = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeDays', this.currentData);
        rateH = cumulativeDays / inmates * 100;
        rateH = (isNaN(rateH) || !isFinite(rateH)) ? '' : rateH.toFixed(1);
        let idxH = (rateH == '') ? '' : (Number(rateH) >= 50) ? 5 : (35 <= Number(rateH) && Number(rateH) < 50) ? 3 : 0;

        let rateI;
        let aspiration = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'aspiration', this.currentData);
        rateI = aspiration / inmates * 100;
        rateI = (isNaN(rateI) || !isFinite(rateI)) ? '' : rateI.toFixed(1);
        let idxI = (rateI == '') ? '' : (Number(rateI) >= 10) ? 5 : (5 <= Number(rateI) && Number(rateI) < 10) ? 3 : 0;

        let rateJ;
        let feeding = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'feeding', this.currentData);
        rateJ = feeding / inmates * 100;
        rateJ = (isNaN(rateJ) || !isFinite(rateJ)) ? '' : rateJ.toFixed(1);
        let idxJ = (rateJ == '') ? '' : (Number(rateJ) >= 10) ? 5 : (5 <= Number(rateJ) && Number(rateJ) < 10) ? 3 : 0;
        this.dataTblIndex.push({ date: element.date, idxA: idxA, idxB: idxB, idxC: idxC, idxD: idxD, idxE: idxE, idxF: idxF, idxG: idxG, idxH: idxH, idxI: idxI, idxJ: idxJ })
      });

      this.dataTblIndex[0].sum = "合計";
      for (let i = 1; i < this.dataTblIndex.length; i++) {
        let sum = 0;
        for (let prop in this.dataTblIndex[i]) {
          if (prop.startsWith('idx')) {
            sum += (isNaN(this.dataTblIndex[i][prop]) || !isFinite(this.dataTblIndex[i][prop])) ? 0 : Number(this.dataTblIndex[i][prop]);
          }
        }
        this.dataTblIndex[i]['sum'] = sum;
      }
      this.dataChart.categories = [];
      this.dataChart.columns = [];
      let dataArr = [];
      dataArr.push("指標");
      for (let i = 1; i < this.dataTblIndex.length; i++) {
        const element = this.dataTblIndex[i];
        this.dataChart.categories.push(element.date);
        dataArr.push(element.sum);
      }
      this.dataChart.columns.push(dataArr);
    }

    if (this.zaitakuId === "ZD00000002") {
      // ■指標 include 合計（A～J）
      this.dataTblIndexSum.push({ date: "", idxA: "在宅復帰率", idxB: "ベッド回転率", idxC: "入所前後訪問指導割合", idxD: "退所前後訪問指導割合", idxE: "居宅サービス実施数", idxF: "リハ専門職配置割合", idxG: "支援相談員配置割合", idxH: "要介護4·5割合", idxI: "喀痰吸引実施割合", idxJ: "経管栄蓑実施割合" })
      this.dataZaitaku.forEach(element => {
        let after202404 = seidokaiseiAfter202404(3, convertToDate(element.date), this.currentData);
        let rateA;
        let peopleLeavingA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'peopleLeaving', this.currentData);
        let newLeaversA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'newLeavers', this.currentData);
        let deathA = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'death', this.currentData);
        rateA = peopleLeavingA / (newLeaversA - deathA) * 100;
        rateA = (isNaN(rateA) || !isFinite(rateA)) ? '' : rateA.toFixed(1);
        let idxA = (rateA == '') ? '' : (Number(rateA) > 50) ? 20 : (30 < Number(rateA) && Number(rateA) <= 50) ? 10 : 0;

        let rateB;
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let cumulativeNew = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeNew', this.currentData);
        let newLeavers = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'newLeavers', this.currentData);
        rateB = 30.4 / inmates * (cumulativeNew + newLeavers) / 2 * 100;
        rateB = (isNaN(rateB) || !isFinite(rateB)) ? '' : rateB.toFixed(1);
        let idxB = (rateB == '') ? '' : (Number(rateB) > 10) ? 20 : (5 <= Number(rateB) && Number(rateB) < 10) ? 10 : 0;

        let rateC;
        let admission = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'admission', this.currentData);
        rateC = admission / cumulativeNew * 100;
        rateC = (isNaN(rateC) || !isFinite(rateC)) ? '' : rateC.toFixed(1);

        let idxC;
        if (after202404) {
          idxC = (rateC == '') ? '' : (Number(rateC) >= 35) ? 10 : (15 <= Number(rateC) && Number(rateC) < 35) ? 5 : 0;
        } else {
          idxC = (rateC == '') ? '' : (Number(rateC) >= 30) ? 10 : (10 <= Number(rateC) && Number(rateC) < 30) ? 5 : 0;
        }

        let rateD;
        let discharge = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'discharge', this.currentData);
        let peopleLeavingD = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'peopleLeaving', this.currentData);
        rateD = discharge / peopleLeavingD * 100;
        rateD = (isNaN(rateD) || !isFinite(rateD)) ? '' : rateD.toFixed(1);

        let idxD;
        if (after202404) {
          idxD = (rateD == '') ? '' : (Number(rateD) >= 35) ? 10 : (15 <= Number(rateD) && Number(rateD) < 35) ? 5 : 0;
        } else {
          idxD = (rateD == '') ? '' : (Number(rateD) >= 30) ? 10 : (10 <= Number(rateD) && Number(rateD) < 30) ? 5 : 0;
        }

        // let serviceType = calculaTotalByPreviousMonth(3, new Date(element.date), 'serviceType', this.currentData);
        // let idxE = (serviceType == 3) ? 5 : (serviceType == 2) ? 3 : (serviceType == 1) ? 2 : 0;
        let idxE = 0;
        var dataService = getServiceTypeByPreviousMonth(3, convertToDate(element.date), 'serviceType', this.currentData);
        if(dataService) {
          idxE = dataService.serviceType;
        } 

        let rateF;
        let healthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'healthFacility', this.currentData);
        let physicalTherapists = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'physicalTherapists', this.currentData);
        let dayInMonth = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'dayInMonth', this.currentData);
        rateF = healthFacility / physicalTherapists / inmates * dayInMonth * 100;
        rateF = (isNaN(rateF) || !isFinite(rateF)) ? '' : rateF.toFixed(1);
        let idxF = (rateF == '') ? '' : (Number(rateF) >= 5) ? 5 : (3 <= Number(rateF) && Number(rateF) < 5) ? 3 : 0;

        let rateG;
        let careHealthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'careHealthFacility', this.currentData);
        let coumselors = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'coumselors', this.currentData);
        rateG = careHealthFacility / coumselors / inmates * dayInMonth * 100;
        rateG = (isNaN(rateG) || !isFinite(rateG)) ? '' : rateG.toFixed(1);

        let idxG;
        if (after202404) {
          let hasSocialLicense = element.hasSocialLicense;
          if (rateG == '') idxG = ''
          else if (Number(rateG) >= 3 && hasSocialLicense) idxG = 5
          else if (Number(rateG) >= 3 && !hasSocialLicense) idxG = 3
          else if (Number(rateG) >= 2 && Number(rateG) < 3) idxG = 1
          else idxG = 0
        } else {
          idxG = (rateG == '') ? '' : (Number(rateG) >= 3) ? 5 : (2 <= Number(rateG) && Number(rateG) < 3) ? 3 : 0;
        }


        let rateH;
        let cumulativeDays = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeDays', this.currentData);
        rateH = cumulativeDays / inmates * 100;
        rateH = (isNaN(rateH) || !isFinite(rateH)) ? '' : rateH.toFixed(1);
        let idxH = (rateH == '') ? '' : (Number(rateH) >= 50) ? 5 : (35 <= Number(rateH) && Number(rateH) < 50) ? 3 : 0;

        let rateI;
        let aspiration = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'aspiration', this.currentData);
        rateI = aspiration / inmates * 100;
        rateI = (isNaN(rateI) || !isFinite(rateI)) ? '' : rateI.toFixed(1);
        let idxI = (rateI == '') ? '' : (Number(rateI) >= 10) ? 5 : (5 <= Number(rateI) && Number(rateI) < 10) ? 3 : 0;

        let rateJ;
        let feeding = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'feeding', this.currentData);
        rateJ = feeding / inmates * 100;
        rateJ = (isNaN(rateJ) || !isFinite(rateJ)) ? '' : rateJ.toFixed(1);
        let idxJ = (rateJ == '') ? '' : (Number(rateJ) >= 10) ? 5 : (5 <= Number(rateJ) && Number(rateJ) < 10) ? 3 : 0;
        this.dataTblIndexSum.push({ date: element.date, idxA: idxA, idxB: idxB, idxC: idxC, idxD: idxD, idxE: idxE, idxF: idxF, idxG: idxG, idxH: idxH, idxI: idxI, idxJ: idxJ })
      });
  
      this.dataTblIndexSum[0].sum = "合計（A～J）";
      for (let i = 1; i < this.dataTblIndexSum.length; i++) {
        let sum = 0;
        for (let prop in this.dataTblIndexSum[i]) {
          if (prop.startsWith('idx')) {
            sum += (isNaN(this.dataTblIndexSum[i][prop]) || !isFinite(this.dataTblIndexSum[i][prop])) ? 0 : Number(this.dataTblIndexSum[i][prop]);
          }
        }
        this.dataTblIndexSum[i]['sum'] = sum;
      }
    }

    if (this.zaitakuId === "ZD00000003") {
      // ■A　在宅復帰率
      this.dataTblA.push({ date: "", leave: "①前6月間における居宅への退所者数", discharge: "②前6月間における退所者の延数", death: "③前6月間における死亡した者の総数", rate: "④在宅復帰率(6ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let peopleLeaving = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'peopleLeaving', this.currentData);
        let newLeavers = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'newLeavers', this.currentData);
        let death = calculaTotalByPreviousMonth(6, convertToDate(element.date), 'death', this.currentData);
        rate = peopleLeaving / (newLeavers - death) * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) > 50) ? 20 : (30 < Number(rate) && Number(rate) <= 50) ? 10 : 0;
        this.dataTblA.push({ date: element.date, leave: peopleLeaving, discharge: newLeavers, death: death, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000004") {
      // ■B　ベッド回転率
      this.dataTblB.push({ date: "", inmates: "①直近3月間の延入所者日数", newly: "②直近3月間の新規入所者の延数", leave: "③直近3月間の新規退所者数", rate: "④ベッド回転率(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let cumulativeNew = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeNew', this.currentData);
        let newLeavers = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'newLeavers', this.currentData);
        rate = 30.4 / inmates * (cumulativeNew + newLeavers) / 2 * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) > 10) ? 20 : (5 <= Number(rate) && Number(rate) < 10) ? 10 : 0;
        this.dataTblB.push({ date: element.date, inmates: inmates, newly: cumulativeNew, leave: newLeavers, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000005") {
      // ■C　入所前後訪問指導割合
      this.dataTblC.push({ date: "", admission: "①前3月間の入所前後訪問指導を行った者の延数", newly: "②前3月間の新規入所者の延数", rate: "③入所前後訪問指導割合(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let admission = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'admission', this.currentData);
        let cumulativeNew = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeNew', this.currentData);
        rate = admission / cumulativeNew * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);

        let after202404 = seidokaiseiAfter202404(3, convertToDate(element.date), this.currentData);
        let index;
        if (after202404) {
          index = (rate == '') ? '' : (Number(rate) >= 35) ? 10 : (15 <= Number(rate) && Number(rate) < 35) ? 5 : 0;
        } else {
          index = (rate == '') ? '' : (Number(rate) >= 30) ? 10 : (10 <= Number(rate) && Number(rate) < 30) ? 5 : 0;
        }

        this.dataTblC.push({ date: element.date, admission: admission, newly: cumulativeNew, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000006") {
      // ■D　退所前後訪問指導割合
      this.dataTblD.push({ date: "", discharge: "①前3月間の退所前後訪問指導を行った者の延数", peopleLeaving: "②前3月間の居宅への新規退所者の延数", rate: "③退所前後訪問指導割合(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let discharge = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'discharge', this.currentData);
        let peopleLeaving = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'peopleLeaving', this.currentData);
        rate = discharge / peopleLeaving * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);

        let after202404 = seidokaiseiAfter202404(3, convertToDate(element.date), this.currentData);
        let index;
        if (after202404) {
          index = (rate == '') ? '' : (Number(rate) >= 35) ? 10 : (15 <= Number(rate) && Number(rate) < 35) ? 5 : 0;
        } else {
          index = (rate == '') ? '' : (Number(rate) >= 30) ? 10 : (10 <= Number(rate) && Number(rate) < 30) ? 5 : 0;
        }

        this.dataTblD.push({ date: element.date, discharge: discharge, peopleLeaving: peopleLeaving, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000007") {
      // ■E　居宅サービスの実施状況
      this.dataTblE.push({ date: "", serviceType: "①前3月間の提供実績（訪問リハ・通所リハ・短期療養）", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let serviceType  = '1';
        let index = 0;
        var dataService = getServiceTypeByPreviousMonth(3, convertToDate(element.date), 'serviceType', this.currentData);
        if(dataService) {
          index = dataService.serviceType
         var servetype = SERVICE_TYPE.filter(x=>x.value ==  (dataService.serviceType??0));
          if( servetype.length >0 ){
            serviceType = servetype[0].title;
          }
        } 
        // let serviceType = calculaTotalByPreviousMonth(3, new Date(element.date), 'serviceType', this.currentData);
        // let index = (serviceType == 3) ? 5 : (serviceType == 2) ? 3 : (serviceType == 1) ? 2 : 0;
        this.dataTblE.push({ date: element.date, serviceType: serviceType, index: index })
      });
    }

    if (this.zaitakuId === "ZD00000008") {
      // ■F　リハ専門職員の配置割合
      this.dataTblF.push({ date: "", healthFacility: "①前3月間の理学療法士等の当該施設に従事する勤務延時間数", physicalTherapists: "②前3月間の理学療法士等が勤務すべき時間", inmates: "③前3月間の延入所者日数", dayInMonth: "④前3月間の日数", rate: "⑤リハ専門職員の配置割合", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let healthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'healthFacility', this.currentData);
        let physicalTherapists = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'physicalTherapists', this.currentData);
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let dayInMonth = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'dayInMonth', this.currentData);
        rate = healthFacility / physicalTherapists / inmates * dayInMonth * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) >= 5) ? 5 : (3 <= Number(rate) && Number(rate) < 5) ? 3 : 0;
        this.dataTblF.push({ date: element.date, healthFacility: healthFacility.toFixed(1), physicalTherapists: physicalTherapists.toFixed(1), inmates: inmates, dayInMonth: dayInMonth, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000009") {
      // ■G　支援相談員の配置割合
      this.dataTblG.push({ date: "", careHealthFacility: "①前3月間の支援相談員の当該施設に従事する勤務延時間数", coumselors: "②前3月間の支援相談員が勤務すべき時間", inmates: "③前3月間の延入所者日数", dayInMonth: "④前3月間の日数", rate: "⑤支援相談員の配置割合", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let careHealthFacility = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'careHealthFacility', this.currentData);
        let coumselors = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'coumselors', this.currentData);
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        let dayInMonth = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'dayInMonth', this.currentData);
        rate = careHealthFacility / coumselors / inmates * dayInMonth * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);

        let after202404 = seidokaiseiAfter202404(3, convertToDate(element.date), this.currentData);
      
        let index;
        if (after202404) {
          let hasSocialLicense = element.hasSocialLicense;
          if (rate == '') index = ''
          else if (Number(rate) >= 3 && hasSocialLicense) index = 5
          else if (Number(rate) >= 3 && !hasSocialLicense) index = 3
          else if (Number(rate) >= 2 && Number(rate) < 3) index = 1
          else index = 0

        } else {
          index = (rate == '') ? '' : (Number(rate) >= 3) ? 5 : (2 <= Number(rate) && Number(rate) < 3) ? 3 : 0;
        }

        this.dataTblG.push({ date: element.date, careHealthFacility: careHealthFacility.toFixed(1), coumselors: coumselors.toFixed(1), inmates: inmates, dayInMonth: dayInMonth, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000010") {
      // ■H　介護度４又は５の割合
      this.dataTblH.push({ date: "", cumulativeDays: "①前3月間における要介護4、5に該当する入所者の延日数", inmates: "②前3月間の延入所者日数", rate: "③介護度４又は５の割合(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let cumulativeDays = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'cumulativeDays', this.currentData);
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        rate = cumulativeDays / inmates * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) >= 50) ? 5 : (35 <= Number(rate) && Number(rate) < 50) ? 3 : 0;
        this.dataTblH.push({ date: element.date, cumulativeDays: cumulativeDays, inmates: inmates, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000011") {
      // ■I　喀痰吸引の実施割合
      this.dataTblI.push({ date: "", aspiration: "①前3月間における喀痰吸引を実施した延入所者日数", inmates: "②前3月間の延入所者日数", rate: "③喀痰吸引の実施割合(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let aspiration = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'aspiration', this.currentData);
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        rate = aspiration / inmates * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) >= 10) ? 5 : (5 <= Number(rate) && Number(rate) < 10) ? 3 : 0;
        this.dataTblI.push({ date: element.date, aspiration: aspiration, inmates: inmates, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000012") {
      // ■J　経管栄養の実施割合
      this.dataTblJ.push({ date: "", feeding: "①前3月間における経管栄養を実施した延入所者日数", inmates: "②前3月間の延入所者日数", rate: "③経過栄養の実施割合(3ヶ月計)(%)", index: "指数" });
      this.dataZaitaku.forEach(element => {
        let rate;
        let feeding = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'feeding', this.currentData);
        let inmates = calculaTotalByPreviousMonth(3, convertToDate(element.date), 'inmates', this.currentData);
        rate = feeding / inmates * 100;
        rate = (isNaN(rate) || !isFinite(rate)) ? '' : rate.toFixed(1);
        let index = (rate == '') ? '' : (Number(rate) >= 10) ? 5 : (5 <= Number(rate) && Number(rate) < 10) ? 3 : 0;
        this.dataTblJ.push({ date: element.date, feeding: feeding, inmates: inmates, rate: rate == '' ? rate : rate + '%', index: index })
      });
    }

    if (this.zaitakuId === "ZD00000013") {
      // ■A　在宅復帰率（各月の実績）
      this.dataTblMonthA.push({ date: "", leave: "居宅", discharge: "退所者", death: "死亡" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthA.push({ date: element.date, leave:  formatValueNumber(element.peopleLeaving, '###'), 
          discharge: formatValueNumber(element.newLeavers, '###'), death: formatValueNumber(element.death, '###') })
      });
    }

    if (this.zaitakuId === "ZD00000014") {
      // ■B　ベッド回転率（各月の実績）
      this.dataTblMonthB.push({ date: "", inmates: "延入所者日数", newly: "新規入所者の延数", leave: "新規退所者数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthB.push({ date: element.date, inmates: formatValueNumber(element.inmates, '####'), newly: formatValueNumber(element.cumulativeNew, '###'), leave: formatValueNumber(element.newLeavers, '###') })
      });
    }

    if (this.zaitakuId === "ZD00000014" && this.hasHospitalNearby) {
      // ■B　ベッド回転率（各月の実績）
      this.dataTblMonthB.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }

    if (this.zaitakuId === "ZD00000015") {
      // ■C　入所前後訪問指導割合（各月の実績）
      this.dataTblMonthC.push({ date: "", admission: "入所前後訪問指導を行った者の延数", newly: "新規入所者の延数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthC.push({ date: element.date, admission: formatValueNumber(element.admission, '###'), newly: formatValueNumber(element.cumulativeNew, '###')})
      });
    }

    if (this.zaitakuId === "ZD00000016") {
      // ■D　退所前後訪問指導割合（各月の実績）
      this.dataTblMonthD.push({ date: "", discharge: "退所前後訪問指導を行った者の延数", peopleLeaving: "居宅への新規退所者の延数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthD.push({ date: element.date, discharge: formatValueNumber(element.discharge, '###'), peopleLeaving: formatValueNumber(element.peopleLeaving, '###')})
      });
    }

    if (this.zaitakuId === "ZD00000017") {
      // ■E　居宅サービスの実施状況（各月の実績）
      this.dataTblMonthE.push({ date: "", serviceType: "サービス種類数" });
      this.dataZaitaku.forEach(element => {
        var serviceType = '1';
        var servetype = SERVICE_TYPE.filter(x=>x.value ==  (element.serviceType??0));
        if(servetype.length > 0 ){
          serviceType = servetype[0].title;
        }
        this.dataTblMonthE.push({ date: element.date, serviceType: serviceType})
      });
    }

    if (this.zaitakuId === "ZD00000018") {
      // ■F　リハ専門職員の配置割合（各月の実績）
      this.dataTblMonthF.push({ date: "", healthFacility: "理学療法士等の当該施設に従事する勤務延時間数", physicalTherapists: "理学療法士等が勤務すべき時間", inmates: "延入所者日数", dayInMonth: "月の日数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthF.push({ date: element.date, healthFacility: formatValueNumber(element.healthFacility, '####.#'), physicalTherapists: formatValueNumber(element.physicalTherapists, '####.#'), inmates: formatValueNumber(element.inmates, '####'), dayInMonth: formatValueNumber(element.dayInMonth, '###')})
      });
    }

    if (this.zaitakuId === "ZD00000018" && this.hasHospitalNearby) {
      this.dataTblMonthF.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }

    if (this.zaitakuId === "ZD00000019") {
      // ■G　支援相談員の配置割合（各月の実績）
      this.dataTblMonthG.push({ date: "", careHealthFacility: "支援相談員の当該施設に従事する勤務延時間数", coumselors: "支援相談員が勤務すべき時間", inmates: "延入所者日数", dayInMonth: "月の日数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthG.push({ date: element.date, careHealthFacility: formatValueNumber(element.careHealthFacility, '####.#'), coumselors: formatValueNumber(element.coumselors, '####.#'), inmates: formatValueNumber(element.inmates, '####'), dayInMonth: formatValueNumber(element.dayInMonth, '###')})
      });
    }

    if (this.zaitakuId === "ZD00000019" && this.hasHospitalNearby) {
      this.dataTblMonthG.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }

    if (this.zaitakuId === "ZD00000020") {
      // ■H　介護度４又は５の割合（各月の実績）
      this.dataTblMonthH.push({ date: "", cumulativeDays: "要介護4、5に該当する入所者の延日数", inmates: "延入所者日数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthH.push({ date: element.date, cumulativeDays: formatValueNumber(element.cumulativeDays, '####'), inmates: formatValueNumber(element.inmates, '####') })
      });
    }
    if (this.zaitakuId === "ZD00000020" && this.hasHospitalNearby) {
      this.dataTblMonthH.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }

    if (this.zaitakuId === "ZD00000021") {
      // ■I　喀痰吸引の実施割合（各月の実績）
      this.dataTblMonthI.push({ date: "", aspiration: "入所ごとの喀痰吸引を実施した延入所者日数", inmates: "延入所者日数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthI.push({ date: element.date, aspiration: formatValueNumber(element.aspiration, '###'), inmates: formatValueNumber(element.inmates, '####') })
      });
    }

    if (this.zaitakuId === "ZD00000021" && this.hasHospitalNearby) {
      this.dataTblMonthI.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }

    if (this.zaitakuId === "ZD00000022") {
      // ■J　経管栄養の実施割合（各月の実績）
      this.dataTblMonthJ.push({ date: "", feeding: "入所ごとの経管栄養を実施した延入所者日数", inmates: "延入所者日数" });
      this.dataZaitaku.forEach(element => {
        this.dataTblMonthJ.push({ date: element.date, feeding: formatValueNumber(element.feeding, '###'), inmates: formatValueNumber(element.inmates, '####') })
      });
    }

    if (this.zaitakuId === "ZD00000022" && this.hasHospitalNearby) {
      this.dataTblMonthJ.map(item => {
        if (item.date == "") {
          item["sameDayDischarge"] = '併設病院から退院日同日入所';
        } else {
          item["sameDayDischarge"] = this.dataZaitaku.find(x => x.date == item.date)?.sameDayDischarge ?? 0;
        }
      })
    }
    
  }


  handleCaseSameDayDischarge() {
    if (this.hasHospitalNearby) {
      this.dataZaitaku = this.dataZaitaku?.map(obj => {
        const newObj = { ...obj };
        newObj.inmates = newObj.inmates - (newObj.sameDayDischarge ?? 0);
        return newObj;
      });
    }
  }

}
