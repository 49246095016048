import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { Folder } from '../../../app/models/response/folder.ro';
import { Assign } from '../../../app/models/response/assign.ro';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { FolderAddDTO } from '../../../app/models/request/folder.dto';
import {ErrorHandleService} from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class FolderService extends ApiService {
  private folderCd: any = null;

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }


  getAll() {
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.ALL;
    let resData = this.get(apiUrl);
    return this.mapModel(resData);
  }

  setFolderCd(folderCd: any) {
    this.folderCd = folderCd;
  }

  getFolderCd() {
    return this.folderCd;
  }

  async getByType(code: string, isSupporterOrAdmin?: boolean) {
    let parameter = [
      code,
      (isSupporterOrAdmin) ? isSupporterOrAdmin : false
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYTYPE;
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModel(resData);
  }

  async getFolderTemplateByType(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYTYPE + "/" + code + API_APP.FOLDER.TEMPLATE;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }

  async getDatasourceFolderStructByLevel(datasourceCds: string[], level: number, isSupporterAdmin: boolean, isTemplate: boolean)
  {
    let parameter = {
      datasourceCds: datasourceCds.join("-"),
      level,
      isSupporterAdmin,
      isTemplate,
    };
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETBYLEVEL;
    let resData = await this.getByParameter(apiUrl, parameter);

    return resData.statuscode !== 200 ? [] : resData.data ?? [];
  }
  async getStructByKeywork(keywork: string, isSupporterAdmin: boolean, isTemplate: boolean)
  {
    let parameter = {
      keywork,
      isSupporterAdmin,
      isTemplate
    };
    let apiUrl = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.GETSTRUCTBYKEYWORK;
    let resData = await this.getByParameter(apiUrl, parameter);

    return resData.statuscode !== 200 ? [] : resData.data ?? [];
  }

  async mapModel(data: any) {
    let res: ResponseData<Folder[]> = new ResponseData<Folder[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let folder = new Folder();
        let assign = new Assign()
        folder.id = element.id;
        folder.folderCd = element.foldercd;
        folder.description = element.folderdesc;
        folder.folderType = element.foldertype;
        folder.name = element.foldername;
        folder.folderName = element.foldername;
        folder.insdate = element.insdate;
        folder.insstfcd = element.insstfcd;
        folder.upddate = element.upddate;
        folder.updstfcd = element.updstfcd;
        assign.AssignId = element.assigncd;
        assign.assignCd = element.assigncd;
        assign.act = element.act;
        assign.teamCd = element.teamcd;
        assign.staffCd = element.staffcd;
        folder.assign = assign;
        folder.istemplate = element.istemplate;
        folder.assigntype = element.assigntype
        folder.act = element.act;
        folder['name-subText'] = element.folderdesc;
        dataArr.push(folder);
      }
    }
    res.data = dataArr;
    return res;
  }

  insertOrUpdate(request: any) {
    let uri = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.INSERTORUPDATE;
    return this.post(uri, [], request);
  }

  delete(folders: FolderAddDTO[]) {
    let uri = API_APP.FOLDER.CONTROLLER + API_APP.FOLDER.DELETE;
    return this.post(uri, [], folders);
  }
}
