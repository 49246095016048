<div class="lazy-tree-node-container" (click)="handleNodeClick($event)"  >

  <ng-container *ngIf = "node.isFolder">
    <div class="folder-container">
      <svg class="folder-icon" *ngIf="node.expanded" width="21" height="18" viewBox="0 0 26 18" fill="none" >
        <path d="M17 17H4.2C3.07989 17 2.51984 17 2.09202 16.782C1.71569 16.5903 1.40973 16.2843 1.21799 15.908C1 15.4802 1 14.9201 1 13.8V4.2C1 3.07989 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1H7.93333C8.3291 1 8.52698 1 8.71704 1.03715C8.88569 1.07012 9.04938 1.12468 9.20408 1.1995C9.37841 1.28381 9.53672 1.40254 9.85333 1.64L10.8133 2.36C11.1299 2.59746 11.2883 2.71619 11.4626 2.8005C11.6173 2.87532 11.781 2.92988 11.9496 2.96285C12.1397 3 12.3376 3 12.7333 3H16.4667C17.5868 3 18.1468 3 18.5746 3.21799C18.951 3.40973 19.2569 3.71569 19.4487 4.09202C19.6667 4.51984 19.6667 5.07989 19.6667 6.2V6.5" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M4.19536 9.01151C4.48589 8.28523 4.63115 7.92208 4.87388 7.65497C5.08836 7.41895 5.35616 7.23763 5.65496 7.12616C5.99312 7 6.38424 7 7.16647 7H20.2734C21.8569 7 22.6486 7 23.1509 7.33301C23.5902 7.62423 23.8968 8.07708 24.0041 8.59311C24.1267 9.18321 23.8326 9.91831 23.2445 11.3885L21.8046 14.9881C21.5141 15.7144 21.3688 16.0775 21.1261 16.3446C20.9116 16.5807 20.6438 16.762 20.345 16.8734C20.0069 16.9996 19.6158 16.9996 18.8335 16.9996H3.36329C2.57156 16.9996 2.1757 16.9996 1.92453 16.8331C1.70488 16.6875 1.55158 16.4611 1.49797 16.203C1.43666 15.908 1.58368 15.5404 1.87773 14.8054L4.19536 9.01151Z" stroke="black" stroke-width="2"/>
      </svg>
      <svg class="folder-icon" *ngIf="!node.expanded" width="21" height="18" viewBox="0 0 23 18" fill="none">
          <path d="M3.05014 1H7.46478C7.89791 1 8.31933 1.14061 8.66569 1.40068L11.957 3.87205C12.3033 4.13212 12.7247 4.27273 13.1579 4.27273H19.6651C20.7454 4.27273 21.6305 5.13068 21.6641 6.21049L21.9358 14.9378C21.9709 16.0662 21.0657 17 19.9368 17H3.34731C2.26203 17 1.37479 16.1345 1.34792 15.0495L1.05075 3.04951C1.02293 1.9259 1.92618 1 3.05014 1Z" stroke="black" stroke-width="2"/>
      </svg>
      <span class="folder-text">{{node.text || COMMON_TEXT.EMPTY_NODE}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!node.isFolder">
    <div class="node-icon" *ngFor="let lastPrent of node.isParentLastChild; index as i">
      <ng-container *ngIf="i == 0">
        <svg *ngIf="!lastPrent" width="20" height="100%">
          <line x1="5" y1="0" x2="5" y2="100%" stroke="#969696" stroke-linecap="butt" />
        </svg>
        <svg *ngIf="lastPrent" width="20" height="100%" class="svg-hide">
          <line x1="5" y1="0" x2="5" y2="100%"  stroke-linecap="butt" />
        </svg>
      </ng-container>
      <ng-container *ngIf="i != 0">
        <svg *ngIf="!lastPrent" width="10" height="100%">
          <line x1="5" y1="0" x2="5" y2="100%" stroke="#969696" stroke-linecap="butt" />
        </svg>
        <svg *ngIf="lastPrent" width="10" height="100%" class="svg-hide">
          <line x1="5" y1="0" x2="5" y2="100%" stroke-linecap="butt" />
        </svg>
      </ng-container>
    </div>
    <div class="node-text-container">
      <div class="node-switch">
        <ng-container *ngIf="node.level == 1">
          <svg *ngIf="!node.isLastChild" width="20" height="27" viewBox="0 0 20 27" fill="none">
            <line x1="5" y1="0" x2="5" y2="30" stroke="#969696" stroke-linecap="butt" />
            <line x1="5" y1="14" x2="20" y2="14" stroke="#969696" stroke-linecap="butt" />
          </svg>
          <svg *ngIf="node.isLastChild" width="20" height="27" viewBox="0 0 20 27" fill="none">
            <line x1="5" y1="0" x2="5" y2="14" stroke="#969696" stroke-linecap="butt" />
            <line x1="5" y1="13.5" x2="20" y2="13.5" stroke="#969696" stroke-linecap="butt" />
          </svg>
        </ng-container>
        <ng-container  *ngIf="node.level != 1">
          <svg *ngIf="!node.isLastChild" width="10" height="27" viewBox="0 0 10 27" fill="none">
            <line x1="5" y1="0" x2="5" y2="30" stroke="#969696" stroke-linecap="butt" />
            <line x1="5" y1="14" x2="10" y2="14" stroke="#969696" stroke-linecap="butt" />
          </svg>
          <svg *ngIf="node.isLastChild" width="10" height="27" viewBox="0 0 10 27" fill="none">
            <line x1="5" y1="0" x2="5" y2="14" stroke="#969696" stroke-linecap="butt" />
            <line x1="5" y1="13.5" x2="10" y2="13.5" stroke="#969696" stroke-linecap="butt" />
          </svg>
        </ng-container>
      </div>
      <div class="node-text" position = "null" [ngClass]="{'draggable': node.draggable ,'not-draggable': !node.draggable, 'selected': node.selected }" [ngStyle]= "{'cursor':'grabbing'}" positionStyle="p-tooltip-right" [pDraggable]="dragScope"
        [pDraggableDisabled]="!node.draggable" [ngStyle]="node.style" (onDragStart)="dragStart($event)" (dragover) = "dragOver($event)"  (dragend) ="dragEnd($event)">
        <div class="node-container-name" (mouseenter)="mouseEnterContainer($event) " [appendTo]="'body'"  tooltipPosition="right" [pTooltip]="node.tooltip ?? ''"
          (mouseleave)="mouseLeaveContainer($event)">
          <div *ngIf="!node.isLastNode" class="node-icon">
            <svg class="node-icon-svg " [class]="node.expanded ? (node.id == nodeClicked.id ? 'animaexpan': 'expan') : (node.id == nodeClicked.id ? 'animcolap': '')" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M4 1L9 6L4 11" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
          <div class="node-name" last-node [ngClass]="{ 'last-node': node.isLastNode}">
            {{node.text || COMMON_TEXT.EMPTY_NODE}}
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </ng-container>
</div>