<div class="sync-data-header">
    <span>{{SYSTEM_SETTING.PIVOT_MASTER_SYNC}}</span>
</div>
<div class="sync-data-body">
    <div>
        <p class="content-2-title pre-line">{{SYSTEM_SETTING.SYNC_INFO}}</p>
    </div>
</div>

<button class="button-show-dialog-update-widget" 
        		(click)="onSyncMasterData()" [ngStyle]="{'background-color': iconType == false? '$white-color' : '$background-gray-color'}">
    			<div class="img-of-button" >
        				<img class="text-of-button" [ngClass]="isDarkMode ? 'dark-mode' : 'default'">{{BUTTON.START_SYNC}}
				</div>
</button>

<pivot-dialog-sync-info-detail class="sync-info-detail"
    [isSyncing]="isSyncing" 
    [isDisplayModal]="showSyncInfoDetailDialog"
    [modalData]="syncInfoDetailModal" 
    [data]="syncDetailData"
    [statusOfAllSyns]="statusOfAllSyns"
    (onSubmitData)="handleModalDetailClose()">
</pivot-dialog-sync-info-detail>

