import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedDataService {

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private notify: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  notify$: Observable<any> = this.notify.asObservable();
  private storeData: any = {};

  constructor() { }


  clearStoreData() {
    this.storeData = {};
  }

  setItem(key: string, value: any) {
    this.storeData[key] = value;
  }

  getItem(key: string) {
    return this.storeData[key] || null;
  }
  removeItem(key: string) {
    delete this.storeData[key];
  }

  setDataObservable(newData: any) {
    this.data.next(newData);
  }

  setNotifyObservable(newData: any) {
    this.notify.next(newData);
  }

}