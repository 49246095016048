import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from 'rxjs/operators';
import { ROUTE_PATH } from "../../../../app/const/route-path";
import { COMMON_TEXT, MENU_NAME } from "../../../../app/const/text-common";
import { Menu } from "../../../../app/models/response/menu.ro";
import { SidebarExpandHandleService } from "../../../../app/services/sidebar-expand-handle.service";
import { Title } from '@angular/platform-browser';
import { DeviceType } from "src/app/enum/common-enum";
import { DetectDeviceService, IDeviceType } from "src/app/services/detect-device.service";

@Component({
  selector: "pivot-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isExpanded: boolean = false;
  @Input() data: Menu[] = [];
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  routePath = ROUTE_PATH;
  menuName = MENU_NAME;
  DeviceType = DeviceType;
  currentRoute: string = '';
  routerSubscriber: Subscription | undefined;
  deviceType: string;

  constructor(
    private router: Router,
    private titleService: Title,
    private sidebarExpandHandleService: SidebarExpandHandleService,
    private detectDeviceService: DetectDeviceService,
  ) {
    this.currentRoute = window.location.pathname;
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
      if (device) this.deviceType = device.type;
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.routerSubscriber = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  handleSidebarToggle(event: any) {
    this.toggleSidebar.emit(!this.isExpanded);
    this.sidebarExpandHandleService.newEvent(!this.isExpanded);
  }

  expandCollapseChild(event: any) {
    if (event.currentTarget.parentElement.getElementsByClassName('children')[0].classList.contains("display-none")) {
      event.currentTarget.parentElement.getElementsByClassName('children')[0].classList.remove("display-none");
      event.currentTarget.getElementsByClassName('expand-collapse')[0].getElementsByClassName('pi-chevron-down')[0].classList.remove("display-none");
      event.currentTarget.getElementsByClassName('expand-collapse')[0].getElementsByClassName('pi-chevron-up')[0].classList.add("display-none");
    } else {
      event.currentTarget.parentElement.getElementsByClassName('children')[0].classList.add("display-none");
      event.currentTarget.getElementsByClassName('expand-collapse')[0].getElementsByClassName('pi-chevron-up')[0].classList.remove("display-none");
      event.currentTarget.getElementsByClassName('expand-collapse')[0].getElementsByClassName('pi-chevron-down')[0].classList.add("display-none");
    }
  }

  handleMouseEnter(e: MouseEvent, menu: Menu) {
    if (this.isActiveRoute(menu)) {
      return;
    }


    const target = e.target as any;
    if (!target) {
      return;
    }

    const imageElement = target.querySelector(`#${this.getMenuImageId(menu)}`);
    if (!imageElement) {
      return;
    }

    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    imageElement.src = this.getIconPath(menu, isDarkMode ? false : true);
  }

  handleMouseLeave(e: MouseEvent, menu: Menu) {
    if (this.isActiveRoute(menu)) {
      return;
    }

    const target = e.target as any;
    if (!target) {
      return;
    }

    const imageElement = target.querySelector(`#${this.getMenuImageId(menu)}`);
    if (!imageElement) {
      return;
    }

    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    imageElement.src = this.getIconPath(menu, isDarkMode ? true : false);
  }

  isActiveRoute(menu: Menu) {
    return this.currentRoute === '/' + menu.routerLink;
  }

  getImageSrc(menu: Menu) {
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (this.isActiveRoute(menu)) {
      return this.getIconPath(menu, isDarkMode ? false : true);
    }

    return this.getIconPath(menu, isDarkMode ? true : false);
  }

  getIconPath(menu: Menu, isHover: boolean = false) {
    if (!menu.icon) {
      return '';
    }

    const iconPath = '../../../../assets/icons/';
    return `${iconPath}${menu.icon}-${isHover ? 'hover' : 'default'}.svg`;
  }

  hasChild(menu: Menu) {
    return menu.childs && menu.childs.length > 0;
  }

  getMenuImageId(menu: Menu) {
    return `menu-${menu.id}-${menu.icon}`;
  }

  getMainMenuIconSource(iconName: string) {
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    switch (iconName) {
      case 'hamburger':
        return isDarkMode ? "../../../../assets/icons/hamburger-hover.svg" : "../../../../assets/icons/hamburger-default.svg";
      case 'r-arrow':
        return isDarkMode ? "../../../../assets/icons/r-arrow-hover.svg" : "../../../../assets/icons/r-arrow-default.svg";
      case 'l-arrow':
        return isDarkMode ? "../../../../assets/icons/l-arrow-hover.svg" : "../../../../assets/icons/l-arrow-default.svg";
      default:
        return ''
    }
  }
  // // Hàm sẽ được sử dụng trong tương lai
  // getBrowserTitle(currentPath: string): string {
  //   const corpLocalStorage = LocalStorageHelper.getCorpPrefix();
  //   const pathElement = currentPath.replace(`/${corpLocalStorage}`, '').split("/");
  //   const path = pathElement[1] == 'datasource' ? pathElement[1] + '/' + pathElement[2] :pathElement[1] == 'dashboard-setting' ? pathElement[1] + '/' + pathElement[3] : pathElement[1]
  //   const menuItem = MENU.find((menu: any) => menu.path === path);
  //   if (menuItem) {
  //     return menuItem.label;
  //   }
  //   return 'PIVOT';
  // }
}
