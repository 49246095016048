<pivot-modal-template class="folder-modal form-modal" [modalTemplate]="modalTemplate"
(closeModalHandler)="closeFormModal()" >
<div class="folder-modal-body" modal-body>
  <div class="controls">
    <div class="row">
      <label>{{COMMON_TEXT.FOLDER}}</label>
    </div>
    <div class="row">
      <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
    </div>
  </div>
  <pivot-table class='table' [data]="tableData" [isLoading]="loadingTable" (clickRow)="clickRowTable($event)" [selectedRow]="selectedFolder">
  </pivot-table>
</div>
<div class="folder-modal-bottom" modal-bottom>
  <div class="left-pannel">
    <pivot-text-button (btnClick)="createNewFolder()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.FOLDER_CREATE"></pivot-text-button>
  </div>
  <div class="right-pannel">
    <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL"></pivot-text-button>
    <pivot-text-button (btnClick)="save()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT"></pivot-text-button>
  </div>

</div>
</pivot-modal-template>

<pivot-dialog-folder-editor [data]="folderData" [isTemplate]="isTemplate" [isDisplayModal]="isDisplayFolderModal" [isShowAssign]="!isTemplate" [modalData]="modalFolder" [folderType]="folderType"
    (onSubmitData)="onChangeDataFolder($event)"></pivot-dialog-folder-editor>
