<p-orderList [value]="params.items" [dragdrop]="dragdrop" [styleClass]="'pivot-list-filter ' + params.styleClass"
    [style]="params.cssStyle" [listStyle]="params.listStyle" (onReorder)="sortListData()"
    [disabled]="params.disabled || false"
    (onSelectionChange)="selectionChange($event)"
    [(selection)]="selection">
    <ng-template let-data pTemplate="item">
        <div class="list-item">
            <div class="list-detail">
                <label class="item-left">{{data[params.itemDisplayText]}}</label>
                <div class="item-right" *ngIf="displayfilter">
                    <div class="item-filter" [ngClass]="[params.onfilter === true && data.filtertype ? 'allow-filter': 'not-filter']"
                        (click)="filterData(data)"></div>
                    <div class="item-delete" (click)="deleteItem(data)">
                        <i class="pi pi-times"></i>
                    </div>
                </div>
                <div class="item-right" *ngIf="!displayfilter && !params.disabled" >
                    <div class="item-filter"></div>
                    <div class="item-close" (click)="deleteItem(data)">
                        <i class="pi pi-times"></i>
                    </div>
            </div>
            </div>
        </div>
    </ng-template>
</p-orderList>