import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DeviceType } from '../../../app/enum/common-enum';
import { ImageCropperComponent } from '../../component/common/image-cropper/image-cropper.component';
import { MESSAGE_TEXT } from '../../const/message';
import { BASIC_INFO } from '../../const/text-common';
import { ModalTemplate } from '../../models/common-model';
import { Staff } from '../../models/response/staff.ro';
import { AuthenticationService } from '../../services/authentication.service';
import { ProcessLoadingService } from '../../services/loading.service';
import { BasicInfoCommunicatorService } from '../../services/modules/basic-info-communicator.service';
import { StaffService } from '../../services/modules/staff.service';
import { getAvatarUrl } from '../../_helper/helper';
import { LocalStorageHelper, LocalStorageKey } from '../../_helper/local-storage.helper';
import { environment } from '../../../environments/environment';
import { LoginInfoModel } from 'src/app/models/login-info.model';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';

@Component({
  selector: 'pivot-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {
  @Output() backPage = new EventEmitter();

  BASIC_INFO = BASIC_INFO;
  MESSAGE_TEXT = MESSAGE_TEXT;
  DeviceType = DeviceType;
  selectedTheme: number = -1;

  deviceType: string = '';
  userAvatarUrl: any = '../../../assets/images/user-avatar.svg';
  roleArr: any[] = [{ rolename: '管理職', lstdashboard: [], lstpermission: [] }];
  isInit: boolean = true;

  currentStaff: Staff = new Staff();
  loggedInUser: any;

  avatarValid: boolean = true;
  showAvatarInfoDialog: boolean = false;
  modalAvatar: ModalTemplate = new ModalTemplate();
  loginInfo: LoginInfoModel = new LoginInfoModel();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenService: AuthenticationService,
    private staffService: StaffService,
    private loadingService: ProcessLoadingService,
    private lyDialog: LyDialog,
    private comtorService: BasicInfoCommunicatorService,
    private errorHandleService: ErrorHandleService
  ) {
    this.deviceType = DeviceType.DESKTOP;

    this.authenService.currentUser.subscribe(async (user: any) => {
      if (!user) {
        return;
      }

      this.loadingService.isLoading.emit(true);
      this.loggedInUser = user;

      if (user.avatarurl) {
        this.userAvatarUrl = getAvatarUrl(this.loggedInUser.staffcode, false, user.avatarurl);
        this.comtorService.sendUserAvatar(this.userAvatarUrl);
      }

      this.currentStaff.firstName = user.stafffirstname;
      this.currentStaff.lastName = user.stafflastname;
      this.currentStaff.fullName = user.fullname;
      this.currentStaff.staffCd = user.staffcode;
      this.currentStaff.userId = user.fullname;
      this.currentStaff.phoneNunber = user.phonenunber;
      this.currentStaff.email = user.email;
      this.currentStaff.roles = this.authenService.getData(LocalStorageKey.CURRENT_USER_ROLE) || [];

      this.loginInfo = LocalStorageHelper.getLoginInfo();
      
      this.loadingService.isLoading.emit(false);
    });
  }

  async ngOnInit(): Promise<void> {
    this.isInit = false;
    this.getLocalStorageTheme();
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
      }
    }
  }

  async openCropperDialog(event: any) {
    const file = event.target?.files[0];
    const filename = file?.name;
    if (filename) {
      if (filename.includes("svg") || filename.includes("jpg") || filename.includes("jpeg") || filename.includes("png")) {
        let imageSize = file?.size;
        if (imageSize > 0) {
          let checkimageSize = imageSize / (1024 * 1024);

          // Check file is image with size image.
          let isValidImage;
          await this.checkIsValidImage(file).then(height => {
            if (height) {
              isValidImage = true;
            }
          }).catch(error => {
            isValidImage = false;
          });

          if (checkimageSize > 3 || !isValidImage) {
            event.target.value = null;
            this.openAvatarRuleDialog();
          } else {
            this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
            this.lyDialog.open<ImageCropperComponent, Event>(ImageCropperComponent, {
              data: event,
              width: 500,
              disableClose: true
            }).afterClosed.subscribe(async (result?: ImgCropperEvent) => {
              if (result) {
                this.loadingService.isLoading.emit(true);

                const res = await this.staffService.uploadAvatar(file);
                if (res.statuscode == 200) {
                  this.avatarValid = true;

                  if (res.data?.avatarurl) {
                    this.userAvatarUrl = getAvatarUrl(this.loggedInUser.staffcode, false, res.data?.avatarurl);
                    this.comtorService.sendUserAvatar(this.userAvatarUrl);

                    const currentUser = this.authenService.getData(LocalStorageKey.CURRENT_USER);
                    currentUser.avatarurl = res.data?.avatarurl;
                    this.authenService.setCurrentUser(currentUser);
                  }
                }

                this.loadingService.isLoading.emit(false);
              }
            });
          }
        }
      }
      else {
        event.target.value = null;
        this.openAvatarRuleDialog();
      }
    }
  }

  goBack() {
    this.backPage.emit();
  }

  async checkIsValidImage(file: any) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img.height);
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  }

  clickRowTable(event: any) { }

  checkRowsTable(event: any) { }

  handleAvatarError(event: any) {
    this.avatarValid = false;
  }

  openAvatarRuleDialog() {
    this.modalAvatar.header = BASIC_INFO.AVATAR_RULE_TITLE;
    this.modalAvatar.style = { 'width': '393px' };
    this.modalAvatar.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.showAvatarInfoDialog = true;
  }

  handleCloseAvatarRuleDialog(event: any) {
    this.showAvatarInfoDialog = false;
  }

  changeBackgroundStyle(theme: any) {
    let className = '';
    switch (theme) {
      case 1:
        className = 'dark';
        break;
      case 2:
        className = 'navi';
        break;
      default:
        className = '';
        break;
    }
    this.selectedTheme = theme;
    this.document.body.className = className;
    this.setLocalStorageTheme(this.currentStaff.staffCd, theme);
  }

  setLocalStorageTheme(staffCd: any, theme: any) {
    let localValue = localStorage.getItem(LocalStorageKey.THEME);
    if (localValue) {
      let themeOptions = JSON.parse(localValue);
      let option = themeOptions.filter((v: any) => v.staffCd === this.currentStaff.staffCd);
      if (option && option.length > 0) {
        option[0].theme = theme;
      } else {
        themeOptions.push({ staffCd: staffCd, theme: theme });
      }
      localStorage.setItem(LocalStorageKey.THEME, JSON.stringify(themeOptions));
    } else {
      localStorage.setItem(LocalStorageKey.THEME, JSON.stringify([{ staffCd: staffCd, theme: theme }]));
    }
  }

  getLocalStorageTheme() {
    let localValue = localStorage.getItem(LocalStorageKey.THEME);
    if (localValue) {
      let themeOptions = JSON.parse(localValue);
      let option = themeOptions.filter((v: any) => v.staffCd === this.currentStaff.staffCd);
      if (option && option.length > 0) {
        this.selectedTheme = option[0].theme;
      }
    }
  }

  onClickChangeUser() {
    const user = this.authenService.currentUserValue;
    if (!user) return;

    window.open(environment.CKPASS_WEBAPP_URL + 'account-information?accessToken=' + user?.token
            + '&refreshToken=' + user?.refreshtoken);
  }
}
