<div class="temdlg">
    <div class="temdlg__body">
      <div class="temdlg__body__common">
        <div class="temdlg__body__common__number">
          <div class="temdlg__body__common__number__circle selected">1</div>
          <div class="temdlg__body__common__number__threedots">
            <i *ngFor='let key of  [1,2,3]' class="pi pi-circle-fill"></i>
          </div>
          <div class="temdlg__body__common__number__circle" [ngClass]="{'selected': tabType > 1}">2</div>
          <div class="temdlg__body__common__number__threedots">
            <i *ngFor='let key of  [1,2,3]' class="pi pi-circle-fill"></i>
          </div>
          <div class="temdlg__body__common__number__circle" [ngClass]="{'selected': tabType > 2}">3</div>
        </div>
        <div class="temdlg__body__common__label">
          <div class="temdlg__body__common__label--msg1">事業所選択</div>
          <div class="temdlg__body__common__label--msg2">データソース情報登録</div>
          <div class="temdlg__body__common__label--msg3">確認</div>
        </div>
        <div  class="temdlg__body__common__message">
            <label *ngIf="tabType == 1">集計対象の事業所を選択してください。</label>
            <label *ngIf="tabType == 2">データソースを保存するフォルダの選択と名称を設定してください。</label>
            <label *ngIf="tabType == 3">設定した内容に間違いがないか確認してください。</label>
        </div>
      </div>
      <div class="temdlg__body__tab" *ngIf="tabType == 1">
        <div class="tabtem1">
          <div class="tabtem1__filter">
            <label class="tabtem1__filter--label">{{COMMON_TEXT.OFFICE}}</label>
            <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
            <div class="tabtem1__filter__services">
              <div class="tabtem1__filter__services__items" [ngClass]="dispalyFilterServices.length > 0 ? 'has-items' : ''">
                <div class="tabtem1__filter__services__items__item" *ngFor="let service of dispalyFilterServices">
                  <div class="tabtem1__filter__services__items__item--name">{{service.name}}</div>
                  <div class="tabtem1__filter__services__items__item--remove">
                    <i class="pi pi-times" (click)="removeServiceFilter(service)"></i>
                  </div>
                </div>
                <div class="tabtem1__filter__services__items__more" *ngIf="isDisplayNumber && remaningNumber > 0">
                  ...
                  <div class="tabtem1__filter__services__items__more--number">
                    {{remaningNumber}}
                  </div>
                </div>
              </div>
              <div class="tabtem1__filter__services__icon">
                <i [ngClass]="filterServices.length > 0 ? 'pi pi-filter-fill': 'pi pi-filter-slash'" id="filter-icon" (click)="showModalFilter()"></i>
                <pivot-filter-modal (closeModalHandler)="isShowModal = false" (checkRows)="filterData($event)" [selectedServices]="filterServices"
                  [styleModal]="filterModalStyle" [itemList]="listServices" *ngIf="isShowModal"></pivot-filter-modal>
              </div>
            </div>
          </div>
          <pivot-table class="tabtem1__table" [data]="tableData" [isLoading]="loadingTable" [selectedRows]="selectedOffices" (checkRows)="checkRowsTable($event)"></pivot-table>
        </div>

      </div>
      <div class="temdlg__body__tab" *ngIf="tabType == 2">
        <div class="tabtem2">
          <label class="tabtem2--label">集計期間</label>
          <div class="tabtem2__period">
            <div class="tabtem2__period__view">
              <div class="tabtem2__period__view--date">{{formatRangeDate(selectedRangeDate)}}</div>
            </div>
            <div class="tabtem2__period__select">
              <p-dropdown class="tabtem2__period__select__primeNgDropdown" [options]="groupedPeriod" [(ngModel)]="selectedPeriod" [group]="true">
                <ng-template let-group pTemplate="group">
                  <div class="p-d-flex p-ai-center">
                    <span>{{group.label}}</span>
                  </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                  <div (click)="openRangeDateModal(option)" class="option-item">{{option.label}} </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <label class="tabtem2--label">データソース保存先設定</label>
          <div class="tabtem2__datasource">
            <label class="tabtem2__datasource--message" *ngIf="isShowMsgExistDs">既に同じデータソースが存在します。</label>
            <div class="tabtem2__datasource__new">
              <div class="tabtem2__datasource__new__radio">
                <pivot-radio-button [isChecked]="isOptionCreate" (onClick)="onCheckbox(1)"></pivot-radio-button>
                <label class="tabtem2__datasource__new__radio--label" (click)="onCheckbox(1)">新規にデータソースを作成する</label>
              </div>
              <div class="tabtem2__datasource__new__folder">
                <label class="tabtem2__datasource__new__folder--label">データソースフォルダ選択</label>
                <div class="tabtem2__datasource__new__folder__select">
                  <div class="tabtem2__datasource__new__folder__select--name">{{selectedFolder.name}}</div>
                  <div class="tabtem2__datasource__new__folder__select--button">
                    <pivot-text-button (btnClick)="showFolderModal()" [disabled]="!isOptionCreate"  [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT_FOLDER"></pivot-text-button>
                  </div>
                 
                </div>
              </div>
              <div class="tabtem2__datasource__new__name">
                <label class="tabtem2__datasource__new__name--label">データソース名</label>
                <pivot-textbox [inputParams]="inputDesParams" (onInput)="onInputDescription($event)" [inputValue]="datasourceNm"></pivot-textbox>
              </div>
            </div>
            
            <div class="tabtem2__datasource__exist">
              <div class="tabtem2__datasource__exist__radio">
                <pivot-radio-button [isChecked]="!isOptionCreate" [disabled]="!isShowMsgExistDs" (onClick)="onCheckbox(2)"></pivot-radio-button>
                <label class="tabtem2__datasource__exist__radio--label" (click)="onCheckbox(2)">既にあるデータソースを使用する</label>
              </div>
              <div class="tabtem2__datasource__exist__table">
                <p-orderList class="tabtem2__datasource__exist__table__primeNgList" [value]="existDatasources" [(selection)]="selectedExistDs" [disabled]="isOptionCreate" (onSelectionChange)="onSelectionChange($event)">
                  <ng-template let-ds pTemplate="item">
                    <div>{{ds.dsname}}</div>
                  </ng-template>
                </p-orderList>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="temdlg__body__tab" *ngIf="tabType == 3">
        <div class="tabtem3">
          <div class="tabtem3__office">
            <label class="tabtem3__office--label">事業所</label>
            <div class="tabtem3__office__content">
              <p-orderList [value]="selectedOffices" [disabled]="true" class="tabtem3__office__content__primeNgList">
                <ng-template let-office pTemplate="item">
                  <div>{{office.officeNm}}</div>
                </ng-template>
              </p-orderList>
            </div>
          </div>
          <div class="tabtem3__period">
            <label class="tabtem3__period--label">集計期間</label>
            <div class="tabtem3__period__content">{{formatRangeDate(selectedRangeDate)}}</div>
          </div>
          <div class="tabtem3__dsfolder">
            <label class="tabtem3__dsfolder--label">データソースフォルダ選択</label>
            <div class="tabtem3__dsfolder__content">{{isOptionCreate ? selectedFolder.name : selectedExistDs[0]?.foldername || ""}}</div>
          </div>
          <div class="tabtem3__dsname">
            <label class="tabtem3__dsname--label">データソース名</label>
            <div class="tabtem3__dsname__content">{{isOptionCreate ? datasourceNm : selectedExistDs[0]?.dsname || ""}}</div>
          </div>
          <div class="tabtem3__dsexist">
            <label class="tabtem3__dsexist--label">既存データソース</label>
            <div class="tabtem3__dsexist__content">{{isOptionCreate ? "" : getExistDatasourceNm()}}</div>
          </div>
        </div>
      </div>
  
  
    </div>
    <div class="temdlg__bottom">
      <pivot-text-button *ngIf="tabType == 1" (btnClick)="onCancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
      </pivot-text-button>
      <pivot-text-button *ngIf="tabType == 2 || tabType == 3" (btnClick)="onBack()" [buttonType]="buttonType.SECONDARY"
      [buttonText]="BUTTON.RETURN"></pivot-text-button>
      <pivot-text-button *ngIf="tabType == 1 || tabType == 2" (btnClick)="onNext()" [disabled]="isDisableNextBtn()" [buttonType]="buttonType.SECONDARY"
        [buttonText]="BUTTON.NEXT"></pivot-text-button>
      <pivot-text-button *ngIf="tabType == 3" (btnClick)="onSave()" [buttonType]="buttonType.PRIMARY"
        [buttonText]="BUTTON.SAVE"></pivot-text-button>
    </div>
</div>

<pivot-range-date-modal *ngIf="isDisplayRangeDateModal" (closeModalHandler)="closeRangeDateModal()"
  (rangeDateHandler)="setRangeDate($event)"  [appendTo]="'body'" [styleClass]="'range-date-modal modal'">
</pivot-range-date-modal>

<pivot-folder-modal *ngIf="isShowFolder" [folderType]="folderType.Datasource" [folderList]="folderEdits" (closeModalHandler)="closeFolderModal()"
  (selectedFolderHandler)="changeFolder($event)" [selectedFolder]="selectedFolderRow" [appendTo]="'body'" [styleClass]="'folder-modal form-modal modal'" [editorAppendTo]="'body'" [editorStyleClass]="'modal'">
</pivot-folder-modal>