import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MenuCode } from '../../../app/enum/common-enum';
import { ResponseData } from '../../../app/models/api-model';
import { Role, RoleDetail } from '../../../app/models/response/role.ro';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class RoleService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }
  async getDetail(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.DETAIL;
    let resData = await this.get(apiUrl, parameter);
    return await this.mapModelDetail(resData);
  }

  async insertOrUpdate(bodyData: any, isUpdate: boolean = false, isSetMenuLst: boolean = false) {
    let parameter = [
      isUpdate,
      isSetMenuLst
    ];
    let apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.INSERTORUPDATE;
    let resData = await this.post(apiUrl, parameter, bodyData);
    return resData;
  }

  async delete(ids: any) {
    let apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.DELETE;
    let resData = await this.post(apiUrl, [], ids);
    return resData;
  }

  async getRoleDetailByStaffCd(staffCd: string) {
    let uri = API_APP.ROLE.CONTROLLER + API_APP.ROLE.ROLEDETAILBYSTAFFID + "?staffcd=" + staffCd;
    return await this.get(uri);
  }

  insertOrUpdateRoleDetail(roleDetail: RoleDetail[], isUpdate: boolean) {
    let uri = API_APP.ROLE.CONTROLLER + API_APP.ROLE.INSERTORUPDATERD + "?isUpdate=" + isUpdate;
    return this.post(uri, [], roleDetail);
  }

  async getByStaff() {
    const apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.GET_BY_STAFF;
    const resData = await this.get(apiUrl);

    return this.mapModel(resData);
  }

  async checkBeforeDelete(roleCd: string) {
    let apiUrl = API_APP.ROLE.CONTROLLER + API_APP.ROLE.CHECK_BEFORE_DELETE;
    apiUrl = apiUrl.replace('{roleCd}', roleCd);
    const resData = await this.get(apiUrl);

    return resData;
  }

  async mapModel(data: any) {
    let res: ResponseData<Role[]> = new ResponseData<Role[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      let tmp = [];
      for await (const element of data.data) {
        let role = new Role();
        role.id = element.id;
        role.roleCd = element.rolecd;
        role.name = element.rolename;
        role.insdate = element.insdate;
        role.insstfcd = element.insstfcd;
        role.upddate = element.upddate;
        role.updstfcd = element.updstfcd;
        role.delflg = element.delflg;
        role.isDisable = false;
        if (!element.menulst) {
          role.permissions = [];
        } else if (element.menulst == '#-1') {
          role.permissions = [
            MenuCode.DATASOURCE,
            MenuCode.DASHBOARD_LIST,
            MenuCode.SYSTEM_SETTING,
            MenuCode.TAB_SEIKUY,
          ]
        } else {
          role.permissions = [];
          let tmp: string[] = element.menulst.split(',');
          tmp.forEach(item => {
            role.permissions.push(item);
          })
        }
        tmp.push(role);
      }
      let roleDefault = ["RL00000099",  "RL00000001",  "RL00000002"];
      let tmp1 = tmp.filter(x => roleDefault.includes(x.roleCd|| ""));
      let tmp2 = _.remove(tmp, function (currentObj: any) {
        return !roleDefault.includes(currentObj.roleCd|| "");
      });
      tmp2 = _.sortBy(tmp2, ['roleCd'], ['asc']);
      dataArr.push(...tmp1, ...tmp2);
    }
    res.data = dataArr;
    return res;
  }

  async mapModelDetail(inputData: any) {
    let res: ResponseData<Role> = new ResponseData<Role>();
    res.statuscode = inputData.statuscode;
    res.statusmessage = inputData.statusmessage;
    let role = new Role();
    let data = inputData.data;
    if (data) {
      role.id = data.id;
      role.roleCd = data.rolecd;
      role.name = data.rolename;
      role.insdate = data.insdate;
      role.insstfcd = data.insstfcd;
      role.upddate = data.upddate;
      role.updstfcd = data.updstfcd;
      role.delflg = data.delflg;
      if (!data.menulst) {
        role.permissions = [];
      } else if (data.menulst == '#-1') {
        role.permissions = [
          MenuCode.DATASOURCE,
          MenuCode.DASHBOARD_LIST,
          MenuCode.SYSTEM_SETTING,
        ]
      } else {
        role.permissions = [];
        let tmp: string[] = data.menulst.split(',');
        tmp.forEach(item => {
          role.permissions.push(item);
        })
      }
    }

    res.data = role;
    return res;
  }



}
