export enum ButtonIconType {
  EDIT = "pi-pencil",
  DELETE = "pi-trash",
  BACK = "pi-angle-double-left",
  SORT = "pi-sort-alt",
  FILTER = "pi-filter",
  CLOSE = "pi-times",
  SEARCH = 'pi-search',
  CLOSE_DIALOG = 'pi-times-circle',
  PLUS = 'pi-plus'
}

export enum ButtonType {
  PRIMARY = "button-primary",
  SECONDARY = "button-secondary",
  TERTIARY = "button-tertiary",
  INFO = "button-info",
  DANGER = "button-danger",
  SHARE = "button-share",
  HYPERLINK = "hyperlink",
}

export enum RadioGroupDirection {
  VERTICAL = 1,
  HORIZONTAL = 2
}

export enum DialogType {
  delete,   //削除確認ダイアログ
  update,   //編集確認ダイアログ
  error,    //失敗ダイアログ
  save      //保存確認ダイアログ
}

export enum SearchType {
  input,
  combo,
  insert
}

export enum ActionHistoryType {
  UNDO = 1,
  REDO = 2
}

export enum UpDownButtonType {
  UP = 1,
  DOWN = 2
}

export enum UpDownIconType {
  FILL = 1,
  SLIM = 2
}

export enum SortMode {
  ASCENDING = 0,
  DECENDING = 1
}

export enum DataType {
  TEXT = 0,
  NUMBER = 1,
  DATETIME = 2,
  PROGRESS = 3,
  STATUS = 4,
  WIDGET = 5,
  INPUT = 6,
  CHECKBOX = 7,
  COMBOBOX = 8,
  IMG = 9,
  EDIT_DELETE_FUNCTION_BTN = 10,
  DRAG_ADD_STAFF = 11,
}

export enum GraphType {
  LINE_CHART = 1,
  NUMBER_CHART = 2,
  DOUGHNUT_CHART = 3,
  PIE_CHART = 4,
  GAUGE_CHART = 5,
  LINE_BAR_COMBINE_CHART = 6,
  BAR_CHART_VERTICAL = 7,
  BAR_CHART_HORIZONTAL = 8,
  TABLE_CHART = 9,
  STACKED_BAR = 10,
  NO_CHART = -1
}

export enum LegendType {
  DRAW_WITH_LINES = 0,
  DRAW_WITH_COLUMNS = 1
}

export enum InputType {
  text, // input type text
  number, // input type number
  tel, // input type tel
}

export enum DeviceType {
  DESKTOP = "DESKTOP",
  TABLET = "TABLET",
  MOBILE = "MOBILE"
}

export enum ScreenMode {
  NONE = 0,
  ADD = 1,
  EDIT = 2,
  PREVIEW = 3,
  TEMPLATE = 4,
}

export enum FooterOption {
  Count = 'F1',
  CountUnique = 'F2',
  Sum = 'F3',
  Average = 'F4',
}

export enum FilterGroup {
  NONE = 0,
  YEAR_MONTH = 1,
  MONTH = 2,
  MONTH_DAY = 3,
  DAY = 4,
  HOUR_MINUTE = 5,
  HOUR = 6
}

export enum SummaryColumnOption {
  Count = 'F1',
  CountUnique = 'F2',
  Sum = 'F3',
  Average = 'F4',
}

export enum UserAction {
  VIEW = 1,
  EDIT = 2,
  MANAGER = 3,
  OWNER = 99
}

export enum AssignType {
  STAFF = 1,
  TEAM = 2,
  ONLY_ME = 3,
  EVERYONE = 4
}

export enum PublishType {
  PUBLISH = 1,
  UNPUBLISH = 2
}

export enum SaveType {
  SAVE = 1,
  NO = 2,
  CANCEL = 3
}


//Khi phát sinh 1 columntype mới thì value của columntype không thể = tổng số value cũng những columntype khác có thể kết hợp được
//Ví dụ: như phát sinh 1 columnttype là Footer, vậy value nó nên là gì? sao không phải là 5,6 or 7 mà phải là 8.
// Lý do nó vì là 8, vì nếu là 5 thì nó sẽ bằng Row + Column = 2 + 3 = 5. Mà 1 column có thể ở trong Row + Column
//Nên chọn 5 sẽ nhầm lẫn là cột đó vừa là Row, vừa Column, tưởng tự cho 6 và 7
export enum ColumnType {
  NoUse = 0,
  InUse = 1,
  Row = 2,
  Column = 3,
  Value = 4,
  // tức là lấy Row + Value
  RowAndValue = 6,
  // tương tự Column + Value
  ColumnAndValue = 7,
  Footer = 8,
  summaryColumn = 9,
  Hidden_Value = 10
}

export enum FormatType {
  NoFormatA = 'A0',
  Date = 'A1',
  Month = 'A2',
  Year = 'A3',
  Group = 'A4',

  NoFormatB = 'B0',
  Count = 'B1',
  CountUnique = 'B2',
  Sum = 'B3',
  Average = 'B4',
  Percent = 'B5',
}

export enum MenuCode {
  DATASOURCE = "#datasource",
  DASHBOARD_LIST = '#dashboard',
  SYSTEM_SETTING = '#systemsetting',
  TAB_SEIKUY = '#tabseikuy',
}

export enum DateFormat {
  FULL_LONG_DATE = 'YYYY-MM-DD-HH-mm-ss',
  FULL_SHORT_DATE = 'YYYY/MM/DD',
  FULL_SHORT_DASH_DATE_TIME = 'YYYY-MM-DD HH:mm',
  FULL_DATE_HOUR = 'YYYY/MM/DD HH:mm:ss',
  DS_FULL_SHORT_DATE = 'YYYY-MM-DD',
  SHORT_DATE = 'yy/MM',
  YEAR = 'YYYY',
  YEAR_MONTH = 'YYYY/MM',
  JP_FULL_SHORT_DATE = 'yy年MM月DD日',
  JP_FULL_LONG_DATE = 'YYYY年MM月DD日',
  JP_LONG_DATE = 'YYYY年MM月',
  JP_YEAR = 'YYYY年',
  JP_MOTH = 'MM月',
  JP_DAY = 'DD日',
  JP_MONTH_DAY ='MM月DD日',
  JP_HOUR_MINUTE ='HH:mm',
  JP_HOUR='HH',
}

export enum PrimengDateFormat {
  JP_FULL_SHORT_DATE = 'yy年mm月dd日',
  FULL_SHORT_DATE = 'yy/mm/dd',
  JP_LONG_DATE = 'yy年mm月',
  MONTH = 'yy/mm',
  JP_YEAR = 'yy年',
  JP_MOTH = 'mm月',
  JP_DAY = 'dd日',
  JP_MONTH_DAY ='mm月dd日',
  JP_HOUR_MINUTE ='hh',
  JP_HOUR ='hh',
}

export enum CheckAllMode {
  SINGLE_PAGE = 1,
  ALL_PAGE = 2
};

export enum OperatingSystemName {
  Mac = 'macos',
  Windows = 'windows'
}

export enum SyncDataStatus {
  NOT_UPDATED = "0",
  UPDATING = '1',
  UPDATE_COMPLETE = '2',
  ERROR = '3'
}

export enum SyncDataStatusBackEnd {
  WAITING = 1,
  INPROGRESS = 2,
  FAIL = 3,
  SUCCEED = 4
}

export enum TreeViewTab {
  MASTER = 1,
  SEIKUY = 2,
  RECORD = 3,
  REPORT = 4,
  SCHEDULE = 5
}

export enum StatusSynsMasterData {
  LOADING = 0,
  SUCCESS = 1,
  FAILED = 2
}

export enum DataSourceType {
  MASTER = 1,
  SEIKUY = 2,
  TITLE = 3,
}

export enum DataSourceColumnType {
  DEFAULT = 0,
  TAG = 1,
  MASTER = 2,
  NORMAL = 3,
  SEIKUY = 4,
  USER_DEFINED = 5,
}

export enum MasterDataFormat {
  NONE = 0,
  DATETIME = 1,
  DATETIMEWITHCONDITION = 2,
  CODEMST = 3,
  CODEMSTWITHCONDITION = 4,
  JONTABLE = 5,
  COMBINEFIEDS = 6
}

export enum LambdaStatus {
  RUNNING = "RUNNING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FALIED"
}

export enum DataSourceAggregationType {
  INCLUDINGSTARTDATE = 0,
  DISCHARGEDATE = 1 
}

export enum WidgetSettingTargetStep {
  // LOADINGEXCEL = 0,
  SETTINGTARGET = 0,
  CHOOSINGCHART = 1
}


export enum ChartType {
  COL = 0,
  ROW = 1
}

export enum AssignFolderType {
  ONLY_ME = 1,
  STAFF = 2,
  GROUP = 3,
  EVERYONE = 4
}

export enum SyncStatusWidgetResult {
  LATEST = 1,
  DOING = 2,
  ERROR = 3,
  REQUEST_SYNC = 4,
  NO_DATA = 5
}

export enum DatasourceValueConfig {
  LASTEST_SORTNO = 100
}

export enum WidgetSelectDataType
{
    FULLDATA = 1,
    TABLEDATA = 2,
    SPECIALCASE = 3
}

export enum GuardsMode {
  IDLE = 0,
  ADD = 1,
  EDIT = 2
}

export enum ConductorStatus {
  ON_ROW_PLAYING = 0,
  ON_EDIT_PLAYING = 1,
  ENDSHOW = 2
}

export enum TypeOfNotification {
  SYNC_DATASOURCE = 0,
  OTHER = 1
}

export enum TargetSettingType {
  FIX_NUMBER = 0, //入力
  FORMULAR = 1 //計算式
}

/* lấy giá trị âm vô cùng 99999 và dương vô cùng 99999 để sort object đưa nó về giá trị min, max
*/
export enum FilterGroupDefaultValue {
  MIN = -99999999,
  MAX = 99999999,
  // sử dụng để sort data đối với trường hợp filter group mà datatype là number thì sử dụng cờ này để sort object đẩy các giá trị max về cuối
  MAXTEXT = 'Z',
  MINTEXT = 'A'
}