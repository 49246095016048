import { environment } from "src/environments/environment";

export const PIVOT_CLIENT_API_URL = environment.PIVOT_CLIENT_API_URL;

export const HTTP_STATUS = {
    OK: 200,
    CREATED: 201,
    NOT_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERBAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    TIME_OUT: 504
};

export const API_APP = {
    AUTH: {
        CONTROLLER: PIVOT_CLIENT_API_URL  + "auth/",
        LOGIN: 'login',
        REFRESH_TOKEN: 'token/refresh',
        LOGOUT: 'logout',
        LOGIN_BY_TOKEN: 'login-by-token'
    },
    TEAM: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'team',
        ALL: '/all',
        DETAIL: '/detail',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/delete',
        DELETETEAMDETAIL: '/deleteTeamDetail',
        INSERTTEAMDETAIL: '/insertTeamDetail',
        INSERTLISTTEAMDETAIL: '/insertListTeamDetail',
        REMOVELISTTEAMDETAIL: '/removeListTeamDetail'
    },
    STAFF: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'staff',
        ALL: '/all',
        NOTTEAM: '/notteam',
        DETAIL: '/detail',
        TEAM: '/team',
        DELETE: '/delete/{id}',
        UPLOAD_AVATAR: '/upload-avatar'
    },
    DATASOURCE: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'datasource',
        ALL: '/all',
        DETAIL: '/detail',
        FOLDER: '/folder',
        DELETE: '/Delete',
        INSERTORUPDATE: '/insertOrUpdate',
        PREVIEW: '/preview',
        GETDATAUPDATE: '/get-data-update',
        GETDATAUPDATEWITHFILTER: '/get-data-update-with-filter',
        SYNCDATA: '/sync-data',
        GETPERIODTEMPLATE: '/getperiod-template',
        GETLISTDATASOURCECD: '/get-list-datasourcecd',
        GETLISTDATASOURCECDTEMPLATE: '/get-list-datasourcecd-template',
        GETBASICINFO: '/basicInfo',
        CLONETEMPLATE: '/create/template',
        DELTEDSTEMPLATE: '/delete/template',
        EXIST: '/exist',
        GETDSTYPE: '/get-dstype/template',
        PREVIEWTEMPLATE: '/preview/template',
        CHECK_TITLE_EXIST: '/check-title-exist',
        GETDATASOURCES3: '/get-url-datasource-s3',
        GETRESULTS3: '/getResultOnS3',
    },
    FOLDER: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'folder',
        ALL: '/all',
        DETAIL: '/detail',
        GETBYTYPE: '/GetByType',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/Delete',
        TEMPLATE: '/template',
        GETBYLEVEL: '/get-by-level',
        GETSTRUCTBYKEYWORK: '/filering'
    },
    ASSIGN: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'assigntemplate',
        ALL: '/all',
        DETAIL: '/detail',
        GETBYASSIGNCD: '/GetByAssignCD',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/Delete'
    },
    OFFICE: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'office',
        ALL: '/all',
        SERVICES_ZAITAKU: '/service-zaitaku',
        SERVICES: '/services',
        TEMPLATE: '/services-template',
        DETAIL: '/detail',
        ZAITAKU_STATUS: '/zaitaku-status'
    },
    MSTCOMMON: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'mstcommon',
        MSTFILTER: '/mst-filter',
        BYTYPE: '/getbytype'
    },
    WIDGET: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'widget',
        ALL: '/all',
        UPDATE_DATA_WIDEGET: '/updateDataWideget',
        BY_FOLDER: '/folder',
        DETAIL: '/detail',
        DELETE: '/delete',
        DS: '/datasources',
        GET: '/get',
        POST: '/post',
        GET_BY_DASHBOARD: '/dashboard',
        DSTABLE: '/datasourcebycd',
        DSTABLE_PAGINATE: '/datasourcebycdPaginate',
        DSTABLE_TABLE_PAGINATE: '/datasourceTablebycdPaginate',
        GETTOTALCOUNT: "/getTotalCount",
        DATASOURCE: '/datasource',
        TEMPLATE: '/template',
        GETCONFIG: '/getWidgetConfig',
        DSTABLETEMPLATE: '/datasourcebycd/template',
        DSTEMPLATE: '/datasources/template',
        COUNT: '/count',
        GET_WIDGET_BY_DATASOURCECD: '/widgets',
        GET_DASHBOARD_BY_WIDGETCD: '/widgets/dashboard',
        COUNTTEMPLATE: '/count/template',
        UPDATESORTCOL: '/update-sortcol',
        GET_BY_WIDGETCD: '/get-by-widgetcd',
        GETUSEDWIDGETS: '/usedWidgets',
        GETPERIOD: '/getperiod',
    },
    WIDGETRESULT: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'WidgetResult',
        GET_BY_WIDGETCD: '/get-by-widgetcd',
        GET_BY_WIDGETCDS: '/get-by-widgetcds',
        INSERT_OR_UPDATE:'/insert-or-update',
        WIDGET_RESULT:'widget-result',
        PIVOTING: '/pivoting',
    },
    DASHBOARD: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'dashboard',
        ALL: '/all',
        FOLDER: '/folder',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/delete',
        SETTING: '/setting',
        PUBLIC: '/public',
        DETAIL: '/detail',
        SETTINGDASHBOARDDETAIL: '/settingdashbaorddetail',
        SETTINGDETAIL: '/settingdetail',
        SETTINGWIDGETDETAIL: '/settingwidget',
        SETTINGWIDGETDETAILTEMPLATE:'/settingwidget/template',
        GETWIDGETLIST: '/getwidgetlist',
        INSERTORUPDATEDETAIL: '/update-dashboarddetail',
        DELETEDASHBOARDANDDETAIL: '/delete-dashboard-and-detail',
        TEMPLATE: '/template',
    },
    TITLE: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'title',
        OFFICE: '/offices',
        GETTAGDATAL: '/getTagData',
        GETTITLEBYTAG: '/gettitlebytag',
    },
    ROLE: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'role',
        ALL: '/all',
        GET_BY_STAFF: '/staff',
        DETAIL: '/detail',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/delete',
        ROLEDETAILBYSTAFFID: '/roledetailbystaffcd',
        INSERTORUPDATERD: '/insertOrUpdateRD',
        CHECK_BEFORE_DELETE: '/checkBeforeDel/{roleCd}'
    },
    PEATUREPUBLIC: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'feature',
        ALL: '/all',
        GETBYTYPE: '/GetByPublicCd',
        INSERTORUPDATE: '/insertOrUpdate',
        DELETE: '/Delete'
    },
    CORPMST: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'corpmst',
        ALL: '/all',
        INSERTORUPDATE: '/insertOrUpdate'
    },
    SYNCLOGDATA: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'syncLogdata',
        GETBYPACK: '/getByPack',
        WRITELOGS: '/writeLogs'
    },
    NOTIFY: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'notify',
        INVITESTAFFSTOPIVOT: '/invite-staffs-to-pivot',
        LIST_PENDING_INVITATION: '/list-pending-invitation',
        DELETE_PENDING_INVITATION: '/delete-pending-invitation/{id}'
    },
    SYNCDATAORALE: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'syncdataorale',
        SYNC_UNIT: '/sync-unit',
        SYNC_BLOCK: '/sync-block',
        SYNC_OFFICE: '/sync-office',
        SYNC_CORP: '/sync-corp',
        SYNC_OFFICE_SERVICE: '/sync-office-service',
        SYNC_TITLE_MAPPING: '/sync-title-mapping',
        DELETE_SYNC_TITLE_MAPPING: '/delete-data-mapping',
        SYNC_ROOM: '/sync-room',
    },
    ZAITAKU: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'zaitaku',
        ALL: '/all',
        SYNC_DATA: '/sync',
        SYNC_DATA_BY_SETTING: '/sync-by-setting',
        INSERT: '/insert',
        DETAIL: '/detail',
        DETAIL_OFFSET: '/detail-offset',
        INSERT_DETAIL: '/insert-detail',
        INSERT_OR_UPDATE_SETTING: '/insert-or-update-setting',
        DELETE_SETTING: '/delete-setting/{id}',
        GET_SETTING_BY_OFFICE: '/get-setting-by-office',
        GET_SETTING_BY_CONDITION: '/get-setting-by-condition',
        GET_ITEMVALUES_BY_CONDITION: '/get-itemvalues-by-condition',
    },
    STEPFUNCTION: {
        CONTROLLER: PIVOT_CLIENT_API_URL + 'stepfunction',
        CHECK_STATUS: '/check-status'
    },
    HELPLIST: {
      CONTROLLER: PIVOT_CLIENT_API_URL + 'helplist',
      ALL: '/all',
      HELP_LIST: '/help-list',
      HELP_POPUP: '/help-popup',
      UPDATE_UNREAD: '/update-unread',
      UPDATE_HIDE_POPUP: '/update-hide_popup'
    },
}
