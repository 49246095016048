import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import * as _ from 'lodash';
import {ErrorHandleService} from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class NotifyService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async inviteStaffsToPivot(bodyData: any) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.INVITESTAFFSTOPIVOT;
    let resData =  await this.post(apiUrl, [], bodyData);
    return resData;
  }

  async getPendingInvitation() {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.LIST_PENDING_INVITATION;
    let resData =  await this.get(apiUrl);

    let lstPendingInvitation: any[] = [];
    if (resData && resData.data && resData.data.length > 0) {
      lstPendingInvitation = [...resData.data].map((el: any) => {
        return {
          id: el.id,
          user: el?.email || el?.phone || "",
          isHoverRemovePendingBtn: false
        }
      });
    }
    return lstPendingInvitation;
  }

  async deletePendingInvitation(id: string) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.DELETE_PENDING_INVITATION;
    apiUrl = apiUrl.replace('{id}', id);
    let resData =  await this.httpdelete(apiUrl);
    return resData;
  }


}
