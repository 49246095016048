<div class="pivot-dsp-graph" [ngStyle]="cssStyle">
    <div *ngFor="let item of itemCharts; let r = index " 
        [ngClass]="{
            'dsp-chart': true,
            'selected': item.selected
        }"
        (click)="onSelectedChange(item)"
        (mouseenter)="onHoverChart(item)" 
        (mouseleave)="onLeaveChart(item)">
        <img id={{item.name}} class="chart-item" [src]="item.mousein || item.selected ? item.imgHover : item.image" />
    </div>
</div>