import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { CorpMstModel } from '../../../app/models/response/corpMst.ro';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class CorpMstService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.CORPMST.CONTROLLER + API_APP.CORPMST.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }

  async insertOrUpdate(bodyData: any, isUpdate: boolean = false) {
    let parameter = [
      isUpdate
    ];
    let apiUrl = API_APP.CORPMST.CONTROLLER + API_APP.CORPMST.INSERTORUPDATE;
    let resData =  await this.post(apiUrl, parameter, bodyData);
    return resData;
  }

  async mapModel(data: any) {
    let res: ResponseData<CorpMstModel[]> = new ResponseData<CorpMstModel[]>();
    let dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let corpMst = new CorpMstModel();
        corpMst.id = element.id;
        corpMst.corpcd = element.corpcd;
        corpMst.contentcd = element.contentcd;
        corpMst.content = element.content;
        corpMst.value = element.value;
        corpMst.insdate = element.insdate;
        corpMst.insstfcd = element.insstfcd;
        corpMst.upddate = element.upddate;
        corpMst.updstfcd = element.updstfcd;
        corpMst.delflg = element.delflg;
        dataArr.push(corpMst);
      }
    }
    res.data = dataArr;
    return res;
  }
}
