import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { isEmpty, orderBy, uniqBy } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { COMMON_TEXT } from 'src/app/const/text-common';
import { DSCUSTOM, FooterName, SummaryColumnName } from '../../../../app/const/const';
import { GraphType } from '../../../../app/enum/common-enum';
import { ChartData, SettingTargetTable, WidgetGraphSetting } from '../../../../app/models/common-model';
import { WidgetDetail } from '../../../../app/models/request/widget.dto';
import { PivotTableConfig } from '../../../../app/models/response/widget.ro';
import { WidgetService } from '../../../../app/services/modules/widget.service';
import { distinctBy,groupSortValue,makePivotConfig, PivotTableData } from '../../../../app/_helper/helper';
import { DialogChartComponent } from './dialog-chart/dialog-chart.component';
import { WindowService } from 'src/app/services/window.service';
import { Observable, Subscription } from 'rxjs';
import { DashboardFilterService } from 'src/app/services/dashboard-filter.service';

import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';

@Component({
  selector: 'pivot-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit , OnChanges, OnDestroy {
  @Input() isWidgetEdit?: boolean = false;;
  @Input() widgetId: string;
  @Input() type: GraphType | undefined |  number;
  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() footerType: any;
  @Input() chartData: ChartData | PivotTableData | any;
  @Input() tableData: ChartData | PivotTableData | any;
  @Input() officeList: any[] = [];
  @Input() settingValueConfig: any = undefined;
  @Input() graphConfig: WidgetGraphSetting[] = []
  @Input() widgetCd:string| undefined;
  @Input() sortParams: any = null;
  @Input() isLoadWidgetResultComplete?:boolean = false;
  @Input() widgetResult?: any;
  @Input() widgetConfig: any ;
  @Input() onShowGraphSettings: Observable<void>;
  @Input() widgetdetails: WidgetDetail[] | undefined = [];
  @Input() isHome:boolean = false ;
  @Input() datasourceDashboard?: any[] = [];
  @Input() isStackedChartSetting: boolean;
  @Input() isLoadingWidget?:boolean = false;
  @Input() isFilterByOffice?:boolean = false;
  @Output() onSaveChartConfig: any = new EventEmitter<any>();
  @Output() onStartRender: any = new EventEmitter();
  @Output() onRenderComplete: any = new EventEmitter();
  @Output() handSortData = new EventEmitter()
  @Output() handleClickTable = new EventEmitter()

  showGraphSettingssSubscription: Subscription;
  isLoading: boolean = true;
  isMultiColumn: boolean = false;
  isComplate: boolean = false;
  config: PivotTableConfig;
  graphType = GraphType;
  isDashboardTemplate: boolean;
  chart: any = null;
  isLoadWidgetDetailComplete?:boolean = false;
  limitColOrRow: number = 31;

  datasourceCDs: any = [];
  officeSeleced: any[] = [];
  isShowChartDialog: boolean = true
  isShowChartConfig: boolean = false;
  isShowChartDialogWhenEdit = true;
  headers = [];
  rows: any[] = [];
  dataSourceIsDeleted: any[] = [];

  nativeWindow: any = {};
  targetTable : SettingTargetTable[] = [];
  options: any;
  LARGE_RECORDS  = 10000;

  constructor(
    private router: Router,
    private widgetService: WidgetService,
    private modalService: DialogService,
    private windowService: WindowService,
    private dashboardFilterService: DashboardFilterService,
    private errorHandleService: ErrorHandleService
  ) {
    this.isDashboardTemplate = this.router.url.includes("dashboard-template") ? true : false;
  }
  ngOnDestroy(): void {
    this.nativeWindow = {};
    this.datasourceCDs = [];
    if(this.chartData)
    {
      if( this.chartData.table){
        this.chartData.table =[];
      }
      if( this.chartData.table){
        this.chartData.table =[];
      }
      if(this.chartData.columns){
        this.chartData.columns=[];
      }
    }
  
    this.chartData = [];
  }

  ngOnInit(): void {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    if(this.isHome){
      this.dashboardFilterService.officesObservable$.subscribe((data: string[])=> {
      this.officeSeleced = data;
      })
    }
    this.initData();
  }
  initData() {
    this.nativeWindow = this.windowService.nativeWindow;
    if (isEmpty(this.chartData)) {
            if (isEmpty(this.widgetId)) {
        console.log('widgetId must not empty: [widgetId]="..."')
        return
      }

      if(this.type == -1) {
        this.isLoading = false;
        console.log('no charttype')
        return
      }

    } 
    else {
      if(this.isWidgetEdit){
        this.isLoading = false;
      }
    } 
    if (this.onShowGraphSettings) {
      this.showGraphSettingssSubscription = this.onShowGraphSettings.subscribe(() => {
        this.isShowChartConfig = true
      });
    }
  }

  handleSortCol(data: any) {
    this.handSortData.emit(data);
  }

  handleClick() {
    this.handleClickTable.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isShowChartDialog = true;
    this.isShowChartConfig = false;
    if (typeof this.isLoadingWidget == "boolean") {
      this.isLoading = this.isLoadingWidget;
    }
    if(changes["isLoadWidgetResultComplete"]) {
      this.isLoading = false;
      if(changes["isLoadWidgetResultComplete"].currentValue){
        if(this.widgetdetails) {
          this.datasourceCDs = distinctBy(this.widgetdetails, 'datasourcecd').filter(x => ![DSCUSTOM, FooterName, SummaryColumnName].includes(x));
          this.dataSourceIsDeleted = distinctBy(this.widgetdetails, 'dsdelflg').filter((dlg : any) => {
            const datasourceCd = this.widgetdetails?.find(e => e?.dsdelflg == dlg)?.datasourcecd;
            return datasourceCd && ![DSCUSTOM, FooterName, SummaryColumnName].includes(datasourceCd);
          });
          this.config = makePivotConfig(this.widgetdetails);
          if(this.isLoadWidgetResultComplete){
            const isValidDataSource = !this.dataSourceIsDeleted[0] && this.dataSourceIsDeleted[0] !== null && this.dataSourceIsDeleted[0] !== undefined;
            if(this.widgetResult && isValidDataSource){
              this.isLoading = true;
              this.loadChartByWidgetResult();
            }
          }
        }
        else {
          // Gọi khi add 1 widget vô dashboard mà chưa lưu dashboard đó
          let api = !this.isDashboardTemplate? this.widgetService.getWidget(this.widgetId):  this.widgetService.getWidgetTemplate(this.widgetId)
          api
          .then(res => {
            const data = res.data as any[];
            this.widgetdetails = data.map(x => new WidgetDetail(x));
            this.datasourceCDs = distinctBy(this.widgetdetails, 'datasourcecd').filter(x => ![DSCUSTOM, FooterName, SummaryColumnName].includes(x));
            this.dataSourceIsDeleted = distinctBy(this.widgetdetails, 'dsdelflg').filter((dlg : any) => {
              const datasourceCd = this.widgetdetails?.find(e => e?.dsdelflg == dlg)?.datasourcecd;
              return datasourceCd && ![DSCUSTOM, FooterName, SummaryColumnName].includes(datasourceCd);
            });
            this.config = makePivotConfig(this.widgetdetails);
            this.isLoadWidgetDetailComplete = true;
            const isValidDataSource = !this.dataSourceIsDeleted[0] && this.dataSourceIsDeleted[0] !== null && this.dataSourceIsDeleted[0] !== undefined;
            if(this.datasourceCDs.length > 0 && isValidDataSource){
              this.loadChartByWidgetResult();
            }
          })
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    }
    if(changes['widgetCd']){
      if (this.widgetCd) { this.isShowChartDialogWhenEdit = false; }
      else { this.isShowChartDialog = false; } //copy widget
    }
    if(changes['chartData'] || changes['graphConfig']){
      this.checkChartData();
    }
    
    if(changes && changes['chartData']) {
      if(!this.graphConfig) {
        if(this.chartData && this.chartData.columns && this.chartData.columns.length > 0) {
          let lengthCols = this.chartData.columns?.length || 0;
          let lengthCategories =  this.chartData.categories?.length || 0;
          if(lengthCols > this.limitColOrRow || lengthCategories > this.limitColOrRow) return;
          this.isShowChartConfig = !this.isMultiColumn ? true: false;
          if(this.type == GraphType.LINE_BAR_COMBINE_CHART) {
            this.loadHeadersForGraphSetting();
          }
        }
      } else {
        if(this.type == GraphType.LINE_BAR_COMBINE_CHART) {
          this.loadHeadersForGraphSetting();
        }
        this.isShowChartConfig = false;
      }
    }
    this.isShowChartDialog = false;
  }


  sortArrayData(data: any[], index: number) {
    return data.sort((itemA: any, itemB: any) => {
      let value1 = groupSortValue(itemA[index]);
      let value2 = groupSortValue(itemB[index]); 
       return value1 - value2;
     });
  }

  loadChartByWidgetResult() {
    if (this.type !== GraphType.TABLE_CHART && this.widgetResult) {
      let chart =  this.widgetResult.chart;
      if(chart.columns) {
        chart = this.sortDataForChart(chart);
      }
      this.chartData = chart;
      this.tableData = {
        table : this.widgetResult.table, 
        config: this.config 
      };
      if(this.config?.footers?.length > 0){
        this.footerType =  this.config?.footers[0]?.formattype;
      }
      if(this.type == GraphType.LINE_BAR_COMBINE_CHART) {
        this.graphConfig = this.widgetResult?.graphConfigs;
        this.loadHeadersForGraphSetting();
      }
      if(this.type == GraphType.STACKED_BAR) {
        if (this.widgetResult.graphConfigs == null){
          this.isStackedChartSetting = false;
        }else {
          this.isStackedChartSetting = this.widgetResult.graphConfigs[0].isStackedChartSetting;
        }
      }
    } else {
      this.tableData = {
        table : this.widgetResult.table, 
        config: this.config 
      };
      let result = this.widgetResult.table;
      this.tableData = {table : {...this.tableData.table, body: result.body}, config: this.tableData.config };
      this.chartData = this.tableData;
    }
    this.isShowChartDialog = false
  }

  loadHeadersForGraphSetting() {
    let widgetGraphSetting: WidgetGraphSetting[] = [];
    if(this.chartData) {
      if(this.chartData.columns && this.chartData.columns.length > 0) {
        this.chartData.columns.forEach((row : any, rowIndex: number) => {
          let item: WidgetGraphSetting = { targetColumn: row[0], graphType: {value: 0, title: "棒"}, isSecondAxis: false, rowIndex};
          widgetGraphSetting.push(item);
        });
      }
    }
    if(widgetGraphSetting.length) {
      if(this.graphConfig && this.graphConfig.length) {
        let deletedSetting = this.graphConfig.filter((item: any) => widgetGraphSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length == 0 );
        let addedSettings = widgetGraphSetting.filter((item: any) =>   !this.graphConfig.some(existingItem => existingItem.targetColumn === item.targetColumn) );
        let order = [...addedSettings, ...this.graphConfig];
        if(deletedSetting && deletedSetting.length) {
          order = order.filter((item: any) => deletedSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length >0 ? false :  true );
        }

        let settings = uniqBy(order, 'targetColumn');
        this.graphConfig = orderBy(settings, ["rowIndex"]);
      }
      else {
        let order = [...widgetGraphSetting];
        this.graphConfig = orderBy(order, ["rowIndex"]);
      }
    }
  }

  openDialog(): void {
    this.isShowChartDialog = true;
    const refBackPage = this.modalService?.open(DialogChartComponent, {
      header: COMMON_TEXT.CONFIRM,
      width: '35%'
    });

    refBackPage?.onClose.subscribe(res => {
      if (res) {
        this.isMultiColumn = false;
        this.isShowChartDialog = false;
        if(this.chartData && this.chartData.columns && this.chartData.columns.length > 0) {
          this.isShowChartConfig = true;
        }
      }
    });
  }

  sortDataForChart(chartData: any) {
    if(chartData?.columns) {
      let body: any[] = chartData?.columns || [];
      let index = body[0]?.findIndex((s:any)=>s?.toString()?.includes('~') || s?.toString()?.includes(COMMON_TEXT.BELOW) || s?.toString()?.includes(COMMON_TEXT.UPPER));
      if(index != -1) {
        chartData.columns = this.sortArrayData(body, index);
      }
    }
    return chartData;
  }

  checkChartData() {
    if(this.chartData)  {
      this.chartData = this.sortDataForChart(this.chartData);
      let lengthCols = this.chartData.columns?.length || 0;
      let lengthCategories =  this.chartData.categories?.length || 0;
      if((lengthCols > this.limitColOrRow && this.type !== GraphType.NUMBER_CHART) || (lengthCategories > this.limitColOrRow) && this.type !== GraphType.NUMBER_CHART) {
        this.modalService?.open(DialogChartComponent, {
          header: COMMON_TEXT.CONFIRM,
          width: '35%',
          data: {
            description: COMMON_TEXT.LIMITDATA,
            isDspBtnCanel: false
          }
        });
        return;
      }
      this.isShowChartDialog = true;
      let condition = ![this.graphType.TABLE_CHART, this.graphType.NUMBER_CHART].includes(Number(this.type))
      if(condition) {
        if ((this.chartData?.columns?.length ?? 0) > 100 && !this.isComplate && this.isShowChartDialogWhenEdit) {
          this.isMultiColumn = true;
          this.openDialog()
        } else {
          this.isShowChartDialog = false
          this.isMultiColumn = false;
        }
      } else {
        this.isShowChartDialog = false
      }
      this.isComplate = false;
    }
    else  this.isShowChartDialog = false;
    this.isShowChartDialogWhenEdit = true;
  }

  onSubmitChartConfig(data: any)  {
    this.isShowChartConfig = false;
    if(data.isSet == true) {
      this.isComplate = true;
      this.chartData.graphConfig = data.data;
      this.onSaveChartConfig.emit(data);
    }
    else {
      this.chartData.graphConfig = data.data;
      this.graphConfig = data.data;
      this.onSaveChartConfig.emit(data);
    }
  }

  updateFormulaRows(inputFormula: string, rowOffset: number) {
    return inputFormula.replace(/([A-Z]+)(\d+)/g, (match, columnLetters, rowNum) => {
        const newNumber = parseInt(rowNum) + (rowOffset -1);
        return columnLetters + newNumber.toString();
    });
  }

  updateFormulaColumns(inputFormula: string, newRowNumber: number) {
    return inputFormula.replace(/([A-Z]+)(\d+)/g, (match, columnLetters, rowNum) => {
      const newColumnLetters = columnLetters;
      const updatedRowNumber = Math.min(newRowNumber, parseInt(rowNum));
      return newColumnLetters + updatedRowNumber.toString();
    });
  }

  onRenderChartComplete(){
    this.onRenderComplete.emit();
  }

  onStartRenderChart(){
    this.onStartRender.emit();
  }

}
