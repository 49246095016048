import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GraphType } from '../../../../app/enum/common-enum';

@Component({
  selector: 'pivot-display-graph',
  templateUrl: './display-graph.component.html',
  styleUrls: ['./display-graph.component.scss']
})

export class DisplayGraphComponent implements OnInit, OnChanges {

  // style css  for panel
  @Input() cssStyle: any = {};
  @Input() selected: GraphType | null
  // event submit data
  @Output() onClickChart = new EventEmitter<any>();
  itemCharts: any[];
  currentSelectItem: any = {}

  constructor() {
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (isDarkMode) {
      this.itemCharts = [
        { value: GraphType.BAR_CHART_HORIZONTAL, image: '../../../../assets/images/horizontal-bar-chart_hover.svg', imgHover: '../../../../assets/images/horizontal-bar-chart_hover.svg' },
        { value: GraphType.BAR_CHART_VERTICAL, image: '../../../../assets/images/vertical-bar-chart_hover.svg', imgHover: '../../../../assets/images/vertical-bar-chart_hover.svg' },
        { value: GraphType.LINE_BAR_COMBINE_CHART, image: '../../../../assets/images/combo-chart_hover.svg', imgHover: '../../../../assets/images/combo-chart_hover.svg' },
        { value: GraphType.LINE_CHART, image: '../../../../assets/images/line-chart_hover.svg', imgHover: '../../../../assets/images/line-chart_hover.svg' },
        { value: GraphType.DOUGHNUT_CHART, image: '../../../../assets/images/doughnut-chart_hover.svg', imgHover: '../../../../assets/images/doughnut-chart_hover.svg' },
        { value: GraphType.NUMBER_CHART, image: '../../../../assets/images/number-chart_hover.svg', imgHover: '../../../../assets/images/number-chart_hover.svg' },
        // { value: GraphType.GAUGE_CHART, image: '../../../../assets/images/gauge-chart_hover.svg', imgHover: '../../../../assets/images/gauge-chart_hover.svg' },
        { value: GraphType.PIE_CHART, image: '../../../../assets/images/pie-chart_hover.svg', imgHover: '../../../../assets/images/pie-chart_hover.svg' },
        { value: GraphType.STACKED_BAR, image: '../../../../assets/images/stacked-bar_chart-hover.svg', imgHover: '../../../../assets/images/stacked-bar_chart-hover.svg' },
        { value: GraphType.TABLE_CHART, image: '../../../../assets/images/table_hover.svg', imgHover: '../../../../assets/images/table_hover.svg' }
      ];
    } else {
      this.itemCharts = [
        { value: GraphType.BAR_CHART_HORIZONTAL, image: '../../../../assets/images/horizontal-bar-chart.svg', imgHover: '../../../../assets/images/horizontal-bar-chart_hover.svg' },
        { value: GraphType.BAR_CHART_VERTICAL, image: '../../../../assets/images/vertical-bar-chart.svg', imgHover: '../../../../assets/images/vertical-bar-chart_hover.svg' },
        { value: GraphType.LINE_BAR_COMBINE_CHART, image: '../../../../assets/images/combo-chart.svg', imgHover: '../../../../assets/images/combo-chart_hover.svg' },
        { value: GraphType.LINE_CHART, image: '../../../../assets/images/line-chart.svg', imgHover: '../../../../assets/images/line-chart_hover.svg' },
        { value: GraphType.DOUGHNUT_CHART, image: '../../../../assets/images/doughnut-chart.svg', imgHover: '../../../../assets/images/doughnut-chart_hover.svg' },
        { value: GraphType.NUMBER_CHART, image: '../../../../assets/images/number-chart.svg', imgHover: '../../../../assets/images/number-chart_hover.svg' },
        // { value: GraphType.GAUGE_CHART, image: '../../../../assets/images/gauge-chart.svg', imgHover: '../../../../assets/images/gauge-chart_hover.svg' },
        { value: GraphType.PIE_CHART, image: '../../../../assets/images/pie-chart.svg', imgHover: '../../../../assets/images/pie-chart_hover.svg' },
        { value: GraphType.STACKED_BAR, image: '../../../../assets/images/stacked-bar_chart.svg', imgHover: '../../../../assets/images/stacked-bar_chart-hover.svg' },
        { value: GraphType.TABLE_CHART, image: '../../../../assets/images/table.svg', imgHover: '../../../../assets/images/table_hover.svg' } 
      ];
    }
  }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selected != null) {
      this.currentSelectItem = this.itemCharts.filter(x => x.value == this.selected).pop()
      if (this.currentSelectItem) {
        this.currentSelectItem.selected = true;
        this.itemCharts.map((x: any) => {
          if(x.value != this.selected){
            x.selected = false
          }
          return x;
        })
      } else {
        this.currentSelectItem = {}
      }
    } else {
      this.currentSelectItem.selected = false
      this.currentSelectItem = {}
    }
  }

  // select chart
  onSelectedChange(item: any) {
    if (this.currentSelectItem == item) {
      this.currentSelectItem.selected = false;
      this.currentSelectItem = {}
      this.onClickChart.emit(null);
    } else {
      this.currentSelectItem.selected = false
      this.currentSelectItem = item
      item.selected = true
      this.onClickChart.emit(item.value);
    }

  }

  // event focusin for chart
  onHoverChart(item: any) {
    item.mousein = true
  }

  // on mouse leave chart
  onLeaveChart(item: any) {
    item.mousein = false
  }
}
