import { DateFormat, MasterDataFormat } from "../enum/common-enum";

/** IMPORTANT: IF YOU CHANGE THE CONFIGURATION OF MASTERDATAFORDATASOURCE
 *  PLEASE CHANGE IN SOURCE CODE BACKEND IN FILE: MasterDataConfig ACCORDINGLY */
export const MASTERDATAFORDATASOURCE = [
  {
    name: "利用者マスタ",
    table: "DAI",
    alias: "D",
    innerJoin: " INNER JOIN DAI D ON D.DAICD = DBL.DAICD ",
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "FULLNAME",
        displayName: "氏名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.COMBINEFIEDS,
          joinColumn: "D.FAMILY || D.GIVEN",
        }
      },
      {
        field: "KANAN",
        displayName: "フリガナ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.COMBINEFIEDS,
          joinColumn: "D.KANAFAMILY || D.KANAGIVEN",
        }
      },
      {
        field: "NICKNM",
        displayName: "ニックネーム",
        dataType: "VARCHAR"
      },
      {
        field: "BIRTH",
        displayName: "生年月日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE,
        }
      },
      {
        field: "SEX",
        displayName: "性別",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "001001"
        }
      },
      {
        field: "KEN",
        displayName: "都道府県",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "KEN K",
          joinColumn: "D.KENCD = K.KENCD",
          columnName: "K.KENNM AS D_KEN"
        }
      },
      {
        field: "CITY", //Trong wiki la CITY
        displayName: "市区町村",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "CITY C",
          joinColumn: "D.CITYCD = C.CITYCD AND D.KENCD = C.KENCD",
          columnName: "C.CITYNM AS D_CITY"
        }
      },
      {
        field: "ZIPCD",
        displayName: "郵便番号",
        dataType: "VARCHAR"
      },
      {
        field: "ADDR1",
        displayName: "住所１",
        dataType: "VARCHAR"
      },
      {
        field: "ADDR2",
        displayName: "住所2",
        dataType: "VARCHAR"
      },
      {
        field: "TELEPHONE",
        // combineFields: ["TELAREA", "TELCITY", "TELNUM"],
        displayName: "電話番号",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.COMBINEFIEDS,
          joinColumn: "D.TELAREA || D.TELCITY || D.TELNUM",
        }
      },
      {
        field: "ENDFLG",
        displayName: "終結",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "001002"
        }
      },
      {
        field: "ENDDATE",
        displayName: "終結日時",
        dataType: "DATETIME",
        outputData: {
          type:  MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "D.ENDFLG = 0"
        }
      },
      {
        field: "ENDRIYU",
        displayName: "終結理由",
        dataType: "VARCHAR"
      },
    ]
  },
  {
    name: "介護保険情報",
    table: "HISNIN",
    dateTimeType: "string",
    startDateColumn: "HN.GSDT",
    endDateColumn: "HN.GEDT",
    alias: "HN",
    innerJoin: " INNER JOIN HISNIN HN ON HN.DAICD = DBL.DAICD ",
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "NAME",
        displayName: "氏名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "DAI D",
          joinColumn: "D.DAICD = HN.DAICD",
          columnName: "D.FAMILY || D.GIVEN AS HN_NAME"
        }
      },
      {
        field: "KANA",
        displayName: "フリガナ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "DAI D",
          joinColumn: "D.DAICD = HN.DAICD",
          columnName: "D.KANAFAMILY || D.KANAGIVEN AS HN_KANA"
        }
      },
      {
        field: "GSDT",
        displayName: "認定有効期間（始)",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE,
        }
      },
      {
        field: "GEDT",
        displayName: "認定有効期間（終)",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE,
        }
      },
      {
        field: "HONM",
        displayName: "保険者",
        dataType: "VARCHAR"
      },
      {
        field: "HOCD",
        displayName: "保険者番号",
        dataType: "VARCHAR"
      },
      {
        field: "DVDT",
        displayName: "交付年月日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "HN.DVDT = '0001-01-01'"
        }
      },
      {
        field: "HTSDT",
        displayName: "区分支給限度基準額適用期間開始",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "HN.HTSDT = '0001-01-01'"
        }
      },
      {
        field: "HTEDT",
        displayName: "区分支給限度基準額適用期間終了",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "HN.HTSDT = '0001-01-01'"
        }
      },
      {
        field: "GKLV",
        displayName: "要介護状態区分",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002001"
        }
      },
      {
        field: "H_HOCD",
        displayName: "被保険者証",
        dataType: "VARCHAR"
      },
      {
        field: "H_HOKBN",
        displayName: "被保険者区分",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002002"
        }
      },
      {
        field: "GNDT",
        displayName: "認定年月日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "HN.GNDT = '0001-01-01'"
        }
      },
      {
        field: "FL4",
        displayName: "障害高齢者の日常生活自立度 (寝たきり度)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002014"
        }
      },
      {
        field: "FL5",
        displayName: "認知症高齢者の日常生活自立度",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002015"
        }
      },
    ]
  },
  {
    name: "負担限度履歴",
    table: "HISGEN",
    dateTimeType: "string",
    startDateColumn: "HG.SDATE",
    endDateColumn: "HG.EDATE",
    alias: "HG",
    innerJoin: " INNER JOIN HISGEN HG ON HG.DAICD = DBL.DAICD ",
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "DVDT",
        displayName: "交付年月日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIMEWITHCONDITION,
          format: DateFormat.FULL_SHORT_DATE,
          blankCondition: "HG.DVDT = '0001-01-01'"
        }
      },
      {
        field: "HIHOCD",
        displayName: "被保険者番号",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "適用年月日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "EDATE",
        displayName: "有効期限",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "KLEVEL",
        displayName: "段階",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002003"
        }
      },
      {
        field: "LIM1",
        displayName: "負担限度額「食費」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM2",
        displayName: "負担限度額「ユニット型個室」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM3",
        displayName: "負担限度額「ユニット型準個室」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM4",
        displayName: "負担限度額「従来型個室 (特養等)」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM5",
        displayName: "負担限度額「従来型個室 (老健・療養等)」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM6",
        displayName: "負担限度額「多床室（特養等」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM7",
        displayName: "負担限度額「多床室(老健・療養等)」",
        dataType: "VARCHAR"
      },
      {
        field: "LIM8",
        displayName: "負担限度額「ショート食費」",
        dataType: "VARCHAR"
      },
    ]
  },
  //HISIO KBN = 0
  {
    name: "入所(院)履歴",
    table: "HISIO",
    dateTimeType: "datetime",
    startDateColumn: "HS0.SDATE",
    endDateColumn: "HS0.EDATE",
    alias: "HS0",
    tableCondition: "HS0.KBN = 0",
    innerJoin: `    INNER JOIN HISIO HS0 ON HS0.DAICD = DBL.DAICD 
                INNER JOIN JIGMST J ON J.SRVKBNCD  = HS0.SRVKBNCD  AND J.JIGNO  = HS0.JIGNO AND J.SITEINO = HS0.SITEINO
                INNER JOIN DAIBELONG DBL1 ON HS0.DAICD  = DBL1.DAICD  AND (DBL1.CORPCD || DBL1.OFFICECD) = SUBSTR(J.SHICD,1,3) `,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNNM",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS HS0_SRVKBNNM"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "SUPNM",
        displayName: "名称",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "入所(院)外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "EDATE",
        displayName: "退所(院)外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ESTAT",
        displayName: "退所(院)後の状況",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002005"
        }
      },
      {
        field: "FLG1",
        displayName: "送迎(迎え)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG2",
        displayName: "送迎(送り)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002007"
        }
      },
      {
        field: "FLG4",
        displayName: "居室の種類",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002008"
        }
      },
      {
        field: "FLG5",
        displayName: "適用理由",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMSTWITHCONDITION,
          classCd: "002009",
          condition: "HS0.ESTAT = 4"
        }
      },
      // DISPLAY NT1 with header "主たる状態" in KBN = 0
      {
        field: "NT1",
        displayName: "主たる状態",
        dataType: "VARCHAR"
      },
      // DISPLAY NT1 with header "主傷病名" in KBN = 0
      {
        field: "NT2",
        displayName: "主傷病名",
        dataType: "VARCHAR"
      },
      {
        field: "NT3",
        displayName: "入所(院)時の状況コメント",
        dataType: "VARCHAR"
      },
      {
        field: "NT4",
        displayName: "入所 (院)時の状況コード",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002012"
        }
      },
      {
        field: "NT5",
        displayName: "初期加算算定",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002013"
        }
      },
      {
        field: "NT6",
        displayName: "看取り加算同意日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NT7",
        displayName: "看取り加算死亡日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //HISIO KBN = 1
  {
    name: "外泊入院履歴",
    table: "HISIO",
    dateTimeType: "datetime",
    startDateColumn: "HS1.SDATE",
    endDateColumn: "HS1.EDATE",
    alias: "HS1",
    tableCondition: "HS1.KBN = 1",
    innerJoin: `  INNER JOIN HISIO HS1 ON HS1.DAICD = DBL.DAICD 
    INNER JOIN JIGMST J ON J.SRVKBNCD  = HS1.SRVKBNCD  AND J.JIGNO  = HS1.JIGNO AND J.SITEINO = HS1.SITEINO
    INNER JOIN DAIBELONG DBL1 ON HS1.DAICD  = DBL1.DAICD  AND (DBL1.CORPCD || DBL1.OFFICECD) = SUBSTR(J.SHICD,1,3) `,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNNM",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS HS1_SRVKBNNM"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "SUPNM",
        displayName: "名称",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "入所(院)外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "SNOTE",
        displayName: "移動：備考",
        dataType: "VARCHAR",
      },
      {
        field: "EDATE",
        displayName: "退所(院)外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ENOTE",
        displayName: "戻り：備考",
        dataType: "VARCHAR",
      },
      {
        field: "ESTAT",
        displayName: "退所(院)後の状況",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002005"
        }
      },
      {
        field: "FLG1",
        displayName: "送迎(迎え)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG2",
        displayName: "送迎(送り)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002007"
        }
      },
      {
        field: "FLG4",
        displayName: "居室の種類",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002008"
        }
      },
      {
        field: "FLG5",
        displayName: "適用理由",
        dataType: "VARCHAR",
      },
      {
        field: "NT2",
        displayName: "主傷病名",
        dataType: "VARCHAR"
      },
      {
        field: "NT3",
        displayName: "入所(院)時の状況コメント",
        dataType: "VARCHAR"
      },
      {
        field: "NT4",
        displayName: "入所 (院)時の状況コード",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002012"
        }
      },
      {
        field: "NT5",
        displayName: "初期加算算定",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002013"
        }
      },
      {
        field: "NT6",
        displayName: "看取り加算同意日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NT7",
        displayName: "看取り加算死亡日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //HISIO KBN = 2
  {
    name: "居室移動履歴",
    table: "HISIO",
    dateTimeType: "datetime",
    startDateColumn: "HS2.SDATE",
    endDateColumn: "HS2.EDATE",
    alias: "HS2",
    tableCondition: "HS2.KBN = 2",
    innerJoin: `  INNER JOIN HISIO HS2 ON HS2.DAICD = DBL.DAICD 
    INNER JOIN JIGMST J ON J.SRVKBNCD  = HS2.SRVKBNCD  AND J.JIGNO  = HS2.JIGNO AND J.SITEINO = HS2.SITEINO
    INNER JOIN DAIBELONG DBL1 ON HS2.DAICD  = DBL1.DAICD  AND (DBL1.CORPCD || DBL1.OFFICECD) = SUBSTR(J.SHICD,1,3) `,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNNM",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS HS2_SRVKBNNM"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "SUPNM",
        displayName: "名称",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "入所(院)外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "EDATE",
        displayName: "退所(院)外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ESTAT",
        displayName: "退所(院)後の状況",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002005"
        }
      },
      {
        field: "FLG1",
        displayName: "送迎(迎え)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG2",
        displayName: "送迎(送り)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002007"
        }
      },
      {
        field: "FLG4",
        displayName: "居室の種類",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002008"
        }
      },
      {
        field: "FLG5",
        displayName: "適用理由",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMSTWITHCONDITION,
          classCd: "002010",
          condition: "HS2.FLG4 = 4"
        }
      },
      // DISPLAY NT1 with header "居室コード" in KBN = 2
      {
        field: "NT1",
        displayName: "居室コード",
        dataType: "VARCHAR"
      },
      // DISPLAY NT2 with header "組織コード" in KBN = 2
      {
        field: "NT2",
        displayName: "組織コード",
        dataType: "VARCHAR"
      },
      {
        field: "NT3",
        displayName: "入所(院)時の状況コメント",
        dataType: "VARCHAR"
      },
      {
        field: "NT4",
        displayName: "入所 (院)時の状況コード",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002012"
        }
      },
      {
        field: "NT5",
        displayName: "初期加算算定",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002013"
        }
      },
      {
        field: "NT6",
        displayName: "看取り加算同意日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NT7",
        displayName: "看取り加算死亡日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //HISIO KBN = 3
  {
    name: "居宅サービス開始中止履歴",
    table: "HISIO",
    dateTimeType: "datetime",
    startDateColumn: "HS3.SDATE",
    endDateColumn: "HS3.EDATE",
    alias: "HS3",
    tableCondition: "HS3.KBN = 3",
    innerJoin: `   INNER JOIN HISIO HS3 ON HS3.DAICD = DBL.DAICD 
    INNER JOIN JIGMST J ON J.SRVKBNCD  = HS3.SRVKBNCD  AND J.JIGNO  = HS3.JIGNO AND J.SITEINO = HS3.SITEINO
    INNER JOIN DAIBELONG DBL1 ON HS3.DAICD  = DBL1.DAICD  AND (DBL1.CORPCD || DBL1.OFFICECD) = SUBSTR(J.SHICD,1,3) `,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNNM",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS HS3_SRVKBNNM"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "SUPNM",
        displayName: "名称",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "入所(院)外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "EDATE",
        displayName: "退所(院)外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ESTAT",
        displayName: "退所(院)後の状況",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002005"
        }
      },
      {
        field: "FLG1",
        displayName: "送迎(迎え)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG2",
        displayName: "送迎(送り)",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002006"
        }
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002007"
        }
      },
      {
        field: "FLG4",
        displayName: "居室の種類",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002008"
        }
      },
      {
        field: "FLG5",
        displayName: "適用理由",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002011"
        }
      },
      {
        field: "NT3",
        displayName: "入所(院)時の状況コメント",
        dataType: "VARCHAR"
      },
      {
        field: "NT4",
        displayName: "入所 (院)時の状況コード",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002012"
        }
      },
      {
        field: "NT5",
        displayName: "初期加算算定",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "002013"
        }
      },
      {
        field: "NT6",
        displayName: "看取り加算同意日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NT7",
        displayName: "看取り加算死亡日",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  {
    name: "職員マスタ",
    table: "STAFF",
    dateTimeType: "string",
    startDateColumn: "STF.SDATE",
    endDateColumn: "STF.EDATE",
    alias: "STF",
    fields: [
      {
        field: "STFCD",
        displayName: "職員コード",
        dataType: "VARCHAR"
      },
      {
        field: "NAME",
        displayName: "氏名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CONCAT(STF.FAMILY, STF.GIVEN) AS STF_NAME"
        }
      },
      {
        field: "KANA",
        displayName: "フリガナ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CONCAT(STF.KANAFAMILY, STF.KANAGIVEN) AS STF_KANA"
        }
      },
      {
        field: "BIRTH",
        displayName: "生年月日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE,
        }
      },
      {
        field: "SEX",
        displayName: "性別",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "001001"
        }
      },
      {
        field: "INDATE",
        displayName: "入社日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE,
        }
      },
      {
        field: "JOBNM",
        displayName: "職種",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "JOB J",
          joinColumn: "STF.JOBCD = J.JOBCD",
          columnName: "J.JOBNM AS STF_JOBNM"
        }
      },
      {
        field: "POSTNM",
        displayName: "役職",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          table: "POST P",
          joinColumn: "STF.POSTCD = P.POSTCD",
          columnName: "P.POSTNM AS STF_POSTNM"
        }
      },
      {
        field: "DELFLG",
        displayName: "使用しないチェック",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "009008"
        }
      },
    ]
  },
  {
    name: "公費情報",
    table: "HISKH",
    dateTimeType: "string",
    startDateColumn: "HKH.SDATE",
    endDateColumn: "HKH.EDATE",
    alias: "HKH",
    innerJoin: `  INNER JOIN HISKH HKH ON HKH.DAICD = DBL.DAICD  
    LEFT JOIN DAI D ON D.DAICD = HKH.DAICD  
    LEFT JOIN RECETITLE R ON HKH.SRVKBNCD = R.CODE  `,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "NAME",
        displayName: "氏名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CONCAT(D.FAMILY, D.GIVEN) AS HKH_NAME"
        }
      },
      {
        field: "KHNO",
        displayName: "法番",
        dataType: "VARCHAR"
      },
      {
        field: "SEIDO",
        displayName: "制度",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "002016",
          columnName: "CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO)"
        }
      },
      {
        field: "KHPC",
        displayName: "給付割合",
        dataType: "INT"
      },
      {
        field: "KHHNO",
        displayName: "負担者番号",
        dataType: "VARCHAR"
      },
      {
        field: "KHJNO",
        displayName: "受給者番号",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "適用期間 (開始)",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "EDATE",
        displayName: "適用期間 (終了)",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "KHTKJ",
        displayName: "公費適用事業所",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
          columnName: "CASE WHEN (HKH.FLG2 = 1 OR HKH.FLG2 = 2) AND HKH.SRVKBNCD IS NOT NULL THEN '1' ELSE NULL END"
        }
      },
      {
        field: "HNFTKJ",
        displayName: "本人負担適用事業所",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
          columnName: "CASE WHEN (HKH.FLG2 = 0 OR HKH.FLG2 = 2) AND HKH.SRVKBNCD IS NOT NULL THEN '1' ELSE NULL END"
        }
      },
      {
        field: "SRVKBNCD",
        displayName: "適用事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "R.NAME AS HKH_SRVKBNCD"
        }
      },
      {
        field: "JIGNO",
        displayName: "適用事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "適用事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "SEIHO",
        displayName: "生保・公費単独",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "009008",
          columnName: "CASE WHEN CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO) = '26-25' OR CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO) = '10-12' THEN '1' ELSE NULL END"
        }
      },
      {
        field: "FLG1",
        displayName: "住民税非課税世帯",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "009008",
          columnName: "HKH.FLG1"
        }
      },
      {
        field: "NT4",
        displayName: "公費名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CASE WHEN CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO) = '23-0' THEN HKH.NT4 ELSE NULL END AS HKH_NT4"
        }
      },
      {
        field: "FLG3",
        displayName: "3併時、第1公費の負担を表示",
        dataType: "VARCHAR",
        outputData: {
          type:  MasterDataFormat.CODEMST,
          classCd: "009008",
          columnName: "CASE WHEN CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO) = '23-0' THEN '1' ELSE NULL END"
        }
      },
      {
        field: "FLG4",
        displayName: "独自公費優先順",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CASE WHEN CONCAT(CONCAT(HKH.KHID, '-'), HKH.KHNO) = '23-0' THEN HKH.FLG4 ELSE NULL END AS HKH_FLG4"
        }
      },
      {
        field: "NT1",
        displayName: "本人負担額",
        dataType: "INT",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CASE WHEN (HKH.FLG2 = 0 OR HKH.FLG2 = 2) AND HKH.SRVKBNCD IS NOT NULL THEN HKH.NT1 ELSE NULL END AS HKH_NT1"
        }
      },
      {
        field: "NT2",
        displayName: "補足給付本人負担額",
        dataType: "INT",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CASE WHEN (HKH.FLG2 = 0 OR HKH.FLG2 = 2) AND HKH.SRVKBNCD IS NOT NULL THEN HKH.NT2 ELSE NULL END AS HKH_NT2"
        }
      },
      {
        field: "NT3",
        displayName: "特定診療・治療費本人負担額",
        dataType: "INT",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CASE WHEN (HKH.FLG2 = 0 OR HKH.FLG2 = 2) AND HKH.SRVKBNCD IS NOT NULL THEN HKH.NT3  ELSE NULL END AS HKH_NT3"
        }
      }
    ]
  },
  //HISHNM
  {
    name: "医療保険情報",
    table: "HISHNM",
    dateTimeType: "string",
    startDateColumn: "HM.SDATE",
    endDateColumn: "HM.EDATE",
    alias: "HM",
    innerJoin: `
      INNER JOIN HISHNM HM ON HM.DAICD = DBL.DAICD  
      LEFT JOIN DAI D ON D.DAICD = HM.DAICD 
      LEFT JOIN RECETITLE R ON HM.SRVKBNCD2 = R.CODE`,
    tableCondition: "",
    fields: [
      {//1
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {//2
        field: "NAME",
        displayName: "氏名",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CONCAT(D.FAMILY, D.GIVEN) AS HM_NAME"
        }
      },
      {//3
        field: "KANA",
        displayName: "フリガナ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "CONCAT(D.KANAFAMILY, D.KANAGIVEN) AS HM_KANA"
        }
      },
      {//4
        field: "SDT",
        displayName: "有効期限開始日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {//5
        field: "EDT",
        displayName: "有効期限終了日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {//6
        field: "MLAWCD",
        displayName: "医療保険制度法別コード",
        dataType: "VARCHAR"
      },
      {//7
        field: "HOCD",
        displayName: "保険者番号",
        dataType: "VARCHAR"
      },
      {//9
        field: "HONM",
        displayName: "保険者名称",
        dataType: "VARCHAR"
      },
      {//10
        field: "H_HOCD",
        displayName: "被保険者証記号・番号",
        dataType: "VARCHAR"
      },
      {//11
        field: "WCAUSE",
        displayName: "職務上の事由区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003001",
        }
      },
      {//12
        field: "M_RATIO",
        displayName: "医療保険給付割合",
        dataType: "VARCHAR"
      },
      {//13
        field: "N2",
        displayName: "据置き",
        dataType: "VARCHAR"
      },
      {//14
        field: "M_MEMO",
        displayName: "特記",
        dataType: "VARCHAR"
      },
      {//15
        field: "MITMCD",
        displayName: "医療保険種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003002",
        }
      },
      {//16
        field: "H_HOKBN",
        displayName: "被保険者区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003003",
        }
      },
      {//17
        field: "GENPRICE",
        displayName: "負担金額",
        dataType: "INT"
      },
      {//18
        field: "GENFLG",
        displayName: "単位",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003004",
        }
      },
      {//19
        field: "GENKBN",
        displayName: "区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003005",
        }
      },
      {//20
        field: "LIMIT",
        displayName: "上限金額",
        dataType: "INT"
      },
      {//21
        field: "N3",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {//22
        field: "JIGNO2",
        displayName: "提供事業所番号",
        dataType: "VARCHAR"
      },
      {//23
        field: "SITEINO2",
        displayName: "提供事業所指定番号",
        dataType: "VARCHAR"
      },
      {//24
        field: "JIGNM2",
        displayName: "提供事業所名",
        dataType: "VARCHAR"
      },
      {//25
        field: "SRVKBNCD2",
        displayName: "提供事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "R.NAME AS HM_SRVKBNCD2"
        }
      },
      {//26
        field: "H_SDT",
        displayName: "訪問開始日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {//27
        field: "H_EDT",
        displayName: "訪問終了日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {//28
        field: "H_ETM",
        displayName: "訪問終了時刻",
        dataType: "VARCHAR"
      },
      {//29
        field: "H_ECN",
        displayName: "訪問終了状況",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003006",
        }
      },
      {//30
        field: "H_DDT",
        displayName: "死亡日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {//31
        field: "H_DTM",
        displayName: "死亡時刻",
        dataType: "VARCHAR"
      },
      {//32
        field: "F4",
        displayName: "死亡場所",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "003007",
        }
      },
      {//33
        field: "SICKKBN",
        displayName: "管理療養費",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//34
        field: "A21FLG",
        displayName: "難病",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//35
        field: "SPFLG",
        displayName: "情報提供療養費1",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//36
        field: "F8",
        displayName: "情報提供療養費2",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//37
        field: "F9",
        displayName: "情報提供療養費3",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//38
        field: "F3",
        displayName: "同一建物居住者",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//39
        field: "F12",
        displayName: "同一建物3人以上",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//40
        field: "F5",
        displayName: "自動算定補正対象外",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//41
        field: "ADD1FLG",
        displayName: "退院時共同指導",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//42
        field: "F2",
        displayName: "24時間対応体制",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//43
        field: "ADD2FLG",
        displayName: "24時間連絡体制",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//44
        field: "ADD3FLG",
        displayName: "特別管理(250)",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//45
        field: "F1",
        displayName: "特別管理(500)",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//46
        field: "F6",
        displayName: "特別管理指導",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//47
        field: "F7",
        displayName: "(精)重症患者早期集中支援",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//48
        field: "F10",
        displayName: "(精)重症患者早期集中支援2のイ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      },
      {//49
        field: "F11",
        displayName: "(精)重症患者早期集中支援2のロ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "009008",
        }
      }
    ]
  },
  {
    name: "受給者証情報 (総合支援)",
    table: "JHISJK",
    dateTimeType: "string",
    startDateColumn: "JHK.SDATE",
    endDateColumn: "JHK.EDATE",
    alias: "JHK",
    innerJoin: ``,
    fields: [
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "NAME",
        displayName: "氏名",
        dataType: "VARCHAR"
      },
      {
        field: "KANA",
        displayName: "フリガナ",
        dataType: "VARCHAR"
      },
      {
        field: "J_NAME",
        displayName: "氏名(障害児)",
        dataType: "VARCHAR"
      },
      {
        field: "J_KANA",
        displayName: "フリガナ(障害児)",
        dataType: "VARCHAR"
      },
      {
        field: "CITYNM",
        displayName: "市町村名",
        dataType: "VARCHAR"
      },
      {
        field: "CITYCD",
        displayName: "市町村コード",
        dataType: "VARCHAR"
      },
      {
        field: "KDATE",
        displayName: "交付年月日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "SDATE",
        displayName: "有効期間（始）",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "EDATE",
        displayName: "有効期間（終)",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_SHORT_DATE
        }
      },
      {
        field: "SJNO",
        displayName: "受給者証番号",
        dataType: "VARCHAR"
      },
      {
        field: "SYSKBNCD",
        displayName: "制度区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002"
        }
      },
      {
        field: "NT1",
        displayName: "障害種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002",
        }
      },
      {
        field: "STKBN",
        displayName: "障害程度[支援]区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102003",
        }
      }
    ]
  },
  //JHISIO KBN = 0
  {
    name: "入所 (院)履歴 (総合支援)",
    table: "JHISIO",
    dateTimeType: "string",
    startDateColumn: "JHS0.SDATE",
    endDateColumn: "JHS0.EDATE",
    alias: "JHS0",
    innerJoin: ``,
    tableCondition: "JHS0.KBN = 0",
    fields: [
      {
        field: "SYSKBNCD",
        displayName: "制度区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002",
        }
      },
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNCD",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS JHS0_SRVKBNCD"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "KBN",
        displayName: "区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102004",
        }
      },
      {
        field: "SDATE",
        displayName: "入所 (院)、外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "SNOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "EDATE",
        displayName: "退所 (院)日、外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ENOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "FLG1",
        displayName: "送迎 (迎え)",
        dataType: "VARCHAR"
      },
      {
        field: "FLG2",
        displayName: "送迎 (送り)",
        dataType: "VARCHAR"
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102006",
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //JHISIO KBN = 1
  {
    name: "外泊入院履歴 (総合支援)",
    table: "JHISIO",
    dateTimeType: "string",
    startDateColumn: "JHS1.SDATE",
    endDateColumn: "JHS1.EDATE",
    alias: "JHS1",
    innerJoin: ``,
    tableCondition: "JHS1.KBN = 1",
    fields: [
      {
        field: "SYSKBNCD",
        displayName: "制度区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002",
        }
      },
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNCD",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS JHS1_SRVKBNCD"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "KBN",
        displayName: "区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102004",
        }
      },
      {
        field: "SUPNM",
        displayName: "移動先",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "入所 (院)、外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "SNOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "EDATE",
        displayName: "退所 (院)日、外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ENOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102006",
        }
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //JHISIO KBN = 3
  {
    name: "居宅サービス開始中止履歴 (総合支援)",
    table: "JHISIO",
    dateTimeType: "string",
    startDateColumn: "JHS3.SDATE",
    endDateColumn: "JHS3.EDATE",
    alias: "JHS3",
    innerJoin: ``,
    tableCondition: "JHS3.KBN = 3",
    fields: [
      {
        field: "SYSKBNCD",
        displayName: "制度区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002",
        }
      },
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNCD",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS JHS3_SRVKBNCD"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "KBN",
        displayName: "区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102004",
        }
      },
      {
        field: "SDATE",
        displayName: "入所 (院)、外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "SNOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "EDATE",
        displayName: "退所 (院)日、外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ENOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "FLG1",
        displayName: "同一建物内",
        dataType: "VARCHAR"
      },
      {
        field: "FLG2",
        displayName: "50人以上",
        dataType: "VARCHAR"
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102006",
        }
      },
      {
        field: "NT1",
        displayName: "中止理由",
        dataType: "VARCHAR"
      },
      {
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  },
  //JHISIO KBN = 4
  {
    name: "短期利用加算起算日履歴 (総合支援)",
    table: "JHISIO",
    dateTimeType: "string",
    startDateColumn: "JHS4.SDATE",
    endDateColumn: "JHS4.EDATE",
    alias: "JHS4",
    innerJoin: ``,
    tableCondition: "JHS4.KBN = 4",
    fields: [
      {
        field: "SYSKBNCD",
        displayName: "制度区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102002",
        }
      },
      {
        field: "DAICD",
        displayName: "利用者コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVKBNCD",
        displayName: "事業所サービス種別",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.JONTABLE,
          columnName: "OS.SRVKBNNM AS JHS4_SRVKBNCD"
        }
      },
      {
        field: "JIGNO",
        displayName: "事業所番号",
        dataType: "VARCHAR"
      },
      {
        field: "SITEINO",
        displayName: "事業所指定番号",
        dataType: "VARCHAR"
      },
      {
        field: "KBN",
        displayName: "区分",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102004",
        }
      },
      {
        field: "SDATE",
        displayName: "入所 (院)、外泊日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "SNOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "EDATE",
        displayName: "退所 (院)日、外泊戻り日",
        dataType: "DATETIME",
        outputData: {
          type: MasterDataFormat.DATETIME,
          format: DateFormat.FULL_DATE_HOUR
        }
      },
      {
        field: "ENOTE",
        displayName: "備考欄",
        dataType: "VARCHAR"
      },
      {
        field: "FLG3",
        displayName: "外泊入院フラグ",
        dataType: "VARCHAR",
        outputData: {
          type: MasterDataFormat.CODEMST,
          classCd: "102006",
        }
      },
      { 
        field: "NUMBEROFDAYS",
        displayName: "利用日数",
        dataType: "VARCHAR",
      }
    ]
  }
]