<div class="staff-info-container">
    <pivot-single-pannel-template [titlePage]="titlePage" id="role-setting-staff-info">
        <div class="staff-pannel-left-content" pannel-left-content>
            <pivot-search-bar class="search-team" [searchParams]="searchTeam" (onFilterData)="onFilterTeamData($event)">
            </pivot-search-bar>
            <div class="left-table-wrapper" [style]="{'min-height':'calc(100vh - 255px)'}">
                <div class="team-list-label-group">
                    <span class="team-list-label">{{sysTemText.STAFF_LIST_LABEL}}</span>
                </div>
                <div class="table-team-format">
                    <p-listbox optionLabel="teamName" [options]="teamData.body" [(ngModel)]="selectedRow"
                    (onClick)="onClickTeamRow($event.value)" emptyMessage="{{commonText.NO_DATA}}" id="listboxRole"
                    [styleClass]="'roles-listbox'"
                    [listStyle]="{'max-height':'calc(100vh - 375px)'}" [listStyleClass]="'roles-style'">
                        <ng-template let-role pTemplate="item">
                            <div class="row-wrapper" pDroppable="teams" (onDrop)="drop(role)">
                                <div *ngIf="!role.onEdit" [ngClass]="{'role-item': true, 'role-area-drop': isShowDropAreaOfListTeam, 'selected-item': role.onEdit}">
                                    <div class="team-name-wrapper">
                                        <span ngClass="team-name" class="role-item--name">{{role.teamName}}</span>
                                    </div>
                                    <ng-template [ngIf]="role.teamCd !== undecidedGroupLabel && role.checked && !role.onEdit">
                                        <pivot-icon-button class="pen-btn" [styleClass]="'row-pen-btn'" (onClick)="onShowEdit($event, role, 'onRow')" icon="assets/icons/row_edit_pen.svg"></pivot-icon-button>
                                    </ng-template>
                                    <ng-template [ngIf]="role.teamCd !== undecidedGroupLabel && !role.checked && !role.onEdit">
                                        <pivot-icon-button class="pen-btn" [styleClass]="'row-pen-btn'" class="hover-action" icon="assets/icons/row_edit_pen.svg" (onClick)="onShowEdit($event, role, 'otherRow')"></pivot-icon-button>
                                    </ng-template>
                                </div>
                                <div *ngIf="role.onEdit" class="row-edit-group" [@fadeIn]="role.onEdit">
                                    <pivot-textbox [inputParams]="inputParams" class="row-edit-textbox" [(inputValue)]="teamNameOnProcessing"></pivot-textbox>
                                    <div class="row-btn-group">
                                        <pivot-icon-button (onClick)="rowSaveChange(role)" [styleClass]="'row-function-btn confirm'" [buttonType]="buttonType.SECONDARY" icon="assets/icons/row_confirm.svg"></pivot-icon-button>
                                        <pivot-icon-button *ngIf="guards != guardMode.ADD" (onClick)="onDeleteTeam(role)" [styleClass]="'row-function-btn danger'" [buttonType]="buttonType.DANGER" icon="assets/icons/row_delete.svg"></pivot-icon-button>
                                        <pivot-icon-button (onClick)="rowCancel(role)" [styleClass]="'row-function-btn cancel'" [buttonType]="buttonType.DANGER" icon="assets/icons/row_cancel.svg"></pivot-icon-button>
                                    </div>
                                    <div id="lastestToScroll"></div>
                                </div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
                <div class="add-new-btn-wrapper">
                    <pivot-icon-button (onClick)="addNewTeam()" [styleClass]="'add_new_row_btn'" icon="assets/icons/add_new_row.svg" [buttonType]="buttonType.SECONDARY"></pivot-icon-button>
                </div>
            </div>
        </div>
        <div class="pannel-right-content" pannel-right-content>
            <div class="staff-list-content">
                <div class="staff-list-filter">
                    <pivot-search-bar [searchParams]="searchStaff" (onFilterData)="onFilterStaff($event)">
                    </pivot-search-bar>
                    <div class="btn-invite-employee-container">
                            <pivot-icon-button [styleClass]="'invite-pivot-user-btn'" class="btn-invite-employee" [buttonType]="buttonType.SECONDARY" (onClick)="onShowDlg()" icon="assets/icons/email_invite.svg" [label]="BUTTON.PIVOT_INVITE"></pivot-icon-button>
                    </div>
                </div>
                <div class="table-right">
                    <pivot-table [isLoading]="isLoadingTableStaff" class="table" [selectedRows]="staffListSelecteds" [selectedRowsToDrag]="rowSelected"
                        [data]="staffData" (checkRows)="checkRowsStaffTable($event)" (staffsDragged)="onStaffsDragged($event)"
                        [checkAllMode]="checkAllMode.ALL_PAGE" (onEditDataRow)="onEditStaff($event)" (onDeleteDataRow)="onDeleteStaff($event)"
                        pDraggable="teams" (showDropAreaChange)="onShowDropAreaChange($event)">
                    </pivot-table>
                </div>
            </div>
        </div>
    </pivot-single-pannel-template>
</div>

<pivot-dialog-staff-edit [isDisplayModal]="isDspStaffEditModal" [data]="roles"
    [selectedDataRolecd]="getSelectedRoleCd()" [listTeam]="listTeam" [teamDspField]="teamDspField" [selectedTeams]="selectedTeams"
    (closeModalHandler)="closeStaffEditModal()" (onSubmitData)="handleStaffEditSubmit($event)">
</pivot-dialog-staff-edit>

<pivot-confirm-invite-dialog [listStaff]="allStaffs" [isDisplayModal]="isDspConfirmInviteModal" [modalData]="modalData"
    (onSubmitData)="onSubmitDlg($event)"
    [lstPendingInvitation]="lstPendingInvitation">
</pivot-confirm-invite-dialog>