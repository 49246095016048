import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API_APP } from "../../config/app.config";
import { ApiService } from "../api.service";
import {ErrorHandleService} from "../error-handle.service";

@Injectable({ providedIn: 'root' })
export class SyncDataOracleService extends ApiService {

    constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
        super(http, router, errorHandle);
      }

    syncUnit() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_UNIT;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });

        return resData;
    }

    syncBlock() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_BLOCK;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });

        return resData;
    }

    syncOffice() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_OFFICE;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });

        return resData;
    }

    syncCorp() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_CORP;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });

        return resData;
    }

    syncOfficeService() {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_OFFICE_SERVICE;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });

        return resData;
    }
    
    syncRoom(){
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_ROOM;
        const resData = this.post(apiUrl, undefined, undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }
        });
        return resData;
    }

    syncTitleMapping(office: any) {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.SYNC_TITLE_MAPPING;
        const body = [{ officecd: office.officecd }];
        const resData = this.post(apiUrl, undefined, body).then(res => {
            if (res.statuscode !== 200) {
                res.office = office;
                throw res;
            }

            return res;
        });

        return resData;
    }

    deleteSyncTitleMapping(office: any) {
        const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.DELETE_SYNC_TITLE_MAPPING;

        const resData = this.post(apiUrl, [office.officecd], undefined).then(res => {
            if (res.statuscode !== 200) {
                throw res;
            }

            return res;
        });

        return resData;
    }
}