<div class="dashboard">
	<div class="dashboard-container">
		<div class="dashboard-container--header">
			<button [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'block'"
				class = "icon-filter-office"
				[disabled]="dsbBtnSelectOffice" (click)="openDialogOffice()"  [ngStyle]="{'background-color': isFilterByOffice == false? '' : '#969696'}">
				<span [ngClass]="isFilterByOffice == false ? 'filter-icon icon-office' : 'filter-icon icon-office-selected'"></span>
      		</button>
			<pivot-search-bar class="search-bar" [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"
				[placeHolderFilter]="HOME.SEARCH_DASHBOARD" [style.padding-right.px]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 10 : 0"></pivot-search-bar>
			<div [ngClass]="isUpdateData ? 'btn-update-data btn-disable' : 'btn-update-data'" [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'block'">
				<div class="control-popup" *ngIf="isUpdateData">
					<div *ngIf="dataUpdate[0]">
						<label>{{dataUpdate[0].dsName}}</label>
						<p-progressBar class="progress-bar" [showValue]="false" [value]="dataUpdate[0].note">
						</p-progressBar>
					</div>
					<div *ngIf="dataUpdate[1]">
						<label>{{dataUpdate[1].dsName}}</label>
						<p-progressBar class="progress-bar" [showValue]="false" [value]="dataUpdate[1].note">
						</p-progressBar>
					</div>
					<div *ngIf="dataUpdate[2]">
						<label>{{dataUpdate[2].dsName}}</label>
						<p-progressBar class="progress-bar" [showValue]="false" [value]="dataUpdate[2].note">
						</p-progressBar>
					</div>
					<span>{{dataUpdate.length + '件実行中...'}}</span>
				</div>
			</div>

			<button class="button-show-dialog-update-widget" [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'block'"
        		[ngStyle]="{'background-color': isFilterByOffice == false? '$white-color' : '$background-gray-color'}"
			(click)="onShowDlg()">
    				<div class="img-of-button" >
        				<img class="text-of-button" [ngClass]="isDarkMode ? 'dark-mode' : 'default'">{{BUTTON.UPDATE_NEW_DATA}}
				</div>
			</button>

			<button class="button-export-csv" [style.display]="deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET ? 'none' : 'block'"
        		[ngStyle]="{'background-color': isFilterByOffice == false? '$white-color' : '$background-gray-color'}"
			(click)="getListDataExport()">
    				<div class="img-btn-export" >
        				<img class="csv-btn-text" [ngClass]="isDarkMode ? 'csv-dark-mode' : 'csv-default'">
				</div>
			</button>
		</div>
		<div class="dashboard-container--body"
			*ngIf="currentSelectedDashboard && currentSelectedDashboard.setting.length > 0">
			<gridster [options]="options" #mygridster>
				<ng-container *ngIf="!dashboardCd.includes('ZF')">
					<gridster-item [item]="widget" *ngFor="let widget of currentSelectedDashboard.setting" (itemResize)="widgetResize($event)">
						<div class="gridster-item-body">
							<div class="gridster-item-error" *ngIf="widget.syncStatus == syncStatusWidget.ERROR">
								<div class="error-message">
									{{DATA_UPDATE.ERROR_UPDATE_DATA_MESSAGE}}
								</div>
							</div>
							<div class="gridster-item-error" *ngIf="widget.syncStatus == syncStatusWidget.NO_DATA">
								<div class="no-data-message">
									{{DATA_UPDATE.NO_DATA_MESSAGE}}
								</div>
							</div>
							<div class="gridster-item-content" [ngClass]="{'error': widget.syncStatus ==  syncStatusWidget.ERROR }"[id]="widget.widgetCd" (click)="onGetWidgetSelect(widget)">
								<div class="gridster-item-content--header" [id]="widget.datasourceCd">
									<label class="widget-title">{{ getWidgetTitle(widget.widgetCd) }}</label>
									<div class="item-action">
										<button  *ngIf="widget.syncStatus == syncStatusWidget.REQUEST_SYNC"  class="sync-widget" 
											[ngStyle]="{'background-color': isFilterByOffice == false? '$white-color' : '$background-gray-color'}"
											(click)="syncWidgetResult(widget)" [disabled]="isUpdateData">
												{{BUTTON.REQUEST_SYNC}}
											<img class="sync-icon"  [ngClass]="isDarkMode ? 'dark' : 'normal'" >
										</button>
									</div>
									<i class="pi pi-book" (click)="openLink(widget.widgetCd!)"></i>
								</div>
								<ng-container>
									<pivot-chart 
										*ngIf="widget.isHaveSize === false" 
										[widgetId]="widget.widgetCd || ''" 
										[isFilterByOffice] = "isFilterByOffice"
										[widgetdetails]="widget.widgetdetails"
										[sortParams]="widget.sortArr || []" 
										[widgetResult]="widget.widgetResult"  
										[isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"
										[type]="widget.type" 
										[width]="widget.width" 
										[height]="widget.height" 
										[officeList]="officeList" 
										[widgetConfig]="widget.widgetConfig"
										(handSortData)="handleSortColData($event)"
										(handleClickTable)="onClickHeaderTable()"
										[isHome]="true"
									>
									</pivot-chart>
								</ng-container>
								<div class="gridster-item-content--description" [ngClass]="{ 'mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}" *ngIf="widget.isHaveSize === false">
									<label class="lbl-date">【{{widget.startdate}}～{{widget.enddate}}】</label>
									<label class="lbl-description" [pTooltip]="getWidgetDescription(widget.widgetCd)">{{ getWidgetDescription(widget.widgetCd) }}</label>
								</div>
							</div>
						</div>
					</gridster-item>
				</ng-container>
				<ng-container *ngIf="dashboardCd.includes('ZF')">
					<gridster-item class="grister-item-custom" [item]="widget" *ngFor="let widget of currentSelectedDashboard.setting"	(itemResize)="widgetResize($event)">
						<div class="gridster-item-content" [id]="widget.widgetCd">
							<div class="gridster-item-content--header">
								<label class="widget-title">{{ widget.widgetCd && getZaitakuItemDetailName(widget.widgetCd) }}</label>
								<div class="item-action">
									<i class="pi pi-book" (click)="openLink(widget.widgetCd!)"></i>
								</div>
							</div>
							<ng-container>
								<pivot-zaitaku-item class="zaitaku-item" [dataZaitaku]="dataZaitaku" [zaitakuId]="widget.widgetCd" [itemWidth]="widget.width" [hasHospitalNearby]="hasHospitalNearby"></pivot-zaitaku-item>
							</ng-container>
							<div class="gridster-item-content--description" [ngClass]="{ 'mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}" *ngIf="widget.isHaveSize === false">
								<label class="lbl-date">【{{widget.startdate | date:'yyyy年MM月dd日'}}～{{widget.enddate | date:'yyyy年MM月dd日'}}】</label>
							</div>
						</div>
					</gridster-item>
				</ng-container>
			</gridster>
		</div>
		<div class="dashboard-container--nodata"
			*ngIf="!currentSelectedDashboard || currentSelectedDashboard.setting.length == 0">
			<div class="no-data-label" *ngIf="!currentSelectedDashboard">
				{{HOME.NO_DATA}}</div>
			<div class="no-data-img">
				<div class="no-data-img--img"></div>
			</div>
		</div>
	</div>
  <pivot-help-detail-dialog 
  [data] = "dataHelpPopup" 
  [showCheckbox]="true" 
  [showDialog]="showHelpDialog" 
  (closeDialog) = "onCloseDialogHelp($event)"
  (changeCheckbox) = "onChangeHidepopup($event)"
  [dataCheckbox]="false"
  ></pivot-help-detail-dialog>
  
  <pivot-office-service *ngIf="isDspOfficeModal" [selectedOfficeServices]="officeSelecteds"
    (closeModalHandler)="closeOfficeModal()" (selectedOfficeHandler)="selectedOffice($event)" [isHome]="true">
  </pivot-office-service>

  <pivot-dialog-widget-update-component [isDisplayModal]="isDspConfirmInviteModal" [modalData]="modalData"
  (onSubmitData)="onSubmitDlg($event)">
</pivot-dialog-widget-update-component>
</div>