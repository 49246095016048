import * as moment from "moment";
import { environment } from "../../environments/environment";
import { ChartData, operator } from "../models/common-model";
import { WidgetDetail, WidgetSettingRecord } from "../models/request/widget.dto";
import { FormatType, DateFormat, ColumnType, SummaryColumnOption, ChartType, DataType, FilterGroup, PrimengDateFormat, FilterGroupDefaultValue } from "../enum/common-enum";
import { PivotTableConfig } from "../models/response/widget.ro";
import Utils from "../util/utils";
import { COMMON_TEXT } from "../const/text-common";
import { cloneDeep, concat, every, filter, flatten, flattenDepth, isNull, isNumber, isUndefined, range, reject, maxBy, minBy } from "lodash";
import { MESSAGE_TEXT } from "../const/message";
import { OPERATOR_CUSTOM, OPERATOR_CUSTOM_NAME, OPERATOR_TYPE } from "../const/operator-custom";
import { decodeCharactorOperators, ReplaceAllCharactorOperator } from "./operator-custom-helper";
import { HeaderItem } from "../models/table-model";
import { 
  ColFilterGroup,
  DATA_SOURCE_DEFAULT_HEADERS,
  DATATYPE, 
  DateTimeExpressions, 
  InColumn, 
  InHiddenValue, 
  InRow, 
  InValue, 
  JapanDateFormat, 
  OrderBy, 
  PivotFooterOptions, 
  SortType, 
  FilterValueOption, 
  DATA_SOURCE_EXTRA_MASTER_HEADERS,
  MstFilterGroup,
  MstFilterVarcharItem,
  MstFilterIntItem,
  MstFilterDataTimeItem
} from "../const/const";

export const setValidateMaxLength = (strValue: string, maxLength: number) => {
  const bytes = strValue.length;
  if (bytes > maxLength) {
    let strReturn = '';
    for (let i = 0; i < strValue.length; i++) {
      const tem = strReturn + strValue.charAt(i);
      if (tem.length > maxLength) {
        break;
      }
      strReturn += strValue.charAt(i);
    }
    strValue = strReturn;
  }
  return strValue;
};

export type PivotObject = {
    headers: any[][]
    body: any[][]
    grandTotal?: number
    footers?: any
}

// Sort array by type sort
// OrderBy: ASC, DESC
export function sortBy(array: any, type: any, orderBy = OrderBy.ASC) {
  if (!array || array.length === 0) return;
  // ASC
  if (orderBy === OrderBy.ASC) {
    switch (type) {
      case SortType.INSDATE:
        array.sort((a: any, b: any) => {
          return new Date(a.insdate).getTime() - new Date(b.insdate).getTime();
        });
        break;
      case SortType.SORT_NO:
        array.sort((a: any, b: any) => {
          return a.sortno - b.sortno;
        });
        break;
      case SortType.CATEGORY_CD:
        array.sort((a: any, b: any) => {
          return a.categorycd - b.categorycd;
        });
        break;
      case SortType.UPDDATE:
        array.sort((a: any, b: any) => {
          return Date.parse(a.upddate) - Date.parse(b.upddate);
        });
        break;
      case SortType.DATE:
        array.sort((a: any, b: any) => {
          return Date.parse(a.date) - Date.parse(b.date);
        });
        break;
      case SortType.KEY:
        array.sort((a: any, b: any) => {
          return a.key - b.key;
        });
        break;
    }
  }

  // DESC
  if (orderBy === OrderBy.DESC) {
    switch (type) {
      case SortType.INSDATE:
        array.sort((a: any, b: any) => {
          return new Date(b.insdate).getTime() - new Date(a.insdate).getTime();
        });
        break;
      case SortType.SORT_NO:
        array.sort((a: any, b: any) => {
          return b.sortno - a.sortno;
        });
        break;
      case SortType.CATEGORY_CD:
        array.sort((a: any, b: any) => {
          return b.categorycd - a.categorycd;
        });
        break;
      case SortType.UPDDATE:
        array.sort((a: any, b: any) => {
          return Date.parse(b.upddate) - Date.parse(a.upddate);
        });
        break;
      case SortType.DATE:
        array.sort((a: any, b: any) => {
          return Date.parse(b.date) - Date.parse(a.date);
        });
        break;
      case SortType.KEY:
        array.sort((a: any, b: any) => {
          return b.key - a.key;
        });
        break;
    }
  }
}

export function getAvatarUrl(staffcode: string, isNew: boolean, url: string) {
  const newImageUrl = environment.USER_DATA_URL + url;

  if (isNew) {
    return newImageUrl;
  }

  return newImageUrl + '?' + Date.now().toString();
}

export const fetchFakeData = async (data: any): Promise<any> => {
  let min = 1500, max = 4000
  await delay(Math.random() * (max - min) + min)
  return new Promise(resolve => resolve(data))
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export type PivotTableData = { table: PivotObject, config?: PivotTableConfig }

export type TableAndChartData = { chart: ChartData, table: PivotTableData }

export const modityPivotObject = (pivotObject: PivotObject, config: PivotTableConfig): PivotObject => {
  if (config.summaryColumns.length > 0 && config.summaryColumns[0].formattype) {
    pivotObject.headers = pivotObject.headers.map((row: any) => row.slice(0, -config.values.length));
    pivotObject.body = pivotObject.body.map(row => row.slice(0,  -config.values.length));
  }

  if (config.rows.length === 0) {
    pivotObject.headers.forEach(row => row.unshift(" "));
    pivotObject.body.forEach(row => row.unshift(" "));
  }

  return pivotObject;
}

export const getHeaderColumChart = (pivotObject: PivotObject, config: PivotTableConfig): any => {

  let headerBeautify = pivotObject.headers.map((h: any) => {
    return h.filter((x: any) => typeof x === 'object')
      .map((x: any) => beautify(replaceNameNashi(x.value), x.formattype))
  });
  if (config.values.length == 1 && config.columns.length == 1) {
    return [...headerBeautify[0]]
  }
  return headerBeautify[0].map((x: any, index: any) => {
    let mergeheader = '';
    headerBeautify.map((d: any) => {
        Utils.isNullOrEmpty(d[index])? mergeheader : mergeheader += '_' + d[index]
      });
    return mergeheader.substring(1);
  })
}

export const getHeaderRowChart = (pivotObject: PivotObject, config: PivotTableConfig): any => {
  return  pivotObject.body.map((h: any) =>  h[0].value);
}

export const createXNode = (textContent?: string | null, styleClass?: string | null, className?: string | null, contentEditable?: boolean|null) => {
  let node = document.createElement('span')
  node.className = "x-node"
  if (className) {
    node.classList.add(className??"");
  }
  node.contentEditable = 'false'
  if(contentEditable) {
    node.contentEditable = 'true'
  }

  node.textContent = textContent || null

  if (styleClass) node.classList.add(styleClass)

  return node
}

export const evaluateFormula = (formula: string): any => {
  try {
    return eval(formula)
  } catch (err) {
    return null
  }
}

export const makePivotConfig = (data: WidgetDetail[]): PivotTableConfig => {
  // let using = data.filter(x => !x.delflg && x.columntype)
  let hiddens: any[] = [];
  let comlumFilterHiddens: any[] = [];
  const rows = data.filter(x => InRow[x.columntype]).sort((a, b) => Number(a.pivotorder) - Number(b.pivotorder))
  const columns = data.filter(x => InColumn[x.columntype]).sort((a, b) => Number(a.pivotorder) - Number(b.pivotorder))
  const values = data.filter(x => InValue[x.columntype]).sort((a, b) => Number(a.pivotorder) - Number(b.pivotorder))
  const footers = data.filter(x => x.columntype === ColumnType.Footer)
  hiddens = data.filter(x => InHiddenValue[x.columntype])
  comlumFilterHiddens = data.filter(x => (x.filtertype != null || x.pivotfiltertype != null)
  && (!InRow[x.columntype])
  && (!InColumn[x.columntype])
  && (!InValue[x.columntype])
  && (!InHiddenValue[x.columntype])
  );
if(comlumFilterHiddens?.length > 0) hiddens = hiddens.concat(comlumFilterHiddens);
  const summaryColumns = data.filter(x => x.columntype === ColumnType.summaryColumn)

  return { rows, columns, values, footers, summaryColumns, hiddens }
}

export function checkContainsString(value: string, condition: string[] = []): any {
  if(condition.length == 0) return false;
  for (let char of condition) {
    if (value?.toString()?.includes(char)) {
      return { isContains: true, values: value?.split(char) };
    }
  }
  return { isContains: false, values: null };
}


  /* 
  get value from format date
  */
  export const getValueFromFormatDate = (option: any, value: any) => {
    let newVal = new Date(value);
    switch(option) { 
      case  FilterGroup.YEAR_MONTH:
        return new Date(newVal.getFullYear(), newVal.getMonth())
      case  FilterGroup.MONTH_DAY:
      case  FilterGroup.MONTH:
        return new Date(new Date().getFullYear(), newVal.getMonth(), newVal.getDate())
      case  FilterGroup.HOUR_MINUTE:
      case  FilterGroup.HOUR:     
      if (isNaN(newVal.getTime())) {
          value = value?.toString().split(':') || [];
          return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), Number(value[0]) || 0, Number(value[1]) || 0);
        }   
        else return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), newVal.getHours(), newVal.getMinutes())
      default:
        return value;
    }
  }

 /* 
  get min value and max value from input 
  */
  export const findMinMaxValues = (minMaxCol: any[], isDate: boolean, option: any): { minVal: number, maxVal: number, range: number } => {
    let minVal: any = null;
    let maxVal: any = null;
    let min: any = null;
    let max: any = null;
    let range: number = 0;
    if(isDate) {
      switch(option?.value) {
        case FilterGroup.DAY:
          min = 1;
          max = 31;
          minVal = min,
          maxVal = max;
        break;
        case FilterGroup.HOUR:
        case FilterGroup.HOUR_MINUTE:
          min = "00:00"
          max = "23:59";
          minVal = min;
          maxVal = max;
          break;
        // option is format Month
        case FilterGroup.MONTH: 
          min = 1;
          max = 12;
          minVal = new Date(new Date().getFullYear(), min - 1);
          maxVal = new Date(new Date().getFullYear(), max - 1);
          break;
        // option is format Month_Day
        case FilterGroup.MONTH_DAY:
          min = new Date(new Date().getFullYear(), 0, 1);
          minVal = min;
          max = new Date(new Date().getFullYear(), 11, 31);
          maxVal = max;
        break;
        // option is format Year_Month
        case FilterGroup.YEAR_MONTH:
          minVal = new Date(minMaxCol[0]);
          maxVal = new Date(minMaxCol[0]);
          for (let i = 1; i < minMaxCol.length; i++) {
            const currentDate = new Date(minMaxCol[i]);
            if (currentDate < minVal) {
              minVal = currentDate;
            }
            if (currentDate > maxVal) {
              maxVal = currentDate;
            }
          }
          break;
        default:
          break;
      }
    }
    else {
      // set min value and max value after format data
      minVal = Math.min(...minMaxCol);
      maxVal = Math.max(...minMaxCol);
      range = maxVal - minVal;
    }
    return { minVal, maxVal , range};
  }

export const distinctBy = (data: any[], field: string): string[] => [...new Set(data.map(x => x[field]))]

export function filterDataTableWithCondition(dataTable: any[], columnFilter: string, filter: any) {
  if (!filter) return dataTable;
  let filterArray = filter.filtertype.split('-');
  let filterType = filterArray[0]; // 001=>VARCHAR 002=>INT 003=>DATATIME
  let filterCondition = filterArray[1];
  let valueFilter: any = null;
  let mutiCondition: boolean = false;
  let valueArray: any[] = [];
  if (filterType === MstFilterGroup.DATATIME) {
      // 013-本年度, 014-前年度, 015-前々年度
      if([MstFilterDataTimeItem.ThisFiscalYear, MstFilterDataTimeItem.LastFiscalYear, MstFilterDataTimeItem.TwoFiscalYearAgo].includes(filterCondition)) {
        valueFilter = filter.filtervalue;
        valueArray = valueFilter.split('-');
      }
      else  valueFilter = new Date(filter.filtervalue);
  }
  else if(filterType === "totaldays") {
    valueFilter = "";
    filter.filtervalue.forEach((obj: any )=> {
      if (obj.code.startsWith("TTD_")) {
        valueFilter += obj.code + "-";
      }
    });
    valueFilter = valueFilter.replace(/-$/, '');
  }
  else valueFilter = filter?.filtervalue?.toLowerCase()?.trim() || "";
  let values = valueFilter;
  let containsData = checkContainsString(valueFilter, [';']);
  if(containsData.isContains && (filterType == MstFilterGroup.VARCHAR || filterType == MstFilterGroup.INT)) {
      values = containsData.values || [];
      if(values.length > 1) mutiCondition = true;
  }

  switch (filterType) {
    case MstFilterGroup.VARCHAR: //VARCHAR
      switch (filterCondition) {
        case MstFilterVarcharItem.Equal: //	入力文字と一致する 	khớp với ký tự đầu vào	match the input character
          if(mutiCondition) {
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) 
            && values?.filter((val: any) =>((Utils.isNullOrEmpty(val) && Utils.isNullOrEmpty(item[columnFilter])) ||(!Utils.isNullOrEmpty(val) && val?.toString().toLowerCase()?.trim() == item[columnFilter]?.toString().toLowerCase()?.trim())))?.length > 0 ? true: false));
          }
          else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim() == valueFilter));

        case MstFilterVarcharItem.Contains://	入力文字を含む chứa các ký tự đầu vào	contains input characters
        if(mutiCondition) {
          return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || 
          (!Utils.isNullOrEmpty(valueFilter) && values?.filter((f: any) => (Utils.isNullOrEmpty(f) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(f) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(f?.toString().toLowerCase()?.trim())))?.length > 0? true: false))
        }
        else 
          return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(valueFilter)));  

        case MstFilterVarcharItem.EndsWith://	入力文字で終わる kết thúc bằng ký tự đầu vào	ends with the input character
          if(mutiCondition)
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) 
                && values?.filter((val: any) => (Utils.isNullOrEmpty(val) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(val) && item[columnFilter]?.toString().toLowerCase()?.trim()?.endsWith(val?.toString().toLowerCase()?.trim())) )?.length > 0 ? true: false));
          else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim().endsWith(valueFilter)));

        case MstFilterVarcharItem.StartsWith://	入力文字で始まる bắt đầu với ký tự đầu vào	start with the input character
          if(mutiCondition) return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && values?.filter((val: any) => (Utils.isNullOrEmpty(val) && Utils.isNullOrEmpty(item[columnFilter]))  || (!Utils.isNullOrEmpty(val) && item[columnFilter]?.toString().toLowerCase()?.trim()?.startsWith(val?.toString().toLowerCase()?.trim())))?.length > 0 ? true: false));
          else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim().startsWith(valueFilter)));
        case MstFilterVarcharItem.DoesNotEqual://	入力文字と一致しない không khớp với các ký tự đầu vào	does not match input characters
          if(mutiCondition) {
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) 
            && values?.filter((val: any) =>((Utils.isNullOrEmpty(val) && Utils.isNullOrEmpty(item[columnFilter])) ||(!Utils.isNullOrEmpty(val) && val?.toString().toLowerCase()?.trim() == item[columnFilter]?.toString().toLowerCase()?.trim())))?.length > 0 ? false: true));
          }
          else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && !Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim() != valueFilter)); 

        case MstFilterVarcharItem.DoesNotContain://	入力文字を含まない không chứa các ký tự đầu vào	does not contain input characters
          if(mutiCondition) {
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || 
            (!Utils.isNullOrEmpty(valueFilter) && values?.filter((f: any) => (Utils.isNullOrEmpty(f) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(f) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(f?.toString().toLowerCase()?.trim())))?.length > 0? false: true))
          } else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && !Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) &&  !item[columnFilter]?.toString().toLowerCase()?.trim().includes(valueFilter)));
      }
      break;
    case MstFilterGroup.INT: // INT
      switch (filterCondition) {
        case MstFilterIntItem.Equal: // 入力値と一致する phù hợp với giá trị đầu vào	matches the input value
          return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) 
            && mutiCondition? values?.filter((s:any) => Number(s) == Number(item[columnFilter])).length > 0 ? true: false: item[columnFilter] == valueFilter));
        case MstFilterIntItem.DoesNotEqual://	入力値と一致しない Không khớp với giá trị đầu vào	Does not match input value
          return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && !Utils.isNullOrEmpty(item[columnFilter])&& !mutiCondition) ||(!Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter]) && !mutiCondition)
           || (!Utils.isNullOrEmpty(valueFilter) && mutiCondition? values?.filter((s:any) =>(Utils.isNullOrEmpty(s) && Utils.isNullOrEmpty(item[columnFilter])) ||(Number(s) == Number(item[columnFilter]))).length > 0 ? false: true : item[columnFilter] != valueFilter));

        case MstFilterIntItem.Contains://	入力値を含む Chứa giá trị đầu vào	contains the input value
          if(mutiCondition) {
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || 
            (!Utils.isNullOrEmpty(valueFilter) && values?.filter((f: any) => (Utils.isNullOrEmpty(f) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(f) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(f?.toString().toLowerCase()?.trim())))?.length > 0? true: false))
          }
          else 
          return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(valueFilter)));
        case MstFilterIntItem.DoesNotContain://	入力値を含まない không chứa giá trị đầu vào	does not contain the input value
          if(mutiCondition) {
            return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && Utils.isNullOrEmpty(item[columnFilter])) || 
            (!Utils.isNullOrEmpty(valueFilter) && values?.filter((f: any) => (Utils.isNullOrEmpty(f) && Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(f) && item[columnFilter]?.toString().toLowerCase()?.trim().includes(f?.toString().toLowerCase()?.trim())))?.length > 0? false: true))
          } else return dataTable.filter(item => (Utils.isNullOrEmpty(valueFilter) && !Utils.isNullOrEmpty(item[columnFilter])) || (!Utils.isNullOrEmpty(valueFilter) &&  !item[columnFilter]?.toString().toLowerCase()?.trim().includes(valueFilter)));
        case MstFilterIntItem.GreaterOrEqual://	入力値以上 lớn hơn hoặc bằng giá trị đầu vào	greater than or equal to input value
          return dataTable.filter(item => Utils.isNullOrEmpty(valueFilter) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter] != null && Number(item[columnFilter]) >= Number(valueFilter)));
        case MstFilterIntItem.LessOrEqual://	入力値以下 nhỏ hơn hoặc bằng Giá trị đầu vào 	less than or equal to input value
          return dataTable.filter(item => Utils.isNullOrEmpty(valueFilter) || (!Utils.isNullOrEmpty(valueFilter) && item[columnFilter] != null && Number(item[columnFilter]) <= Number(valueFilter)));
      }
      break;
    case MstFilterGroup.DATATIME: // DATETIME
      const currentDate = new Date();
      const currentDay = currentDate.getDate() - currentDate.getDay();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      let sDate = new Date();
      let eDate = new Date();
      const inRange = (item: any) => item[columnFilter] && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') >= moment(sDate).format('YYYY-MM-DD') && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') <= moment(eDate).format('YYYY-MM-DD')
      switch (filterCondition) {
        case MstFilterDataTimeItem.Equal: // 入力値と一致する phù hợp với giá trị đầu vào	matches the input value
          return dataTable.filter(item => (Utils.isNullOrEmpty(filter.filtervalue) && !item[columnFilter]) || (!Utils.isNullOrEmpty(filter.filtervalue) && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') == moment(valueFilter).format('YYYY-MM-DD')));
        case MstFilterDataTimeItem.Before://	入力値より以前 Trước giá trị đầu vào	Before input value
          return dataTable.filter(item => Utils.isNullOrEmpty(filter.filtervalue) || (!Utils.isNullOrEmpty(filter.filtervalue) && item[columnFilter] && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') <= moment(valueFilter).format('YYYY-MM-DD')));
        case MstFilterDataTimeItem.After://	入力値より以後 sau giá trị đầu vào	after the input value
          return dataTable.filter(item => Utils.isNullOrEmpty(filter.filtervalue) || (!Utils.isNullOrEmpty(filter.filtervalue) && item[columnFilter] && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') >= moment(valueFilter).format('YYYY-MM-DD')));
        case MstFilterDataTimeItem.Today://	今日 hôm nay	today
          return dataTable.filter(item => item[columnFilter] && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') == moment(currentDate).format('YYYY-MM-DD'));
        case MstFilterDataTimeItem.Yesterday://	昨日 hôm qua	yesterday
          let yesterday = new Date(currentDate).setDate(currentDate.getDate() - 1)
          return dataTable.filter(item => item[columnFilter] && moment(new Date(getDateStr(item[columnFilter]))).format('YYYY-MM-DD') == moment(yesterday).format('YYYY-MM-DD'));
        case MstFilterDataTimeItem.ThisWeek://	今週 tuần này	this week
          sDate = new Date((new Date()).setDate(currentDay + 1))
          eDate = new Date((new Date()).setDate(currentDay + 7))
          return dataTable.filter(inRange);
        case MstFilterDataTimeItem.LastWeek://	先週 tuần trước last week
          sDate = new Date((new Date()).setDate(currentDay - 6))
          eDate = new Date((new Date()).setDate(currentDay))
          return dataTable.filter(inRange);
        case MstFilterDataTimeItem.ThisMonth://	今月 tháng này	this month
          sDate = new Date(currentYear, currentMonth, 1);
          eDate = new Date(currentYear, currentMonth + 1, 0);
          return dataTable.filter(inRange);
        case MstFilterDataTimeItem.LastMonth://	先月 tháng trước	last month
          if (currentMonth == 1) {
            sDate = new Date(currentYear - 1, 12, 1);
            eDate = new Date(currentYear - 1, 12, 31);
          }
          else {
            let daysInTheMonth = new Date(currentYear, currentMonth, 0).getDate();
            sDate = new Date(currentYear, currentMonth - 1, 1);
            eDate = new Date(currentYear, currentMonth - 1, daysInTheMonth);
          }
          return dataTable.filter(inRange);
        case MstFilterDataTimeItem.ThisYear:// 今年 Năm nay	this year
          return dataTable.filter(item => item[columnFilter] && new Date(getDateStr(item[columnFilter])).getFullYear() == currentDate.getFullYear());
        case MstFilterDataTimeItem.LastYear://	去年 năm ngoái	last year
          return dataTable.filter(item => item[columnFilter] && (currentDate.getFullYear() - new Date(getDateStr(item[columnFilter])).getFullYear() === 1));
        case MstFilterDataTimeItem.FullSchedule:// 全日程 Lịch trình đầy đủ	Full schedule
          return dataTable;
        case MstFilterDataTimeItem.ThisFiscalYear: //本年度 năm nay 
        case MstFilterDataTimeItem.LastFiscalYear: // 前年度 năm trước
        case MstFilterDataTimeItem.TwoFiscalYearAgo: // 前々年度 năm trước nữa
          if(valueArray.length > 1) {
            sDate = new Date(valueArray[0]);
            eDate = new Date(valueArray[1]);
          }
          return dataTable.filter(item => item[columnFilter] && sDate.setHours(0, 0, 0, 0) <= new Date(getDateStr(item[columnFilter])).setHours(0, 0, 0, 0)  &&  new Date(getDateStr(item[columnFilter])).setHours(0, 0, 0, 0) <= eDate.setHours(0, 0, 0, 0));
        case MstFilterDataTimeItem.UntilToday: // 〜今日まで cho đến hôm nay
        case MstFilterDataTimeItem.UntilThisWeek: // 〜今週まで cho đến tuần này      
        case MstFilterDataTimeItem.UntilThisMonth: // 〜今月まで cho đến tháng này
            let range = getDayOfWeekOrMonthCurrent(filterCondition);
            if(filter.rangeDate) {
              return dataTable.filter(item => item[columnFilter] && new Date(filter.rangeDate?.startdate).setHours(0, 0, 0, 0) <= new Date(getDateStr(item[columnFilter])).setHours(0, 0, 0, 0) && new Date(getDateStr(item[columnFilter])).setHours(0, 0, 0, 0) <= range.end.setHours(0, 0, 0, 0));
            }
            return dataTable.filter(item => item[columnFilter] && new Date(getDateStr(item[columnFilter])).setHours(0, 0, 0, 0) <= range.end.setHours(0, 0, 0, 0));
      }
      break;
    case "totaldays": {
      let columns = valueFilter.split('-');
      if(columns.length > 0) {
        dataTable.map(item => {
          Object.keys(item).map(key => {
            if(!columns.includes(key) && key.includes("TTD_")){
              item[key] = 0;
            }
            return item;
          })
        }); 
        return dataTable;
      }
      return dataTable;

    }
  }
  return [];

}

export const runfilteringhelper = (usingWidgetDetails: WidgetDetail[],  sourceTable: any[],detailsBag:any):any => {
  let filtering = runfiltering(usingWidgetDetails,sourceTable);
  let formatedTable = formating(filtering.filteredTable, detailsBag);
  let resData = {
    filterParams: filtering.filterParams,
    filteredTable: filtering.filteredTable,
    formatedTable: formatedTable,
  }
  return resData;
}

export const runfiltering = (usingWidgetDetails: WidgetDetail[], sourceTable: any[]):any => {
  let filterParams = usingWidgetDetails.filter(x => x.filtertype)
  let filteredTable = filtering(sourceTable, filterParams);
  return {filterParams,filteredTable}
}

export const filtering = (table: any[], filters: WidgetDetail[], rangDate: any = null, periods: any[]= []): any[] => {
  let dataTable =  cloneDeep(table);
  filters.forEach((filter:any) => {
    if(FilterValueOption.map(s => s.value).includes(filter.filtertype || '')) {
      let groupFilters = filter.filtertype?.split('-') || [];
      filter.filtervalue = filterByFinancialYearPeriod(groupFilters[1], periods);
    }
    const condition = { filtertype: filter.filtertype, filtervalue: filter.filtervalue , rangeDate: rangDate}
    let filterArray = filter.filtertype.split('-');
    let filterCondition = filterArray[1];
    if (filterCondition !== MstFilterIntItem.Max && filterCondition !== MstFilterIntItem.Min) {
      dataTable = filterDataTableWithCondition(dataTable, filter.columnname, condition) as any[];
    }
  })
  return dataTable
}

export const filteringMaxMin = (table: any[], filters: WidgetDetail[]): any[] => {
  let dataTable = cloneDeep(table);
  filters.forEach((filter: any) => {
    let filterArray = filter.filtertype.split('-');
    let filterType = filterArray[0]; // 001=>VARCHAR 002=>INT 003=>DATATIME
    let filterCondition = filterArray[1];
    if (filterType === MstFilterGroup.INT && (filterCondition === MstFilterIntItem.Max || filterCondition === MstFilterIntItem.Min)) {
      dataTable = filterTableMaxMinWithCondition(dataTable, filter.columnname, filterCondition) as any[];
    }
  })
  return dataTable;
}

export function filterTableMaxMinWithCondition(dataTable: any[], columnFilter: string, filterCondition : any) {
  const arrValue = dataTable.filter(item => !Utils.isNullOrEmpty(item[columnFilter]));
  switch (filterCondition) {
    case MstFilterIntItem.Max:// 最大値 giá trị lớn nhất Max value
      const maxObj = maxBy(arrValue, item => Number(item[columnFilter]));
      const maxValue = Number(maxObj[columnFilter]);
      const returnMax = arrValue.filter(x => Number(x[columnFilter]) === maxValue);
      return returnMax;
    case MstFilterIntItem.Min:// 最小値 giá trị nhỏ nhất Min value
      const minObj = minBy(arrValue, item => Number(item[columnFilter]));
      const minValue = Number(minObj[columnFilter]);
      const returnMin = arrValue.filter(x => Number(x[columnFilter]) === minValue);
      return returnMin;
  }
  return [];
}

export const format = (value: string, formattype: string, item?:any) : string => {
  if (!value) return ''

  const type = selectType(formattype, 'A')
  switch (type) {
    case FormatType.Date:
      return moment(value).format(DateFormat.FULL_SHORT_DATE)

    case FormatType.Month:
      return moment(value).format(DateFormat.SHORT_DATE)

    case FormatType.Year:
      return moment(value).format(DateFormat.YEAR)
    default:
      return value
  }
}

export const getRangeValueTypeDate = (option: any, minVal: any, maxVal: any, unit: any) => {
  let min: any = null;
  let max: any = null;
  switch(option) {
    // get list range format is year_month
    case  FilterGroup.YEAR_MONTH: 
        return getYearMonthGroups(new Date(minVal),new Date(maxVal), unit) || [];  
    // get list range format is MONTH
    case  FilterGroup.MONTH:
        return getMonthGroups(new Date(minVal),new Date(maxVal), unit) || [];
    // get list range format is MONTH_DAY
    case  FilterGroup.MONTH_DAY:
     min = new Date(new Date().getFullYear(), new Date(minVal).getMonth(), new Date(minVal).getDate());
     max = new Date(new Date().getFullYear(), new Date(maxVal).getMonth(), new Date(maxVal).getDate());
     return getMonthDayGroups(min, max, unit) || [];
    // get list range format is DAY
    case  FilterGroup.DAY:
      min = Number(minVal);
      max = Number(maxVal);
      return getDayGroups(min, max, unit) || [];
    // get list range format is HOUR_MINUTE
    case  FilterGroup.HOUR_MINUTE:
      min = new Date(minVal).getHours() + ':' + new Date(minVal).getMinutes();
      max = new Date(maxVal).getHours() + ':' + new Date(maxVal).getMinutes(); 
      return getGroupFilterHourAndMinutes(min, max, unit);
    // get list range format is HOUR
    case  FilterGroup.HOUR:
      return getHourGroups(new Date(minVal)?.getHours(), new Date(maxVal)?.getHours(), unit) || [];
    default:
      return null;
  }
}

export const formating = (table: any[], bag: any, columns: any[] = []) : any[] => {
  let filterGrp: any = {};
  let rangeFilter: any = '';
  // get list columns filter group
  let columnFilterGrps = columns.filter(x =>x?.formattype?.toString()?.includes(FormatType.Group) && x?.columnname?.includes(ColFilterGroup)) || [];
  if(columnFilterGrps.length > 0) {
    columnFilterGrps = columnFilterGrps.map(col => {
      if(col?.groupfilterval && col?.formattype?.toString()?.includes(FormatType.Group)) {
        let groupFilterData =  JSON.parse(col?.groupfilterval); 
        const unit = parseFloat(groupFilterData?.groupFilter?.unitValue);
        let minVal: any = null;
        let maxVal: any = null;
        const option = groupFilterData?.groupFilter?.option;
        if(col.datatype == DATATYPE.Date || option) {
          minVal = groupFilterData?.groupFilter?.minValue;
          maxVal = groupFilterData?.groupFilter?.maxValue;
          let rangeDate = getRangeValueTypeDate(option?.value, minVal, maxVal, unit);
          col.range = rangeDate? JSON.stringify(getRangeValueTypeDate(option?.value, minVal, maxVal, unit)) : null;
        }
        else {
          const minVal = parseFloat(groupFilterData?.groupFilter?.minValue);
          const maxVal = parseFloat(groupFilterData?.groupFilter?.maxValue);
          let numberRanges = getRangesValueNumber(minVal, maxVal, unit);
          col.range = numberRanges? JSON.stringify(numberRanges) : null;
        }
      }
      return col;
    })
  }
  return table.map(x => {
    const y = { ...x }
    Object.keys(y).map((key: any) => {
      if(columns.length > 0) {
        let listColumnsF = columnFilterGrps.filter(x => x.columnname == bag[key]?.columnname) || []; 
        if(listColumnsF.length > 0) {
          let column: any = listColumnsF.pop() as WidgetDetail; 
          if(column?.groupfilterval && column?.formattype?.toString()?.includes(FormatType.Group))
            filterGrp = JSON.parse(column?.groupfilterval); 
            rangeFilter = column.range;
        }
      }
      let columnName = filterGrp?.columnname?.includes(ColFilterGroup) ? filterGrp?.columnname : ColFilterGroup + '_' + filterGrp?.columnname;
      if(filterGrp && filterGrp?.groupFilter && bag[key]?.columnname == columnName && bag[key]?.formattype?.toString()?.includes(FormatType.Group)) {
        if (bag[key]?.datatype === DATATYPE.Date || filterGrp.groupFilter?.option ) {
          // get date format for value type date
          y[key] = getDateFormatValue(y[key], filterGrp, rangeFilter)
        }
        else {
          // set format group for value type number
          const currentVal = parseFloat(y[key]);
          const minVal = parseFloat(filterGrp?.groupFilter?.minValue) || 0;
          const maxVal = parseFloat(filterGrp?.groupFilter?.maxValue) || 0;
          const columnType = Number(filterGrp?.columntype || 0);
          let isColumn = columnType == 7 || columnType == 3 ? true : false;
          let maxValStr = isColumn? FilterGroupDefaultValue.MAXTEXT + maxVal.toString() : getValueAfterFilterGroup(minVal, maxVal, true, false, isColumn);
          if( Utils.isNullOrEmpty(currentVal) ||  currentVal < minVal) {
            y[key] = getValueAfterFilterGroup(minVal, maxVal, false, false, false, isColumn);
          }
          else if(currentVal > maxVal) {
            y[key] = maxValStr;
          }
          else {       
            let rangesNumbers = JSON.parse(rangeFilter) || [];
            let findValue = rangesNumbers?.find( (range: any) => range.start <= currentVal && range.end >= currentVal);
            if(findValue) {
              if(findValue.end > maxVal) y[key] = maxValStr;
              else {     
                if(isColumn) {
                   let numberMaxStr = FilterGroupDefaultValue.MINTEXT + maxVal.toString();
                   let numberMINStr = FilterGroupDefaultValue.MINTEXT + findValue.start.toString();
                   y[key] = findValue.end > maxVal? numberMINStr + '~' + numberMaxStr : getNumberAfterFormat(findValue.start) + '~' + getNumberAfterFormat(findValue.end);
                }
                else y[key] = getNumberAfterFormat(findValue.start) + '~' + getNumberAfterFormat(findValue.end);
              }
            } 
            else {
              if(!y[key]) y[key] = getValueAfterFilterGroup(minVal, maxVal, false, false, false, isColumn);
            }
          }
        }
      } else if (bag[key]?.datatype === DATATYPE.Date) {
        y[key] = format(y[key], bag[key].formattype, bag[key])
      }
    });

    return y;
  });
}

export const getNumberAfterFormat = (value: any) => {
  if(!value) return value;
  let stringNumber: string = value?.toString();
  let containDot = stringNumber.includes(".");
  if(containDot == true) {
    let numberArray = stringNumber.split(".");
    if( numberArray?.length == 2 && (!Number.isNaN(Number(numberArray[0].replace(",", "")))) ||  !Number.isNaN(Number(numberArray[1]))) {
      let containComma = numberArray[0].includes(",");
      return containComma ? value : Number(stringNumber).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
    return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/* 
get list ranges value data is number
*/
export const getRangesValueNumber = (minVal: number, maxVal: number, unit: number) => {
  const groups = [];
  let min: number = minVal;
  const decimalPlaces = unit?.toString()?.split('.')[1]?.length || 0;
  let coefficient: number = Math.pow(10, -decimalPlaces);
  while (min <= maxVal) {
    const endVal = min + unit - coefficient;
    groups.push({start: min, end: endVal > maxVal? maxVal : endVal});
    min = endVal + coefficient;
  }
  return groups;
}

/*
get list ranges value is format Year_Month
*/
export const getYearMonthGroups = (mindate: Date, maxdate: Date, unit: number) => {
  const results: any[] = [];
  let startDate = new Date(mindate.getFullYear(), mindate.getMonth(), 1);
  unit = unit - 1 < 0 ? unit : unit -1;
  while (startDate <= maxdate) {
      let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + unit, 1); 
      results.push({ start: moment(startDate).format('YYYY-MM-DD HH:mm').toString(), end: moment(endDate).format('YYYY-MM-DD HH:mm').toString()});
      startDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1); 
  }
  return results;
}

/*
get formatting based on option
*/
export const getFormatDate = (option: any , isCalendar: boolean = false) => {
  switch(option) { 
    case  FilterGroup.YEAR_MONTH:
      return isCalendar? PrimengDateFormat.JP_LONG_DATE : DateFormat.JP_LONG_DATE;
    case  FilterGroup.MONTH:
      return isCalendar? PrimengDateFormat.JP_MOTH : DateFormat.JP_MOTH;
    case  FilterGroup.MONTH_DAY:
      return isCalendar? PrimengDateFormat.JP_MONTH_DAY : DateFormat.JP_MONTH_DAY;
    case  FilterGroup.DAY:
      return isCalendar? PrimengDateFormat.JP_DAY: DateFormat.JP_DAY;
    case  FilterGroup.HOUR_MINUTE:
      return isCalendar? PrimengDateFormat.JP_HOUR_MINUTE: DateFormat.JP_HOUR_MINUTE;
    case  FilterGroup.HOUR:
      return isCalendar? PrimengDateFormat.JP_HOUR: DateFormat.JP_HOUR;
    default: 
      return isCalendar? PrimengDateFormat.JP_LONG_DATE : DateFormat.JP_LONG_DATE;
  }
}

/*
get list ranges value is format Month
*/
export const getMonthGroups = (minDate: Date, maxDate: Date, unit: number) => {
  const groups = [];
  let minMonth = minDate.getMonth() + 1;
  let maxMonth = maxDate.getMonth() + 1;
  
  while (minMonth <= maxMonth) {
    const endMonth = minMonth + unit - 1;
    groups.push({start: minMonth, end: endMonth > maxMonth? maxMonth : endMonth});
    minMonth += unit;
  }
  return groups;
}

/*
get value format date
*/
export const getDateFormatValue = (value: any, filterParams: any, rangeFilter: any) => {
  const option = filterParams.groupFilter?.option?.value;
  const currentDate = new Date(value);
  let currentMonth = currentDate.getMonth() + 1;
  let min: any = null;
  let max: any = null;
  const minDate = new Date(filterParams.groupFilter?.minValue);
  let maxDateVal = new Date(filterParams?.groupFilter?.maxValue);
  const unit = Number(filterParams?.groupFilter?.unitValue);
  const columnType = Number(filterParams?.columntype || 0);
  let isColumn = columnType == 7 || columnType == 3 ? true : false;
  let rangeFilters = JSON.parse(rangeFilter) || [];
  switch(option) {
    // format value is YEAR_MONTH
    case  FilterGroup.YEAR_MONTH: 
      const dateCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth());
      min = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
      max = new Date(maxDateVal.getFullYear(), maxDateVal.getMonth(), new Date(maxDateVal.getFullYear(), maxDateVal.getMonth() + 1, 0).getDate());
      if (dateCurrent < min) {
        return  getValueAfterFilterGroup(minDate, maxDateVal, false, option, false, isColumn);
      } else if (dateCurrent > max) {
        return getValueAfterFilterGroup(minDate, maxDateVal, true, option, false, isColumn);
      } else {
        const findGroup = rangeFilters?.find((s:any) => isDateInRange(new Date(s.start), new Date(s.end), currentDate));
        if(findGroup) { 
          if(new Date(findGroup.end) >= max) return moment(new Date(findGroup.start)).format(DateFormat.JP_LONG_DATE) + '~' + moment(new Date(max)).format(DateFormat.JP_LONG_DATE);
          return moment(new Date(findGroup.start)).format(DateFormat.JP_LONG_DATE) + '~' + moment(new Date(findGroup.end)).format(DateFormat.JP_LONG_DATE);
        }
        else return getValueAfterFilterGroup(minDate, maxDateVal, false, option, false, isColumn);
      }
    // format value is MONTH
    case  FilterGroup.MONTH:
      if(currentDate.getMonth() < minDate.getMonth()) {
        return getValueAfterFilterGroup(minDate, maxDateVal, false, option, false, isColumn);
      } else if (currentDate.getMonth() > maxDateVal.getMonth()) {
        return getValueAfterFilterGroup(minDate, maxDateVal, true, option, false, isColumn);
      } else {
        let findMonth = rangeFilters?.find((s: any) => s.start <= currentMonth && currentMonth <= s.end);
        if(findMonth) return getStringDayOrHour(findMonth.start) + '月 ~ ' + getStringDayOrHour(findMonth.end) + '月';
        else  return getValueAfterFilterGroup(minDate, maxDateVal, true, option, false, isColumn);
      }
    // format value is MONTH_DAY
    case  FilterGroup.MONTH_DAY:
      const currentVal = new Date(new Date().getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0);
      min = new Date(new Date().getFullYear(), minDate.getMonth(), minDate.getDate());
      max = new Date(new Date().getFullYear(), maxDateVal.getMonth(), maxDateVal.getDate());
      if (currentVal < min) {
        return getValueAfterFilterGroup(minDate, maxDateVal, false, option, false, isColumn);
      } else if (currentVal > max) {
        return getValueAfterFilterGroup(minDate, maxDateVal, true, option, false, isColumn);
      } else {
        const findGroup = rangeFilters?.find((s:any) => new Date(s.start) <= currentVal && currentVal <= new Date(s.end));
        if(findGroup) {
          if(new Date(findGroup.end) >= max) return findGroup.startLabel + '~' + formatDateIsMonthDay(max);
          else return findGroup.startLabel + '~' + findGroup.endLabel;
        }
        return getValueAfterFilterGroup(minDate, maxDateVal, false, option, false, isColumn);
      }
    // format value is DAY
    case  FilterGroup.DAY:
      min = Number(filterParams.groupFilter?.minValue);
      max = Number(filterParams.groupFilter?.maxValue);
      let minStr = min.toString() + COMMON_TEXT.DAY;
      let maxStr = max.toString() + COMMON_TEXT.DAY;
      if(currentDate.getDate() < min) {
        return isColumn? FilterGroupDefaultValue.MIN + minStr : minStr + COMMON_TEXT.BELOW;
      } else if (currentDate.getDate() > max) {
        return isColumn? FilterGroupDefaultValue.MAX + maxStr : maxStr + COMMON_TEXT.UPPER;
      } else {
        let findDay = rangeFilters?.find((s: any) => s.start <= currentDate.getDate() && currentDate.getDate() <= s.end);
        if(findDay) {
          if(findDay.end >= max) return getStringDayOrHour(findDay.start) +  COMMON_TEXT.DAY + ' ~ ' + getStringDayOrHour(max) + COMMON_TEXT.DAY;
          else return getStringDayOrHour(findDay.start) + COMMON_TEXT.DAY + ' ~ ' + getStringDayOrHour(findDay.end) + COMMON_TEXT.DAY;
        }
        else return  isColumn? FilterGroupDefaultValue.MIN + minStr : minStr + COMMON_TEXT.BELOW;
      }
    // format value is HOUR_MINUTE
    case  FilterGroup.HOUR_MINUTE:
      min = minDate.getHours() + ':' + minDate.getMinutes();
      max = maxDateVal.getHours() + ':' + maxDateVal.getMinutes(); 
      let dateValue = new Date(value);
      const minTime = parseTime(min);
      const maxTime = parseTime(max);
      let currentHour = parseTime(dateValue.getHours() + ':' + dateValue.getMinutes());
      if(currentHour < minTime)  return  isColumn? FilterGroupDefaultValue.MIN + formatTime(minTime).toString() : formatTime(minTime) + COMMON_TEXT.BELOW ;
      else if(currentHour > maxTime) return isColumn? FilterGroupDefaultValue.MAX + formatTime(maxTime).toString():  formatTime(maxTime) + COMMON_TEXT.UPPER;
      else {
        let findTime = rangeFilters?.find((t: any) => new Date(t.start) <= currentHour &&  currentHour <= new Date(t.end));
        if(findTime) {
          if(new Date(findTime.end) >  maxTime) return `${formatTime(new Date(findTime.start))}~${formatTime(maxTime)}`; 
          return `${formatTime(new Date(findTime.start))}~${formatTime(new Date(findTime.end))}`;
        } 
        else return  isColumn? FilterGroupDefaultValue.MIN + formatTime(minTime).toString() : formatTime(minTime) + COMMON_TEXT.BELOW ;
      }
    // format value is HOUR
    case  FilterGroup.HOUR:
      let maxDate: Date = new Date(maxDateVal.getFullYear(), maxDateVal.getMonth(), maxDateVal.getDate(), maxDateVal.getHours());
      if(currentDate.getHours() < minDate.getHours()) {     
        return getValueAfterFilterGroup(minDate, maxDateVal, false, option,true, isColumn);
      } else if (currentDate.getHours() > maxDateVal.getHours()) {
        return getValueAfterFilterGroup(minDate, maxDate, true, option,true, isColumn);
      } else {
        let hours = rangeFilters || [];
        let hour = hours?.find((s: any) => s.start <= currentDate.getHours() && currentDate.getHours() <= s.end);
        if(hour) {
          if(hour.start >= maxDate.getHours()) 
            return getValueAfterFilterGroup(minDate, maxDate, true, option,true);
          else if(hour.end > maxDate.getHours())  return getStringDayOrHour(hour.start) + '時 ~ ' + getStringDayOrHour(maxDate.getHours()) + COMMON_TEXT.HOUR;
          return getStringDayOrHour(hour.start) + '時 ~ ' + getStringDayOrHour(hour.end) + COMMON_TEXT.HOUR;
        }
        else  return getValueAfterFilterGroup(minDate, maxDateVal, false, option,true, isColumn);
      }
    default:
      return moment(value).format(DateFormat.JP_FULL_LONG_DATE);
  }
}

const getGroupFilterHourAndMinutes = (min: any, max: any, unit: any) => {
  const minTime = parseTime(min);
  const maxTime = parseTime(max);
  let groupTimes: any[] = [];
  let startTime = minTime;
  while (startTime <= maxTime) { 
    if(startTime >= maxTime) {
      groupTimes.push({ start: startTime, end: maxTime })
      break;
    }
    else {
      const endTime = addMinutes(startTime, unit - 1 < 0? 1 : unit - 1);
      groupTimes.push({ start: startTime, end: endTime > maxTime ? maxTime : endTime })
      startTime = addMinutes(endTime, 1);
    }
  }
  return groupTimes;
}

/*
convert time to date
*/
const parseTime = (timeStr: string): Date => {
  const [hour, minute] = timeStr.split(':').map(Number);
  return new Date(new Date().getFullYear(), 0, 1, hour, minute);
}

/* 
   add minute for date value
*/
const addMinutes = (time: Date, minute: number): Date => { 
  return new Date(time.getTime() + minute *60000);
}

/* 
   get value out of limit min max
*/
export const getValueAfterFilterGroup = (minVal: any, maxVal: any, isMax: boolean, option: any = null, isTime: boolean = false, isColumn: boolean = false) => {
  // option != null value is Date option == null value is number
  let minValStr = option? moment(new Date(minVal)).format(getFormatDate(option)): getNumberAfterFormat(minVal);
  let maxValStr = option? moment(new Date(maxVal)).format(getFormatDate(option)): getNumberAfterFormat(maxVal);
  // isTime = true value format hour: minutes isTime = false value format != hour and minute
  minValStr = isTime ? minValStr + COMMON_TEXT.HOUR : minValStr;
  maxValStr = isTime? maxValStr + COMMON_TEXT.HOUR : maxValStr;
  if(isColumn) return isMax? FilterGroupDefaultValue.MAX + maxValStr.toString() : FilterGroupDefaultValue.MIN + minValStr.toString();
  return isMax? maxValStr + COMMON_TEXT.UPPER: minValStr + COMMON_TEXT.BELOW;
}

/* 
   format time value
*/
export const formatTime = (time: Date): string =>{
  const hour = ('0' + time.getHours()).slice(-2);
  const minute = ('0' + time.getMinutes()).slice(-2);
  return hour + COMMON_TEXT.HOUR + minute + COMMON_TEXT.TIME;
}

export const minAndMaxArray = (data: any[]): { min: number, max: number } => {
  if (!data || data.length === 0) {
    throw new Error("Array is empty or null");
  }

  let min = data[0];
  let max = data[0];

  for (let i = 0; i < data.length; i++) {
    let value = Number(data[i]) || 0;
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  }

  return { min: min, max: max };
}

export const formatingDateTimeData = (table: any[], bag: any) : any[] => {
  return table.map(x => {
    x = format(x, bag.formattype, bag)
    return x;
  })
}

/* 
   get list month_day ranges 
*/
const getMonthDayGroups = (mindate: Date, maxdate: Date, unit: number) => {
  const groups = [];
  let startDate = new Date(mindate);
  const endDate = new Date(maxdate);
  let i = 0;
  while (startDate <= endDate) {
    if(i == 0) unit = unit - 1 < 0 ? 1 : unit - 1;
    const endDateOfMonth = new Date(startDate.getTime() + unit * 24 * 60 * 60 * 1000);
    let endVal = endDateOfMonth >= endDate? endDate : endDateOfMonth;
    groups.push({ startLabel: formatDateIsMonthDay(startDate), endLabel: formatDateIsMonthDay(endVal), start: moment(startDate).format('YYYY-MM-DD HH:mm').toString(), end: moment(endVal).format('YYYY-MM-DD HH:mm').toString()});
    startDate = new Date(endDateOfMonth.getTime() + 24 * 60 * 60 * 1000); 
    i++;
  }
  return groups;
}

/* 
   get list day ranges
*/
export const getDayGroups = (minday: number, maxday: number, unit: number) => {
  const groups: any[] = [];
  let startDay = minday
  const endDay = maxday;
  
  while (startDay <= endDay) {
    const endDateOfMonth = startDay + unit - 1;
    groups.push({ start: startDay, end: endDateOfMonth >= endDay ? endDay : endDateOfMonth });
    startDay = endDateOfMonth + 1;
  }
  return groups;
}

/* 
   get list hour ranges
*/
export const getHourGroups = (min: number, max: number, unit: number) => {
  const groups: any[] = [];
  let start: number = min
  const end: number = max;
  while (start <= end) {
    const endHour = start + unit - 1;
    groups.push({ start: start, end: endHour >= end? end : endHour })
    start = endHour + 1;
  }
  return groups;
}

/* 
   get string format day or hour 
*/
export const getStringDayOrHour = (value: number) => value < 10 ? '0' + value : '' + value;

/* 
   get string format month or day 
*/
export const formatDateIsMonthDay = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthStr = month < 10 ? '0' + month : '' + month;
  const dayStr = day < 10 ? '0' + day : '' + day;
  return monthStr + '月' +  dayStr + COMMON_TEXT.DAY;
}

export const convertFilterValueToArray = (value: string): any[] => {
  const filValue: any[] = JSON.parse(value);
  return filValue;
}

/* 
   check date in ranges
*/
function isDateInRange(startDate: Date, endDate: Date, checkDate: Date): boolean {
  const startDateObj = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  const endDateObj = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
  const checkDateObj = new Date(checkDate.getFullYear(), checkDate.getMonth(), 1);

  return checkDateObj >= startDateObj && checkDateObj <= endDateObj;
}

export function reFormatFilterValueForTotaldays(arr: any){
  const formatArray = arr.map((value: any) => ({
    name: createNameOfColsTotalDays(value),
    code: value,
    checked: false,
  }));
  return formatArray;
}

export const createNameOfColsTotalDays = (name: string) => {
  let columnDisplayNameFormat: string = "{0}年{1}月";
  const MonthAndYear: string[] = name.split('_');
  const Year: string = MonthAndYear[1].substring(0, 4);
  const Month: string = MonthAndYear[1].substring(4);
  const columnDisplayName: string = columnDisplayNameFormat.replace("{0}", Year).replace("{1}", Month);
  return columnDisplayName;
}

export const beautify = (value: string | number, formattype?: string) => {
  if (!formattype) return value

  // string is not empty and not just whitespace
  if (!/\S/.test(String(value))) return value
  
  const type = selectType(formattype, 'A')
  if (!JapanDateFormat[type!]) return value
  
  return moment(value,'YYYY-MM-DD').format(JapanDateFormat[type] as string)
}

export const selectType = (formattype: string|undefined, firstChar: string) => formattype?.match(/.{2}/g)?.filter(x => x[0] === firstChar).pop() || ''

function getDataType(im: number, wtype: number) {
  if (wtype == 4) return 'DATETIME';
  else if (wtype == 8) return "INT";
  else if (im == 2) return 'INT';
  else return 'VARCHAR';
}
export function getDataTypeNumber(datattype: string) {
 switch (datattype){
  case "INT": return DataType.NUMBER;
  case "FLOAT": return DataType.NUMBER;
  case "DATETIME": return DataType.DATETIME;
  default: return DataType.TEXT;
 }
}

export const pushColumnToTable = (sourceTable: any[], column: WidgetDetail) => {
  return sourceTable.map(x => {
    let value = column.operator! as any

    for (const key in x) {
      let y = x[key]
      if (typeof x[key] === 'string' && !isDecimal(x[key]))   y = `\`${x[key]}\``
      if (y === null)                   y = "''"
      
      value = value.replaceAll(`{${key}}`, y)
    }

    let valueBefore =  calculateExpression( value , 0 );
    if(typeof valueBefore === 'number' && isNaN(valueBefore)) {
      x[column.columnname] = "";
    }
    else {
      if(!Utils.isNullOrEmpty(column.rounding)) {
        let rounding = column.rounding?.split(',') || [];
        let option =  parseInt(rounding[1]);
        if(option != 1) {
          let op = option > 1 ? option -1 : option;
          x[column.columnname] = getRoundNum(valueBefore, parseInt(rounding[0]) || 0, op || 0);
        }
        else {
          x[column.columnname] = roundNumDecimal(valueBefore, parseInt(rounding[0]));
        }
      }
      else {
        x[column.columnname] = valueBefore;
      }
    }
  })
}


export function calculateExpression(inputExpression: any, level: number): any{
  if (!inputExpression) return "";
  if(!checkStringContainsFunction(inputExpression)) return calculateExpressionMath(inputExpression)
  let depthFunction = getDepthFunction(inputExpression, 0);
  if (level > 100) return ""
  if (depthFunction.length == 0)
  {
    if(!checkContainsString(inputExpression)) return calculateExpressionMath(inputExpression);
    else if (!inputExpression.includes("(") || !inputExpression.includes(")"))   return "";// check PIVOT_ROUNDING(0/0,1,1
  }
  for (var i = 0 ; i < depthFunction.length ; i ++ ){
    var listo = OPERATOR_CUSTOM_NAME.join("|");
    const strRe = `(${listo})\\(([^()]+(?:\\((?:[^()]+|\\((?:[^()]+|\\([^()]*\\))*\\))*\\))*)\\)`;
    let params = depthFunction[i].match(strRe);
    if(params && params?.length>=3) {
      let op = params[1];
      let par = params[2];
      let opFun =  OPERATOR_CUSTOM.find((x:any)=> x.name == op);
      if(opFun) {
        let value =  opFun.function(par);
        inputExpression = inputExpression.replace(`${depthFunction[i]}`, value?.toString());
      }else{
        let value = calculateExpressionMath(par);
        inputExpression = inputExpression.replace(`${depthFunction[i]}`, value?.toString());
      }
    }else{
      let value = calculateExpressionMath(depthFunction[i]);
      inputExpression = inputExpression.replace(`${depthFunction[i]}`, value?.toString());

    }
  }
  return calculateExpression(inputExpression, level + 1);
}

function calculateExpressionMath(inputExpression: any): any{

  if(inputExpression.includes(':')) {
    return inputExpression;
  } else if(inputExpression.includes('+')||inputExpression.includes('-')
  ||inputExpression.includes('*')||inputExpression.includes('/')) {
    return evaluateFormula(inputExpression);

  }
  return inputExpression;
}
/// Tìm Operator ko chưa OperatorCustom con bên trong
export function getDepthFunction(inputExpression: string, level: number ):string []{
  if(level >100) return [inputExpression];
  if (!inputExpression) return [];
  var listo = OPERATOR_CUSTOM_NAME.join("|");
  const str = `(?:${listo}|)?\\(([^()]+(?:\\((?:[^()]+|\\((?:[^()]+|\\([^()]*\\))*\\))*\\))*)\\)`;
  const regex = new RegExp(str, "g");
  var matches = inputExpression.match(regex);
  if (!matches || (matches?.length) == 0) return [inputExpression];
  let result:any[] = [];
  for(var i = 0;i < matches.length ; i ++){
    const strRe = `(${listo})\\(([^()]+(?:\\((?:[^()]+|\\((?:[^()]+|\\([^()]*\\))*\\))*\\))*)\\)`;
     let params = matches[i].match(strRe);
     if(params && params?.length > 2){
      if(!checkStringContainsFunction(params[2])){
        result.push(matches[i]);
      }else{
        result =[ ...result, ...getDepthFunction(params[2],level+1)]
      }
      
     }else {
      result.push(matches[i]);
     }
  }
  return result
}
function checkStringContainsFunction(funntionstring:string):boolean{
  let isContains = false;
  OPERATOR_CUSTOM_NAME.map((x:string)=>{
    if(funntionstring.includes(x)){
      isContains = true;
    }
  })
  return isContains;
}
export const pushColumnTargetToTable = (sourceTable: any[], setting: WidgetSettingRecord) => {
  return sourceTable.map((x: any) => {
      let value = setting.targetTable.filter((target: any) => target.value == x[setting.row.code]).map((item: any) => item.targetValue).pop();
      x[setting.targetColumn.columnname] = value;
  })
}

//小数点第二を
export function roundNumDecimal(value: number, option: number) {
  switch (option)
  {
    //切捨て
    case 0: 
      let decimalPart = value.toString().split('.')[1];
        if (decimalPart) {
          return Number(`${parseInt(value.toString())}.${decimalPart.substring(0,1)}`);
        } else {
          return value;
    }
    //切り上げ
    case 1: 
      if(value > 0) {
        let valueStr = value.toString(); 
        let arrNum = valueStr?.split('.');
        if(arrNum?.length > 1) {
          let unitStr = arrNum[1];
          if(unitStr.length >= 2) {
            let decimal1 = unitStr.substring(0,1) || '0';
            let decimal2 = unitStr.substring(1,2) || '0';
            if(parseInt(decimal2) > 0) {
              if((parseInt(decimal1) + 1) > 9) return parseFloat((parseInt(valueStr) + 1).toString());
              else
                return parseFloat(parseInt(valueStr).toString() + '.' + (parseInt(decimal1) + 1) );
            }
            else return parseFloat(parseInt(valueStr).toString() + '.' + parseInt(decimal1)); 
          }
        }
        else {
          return value;
        }
      }
      return value;
    //四捨五入
    case 2: 
      let multiplier = Math.pow(10, 1); 
      return Math.round(value * multiplier) / multiplier; 
  }
  return value;
}
export const isDecimal = (str: string) => /^[-+]?[0-9]*\.?[0-9]+$/.test(str);

export function roundDown(roundValue: number, digits: number = 0)
	{
    if (Utils.isNullOrEmpty(roundValue)) {
      return 0;
    }
    let resVal = roundValue;
    try
    {
      if (roundValue > 0)
      {
        let shift = Math.pow(10, parseFloat(digits.toString())) || 0;
        let resDubVal = Math.floor(parseFloat((roundValue / parseFloat(shift.toString())).toString())) * shift;
        resVal = parseInt(resDubVal.toString());
        return resVal;
      }
      else
      {   // マイナスの対応
        let shift = Math.pow(10, parseFloat(digits.toString())) || 0;
        let resDubVal = Math.floor(parseFloat(((-1 * roundValue) / parseFloat(shift.toString())).toString())) * shift;
        resVal = parseInt(resDubVal.toString());
        return (-1 * resVal);
      }
    }
    catch
    {
      return resVal;
    }
	}

  export function roundUp(roundValue: number, digits: number = 0)
  {
    if (Utils.isNullOrEmpty(roundValue)) {
      return 0;
    }
    let resVal = roundValue;
    try
    {
      let shift = Math.pow(10, parseFloat(digits.toString())) || 0;
      let resDubVal = Math.floor(parseFloat(((roundValue / parseFloat(shift.toString())) + 0.9).toString())) * shift;
      resVal = parseFloat(resDubVal.toString());
      return resVal;
    }
    catch
    {
      return resVal;
    }
  }

  export function roundOff(roundValue: number, digits: number)
  {
    if (Utils.isNullOrEmpty(roundValue)) {
      return 0;
    }
    let resVal = roundValue;
    try
    {
      let shift = Math.pow(10, parseFloat(digits.toString()));
      let resDubVal = Math.floor(parseFloat(((roundValue / parseFloat(shift.toString())) + 0.5).toString())) * shift;
      resVal = parseFloat(resDubVal.toString());
      return resVal;
    }
    catch
    {
      return resVal;
    }
  }

export function getRoundNum(value: number, item: number, option: number)
{
  switch (item)	// 端数処理・・・0:切捨て 1:切り上げ 2:四捨五入)
  {
    case 0: return roundDown(value, option);
    case 1: return roundUp(value, option);
    case 2: return roundOff(	value, option);
  }
  return value;
}

export function isMatchesExpression(str: string) {
  return DateTimeExpressions.filter(exp => {
    let date = moment(str, exp, true);
    return date.isValid();
  });
}

export function  isPubishWidget(publicDate: string): boolean {
  if(!publicDate) return false;
  let dateArray = publicDate.split('~');
  const today = new Date();
  const start = new Date(dateArray[0]);
  const end = dateArray[1] != "未定" ? new Date(dateArray[1]) : new Date('9999-12-31');
  return start <= today && today <= end ? true : false;
}
export function replaceNameNashi(name: string){
  if(!name) return COMMON_TEXT.VALUE_EMPTY;
  return !name.includes('COL') ? name : COMMON_TEXT.VALUE_EMPTY
}

const getDateStr = (datetime: string) => datetime?.split(" ")[0] || "";

export const calculateTotal = (array: { [key: string]: any }[], isUpdate: boolean) => {
  if(!isUpdate) {
    for (const obj of array) {
      let total = 0;
      for (const key in obj) {
        if (key.toUpperCase().startsWith("TTD_")) {
          total += obj[key];
        }
      }
      obj['totaldays'] = total;
    }
  }
  else {
    for (const obj of array) {
      let total = 0;
      for (const key in obj) {
        if (key.toUpperCase().startsWith("TTD_")) {
          total += obj[key];
        }
      }
      obj["TOTALDAYS"] = total;
    }
  }
    
  return array;
}
export const convertToDate = (datestring: string): Date => {
  let monthsdate = new Date();
  if (datestring && datestring.includes("/")) {
    let year = parseInt(datestring.split("/")[0]);
    let months = parseInt(datestring.split("/")[1]);
    monthsdate = new Date(year, months - 1, 1);
  }
  return monthsdate;
}

export const calculaTotalByPreviousMonth = (totalMonth: number, startDate: Date, column: string, tableData: any[]) => {
  let tableDataClone = cloneDeep(tableData)
  let dataFilter: any[] = [];
  var dattcountOffset = tableDataClone?.filter((item: any) => (item.countflg === true || item.countflg === undefined || item.countflg === null)
    && convertToDate(item.date).getTime() < startDate.getTime());
  var dattcount = tableDataClone?.filter((item: any) => convertToDate(item.date).getTime() >= startDate.getTime())
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var dataTable = [...dattcountOffset, ...dattcount]
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var itemMonth = dataTable.find(x => x.date === moment(startDate).format(DateFormat.YEAR_MONTH));
  var indexMonth = dataTable.indexOf(itemMonth);
  dataFilter = dataTable.slice((indexMonth - totalMonth + 1), indexMonth + 1);
  if(itemMonth.countflg == false){
    dataFilter = dataTable.slice((indexMonth - totalMonth), indexMonth );
  }
  let sum = Number(dataFilter?.reduce((sum: number, obj) => sum + Number(obj[column]), 0)) || 0;
  return Number(sum?.toFixed(1)) || 0;
}
export const getServiceTypeByPreviousMonth = (totalMonth: number, startDate: Date, column: string, tableData: any[]) => {
  let tableDataClone = cloneDeep(tableData)
  let dataFilter: any[] = [];
  var dattcountOffset = tableDataClone?.filter((item: any) => (item.countflg === true || item.countflg === undefined || item.countflg === null)
    && convertToDate(item.date).getTime() < startDate.getTime());
  var dattcount = tableDataClone?.filter((item: any) => convertToDate(item.date).getTime() >= startDate.getTime())
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var dataTable = [...dattcountOffset, ...dattcount]
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var itemMonth = dataTable.find(x => x.date === moment(startDate).format(DateFormat.YEAR_MONTH));
  var indexMonth = dataTable.indexOf(itemMonth);
  dataFilter = dataTable.slice((indexMonth - totalMonth + 1), indexMonth + 1);
  if(itemMonth.countflg == false){
    dataFilter = dataTable.slice((indexMonth - totalMonth), indexMonth );
  }
  dataFilter = dataFilter.sort((a:any,b:any)=>{
    return( Number(b.serviceType??0) - Number(a.serviceType??0)) ;
  })
  return dataFilter[0];
}

export const formatValueNumber = (value: number, format: string) => {
  const integerPart = Math.floor(value);
  const decimalPart = decimalMod(value, 1);

  let formattedNumber = '';

  if (format.includes('#')) {
    const integerDigits = format.split('.')[0].length || 0;
    formattedNumber += String(integerPart).slice(0, integerDigits);

    if (format.includes('.')) {
      formattedNumber += '.';
    }

    const decimalDigits = format.split('.').length > 1 ? format.split('.')[1]?.length : 0;
    if(decimalPart?.toString()?.split('.')[1]) formattedNumber += decimalPart?.toString()?.split('.')[1].substring(0,decimalDigits);
    else formattedNumber += decimalPart.toString().substring(0,decimalDigits);
  } else {
    formattedNumber = String(integerPart).slice(0, format.length);
  }

  return formattedNumber;
}

const decimalMod = (value: number, divisor: number): number  => {

  //handle floating-point issue
  const scaledValue = Math.round(value * 100000);
  const scaledDivisor = divisor * 100000;
  const scaledResult = scaledValue % scaledDivisor;
  return scaledResult / 100000;
}
export function checkIfUserIsSupporterOrAdmin(currentUser: any): boolean {
  let isAdminOrSupport = false;
  let userPermissionsList = currentUser.pivotpermission ? currentUser.pivotpermission : [];
  let currentUserRoleCd = "";

  if(userPermissionsList.length > 0) currentUserRoleCd = userPermissionsList[0].rolecd ? userPermissionsList[0].rolecd : "";
  if((currentUser.issupport && currentUserRoleCd === "RL00000099") || currentUserRoleCd ===  "RL00000001") {
    isAdminOrSupport = true;
  }
  
  return isAdminOrSupport;
}

export function convertJapaneseNumber(input: any) {
  const fullwidthNumbers = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９', '。', '．'];
  const halfwidthNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '.'];

  for (let i = 0; i < fullwidthNumbers.length; i++) {
    input = input?.replace(new RegExp(fullwidthNumbers[i], 'g'), halfwidthNumbers[i]);
  }
  return input;
}

export function filterByFinancialYearPeriod(type: any, groupedPeriod: any) {
  let sDate = new Date();
  let eDate = new Date();
  let periodFinancialYear ;
  let period
  if(groupedPeriod){
    periodFinancialYear = groupedPeriod.filter((x: any) => x.value == '005')[0];
     switch (type) {
      case "013": //本年度
        if(periodFinancialYear) {
           period =  periodFinancialYear.items.filter((x:any) => x.value == '005-002')[0];
        } 
        break;
      case "014": //前年度
        if(periodFinancialYear) {
           period = periodFinancialYear.items.filter((x:any) => x.value == '005-003')[0];
        }
        break;
      case "015":// 前々年度
        if(periodFinancialYear) {
           period = periodFinancialYear.items.filter((x:any) => x.value == '005-001')[0];
        }
        break;
      default:
        break;
    }
  }
  if(period) {
    sDate = new Date(period.startdate);
    eDate = new Date(period.enddate);
    return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)
  }
  return '';
}


export function getDayOfWeekOrMonthCurrent(type: string) {
  let currentDate = new Date();
  let sDate = new Date();
  let eDate = new Date();
   switch(type) {
      case "017":
        const dayOfWeek = currentDate.getDay();
        const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        sDate = new Date(currentDate.setDate(diff));
        eDate = new Date(currentDate.setDate(sDate.getDate() + 6));
        return { start: sDate, end: eDate };
      case "018":
        sDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        eDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        return { start: sDate, end: eDate };
      case "016":
      default:
        return { start: currentDate, end: currentDate };
  }
}

export const formulaToOperator = (value: any, key: 'operator' | 'targetValue' , source: any[], columnDataSource?: boolean, colUsed?: any[] ): operator[] => {
  //const japaneseCharacterPattern = /[\u3040-\u30FF\u4E00-\u9FFF]/;

  let formula = (value[key] as any)
  formula = ReplaceAllCharactorOperator(formula);
  let regex = /([<>=!;()+\-*\/])/g;
  let operatorSplit = (formula as string)
  .split(regex)
  .filter((str:any) => str !== "")
  .map((str:string) => {
    if(str.includes("{"))
    {
      let pattern = /\{([^}]*)\}/g;
      var columns = str.match(pattern) as string[];
      return columns;
    }
    else
      return str;
  }).flat();

  let result = operatorSplit.map( operator => {
            switch (operator) {
              case '+':
              case '-':
              case '/':
              case '(':
              case ')':
              case '.':
              case '<':
              case '>':
              case '=':
              case '!':
                return { type: OPERATOR_TYPE.OPERATOR, label: operator, value: operator } as operator
              case '*':
                return { type: OPERATOR_TYPE.OPERATOR, label: 'x', value: operator } as operator
              default:
                if(operator.includes('{')){
                  operator = operator.replace('{','').replace('}','');
                  if(columnDataSource){
                    let col = source.filter(y => y.columnId.toUpperCase() === operator.toLocaleUpperCase()).pop()
                    if(!col){
                       col = colUsed?.filter(y => y.columnId.toUpperCase() === operator.toLocaleUpperCase()).pop()
                    }
                    return { type: 'column', label: col?.data.displayname || MESSAGE_TEXT.ERR_REMOVE_COLUMN, value: col } as operator
                  }else{
                    let col = source.filter(y => y.columnname.toUpperCase() === operator.toUpperCase()).pop() as WidgetDetail
                    return { type: 'column', label: col?.displayname || MESSAGE_TEXT.ERR_REMOVE_COLUMN, value: col } as operator
                  }
                }else if (OPERATOR_CUSTOM_NAME.includes(operator)){
                  return { type: OPERATOR_TYPE.OPERATOR_CUSTOM, label: operator, value: operator } as operator
                }else{
                  return { type: OPERATOR_TYPE.OPERATOR, label: decodeCharactorOperators(operator), value: decodeCharactorOperators(operator) } as operator
                }
              }
          });

          return result
}

export const formatExcelFile = (data: any, ws: any) => {
  let border = {style: 'thin', color: { rgb: "969696" }};
  let listOption = PivotFooterOptions.map(s => s.name);
  let headerLevel = data?.headers.length + 1;
   for (let keys in ws) {	
      if (typeof ws[keys] != 'object') continue;
      // get keys index
      const numbers = keys?.match(/\d+/g);
      let indexKey = parseInt(numbers ? numbers.join('') : '0');
      let bgColor = 'FFFFFF';
      let color = '000000';
      let isHeaderCol = false, isFooter: boolean = false;
      // set background header and text color
      data?.headers.forEach((header: any) => {
          if(indexKey < headerLevel && header?.find((s:any) => s?.value == ws[keys].v || s ==  ws[keys].v)) {
              bgColor = '737F8D';
              color = 'FFFFFF';
              isHeaderCol = true;
              return;
          }
      });
      let cellValue = ws[keys]?.v || '';
      if(listOption.includes(cellValue)) {
          isFooter = true;
      }
      
      if(Utils.isNullOrEmpty(ws[keys].v) && !isHeaderCol) ws[keys].v = '';
      let isNumberVal =  ws[keys]?.t == 'n' || isFooter || cellValue.includes('%') || cellValue.includes('.00') ? true: false;
      ws[keys].s =
      {
          // set color background
          fill: { fgColor: { rgb: bgColor } },
          font: {
              name: 'Roboto", Arial, sans-serif',
              italic: false,
              bold: isHeaderCol? true : false,
              color: { rgb: color }
          },
          alignment: {
              vertical: 'center',
              horizontal: isHeaderCol ? 'center' : isNumberVal ? 'right' : 'left',
          },
          border: {
              right: border,
              left: border, 
              top : border,
              bottom: border,
          },
          margin: { left: 5, right: 5 }
      };	
  };
  return ws;
}

export const handleSort = (sortArr: string[], body: any[][], config: any) => {
  let rowsLength = config?.rows?.length
  if (!rowsLength) return body
  let sliceSizesArr: any[] = [];
  for (let i = 0; i < rowsLength - 1; i++) {
    let rowspanArr = body.map(x => x[i].rowspan).filter(x => x != 0)
    sliceSizesArr[i] = rowspanArr
  }
  sliceSizesArr = groupRows(sliceSizesArr)
  if(sliceSizesArr.length > 0) sliceSizesArr = sliceSizesArr.filter(arr => !arr?.every((item: any) => item === 1));
  if (checkAllOnes(sliceSizesArr)) sliceSizesArr = []
  let filteredIndexes = reject(range(sortArr.length), index => sortArr[index] == 'none')
  let filteredValues: any[] =filter(sortArr, x => x != 'none')
  let res: any[][] = cloneDeep(body)
  for (let i = filteredIndexes.length - 1; i >= 0; i--) {
    const index = filteredIndexes[i];
    let bodyNew : any[]
    let sortType = filteredValues[i]
    let groupIndex: number = 0;

    groupIndex = index < rowsLength - 1 ? index : rowsLength - 1 <= sliceSizesArr.length ? rowsLength - 1 : sliceSizesArr.length;
    if (!sliceSizesArr.length) groupIndex = 0
    bodyNew = sliceArray(res, sliceSizesArr, groupIndex, sortType, index)
    res = flattenDepth(bodyNew, 1)
  }
  if(filteredIndexes.length == 0) {
    if(res.length > 0) {
      let index = body[0]?.findIndex(s=>s.formattype?.toString()?.includes(FormatType.Group));
      if(index != -1) {
        let groupIndex = index < rowsLength - 1? index : rowsLength - 1;
        let sliceArr: any[] = [];
        let rowLength = rowsLength - 1 <=0 ? 1: rowsLength - 1;
        for (let i = 0; i < rowLength; i++) {
          let rowspanArr = body.map(x => x[i].rowspan).filter(x => x != 0)
          sliceArr[i] = rowspanArr
        }
        sliceArr = groupRows(sliceArr)
        if(sliceArr?.length > 0) {
          let bodyNew: any[] = sliceArray(res, sliceArr, groupIndex, 'asc', index);
          if(bodyNew?.length > 0) res = flattenDepth(bodyNew, 1)
        }
      }   
    } 
  }

  res = resetRowSpan(res, rowsLength - 1)
  return res;
}

const sliceArray = (arr: any[], sliceSizesArr: any[], groupIndex: number, sortType: string, index: number) => {
  if (groupIndex == 0) return [sortArray(arr, sortType, (x: any) => x[index].value, index)]
  let sliceSizes = sliceSizesArr[groupIndex - 1]
  let res: any[] = []
  let child: any[] = []
  let count: number = 0
  let indexSliceSizes: number = 0
  for (let i = 0; i < arr.length; i++) {
    child.push(arr[i])
    count++
    if (sliceSizes && count == sliceSizes[indexSliceSizes]) {
      child = sortArray(child, sortType, (x: any) => x[index].value, index)
      res.push(child)
      indexSliceSizes++
      child = []
      count = 0
    }
  }
  return res;
}

let i: any = null;
export const sortArray = (data: any[], sortType: string, iteratee: any, index: Number) => {
  i = index;
  return data.sort((itemA, itemB) => {
    let a: any = iteratee(itemA)
    let b: any = iteratee(itemB)
    let dataTypeA = itemA[i]?.datatype ? itemA[i].datatype : '';
    let dataTypeB = itemB[i]?.datatype? itemB[i].datatype : '';
    let value1 = getValueGroupFilterSort(a, dataTypeA, itemA[i]?.formattype);
    let value2 = getValueGroupFilterSort(b, dataTypeB, itemB[i]?.formattype);
    a = value1.value;
    dataTypeA = value1.type;
    b = value2.value;
    dataTypeB = value2.type;
    var validDataTypes = ["INT", "FLOAT", "TOTALDAYSTYPE"];
    if (isKanji(a) || isKanji(b)) {
      a = a ? a : ""
      b = b ? b : ""
      return sortType == 'asc' ? a.localeCompare(b, 'ja-JP-u-co-unihan') : b.localeCompare(a, 'ja-JP-u-co-unihan')
    } else if (isNumber(a) || isNumber(b) ||validDataTypes.find(s=>s == dataTypeA || s == dataTypeB)) {
      if(isNotVal(a) && isNotVal(b)) return 0
      if (isNotVal(a)) return sortType == 'asc' ? -1 : 1
      if (isNotVal(b)) return sortType == 'asc' ? 1 : -1
      a = a ? a : 0
      b = b ? b : 0
      return sortType == 'asc' || sortType == 'none' ? a - b : b - a
    } else if(typeof a == 'object' || typeof b == 'object') {
      a = a ? a.value ? a.value : "" : ""
      b = b ? b.value ? b.value : "" : ""
      return sortType == 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    }
    else {
      a = a ? a : ""
      b = b ? b : ""
      return sortType == 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    }
  })
} 

const numberType = 'FLOAT';

export const getValueGroupFilterSort = (value: any, dataType: string, formatType: string) =>  {
  if(formatType?.includes(FormatType.Group)) {
    if(!value) return { value: value, type:  dataType };
    value = value.toString();
    let items = value?.split('~') || [];
    let filterVal: any = null;
    let indexMin = value.indexOf(COMMON_TEXT.BELOW) || -1;
    let index = value.indexOf(COMMON_TEXT.BELOW) != -1 ? value.indexOf(COMMON_TEXT.BELOW) : value.indexOf(COMMON_TEXT.UPPER) ? value.indexOf(COMMON_TEXT.UPPER) : -1
    dataType = dataType == "VARCHAR"? numberType : dataType;
    if(dataType == 'DATETIME') {
      filterVal = items.length > 1? items[1].toString() : index != -1? value.substring(0, index) : '';
      filterVal = filterVal.toString()?.replace('年', '').replace('月', '')?.replace('日', '')?.replace('時', '')?.replace('分', '')?.replace(':', '');
      dataType = numberType;
      filterVal = index != -1? indexMin != -1? FilterGroupDefaultValue.MIN : FilterGroupDefaultValue.MAX : filterVal;
    }
    else {
      // type number
      if(items.length > 1) filterVal = Number(items[1].toString().replace(/,/g, ''));
      else {
        filterVal = indexMin != -1? Number(value.substring(0, index).replace(/,/g, '')) + FilterGroupDefaultValue.MIN : Number(value.substring(0, index).replace(/,/g, '')) + FilterGroupDefaultValue.MAX;
      }
    }
    return { value: filterVal, type:  dataType };
  }
  return { value: value, type: dataType };  
};

const resetRowSpan = (data: any[][], rowsLength: number) => {
  for (let iCol = 0; iCol < rowsLength; iCol++) {
    let count = 1
    let preIndex = 0
    for (let iRow = 0; iRow < data.length; iRow++) {
      data[iRow][iCol].rowspan = 1
      if (iRow === 0) continue;
      if (data[iRow][iCol].value == data[iRow-1][iCol].value && (iCol === 0 || (iCol !== 0 &&data[iRow][iCol-1].rowspan == 0))) {
        count++
        data[iRow][iCol].rowspan = 0
        if (iRow === data.length - 1)  data[preIndex][iCol].rowspan = count
      } else {
        data[preIndex][iCol].rowspan = count
        preIndex = iRow
        count = 1
      }
    }
  }
  return data
}

export const isKanji = (character: string): boolean => {
  const kanjiRegExp = new RegExp('^[\u4e00-\u9faf]$'); // Regular expression for matching kanji characters
  return kanjiRegExp.test(character);
}

const groupRows = (arr: number[][]) => {
  let prev : number[] = []
  let res : number[][] = []

  for (let i = 0; i < arr.length; i++) {
    if (i == 0) {
      res.push(arr[0])
      prev = arr[0]
      continue
    }
    let index = 0
    let tmpArr = []
    let sum = 0
    let current : number[] = []
    for (let j = 0; j < arr[i].length; j++) {
      tmpArr.push(arr[i][j])
      sum += arr[i][j]
      if (sum === prev[index]) {
        if (tmpArr.findIndex(x => x != 1) == -1) {
          current.push(sum)
        } else {
          current = concat(current, tmpArr)
        }
        index++
        tmpArr = []
        sum = 0
      }
    }
    res.push(current)
    prev = arr[i]
  }
  return res
}

export const isNotVal = (x: any) => isUndefined(x) || isNull(x) || isNaN(x)

const checkAllOnes = (array: any[]): boolean => {
  return every(flatten(array), (element) => element === 1);
}

/**
 * merge cell
 * | A | B |      |   | B |
 * | A | C |  =>  | A | C |
 * | A | D |      |   | D |
 */
export const mergeCellVertically = (table: any[][], config: PivotTableConfig) => {
  const { rows } = config
  let previousColumn: any[] = []

  for (let j = 0; j < rows.length; j++) {

    const spans: number[] = []
    const length = table?.length || 0
    const lastIndex = length - 1
    if(lastIndex < 0) return []
    let previousValue = table[lastIndex][j]?.value
    let spanProcessor = 1    // processor for rowspan

    if (length > 1) {

      spans[lastIndex] = table[lastIndex][j].value == table[length - 2][j].value
        && (!previousColumn.length || previousColumn[lastIndex].rowspan == 0)
        ? 0 : 1

      // iterate table, add rowspan to the cell
      // then transform value  =>  { value, rowspan }
      for (let i = length - 2; i >= 0; i--) {
        // in the same group of previous column
        if (table[i][j].value == previousValue && (!previousColumn.length || previousColumn[i + 1].rowspan == 0)) {

          spanProcessor++

        } else {

          spans[i + 1] = spanProcessor
          spanProcessor = 1

        }

        previousValue = table[i][j].value
        spans[i] = 0
      }

      spans[0] = spanProcessor

    }

    table?.map((x, i) => {
      x[j] = {
        rowspan: j < rows.length ? spans[i] : 1,
        ...x[j],
      }
      previousColumn[i] = x[j]
      return x;
    })
  }

  return table?.map(x => x.map(y => ({
    rowspan: y?.rowspan !== undefined ? y.rowspan : 1,
    value: y?.value !== undefined ? y.value : y,
    formattype: y?.formattype,
    datatype: y?.datatype,
    cssStyle: y?.cssstyle || y?.cssStyle 
  })))
}

export const getExtraHeader = (data:any[],isDspAge: boolean, isDspLapsedDay: boolean , isAutoColumn: boolean, isShowOwsStfExtraCol: boolean):HeaderItem[] => {
  let extraHeader: HeaderItem[] = [];
  let isHasTotalDays = false;
  let isAddTotalDays = false
  if(!data || data.length == 0) return extraHeader;
  Object.keys(data[0]).forEach(key => {

    if(key.toLowerCase() == 'blocknm') {
      let block = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "blocknm").map(item => {
        let header: any = { ...item };
        header.visible = true;
        header.sortno = 5;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(block);
    }
    if(key.toLowerCase() == 'unitnm') {
      let unit = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "unitnm").map(item => {
        let header: any = { ...item };
        header.visible = true;
        header.sortno = 6;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(unit);
    }
    if (key.toLowerCase() == "totaldays") {
      let totalDay = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "totaldays").map(item => {
        let header: any = { ...item };
        header.visible = true;
        header.attribute ={
            header: { },
            row: {},
            childrenCols: [],
            isMergeCells: false
          }
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(totalDay);
      isHasTotalDays = true;
    }

    if (key.toLowerCase() == "totaltime") {
      let totalTime = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "totaltime").map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(totalTime);
    }

    if(isHasTotalDays === false) {
      if (key.toLowerCase().startsWith("ttd")) {

        if(isAddTotalDays === false){
        }

        const MonthAndYear: string[] = key.split('_');
        let columnDisplayName: string = '';
        const Month: string = MonthAndYear[1].substring(4, 6);
        if(MonthAndYear[1].length != 8) {
          columnDisplayName = `${Month}月`;
        }
        else {
          const Day: string = MonthAndYear[1].substring(6, 8);
          columnDisplayName = `${Month}/${Day}`;
        }
        let tdDetail =   {
          field: key,
          title: columnDisplayName,
          sortable: false,
          filterable: false,
          visible: true,
          attribute: {
            header: { },
            row: {}
          },
          dataType: DataType.NUMBER,
          sortno: Number.MAX_SAFE_INTEGER + 1
        } as HeaderItem;
        extraHeader.push(tdDetail);
        isAddTotalDays = true;
      }
    }

    if(key.toLowerCase() == "totaltime" || isHasTotalDays || isAddTotalDays) {
      if(extraHeader.filter(h => h.field === "resultatr").length == 0) {
        let resultatr = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "resultatr" ).map(item => {
          let header: any = { ...item };
          header.visible = true;
          return header;
        }).pop() as HeaderItem;
        extraHeader.push(resultatr);
      }
    }

    if(key.toLowerCase() == "roomattr")
    {
      let roomAttrHeader = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "roomattr").map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(roomAttrHeader);
    }

    if(key.toLowerCase() == "prvtime")
    {
      let prvTime = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "prvtime").map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(prvTime);
    }

    if(key.toLowerCase() == "odrstfnm")
    {
      let odrstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "odrstfnm").map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(odrstfnm);
    }

    if(key.toLowerCase() == "sndstfnm")
    {
      let sndstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "sndstfnm").map(item => {
        let header: any = { ...item };
        header.visible = true;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(sndstfnm);
    }
    if(isAutoColumn){
      if(key.toLowerCase().includes("_numberofdays"))
        {
          let numberofday = DATA_SOURCE_EXTRA_MASTER_HEADERS.filter(header => header.field === "numberofdays").map(item => {
              let header: any = { ...item };
              header.visible = true;
              return header;
          }).pop() as HeaderItem;
          numberofday.field = key.toLowerCase();
          extraHeader.push(numberofday);
        }
    }
    if (key.toLowerCase() == "roomnm") {
      let roomnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "roomnm").map(item => {
        let header: any = { ...item };
        header.visible = true;
        header.sortno = 7;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(roomnm);
    }
    
    if (key.toLowerCase() == "schedbedcd") {
      let schedbedcd = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "schedbedcd").map(item => {
        let header: any = { ...item };
        header.visible = true;
        header.sortno = 8;
        return header;
      }).pop() as HeaderItem;
      extraHeader.push(schedbedcd);
    }

    if (isShowOwsStfExtraCol) {
      if (key.toLowerCase() == "ownstfnm") {
        let ownstfnm = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === "ownstfnm").map(item => {
          let header: any = { ...item };
          header.visible = true;
          header.sortno = 10;
          return header;
        }).pop() as HeaderItem;
        extraHeader.push(ownstfnm);
      }
    }

  })
  if(isDspAge) {
    let age = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'age').map(item => {
      let header: any = { ...item };
      header.visible = true;
      return header;
    }).pop() as HeaderItem;
    let calcAge = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'calcage').map(item => {
      let header: any = { ...item };
      header.visible = true;
      return header;
    }).pop() as HeaderItem;
    extraHeader.push(age);
    extraHeader.push(calcAge);
  }
  if(isDspLapsedDay) {
    let lapsedDayHeader = DATA_SOURCE_DEFAULT_HEADERS.filter(header => header.field === 'lapsedday').map(item => {
      let header: any = { ...item };
      header.visible = true;
      return header;
    }).pop() as HeaderItem;
    extraHeader.push(lapsedDayHeader);
  }

  return extraHeader;
}

/**
 * Due to the system revision in April 2024, the evaluation criteria for returning to home indicators will change.
 * References: 
 * https://fds-ssc.backlog.jp/view/PIVOT-3102
 * https://fds-ssc.backlog.jp/view/PIVOT-3139
 * https://fds-ssc.backlog.jp/alias/wiki/1129501
 * 
 */
export const seidokaiseiAfter202404 = (totalMonth: number, startDate: Date, tableData: any[]) => {
  let tableDataClone = cloneDeep(tableData)
  let dataFilter: any[] = [];
  var dattcountOffset = tableDataClone?.filter((item: any) => (item.countflg === true || item.countflg === undefined || item.countflg === null)
    && convertToDate(item.date).getTime() < startDate.getTime());
  var dattcount = tableDataClone?.filter((item: any) => convertToDate(item.date).getTime() >= startDate.getTime())
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var dataTable = [...dattcountOffset, ...dattcount]
    .sort((a: any, b: any) => {
      return convertToDate(a.date).getTime() - convertToDate(b.date).getTime();
    });
  var itemMonth = dataTable.find(x => x.date === moment(startDate).format(DateFormat.YEAR_MONTH));
  var indexMonth = dataTable.indexOf(itemMonth);
  dataFilter = dataTable.slice((indexMonth - totalMonth + 1), indexMonth + 1);
  if(itemMonth.countflg == false){
    dataFilter = dataTable.slice((indexMonth - totalMonth), indexMonth );
  }
  if (dataFilter.length == 0) return false;
  
  const closestDate = dataFilter.reduce((oldest, current) => oldest.date < current.date ? oldest : current).date;
  if (closestDate >= "2024/04") return true;
  return false;
}

export const getRange = (type: string, periodSelected: any, periodList: any[]) =>{
  let sDate = new Date();
  let eDate = new Date();
  const currentDate = new Date();
  const currentDay = currentDate.getDate() - currentDate.getDay();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  switch (type) {
    case MstFilterDataTimeItem.ThisWeek://	今週 tuần này	this week
      sDate = new Date((new Date()).setDate(currentDay + 1))
      eDate = new Date((new Date()).setDate(currentDay + 7))
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.LastWeek://	先週 tuần trước last week
      sDate = new Date((new Date()).setDate(currentDay - 6))
      eDate = new Date((new Date()).setDate(currentDay))
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.ThisMonth://	今月 tháng này	this month
      sDate = new Date(currentYear, currentMonth, 1);
      eDate = new Date(currentYear, currentMonth + 1, 0);
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.LastMonth://	先月 tháng trước	last month
      if (currentMonth == 1) {
        sDate = new Date(currentYear - 1, 12, 1);
        eDate = new Date(currentYear - 1, 12, 31);
      }
      else {
        let daysInTheMonth = new Date(currentYear, currentMonth, 0).getDate();
        sDate = new Date(currentYear, currentMonth - 1, 1);
        eDate = new Date(currentYear, currentMonth - 1, daysInTheMonth);
      }
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.Today://	今日 hôm nay	today
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE)
    
    case MstFilterDataTimeItem.Yesterday://	昨日 hôm qua	yesterday
      sDate.setDate(sDate.getDate() - 1)
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.ThisYear:// 今年 Năm nay	this year
      sDate.setMonth(0)
      sDate.setDate(1)
      eDate.setMonth(11)
      eDate.setDate(31)
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.LastYear://	去年 năm ngoái	last year
      sDate.setFullYear(sDate.getFullYear() - 1)
      sDate.setMonth(0)
      sDate.setDate(1)
      eDate.setFullYear(eDate.getFullYear() - 1)
      eDate.setMonth(11)
      eDate.setDate(31)
      return moment(sDate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(eDate).format(DateFormat.FULL_SHORT_DATE)

    case MstFilterDataTimeItem.ThisFiscalYear: //本年度
    case MstFilterDataTimeItem.LastFiscalYear: //前年度
    case MstFilterDataTimeItem.TwoFiscalYearAgo:// 前々年度
      return filterByFinancialYearPeriod(type, periodList);
    case MstFilterDataTimeItem.UntilToday: //〜今日まで
    case MstFilterDataTimeItem.UntilThisWeek: //〜今週まで
    case MstFilterDataTimeItem.UntilThisMonth:// 〜今月まで
    let dateCurrent = getDayOfWeekOrMonthCurrent(type);
    if(periodSelected) {
      let rangeDate = null;
      let groups = periodSelected.split('-');
      let findPeriod = periodList.find(s=>s.value == groups[0]);
      if(findPeriod) {
        rangeDate = findPeriod.items?.find((p: any) => p.value == periodSelected);
        return moment(rangeDate?.startdate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent.end).format(DateFormat.FULL_SHORT_DATE)
      }
      else {
        rangeDate = periodSelected.split('~');
        if(rangeDate?.length > 1) return moment(rangeDate[0]).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent.end).format(DateFormat.FULL_SHORT_DATE)
      }
    }
    return moment(dateCurrent?.start).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent.end).format(DateFormat.FULL_SHORT_DATE)
    default:
      return ''
  }
}


export const getValueFilterGroup = (value: any, isMax: boolean, isMin: boolean) => {
  // get value is numner type
  if(isNumber(value)) {
    let numVal: number = Number(value?.toString()?.replace(/,/g, ''));
    if(isMax) return numVal + FilterGroupDefaultValue.MAX;
    else if(isMin) return numVal + FilterGroupDefaultValue.MIN;
    return numVal;
  }
  else {
    value = value.toString()?.replace('年', '')?.replace('月', '')?.replace('日', '')?.replace('時', '')?.replace('分', '')?.replace(':', '');
    return isMax? Number(value) + Number(FilterGroupDefaultValue.MAX) : isMin? Number(value) + Number(FilterGroupDefaultValue.MAX): Number(value);
  }
}

export const groupSortValue = (value: any) => {
  // get value for item filter group
  if(!value) return value;
  let isMax: boolean = value.indexOf(COMMON_TEXT.UPPER);
  let isMin: boolean = value.indexOf(COMMON_TEXT.BELOW);
  if(isMax || isMin) {
    value = value.toString()?.replace(COMMON_TEXT.BELOW,'').replace(/,/g, '')?.replace(COMMON_TEXT.UPPER, '');
  }
  value = value?.toString();
  let items = value?.split('~') || [];
  if(items.length > 1) {
      let itemValue = items[1];
      return getValueFilterGroup(itemValue, isMax, isMin);
  }
  else {
    return getValueFilterGroup(value, isMax, isMin);
  }
}

export const getHeadersForTarget = (headers: any[][], config: PivotTableConfig) => {
    const result: any[][] = [];
    if(headers && headers.length > 0) {
        let rowCount = config.rows.length;
        let valueCount = config.values.length;
        let bkHeaders = cloneDeep(headers[headers.length-1]);
        bkHeaders.splice(0, rowCount);
        for (let i = 0; i < bkHeaders.length; i += valueCount) {
            result.push(bkHeaders.slice(i, i +  valueCount));
        }
    }
    return result;
}

export const checkACustomColumnContainTargetColumns = (value: WidgetDetail, settingTargets: WidgetSettingRecord[]): boolean => {
    let exist = false;
    if(value && settingTargets) {
        const listTargetColumns = settingTargets.map((target: WidgetSettingRecord) => target.targetColumnName);
            if(listTargetColumns) {
                let operator = value.operator!
                listTargetColumns.forEach((key: string) => {
                    if (operator.includes(key))  {
                        exist = true;
                    }
                });
            }
    }
    return exist;
}

export const countUnique = (data: any[][], column: number) => {
    let a: any = {}
    for (let i = 0; i < data.length; i++) {
        if (data[i][column] !== undefined)
            a[data[i][column]] = true
    }
    return Object.keys(a).length
}

export const countByColumn = (data: any[][], column: number) => {
    let sum = 0
    for (let i = 0; i < data.length; i++) {
        if (data[i][column] !== undefined)
            sum++
    }
    return sum
}

export const sumByColumn = (data: any[][], column: number) => {
    let sum = 0
    for (let i = 0; i < data.length; i++) {
        if (data[i][column] !== undefined)
        {
            let value = data[i][column];
            if(typeof value == "string") {
                value = value.replace(/\,/g, '');
            }
            else if(typeof value == "number" && Number.isNaN(value)) 
            {
                value = 0;
            }
            sum += Number(value)
        }
            
    }
    return Number(sum.toFixed(2))
}