export const MESSAGE_TEXT = {
    CONTACT_ADMIN: '管理者にご連絡お願い致します。',
    NO_PIVOT_PERMISSION: 'Pivotを使用する権限がないです。',
    INCORRECT_MAIL_FORMAT: 'メールアドレスの形式が間違っています。正しい形式で入力してください。',
    DUPLICATE_VALIDATE: '既に登録されています。',
    ERR_REMOVE_COLUMN: '[エラー!この列は削除されました]',
    NOT_AUTHORIZED: '権限がありません。',

    CONFIRM_SYNC_SINGLE_MONTH: 'の同期データのみを処理する。\n',
    CONFRIM_OVERRIDE: 'データが上書きされますが良いでしょうか？',
    CONFIRM_DELETE: '削除してもよろしいですか。',
    CONFIRM_DELETE_SELECTED_ITEM: '選択中の{0}を削除します。よろしいですか？',
    CONFIRM_DELETE_TEAM: '選択中のグループを削除します。よろしいですか？',
    CONFIRM_DELETE_STAFF: '選択中の職員を削除します。よろしいですか？',
    CONFIRM_DELETE_PENDING_INVITATION_STAFF: '招待を取消します。よろしいですか？',
    CONFIRM_UNSAVE: '未保存のデータがあります。保存しますか？',
    CONFIRM_UNSAVE_OPTION_1: '【はい】...　データ保存して、処理を続行します。',
    CONFIRM_UNSAVE_OPTION_2: '【いいえ】...　データ保存せず、処理を続行します。',
    CONFIRM_UNSAVE_OPTION_3: '【キャンセル】...　処理をキャンセルし元の画面に戻ります。',
    CONFIRM_CHART: '設定されている列・行の項目が多い為、グラフ作成するのに時間がかかります。\nグラフを作成しますか？',

    REQUIRE_FIELD: '必須項目です。',
    REQUIRE_INPUT: '必須項目を入力してください。',
    REQUIRE_ADD_STAFF_TO_GROUP: 'グループに移動します。よろしいでしょうか？',
    REQUIRE_ADD_STAFF_TO_UNGROUP: 'グループ未定の学校に職員を追加することはできません',
    REQUIRE_INPUT_TEXT: 'テキストを入力してください。',
    REQUITE_TEXT_INPUT: 'テキスト入力してください。',
    REQUIRE_SELECT_ROLE: 'ロール選択してください。',
    REQUIRE_INPUT_DATASOURCE: 'データソースを選択してください。',

    DELETE_COMPLETE: '削除完了!',
    SAVE_COMPLETE: '保存完了!',
    UPDATE_COMPLETE: '編集完了!',
    ERR: 'エラー!',
    CANCEL_COMPLETE: '取消完了!',

    UPDATE_SUCCESS: 'が更新できました。',
    UPDATE_FAIL: 'の更新が失敗しました。',
    DS_OVER_LIMIT_RECORDS: "データ取得件数が上限の20,000件を超えています。事業所や期間の再設定をおねがいします。",
    CREATE_DATASOURCE_FALSE_CAUSE_UNKNOWN_ERROR: 'システムエラーが発生しました。',
    DUPLICATE_NAME: '既に登録されています。',
    PERIOD_CONDITIONS_DUPLICATED: ' 期間条件が重複されている。'
}