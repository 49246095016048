<pivot-modal-template *ngIf="isDisplayModal" class="dlg-del-body" [modalTemplate]="modalData"
    (closeModalHandler)="onSubmit(false)">
    <div modal-body>
        <div *ngIf="!isCustomDialogForValidZaitaku" class="dlg-delete-content dlg-flex-start dlg-fex-center">
            <div><span class="dlg-delete-icon"></span></div>
            <div class="dlg-txt"><label>{{getMessage()}}</label></div>
        </div>
        <div *ngIf="isCustomDialogForValidZaitaku" class="dlg-info-content dlg-flex-start dlg-fex-center">
            <img class="dlg-info-icon" src="../../../assets/icons/exclamation-mark-blue.svg">
            <div class="dlg-confirm-info-txt"><label>{{getMessage()}}</label></div>
        </div>
        <div *ngIf="deleteItems.length > 0" class="item-delete">
            <div *ngFor="let item of deleteItems; let r = index ">
                <label class="item-text">{{item}}</label>
            </div>
        </div>
        <div *ngIf="duplicateItems.length > 0" class="duplicate-item">
            <div *ngFor="let item of duplicateItems; let r = index ">
                <label class="item-text">{{item}}</label>
            </div>
        </div>
        <label class="datasource-label" *ngIf="isCustomDialogForDataSourceList">{{warningLabel}}</label>
        <div *ngIf="isCustomDialogForDataSourceList" class="item-delete dtsource">
            <div class="dtsource-render">
                <label>{{widgetLabel}}</label>
                <ul *ngIf="renderArray[0].widgetNames.length== 0">{{emptyNode}}</ul>
                <ul class="item-list" *ngFor="let a of renderArray[0].widgetNames">
                    <li>{{a}}</li>
                </ul>
                <label class="dashboards">{{dashboardLabel}}</label>
                <ul *ngIf="renderArray[1].dashboardNames.length== 0">{{emptyNode}}</ul>
                <ul class="item-list" *ngFor="let e of renderArray[1].dashboardNames">
                    <li>{{e}}</li>
                </ul>
            </div>
        </div>
        <label class="datasource-label" *ngIf="isCustomDialogForWidgetList">{{warningLabel}}</label>
        <div *ngIf="isCustomDialogForWidgetList" class="item-delete dtsource">
            <div class="dtsource-render">
                <label class="widget-dashboards">{{dashboardLabel}}</label>
                <ul *ngIf="renderArray.length == 0">{{emptyNode}}</ul>
                <ul class="item-list" *ngFor="let e of renderArray">
                    <li>{{e.dashboardname}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div modal-bottom class="dlg-delete-content">
        <div class="dlg-div-btn" *ngIf="!isCustomDialogForValidZaitaku">
            <pivot-text-button class="dlg-btn" (btnClick)="onSubmit(true)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="btnOkText">
            </pivot-text-button>
            <pivot-text-button class="dlg-btn" (btnClick)="onSubmit(false)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="btnCancelText">
            </pivot-text-button>
        </div>
        <div class="dlg-div-btn dlg-confirm-zaitaku" *ngIf="isCustomDialogForValidZaitaku">
            <pivot-text-button class="dlg-btn" (btnClick)="onSubmit(false)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="btnOkText">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>