<div class="pagination" >
  <div class="pagination-group">
      <img class="previous-end" (click)="currentPage = 1" [ngClass]="totalPage === 1 || currentPage === 1 ? 'inactive' :'active'" alt="previous end">
      <img class="previous-single" (click)="currentPage = currentPage - 1" [ngClass]="totalPage === 1 || currentPage === 1? 'inactive' :'active'" alt="previous">
      <span class="description">{{currentPage}}/{{totalPage}}</span>
      <img class="next-single" (click)="currentPage = currentPage + 1" [ngClass]="currentPage >= totalPage || totalPage === 1 ? 'inactive' :'active'" alt="next single">
      <img class="next-end" (click)="currentPage = totalPage" [ngClass]="currentPage >= totalPage || totalPage === 1  ? 'inactive' :'active'" alt="next end">
  </div>
  <div class="pagination-group" >
    <span>{{COMMON_TEXT.RESULT}}</span>
    <div class="pagination-selection" id='pagination'>
      <div class="pagination-label" (click)="toogleDropdown = !toogleDropdown">{{itemsPerPage}}</div>
      <div class="pagination-dropdown" id="pagination-modal" [ngClass]="{'show' : toogleDropdown}">
        <div *ngFor="let item of [20, 50, 100]; let r = index " (click)="changePageSize(item)" class="pagination-item">
          {{item }}
        </div>
      </div>
    </div>
  </div>
</div>