import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DATATYPE } from '../../../const/const';
import { OPERATOR_CUSTOM, TEXT_OPERATOR_CUSTOM } from 'src/app/const/operator-custom';
import { OperatorCustom } from 'src/app/models/operator-custom';

@Component({
  selector: 'pivot-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  // input set style css for common
  @Input() cssStyle: any = {};
  @Input() dataType: any;
  @Input() isDataSource: boolean = false;
  // event submit data
  @Output() onClickButton = new EventEmitter<any>();


  items: any[] = [{label: '+'},{label: '-'},{label: 'x'},{label: '/'},{label: '('},{label: ')'}];
  itemsCustom: any[] = []

  DATATYPE = DATATYPE;
  constructor() {
    this.itemsCustom =[];
    OPERATOR_CUSTOM.map((op:OperatorCustom)=>{
      this.itemsCustom.push({
        label: op.name,
        hover: op.hover,
      })
    })
   }

  ngOnInit(): void {
  }

  // event click button
  buttonClick(data: any) {
    this.onClickButton.emit(data);
  }
}
