<div modal-body>
    <div class="dlgu-content dlgu-flex-start">
        <div class="dlgu-block1">
            <div> <img class="dlgu-icon" /></div>
        </div>
        <div class="dlgu-block2">
            <div class="dlgu-txt"><label>{{MESSAGE_TEXT.CONFIRM_UNSAVE}}</label></div>
            <div class="dlgu-txt"><label>{{MESSAGE_TEXT.CONFIRM_UNSAVE_OPTION_1}}</label></div>
            <div class="dlgu-txt"><label>{{MESSAGE_TEXT.CONFIRM_UNSAVE_OPTION_2}}</label></div>
            <div class="dlgu-txt"><label>{{MESSAGE_TEXT.CONFIRM_UNSAVE_OPTION_3}}</label></div>
        </div>
    </div>
</div>
<div modal-bottom class="dlgu-content">
    <div class="dlgu-div-btn">
        <pivot-text-button class="dlgu-btn" (btnClick)="onSubmit(saveType.SAVE)" [buttonType]="buttonType.SECONDARY"
            [buttonText]="BUTTON.YES">
        </pivot-text-button>
        <pivot-text-button class="dlgu-btn" (btnClick)="onSubmit(saveType.NO)" [buttonType]="buttonType.SECONDARY"
            [buttonText]="BUTTON.NO">
        </pivot-text-button>
        <pivot-text-button class="dlgu-btn" (btnClick)="onSubmit(saveType.CANCEL)" [buttonType]="buttonType.SECONDARY"
            [buttonText]="BUTTON.CANCEL">
        </pivot-text-button>
    </div>
</div>