<div>
  <div *ngIf="isDataSource" class="pivot-calculator " >
    <ng-container  *ngFor="let item of itemsCustom; let r = index " >
      <div *ngIf="item.hover" class="calculator-item" 
        [pTooltip]="item.hover"  tooltipPosition = "top"
        (click)="buttonClick(item.label)">{{item.label}}
      </div>
      <div *ngIf="!item.hover" class="calculator-item" 
        (click)="buttonClick(item.label)">{{item.label}}
      </div>
    </ng-container>
  </div>
  <div class="pivot-calculator itemCustom">
    <ng-container  *ngFor="let item of items; let r = index " >
      <div *ngIf="item.hover" class="calculator-item" 
        [pTooltip]="item.hover"  tooltipPosition = "top"
        (click)="buttonClick(item.label)">{{item.label}}
      </div>
      <div *ngIf="!item.hover" class="calculator-item" 
        (click)="buttonClick(item.label)">{{item.label}}
      </div>
    </ng-container>
  </div>
</div>