import { DashboardSetting } from "../models/response/dashboard.ro";

export const ZaitakuItemDetails: DashboardSetting[] = [
  {
    widgetId: 'ZD00000001',
    widgetCd: 'ZD00000001',
    cols: 28,
    rows: 25,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 21, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000002',
    widgetCd: 'ZD00000002',
    cols: 28,
    rows: 10,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 10, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000003',
    widgetCd: 'ZD00000003',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000004',
    widgetCd: 'ZD00000004',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000005',
    widgetCd: 'ZD00000005',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000006',
    widgetCd: 'ZD00000006',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000007',
    widgetCd: 'ZD00000007',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000008',
    widgetCd: 'ZD00000008',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000009',
    widgetCd: 'ZD00000009',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000010',
    widgetCd: 'ZD00000010',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000011',
    widgetCd: 'ZD00000011',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000012',
    widgetCd: 'ZD00000012',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000013',
    widgetCd: 'ZD00000013',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000014',
    widgetCd: 'ZD00000014',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000015',
    widgetCd: 'ZD00000015',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000016',
    widgetCd: 'ZD00000016',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000017',
    widgetCd: 'ZD00000017',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000018',
    widgetCd: 'ZD00000018',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000019',
    widgetCd: 'ZD00000019',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000020',
    widgetCd: 'ZD00000020',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000021',
    widgetCd: 'ZD00000021',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
  {
    widgetId: 'ZD00000022',
    widgetCd: 'ZD00000022',
    cols: 28,
    rows: 7,
    height: 670,
    width: 14,
    x: 0,
    y: 0,
    widgetPosition: '{x: 0, y: 0, rows: 7, cols: 28, width: 14, height: 670}'
  },
]

export const ZaitakuItemDetailName: {[key: string]: string} = {
  'ZD00000001': '_在宅復帰・在宅療養等指標',
  'ZD00000002': '_在宅復帰・在宅療養等指標',
  'ZD00000003': 'A　在宅復帰率',
  'ZD00000004': 'B　ベッド回転率',
  'ZD00000005': 'C　入所前後訪問指導割合',
  'ZD00000006': 'D　退所前後訪問指導割合',
  'ZD00000007': 'E　居宅サービスの実施状況',
  'ZD00000008': 'F　リハ専門職員の配置割合',
  'ZD00000009': 'G　支援相談員の配置割合',
  'ZD00000010': 'H　介護度４又は５の割合',
  'ZD00000011': 'I　喀痰吸引の実施割合',
  'ZD00000012': 'J　経管栄養の実施割合',
  'ZD00000013': 'A　在宅復帰率（各月の実績）',
  'ZD00000014': 'B　ベッド回転率（各月の実績）',
  'ZD00000015': 'C　入所前後訪問指導割合（各月の実績）',
  'ZD00000016': 'D　退所前後訪問指導割合（各月の実績）',
  'ZD00000017': 'E　居宅サービスの実施状況（各月の実績）',
  'ZD00000018': 'F　リハ専門職員の配置割合（各月の実績）',
  'ZD00000019': 'G　支援相談員の配置割合（各月の実績）',
  'ZD00000020': 'H　介護度４又は５の割合（各月の実績）',
  'ZD00000021': 'I　喀痰吸引の実施割合（各月の実績）',
  'ZD00000022': 'J　経管栄養の実施割合（各月の実績）',
}

export const SERVICE_TYPE =[
  {
    value: 0,
    title: '1'
  },
  {
    value: 1,
    title: '2'
  },
  {
    value: 3,
    title: '2 訪問リハあり'
  },
  {
    value: 5,
    title: '3'
  }
]