import { attempt } from "lodash";
import { isDecimal, evaluateFormula } from "../_helper/helper";
import { OperatorCustom, OperatorType } from "../models/operator-custom";

export const TEXT_OPERATOR_CUSTOM = {
  FUNCTION: '関数',
  TODAY_HOVER: 'TODAY()',
  DAYS_HOVER: 'DAYS(開始日;終了日)',
  MONTHS_HOVER: 'MONTHS(開始日;終了日)',
  YEARS_HOVER: 'YEARS(開始日;終了日)',
  IF_HOVER: 'IF(条件;TRUE时の値;FALSE时の値)',
  EOM_HOVER: 'EOM(指定日)',
  COLUMN: '列一覧',
  FORMAT_HOVER: 'Format(値;表示形式)'
}

export const MIN_DATE = '1990-01-01';

export const  OPERATOR_TYPE :OperatorType = {
  OPERATOR: 'operator',
  COLUMN: 'column',
  OPERATOR_CUSTOM: 'operator_custom',
  OPERATOR_CUSOR: 'cusor',
}



export const OPERATOR_LOGIC_TYPE = {
  DateTime: 'DateTime',
  Decimal: 'Decimal',
  String: 'String',
  Boolean: 'Boolean'
}

export const  OPERATOR_MATH: string[] = ['+', '-', 'x', '*' , '/' , ';'];// '(' , ')'
export const  OPERATOR_LOGICAL: string[] = ['>=', '<=', '!=', '=' , '>' , '<' ];

export const OPERATOR_CUSTOM: OperatorCustom[] = [
  {
    name:'TODAY',
    function: TODAY,
    hover: TEXT_OPERATOR_CUSTOM.TODAY_HOVER
  }, 
  {
    name:'DAYS',
    function: DAYS,
    hover: TEXT_OPERATOR_CUSTOM.DAYS_HOVER
  },
  {
    name:'MONTHS',
    function: MONTHS,
    hover: TEXT_OPERATOR_CUSTOM.MONTHS_HOVER
  },
  {
    name:'YEARS',
    function: YEARS,
    hover: TEXT_OPERATOR_CUSTOM.YEARS_HOVER
  },
  {
    name:'IF',
    function: IF,
    hover: TEXT_OPERATOR_CUSTOM.IF_HOVER
  },
  {
    name:'EOM',
    function: EOM,
    hover: TEXT_OPERATOR_CUSTOM.EOM_HOVER
  },
  {
    name:'FORMAT',
    function: FORMAT,
    hover: TEXT_OPERATOR_CUSTOM.FORMAT_HOVER
  },
  
]
export const OPERATOR_CUSTOM_NAME: string[] = OPERATOR_CUSTOM.map((op: OperatorCustom) =>  op.name ?? "");// ['TODAY', 'DAYS', 'MONTHS', 'YEARS',  'IF', 'EOM' ]

function DAYS(par:any): any {

  var params = par.toString().replace(/'/g, "").replace(/`/g, "").split(";");
  if(params.length != 2 ) return "";

  let sDate = new Date(params[0].toString().replace(/'/g, "").replace(/`/g, "").trim());
  if(isDecimal(params[0].toString().trim()) ||  isNaN(sDate.getDate())){
    let number = evaluateFormula(params[0].toString().trim());
    if(number && isDecimal(number.toString())){
      sDate = new Date(MIN_DATE);
      sDate.setDate(sDate.getDate() + number);
    }else{
      return NaN;
    }
  }
  let eDate = new Date(params[1].toString().replace(/'/g, "").replace(/`/g, "").trim());
  if(isDecimal(params[1].toString().trim()) || isNaN(eDate.getDate())){
    let number = evaluateFormula(params[1].toString().trim());
    if(number && isDecimal(number.toString())){
      eDate = new Date(MIN_DATE);
      eDate.setDate(eDate.getDate() + number);
    }else{
      return NaN;
    }
  }

  const startTime = sDate.getTime();
  const endTime = eDate.getTime();

  const timeDiff = endTime - startTime;

  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
}

function EOM(par:any): any {
  let parm = par.toString().replace(/'/g, "").replace(/`/g, "");

  let timestamp = new Date(parm.toString().trim());
  if(isDecimal(parm) || isNaN(timestamp.getDate())){
    let no = evaluateFormula(parm.toString().trim());
    if(no && isDecimal(no.toString())){

      timestamp = new Date(MIN_DATE);
      timestamp.setDate(timestamp.getDate() + no);
    }else{
      return NaN;
    }

  }


  const currentMonth = timestamp.getMonth();

  const nextMonth = new Date(timestamp);
  nextMonth.setMonth(currentMonth + 1);
  nextMonth.setDate(1);

  const lastDayOfMonth = new Date(nextMonth);
  lastDayOfMonth.setDate(0);

  const lastDay = lastDayOfMonth.getDate();
  return lastDay;
}

function IF(par:any): any {
  var params = par.toString().replace(/'/g, "").replace(/'/g, "").replace(/`/g, "").split(";");
  if(params.length != 3 ) return "";

  let condtion = params[0].toString().replace(/'/g, "").replace(/`/g, "").trim();
  let trueResust = params[1].toString().trim();
  let falseResust = params[2].toString().trim();
  
  if(condtion.includes('>=')){
    var paraLogic=condtion ?.toString().split(">=");
    return checkLogicalExpression(">=", paraLogic[0], paraLogic[1], trueResust, falseResust);

  } else if(condtion.includes('=>')){
    var paraLogic=condtion ?.toString().split("=>");
    return checkLogicalExpression(">=", paraLogic[0], paraLogic[1], trueResust, falseResust);

  } else if(condtion.includes('<=')){
    var paraLogic=condtion ?.toString().split("<=");
    return checkLogicalExpression("<=", paraLogic[0], paraLogic[1], trueResust, falseResust);

  } else if(condtion.includes('=<')){
    var paraLogic=condtion ?.toString().split("=<");
    return checkLogicalExpression("<=", paraLogic[0], paraLogic[1], trueResust, falseResust);

  } else if(condtion.includes('>')){
    var paraLogic=condtion ?.toString().split(">");
    return checkLogicalExpression(">", paraLogic[0], paraLogic[1], trueResust, falseResust);

  }else if(condtion.includes('<')){
    var paraLogic=condtion ?.toString().split("<");
    return checkLogicalExpression("<", paraLogic[0], paraLogic[1], trueResust, falseResust);
  }
  else if(condtion.includes('!=')){
    var paraLogic=condtion ?.toString().split("!=");
    return checkLogicalExpression("!=", paraLogic[0], paraLogic[1], trueResust, falseResust);
  }else if(condtion.includes('=')){
    var paraLogic=condtion ?.toString().split("=");
    return checkLogicalExpression("=", paraLogic[0], paraLogic[1], trueResust, falseResust);
  }

 return falseResust
}

function checkLogicalExpression( operatorString : string, value1 : string,value2 : string, valueIfTrue: any, valueIfFalse : any): any{
  var value1String = value1.trim();
  var value2String = value2.trim();
  var value1decimal = evaluateFormula(value1.trim());
  var value2decimal = evaluateFormula(value2.trim());
  var valueType = OPERATOR_LOGIC_TYPE.Decimal;
  var value1DateTine = new Date();
  var value2DateTine = new Date();

  if(!isDecimal(value1.trim()) && !isDecimal(value2.trim())){
    valueType = OPERATOR_LOGIC_TYPE.DateTime;;
    value1DateTine =  new Date(value1.trim());
    value2DateTine =  new Date(value2.trim());
    if(isNaN(value1DateTine.getDate())||isNaN(value2DateTine.getDate())){
      valueType = OPERATOR_LOGIC_TYPE.String;
    }
  }

  switch (operatorString)
  {
    case '>=':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal >= value2decimal) ? valueIfTrue : valueIfFalse;
      }else {
        return (value1DateTine >= value2DateTine) ? valueIfTrue : valueIfFalse;
      }
    case '<=':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal <= value2decimal) ? valueIfTrue : valueIfFalse;
      }else {
        return (value1DateTine <= value2DateTine) ? valueIfTrue : valueIfFalse;
      }    
    case '>':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal > value2decimal) ? valueIfTrue : valueIfFalse;
      }else {
        return (value1DateTine > value2DateTine) ? valueIfTrue : valueIfFalse;
      }
    case '<':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal < value2decimal) ? valueIfTrue : valueIfFalse;
      }else {
        return (value1DateTine < value2DateTine) ? valueIfTrue : valueIfFalse;
      }

    case '!=':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal != value2decimal) ? valueIfTrue : valueIfFalse;
      }else {
        return (value1DateTine != value2DateTine) ? valueIfTrue : valueIfFalse;
      }
    case '=':
      if(valueType == OPERATOR_LOGIC_TYPE.Decimal){
        return (value1decimal == value2decimal) ? valueIfTrue : valueIfFalse;

      } else if(valueType == OPERATOR_LOGIC_TYPE.DateTime){
        return (value1DateTine == value2DateTine) ? valueIfTrue : valueIfFalse;

      }else{
        if(value2String.includes('%')) {
          var escapedPattern = value2String.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');//Replaces all special characters in a string by adding a \\ before them
          var regexPattern = escapedPattern.replace(/%/g, '.*');
          let rex =  new RegExp(regexPattern);
          return (rex.test(value1String)) ? valueIfTrue : valueIfFalse;
        }else{
          return (value1String == value2String) ? valueIfTrue : valueIfFalse;
        }
      }
  }

  return valueIfFalse;
}


function YEARS(par: any): any {
  return ""
}

function TODAY(par: any): any {
  return ""
}

function MONTHS(par: any): any {
  return ""
}
function FORMAT(par: any): any {
  return ""
}

