<div class="chart-container">
    <div *ngIf="isLoading" class="loading">
		<div class="loader"></div>
	</div>
    <pivot-line-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.LINE_CHART && !!chartData" [data]="chartData" [width]="width" [height]="height"></pivot-line-chart>
    <pivot-number-chart *ngIf="!isLoading && type == graphType.NUMBER_CHART && !!chartData" [data]="chartData" [tableData]="tableData" [footerType]="footerType" [height]="height"></pivot-number-chart>
    <pivot-doughnut-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.DOUGHNUT_CHART && !!chartData" [data]="chartData" [width]="width" [height]="height"></pivot-doughnut-chart>
    <pivot-pie-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.PIE_CHART && !!chartData" [data]="chartData" [width]="width" [height]="height"></pivot-pie-chart>
    <pivot-gauge-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.GAUGE_CHART && !!chartData" [data]="chartData" [width]="width" [height]="height"></pivot-gauge-chart>
    <pivot-line-bar-combine-chart *ngIf="!isShowChartConfig && !isShowChartDialog && !isLoading && type == graphType.LINE_BAR_COMBINE_CHART && !!chartData" [data]="chartData" [width]="width" [height]="height" [graphConfig]="graphConfig" (onStartRender)="onStartRenderChart()" (onRenderComplete)="onRenderChartComplete()"></pivot-line-bar-combine-chart>
    <pivot-bar-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.BAR_CHART_VERTICAL && !!chartData" [data]="chartData" [width]="width" [height]="height" [isHorizontal]="false"></pivot-bar-chart>
    <pivot-bar-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.BAR_CHART_HORIZONTAL && !!chartData" [data]="chartData" [width]="width" [height]="height" [isHorizontal]="true"></pivot-bar-chart>
    <pivot-table-chart *ngIf="!isLoading && type == graphType.TABLE_CHART && !!chartData" [sortParams]="sortParams" [data]="chartData" [isFilterByOffice] = "isFilterByOffice" (handleClick)="handleClick()" (handleSortData)="handleSortCol($event)"></pivot-table-chart>
    <pivot-stacked-bar-chart *ngIf="!isShowChartDialog && !isLoading && type == graphType.STACKED_BAR && !!chartData" [data]="chartData" [width]="width" [height]="height" [isStackedChartSetting]="isStackedChartSetting" ></pivot-stacked-bar-chart>
</div> 
<pivot-combo-chart-setting *ngIf="isShowChartConfig && !isLoading && type == graphType.LINE_BAR_COMBINE_CHART && !!chartData" [widgetGraphSetting]="graphConfig!" [chartData]="chartData"
(onSaveChartConfig)="onSubmitChartConfig($event)"></pivot-combo-chart-setting>