<pivot-modal-template [modalTemplate]="modalData" (closeModalHandler)="onClose()">
    <div class="setting-container" modal-body>
        <div class="tabs">
            <input type="radio" name="tab-btn" id="tab-btn-1" [disabled]="selected && selected.datasourcecd !== DSCUSTOM" [checked]="isDefineNewColumnTab" [value]="isDefineNewColumnTab" (click)="onTabChange(true)">
            <label id="content1" for="tab-btn-1" [ngClass]="{'disabled': selected && selected.datasourcecd !== DSCUSTOM}" class="tabs-border">定義列の追加</label>
            <input type="radio" name="tab-btn" id="tab-btn-2" [disabled]="(selected && selected.datasourcecd !== DSTARGET)" [checked]="!isDefineNewColumnTab" [value]="!isDefineNewColumnTab" (click)="onTabChange(false)">
            <label id="content2" for="tab-btn-2" [ngClass]="{'disabled': (selected && selected.datasourcecd !== DSTARGET)}" class="tabs-border">目標値設定</label>
            <div id="content-1" class="tab-define-columns">
                <div class="def-item">
                    <label>{{WIDGET_SETTING.DEFINED_COL_NAME}} <span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
                    <div class="def-item-txtbox">
                        <pivot-textbox [inputParams]="inputNameParams"
                        [inputValue]="column.columnname" (onFocusOut)="onInputName($event)"></pivot-textbox>
                    </div>
                </div>
                <pivot-input-formula #inputFormula [displayCols] = "displayCols" [selected] = "selected" >
                </pivot-input-formula>
            </div>
            <div id="content-2" class="tab-target-setting">
                <div class="setting-columns setting-config">
                    <div class="setting-row row-error">
                        <div class="setting-col">
                            <label> 目標名称 </label>
                            <span class="input-required">*</span>
                            <pivot-textbox class="setting-item target-name" [inputParams]="inputTargetName" (onFocusOut)="setTargetName($event)" [inputValue]="selectedSetting.targetColumnName">
                            </pivot-textbox>
                        </div>
                        <div class="setting-col">
                            <label>目標設定対象（列）</label>
                            <span class="input-required">*</span>
                            <pivot-search-bar class="search-bar" [searchParams]="targetParams" [_required]="validation && validation.column" (onFilterData)="changeColumnItem($event)"
				                [placeHolderFilter]="COMMON_TEXT.SEARCH_TEXT"></pivot-search-bar>
                        </div>
                    </div>
                    <div class="setting-row col-option" >
                        <div class="setting-col setting-col-option ">
                            <span>目標設定対象（行）</span>
                            <span class="input-required">*</span>
                            <pivot-search-bar class="search-bar" [searchParams]="rowParams" [_required]="validation && validation.row"  (onFilterData)="changeRowItem($event)"
                                [placeHolderFilter]="COMMON_TEXT.SEARCH_TEXT"></pivot-search-bar>
                        </div>
                        <div  class="setting-col" >
                            <p-dropdown class="drop-down setting-item datetime-dropdown" (onChange)="changeDateFormat($event)"  [disabled]="enableDropDownDateTime()" optionLabel="name" [options]="datetimeFormatOptions" [(ngModel)]="formatDateTime">
                                <ng-template let-option pTemplate="item">
                                    <div class="option-item">{{option.name}} </div>
                                </ng-template>
                            </p-dropdown>
                        </div>

                    </div>
                    <div class="setting-row row-group">
                        <div class="setting-col-group">
                            <span>設定</span>
                            <div class="setting-row">
                                <div class="content-type">
                                    <pivot-radio-button [isChecked]="!selectedSetting.isApplyAll" (onClick)="onChangeApplyStyle(false)"></pivot-radio-button>
                                    <label>単一</label>
                                    <pivot-radio-button [isChecked]="selectedSetting.isApplyAll" (onClick)="onChangeApplyStyle(true)"></pivot-radio-button>
                                    <label>全て</label>
                                </div>
                            </div>
                        </div>
                        <div class="setting-col-group color-setting-group">
                            <div class="color-setting">
                                以下
                                <p-colorPicker [disabled]="selectedSetting.isNoColor" [(ngModel)]="selectedSetting.lessThanColor"></p-colorPicker>
                            </div>
                            <div class="color-setting">
                                以上
                                <p-colorPicker [disabled]="selectedSetting.isNoColor" [(ngModel)]="selectedSetting.greaterThanColor"></p-colorPicker>
                            </div>
                            <div class="color-setting no-color">
                                <pivot-checkbox class="checkbox-axis" (click)="selectedSetting.isNoColor = !selectedSetting.isNoColor" [value]="selectedSetting.isNoColor" >
                                </pivot-checkbox>
                                色なし
                            </div>
                        </div>
                    </div>
                    <div class="table-container">
                        <table >
                            <thead *ngIf="!selectedSetting.isApplyAll">
                                <tr>
                                    <th class="subject">
                                        <label>対象</label>
                                        <span class="item-filter" [style]="{'opacity': targetTable.length ? '1' : '0.5' }" [ngClass]="[!rowFilters.length? 'not-filter' : 'allow-filter' ]" (click)="filterData($event)"></span>
                                    </th> 
                                    <th style="width: 60%">目標値</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let data of targetTable; let index = index"  [ngClass]="{ 'applyall': selectedSetting.isApplyAll }"> 
                                    <td>{{data.value}}</td>
                                    <td style="width: 60%" >
                                      <div class="target-value">
                                        <p-dropdown class="drop-down setting-item"  appendTo="body" optionLabel="name" [options]="targetFomular" 
                                        [(ngModel)]="data.targetFomular" (onChange)="changeFormulaType($event, data)" >
                                            <ng-template let-option pTemplate="item">
                                                <div  class="option-item">{{option.name}} </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <pivot-input-formula-inline  *ngIf="data.type == TARGET_SETTING_TYPE.FORMULAR" [selected] = "data" [displayCols] = "datasourceCols" [formula] = "data.targetValue" [rounding] = "data.rounding"> 
                                        </pivot-input-formula-inline>
                                         <input *ngIf="data.type != TARGET_SETTING_TYPE.FORMULAR" type="text" [tabIndex]="1000 + index" [disabled]="data.type == TARGET_SETTING_TYPE.FORMULAR" (focusout)="formatNumber($event.target, data)"
                                            (keydown)="inputKeyDown($event)" [(ngModel)]="data.targetFomular && data.targetFomular.code === TARGET_SETTING_TYPE.FORMULAR ? data.displayText : data.targetValue"/>
                                        <img [ngClass]="{'display': data.targetFomular && data.targetFomular.code === TARGET_SETTING_TYPE.FORMULAR}" [src]="popupIcon"
                                        (click)="showTargetFormulaModal(data)">
                                      </div>
                                       
                                    </td>
                                </tr>
                                <tr class="no-data" *ngIf="!targetTable.length">
                                    <td colspan="2">{{COMMON_TEXT.NO_DATA}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="setting-row add-button">
                        <pivot-text-button (btnClick)="resetRecord(true)" [buttonType]="buttonType.SECONDARY" buttonText="クリア">
                        </pivot-text-button>
                        <pivot-text-button (btnClick)="addRecord()" [buttonType]="buttonType.SECONDARY" [buttonText]="buttonName">
                        </pivot-text-button>
                    </div>
                </div>
                <div class="setting-columns table-container">
                    <div class="table-container list-target">
                        <table >
                            <thead>
                                <tr>
                                    <th style="width: 7%">No.</th> 
                                    <th style="width: 28%">目標名称</th>
                                    <th style="width: 30%">対象（列）</th>
                                    <th style="width: 20%">対象（行）</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let data of targetSettings; let index = index" [ngClass]="{'selected': data.id === selectedSetting.id }">
                                    <td style="width: 7%">{{index +1 }}</td> 
                                    <td style="width: 28%">{{data.targetColumnName}}</td>
                                    <td style="width: 30%">{{data.column.name}}</td>
                                    <td style="width: 20%">{{data.row.name}}</td>
                                    <td style="width: 15%">
                                        <span class="table-action" (click)="editTarget(data.id)" style="color: blue">編集</span>
                                        | 
                                        <span class="table-action" (click)="deleteTarget(data.id)"  style="color: red">削除</span>
                                    </td>
                                </tr>
                                <tr class="no-data" *ngIf="targetSettings && !targetSettings.length">
                                    <td colspan="5"> {{COMMON_TEXT.NO_DATA}}</td>
                                    <!-- データがありません。 -->
                                </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
    
        </div>

    </div>
    <div modal-bottom class="bottom-button">
        <div class="btn-grp">
            <pivot-text-button *ngIf="selected && selected.datasourcecd === DSCUSTOM && isDefineNewColumnTab"
                (btnClick)="remove()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.DELETE"
                (mouseover)="hover = true" (mouseleave)="hover = false"
                [buttonStyle]="hover 
                ? { 'color': 'white', 'background-color': '#C8424C' }
                : { 'color': '#C8424C' }">
            </pivot-text-button>
            <pivot-text-button (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button (btnClick)="onSubmit()" [disabled]="isDisableSaveButton()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SAVE">
            </pivot-text-button>
        </div>
    </div>
    
</pivot-modal-template>

<p-overlayPanel #lstRowOption [styleClass]="'display-overlaypanel'">
    <ng-template pTemplate>
        <p-listbox [options]="targetOptions" optionLabel="name" class="filter-target-value"
            [filter]="true"
            [multiple]="true"
            [checkbox]="true"
            [style]="{width: '250px'}"
            [filterPlaceHolder]="COMMON_TEXT.SEARCH_TEXT"
            [(ngModel)]="rowFilters"
            [emptyFilterMessage]="COMMON_TEXT.NO_DATA"
            (onChange)="onSelectedOption($event)">
            <ng-template let-option pTemplate="item">
                <div>
                    <label>{{option.name}}</label>
                </div>
            </ng-template>
        </p-listbox>
    </ng-template>
</p-overlayPanel>

<pivot-target-setting-formula *ngIf="showFormulaModal" (onSubmitData)="submitFormula($event)" [selected]="selectedRowFormula" [displayCols]="datasourceCols"></pivot-target-setting-formula>
