import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation, resolveForwardRef } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ScrollerOptions } from 'primeng/scroller';
import { LazyTreeNode } from '../../../models/common-model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'pivot-lazy-tree-view',
  templateUrl: './lazy-tree-view.component.html',
  styleUrls: ['./lazy-tree-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LazyTreeViewComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() dataNode: any;
  @Input() dragScope: string;
  @Input() scrollHeight: number = 0;
  @Input() rowHeight: number = 0;
  @Input() nodeClicked: LazyTreeNode;
  @Input() isZaitaku: boolean = false;
  @Output() nodeClick = new EventEmitter<any>();
  @Output() leafNodeClick = new EventEmitter<any>();

  virtualData: LazyTreeNode[] = [];
  options: ScrollerOptions = {};
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.virtualData = Array.from({length: this.data.length});
      if (this.isZaitaku) this.virtualData = cloneDeep(this.data);
      //load data of required page
      let loadedCars = [...this.data].slice(0, 1);
      // //populate page of virtual cars
      this.virtualData.splice(0, 1,...loadedCars);
      // //trigger change detection
      this.virtualData = [...this.virtualData];
    }
  }

  ngOnInit(): void {
    this.virtualData = Array.from({length: this.data.length});
  }

  loadData(event: LazyLoadEvent) {
    if (!event.first && event.last === 1 && this.virtualData[0]) {
      return;
    } 

    //load data of required page
    let loadedCars = [...this.data].slice(event.first, event.last);

    // //populate page of virtual cars
    this.virtualData.splice(event.first ?? 0, (event.last ?? 0) - (event.first || 0),...loadedCars);
    
    // //trigger change detection
    this.virtualData = [...this.virtualData];
 
  }


  handleClick(event: any) {
    this.nodeClick.emit(event);
  }

  onClickLeafNode(event: any) {
    this.virtualData.forEach(element => {
      if (element) {
        element.selected = (event.id == element.id);
        if (element.node) element.node.selected = (event.id == element.id);
      }
    });
    this.virtualData = [...this.virtualData];
    this.leafNodeClick.emit(event);
  }
}
