<pivot-splitter-panel-template class="widget-setting" [titlePage]="pageTitle"
  [nameParams]="nameParams" [name]="widget.widgetname" [isDisplayControlIcon]="mode === 2 ? true : false"
  [panelLeftSize]="panelLeftSize"
  [panelRightSize]="panelRightSize"
  [defaultSizeLPanel]="defaultLeftSize"
  [defaultSizeRPanel]="defaultRightSize"
  (nameFieldHandler)="checkValidateName()"
  (backPageHandler)="mode !== 3 ? backPage() : cancel()"
  [isDisplayLeftPannel]="true" [isDisplayRightPannel]="true" (nameFieldHandler)="changeName($event)">
  <div top-buttons class="widget-setting--top-buttons">
    <pivot-text-button  *ngIf="(chartData && widget.charttype == _graphType.LINE_BAR_COMBINE_CHART)" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.GRAPH_SETTINGS" 
    (click)="showGraphsetting()">
    </pivot-text-button>

    <pivot-text-button *ngIf="!this.isWidgetTemplate" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT_TEMPLATE" 
    (click)="chooseWidgetTemplate()">
    </pivot-text-button>
    <pivot-text-button [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL" (btnClick)="cancel()"></pivot-text-button>
    <pivot-text-button [buttonType]="buttonType.PRIMARY" [buttonText]="BUTTON.SAVE" (btnClick)="save()" [disabled]="!canSave()">
    </pivot-text-button>
  </div>

  <div class="widget-setting--controls-popup" control-popup>
    <label class="widget-setting--controls-popup-item" (click)="onCloneData()">{{BUTTON.COPY}} </label>
    <label class="widget-setting--controls-popup-item" (click)="onDeleteBtnClick()">{{BUTTON.DELETE}} </label>
  </div>

  <div class="widget-setting--pannel" left-pannel>
    <div class="widget-setting--pannel-left">

      <div class="datasource-section">
        <div class="datasource-section--header">
          <label>{{BUTTON.DATASOURCE_SELECT}}</label>
          <pivot-add-button (onClick)="openDatasourceSelectionDialog()"></pivot-add-button>
        </div>
        <label>列</label>
        <div [ngClass]="showValidateErrDS ? 'datasource-section--err' : 'datasource-section--content'">
          <pivot-tree-view [params]="choosenDSTree" (nodeMouseDoubleClick)="onDoubleClick($event)"></pivot-tree-view>
        </div>
        <label *ngIf="showValidateErrDS" class="datasource-section-label-err">{{MESSAGE_TEXT.REQUIRE_INPUT_DATASOURCE}}</label>
      </div>

      <div class="description-section">
        <div class="description-section--header">
          <label>{{WIDGET_SETTING.CUSTOM_TREE}}</label>
          <pivot-add-button (onClick)="openDefinedColumnDlg(true)"></pivot-add-button>
        </div>
        <div class="description-section--content"  pDroppable="pivot-config" onDrop="return false;">
          <label>{{WIDGET_SETTING.WIDGET_DESC}}</label>
          <textarea maxlength="200"  [(ngModel)]="widget.widgetdesc"></textarea>
        </div>
      </div>
  </div>
  </div>

  <div class="widget-setting--pannel" right-pannel>
    <div class="widget-setting--pannel-right">

      <div class="drop-area-section">
        <div class="drop-area-section--header">
          <label>{{COMMON_TEXT.TOGGLE}}</label>
        </div>
        <div class="drop-area-section--content" #treeDropArea pDroppable="pivot-config" (onDrop)="onDrop($event)">
          <pivot-list-box (onFilterData)="onFilterDataClick($event)" (onDelete)="onDeleteColumn($event)" (onSortData)="onReorder($event)"
            [params]="selected" 
            [selection]="pivotConfigSelection">
          </pivot-list-box>
        </div>
      </div>

      <div class="group-btn-section">
        <div class="group-btn-section--header">
          <label>{{WIDGET_SETTING.WIDGET_CHART_TYPE}}</label>
        </div>
        <div class="group-btn-section--content">
          <pivot-display-graph (onClickChart)="onClickChart($event)" [selected]="widget.charttype"></pivot-display-graph>
        </div>
      </div>

      <div class="graph-section">
        <div class="graph-section--content" #treeDropArea>
          <label>行</label>
          <div pDroppable="pivot-config" (onDrop)="onDrop($event, 'rows')" #rowsListItem>
            <pivot-list-item (onDelete)="onDeleteColumn($event, ColumnType.Row)" (onSortData)="onReorder($event, true)" 
              [isFilterGroup]="isFilterGroup" [isRowOrColumn]="true"
              (onChangeOption)="onChangeOption($event)" [params]="rows" baseOn="datatype"
              [selection]="rowsListItemSelection">
            </pivot-list-item>
          </div>
          <label>列</label>
          <div pDroppable="pivot-config" (onDrop)="onDrop($event, 'cols')" #colsListItem>
            <pivot-list-item (onDelete)="onDeleteColumn($event, ColumnType.Column)" (onSortData)="onReorder($event, true)" 
              [isFilterGroup]="isFilterGroup"
              [isRowOrColumn]="true"
              (onChangeOption)="onChangeOption($event)" [params]="cols" baseOn="datatype"
              [selection]="colsListItemSelection">
            </pivot-list-item>
          </div>
          <div class="change-option">   
            <label class="left">{{WIDGET_SETTING.VALUE}}</label>
            <label (click)="onShowListOption($event)" class="right">{{WIDGET_SETTING.CHANE_ALL_OPTION}}</label>
          </div>
          <div pDroppable="pivot-config" (onDrop)="onDrop($event, 'value')" #valueListItem>
            <pivot-list-item (onDelete)="onDeleteColumn($event, ColumnType.Value)" (onSortData)="onReorder($event, true)" 
              (onFilterData) = "onFilterPivotTableClick($event)"
              (onChangeOption)="onChangeFormat($event)" [params]="value"
              [selection]="valueListItemSelection">
            </pivot-list-item>
          </div>
        </div>
      </div>

      <div class="folder-section">
        <div class="folder-section--header">
          <label>{{WIDGET_SETTING.WIDGET_FOOTER}}</label>
        </div>
        <div class="folder-section--content">
          <div class="footer"  #valueFooterItem>
            <pivot-list-item [params]="footers"  [selection]="footerSelection" (onChangeOption)="onChangeOptionFooter($event)" [forFooter]="true"></pivot-list-item>
          </div>
        </div>
      </div>

      <div class="folder-section">
        <div class="folder-section--header">
          <label>{{WIDGET_SETTING.WIDGET_SUMMARYCOLUMN}}</label>
        </div>
        <div class="folder-section--content">
          <div class="footer"  #valuesummaryColumnItem>
            <pivot-list-item [params]="summaryColumns"  [selection]="summaryColumnSelection" (onChangeOption)="onChangeOptionsummaryColumn($event)" [forFooter]="true"></pivot-list-item>
          </div>
        </div>
      </div>

      <div *ngIf="isWidgetTemplate" class="folder-section">
        <div class="folder-section--header">
          <label>{{WIDGET_SETTING.VALID_PERIOD}}<span class="input-required">{{COMMON_TEXT.ASTERISK}}</span></label>
        </div>
        <div class="folder-section--content">
          <div class="footer">
            <div class="date-range" (click)="openRangeDateModal()">{{formatRangeDate(selectedRangeDate)}}</div>
          </div>
        </div>
      </div>
      <div class="widget-setting--pannel-right--field-wrapper">
        <span class="widget-setting--pannel-right--field-wrapper--bold-text">フォルダ</span>
        <div class="widget-setting--pannel-right--field-wrapper--field-name">{{targetFolder.folderName}}</div>
      </div>
      <pivot-text-button class="widget-setting--pannel-right--new-folder" (btnClick)="showFolderModal()" [disabled]="mode === screenMode.PREVIEW"  [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT_FOLDER"></pivot-text-button>
    </div>
  </div>

  <div id="widget-body" [ngClass]="{'widget-setting--body_stacked_bar_char': widget.charttype === _graphType.STACKED_BAR, 'widget-setting--body': widget.charttype !== _graphType.STACKED_BAR}"  body-content>
    <div  *ngIf="(chartData && widget.charttype == _graphType.STACKED_BAR)"  class="dlgu-content">
      <pivot-switchbutton  [checked]="isStackedChartSetting" (onClick)="onClickSwitchButton()" class="switch-element" ></pivot-switchbutton>
      <p class="dlgu-txt">{{WIDGET_SETTING.SWITCH_COLUMN_ROW}}</p>
    </div>
    <label *ngIf="widget.charttype ? !chartData : !selected.items.length">{{BUTTON.PREVIEW}}</label>

    <pivot-table-bulk-data  *ngIf="!widget?.charttype && selected.items.length" [data]="dataSource">
    </pivot-table-bulk-data> 

    <pivot-chart 
      *ngIf="widget.charttype && chartData" 
      [isWidgetEdit]="true"
      [settingValueConfig]="settingValueConfig" 
      [graphConfig]="graphConfig" 
      [onShowGraphSettings]="showGraphSetting"
      [chartData]="chartData" 
      [isStackedChartSetting]="isStackedChartSetting"
      [type]="widget.charttype"
      [sortParams]="sortParam"
      [footerType]="footerType"
      [tableData]="tableData" 
      [widgetCd] = "widget.widgetcd" 
      [officeList]="officeList"
      [width]="chartWidth" [height]="chartHeight" 
      (onSaveChartConfig)="saveChartConfig($event)" 
      (handSortData)="handleSortColTable($event)" 
      (onRenderComplete)="onRenderChartComplete()" 
      (onStartRender)="onStartRenderChart()">
    </pivot-chart>
  </div>

</pivot-splitter-panel-template>
<pivot-defined-column-dialog
  *ngIf="isDisplayDefineColumnDialog"
  [datasourceCols]="usingWidgetDetails"
  [modalData]="definedColModalData"
  [selected]="selectedCustom"
  (onDelete)="deleteCustomNode($event)"
  (onSubmitData)="onSubmitDefinedCol($event)"
  [columns]="widgetdetails"
  [dataDS]="sourceTable"
  [targetSettings]="settingValueConfig ? settingValueConfig.targetTable: []"
  >

</pivot-defined-column-dialog>
<pivot-dialog-datasource-selection *ngIf="isDisplayDatasourceSelectionDialog"
  [isTemplate]="isWidgetTemplate"
  (closeModalHandler)="closeDatasourceSelectionDialog($event)" [datasourceTreeData]="folderTree">
</pivot-dialog-datasource-selection>
<pivot-confirm-del-selected-dialog [isDisplayModal]="isDisplayConfirmDeleteModal" [modalData]="modalDataDelete"
  (onSubmitData)="onConfirmDeleteDlg($event)" [isCustomDialogForWidgetList]="isDeleteWG" [deleteItems]="nameWidgetDelete" [inputData]="delTxt" [renderArray]="dashboardNames" ></pivot-confirm-del-selected-dialog>
  
<pivot-dialog-filter-condition *ngIf="displayConditionModal" 
                               [modalData]="modalCondition"
                               [value]="filterF"
                               [sourceTable]="sourceTable"
                               [periodList]="periods"
                               [periodSelected]="periodSelected"
                               [options]="filterOptions"
                               [dataType]="dataType"
                               [isHaveMaxMinFilter]="isHaveMaxMinFilter"
                               (onSubmitData)="onSubmitDataCondition($event)">
</pivot-dialog-filter-condition>

<pivot-dialog-filter-condition *ngIf="isFilterPivotTable" 
                               [modalData]="modalCondition"
                               [value]="pivotFilterF"
                               [options]="filterOptions"
                               [dataType]="dataType"
                               (onSubmitData)="onSubmitFilterCondition($event)">
</pivot-dialog-filter-condition>

<pivot-range-date-modal *ngIf="isDisplayRangeDateModal" 
  [isAllowNullEndDate]="true"
  [startDate]="startDate"
  [endDate]="endDate"
  [isDisplayEdate]="isDisplayEdate"
  (closeModalHandler)="closeRangeDateModal()"
  (rangeDateHandler)="setRangeDate($event)">
</pivot-range-date-modal>

<p-overlayPanel #lstOption [styleClass]="'list-option'" *ngIf="isShowOption">
  <ng-template pTemplate>
      <p-listbox [options]="value.options" optionLabel="name"
          (onChange)="onSelectedOption($event)">
          <ng-template let-option pTemplate="item">
              <div>{{option.name}}</div>
          </ng-template>
      </p-listbox>
  </ng-template>
</p-overlayPanel>


<pivot-folder-modal *ngIf="isShowFolder" [folderType]="folderType.Widget" [folderList]="folderList" (closeModalHandler)="closeFolderModal()"
  (selectedFolderHandler)="changeFolder($event)" [selectedFolder]="targetFolder">
</pivot-folder-modal>
<pivot-dialog-filter-group 
  *ngIf="dspFilterGrpModal"  
  [options]="filterOptions"
  [value]="filterF"
  (onSubmitData)="onFilterGroup($event)"
  [dataType]="dataType"
  [tableData]="tableData"
  [sourceTable]="filteredTable"
  [itemSelected]="itemSelected"
  [modalData]="modalFilterGrp">
</pivot-dialog-filter-group>
