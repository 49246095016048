import { Component, Input } from '@angular/core';
import bb, { bar } from "billboard.js";
import { ChartBase } from '../chart.base';
import * as d3 from 'd3';
import Utils from "../../../../util/utils";

@Component({
  selector: 'pivot-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent extends ChartBase {

  @Input() isHorizontal: boolean = false;

  nodeId = "js-chart-bar"

  override ngOnInit() {
    this.data = Utils.replaceYenCharacterOnChart(this.data);
    if (!this.chartId) {
      this.nodeId = this.nodeId + (this.isHorizontal ? '1' : '2');
    }
    
    super.ngOnInit()
  }

  generateChart(): void {
    const onrendered = ()=>{
      this.onRenderComplete.emit();
    }
    const onresize = ()=>{
      this.onStartRender.emit();
    }
    const renderChart = () =>{
      this.chart.flush();
    }
    this.chart = bb.generate({
      boost: {
        useWorker: false,
      },
      zoom: {
        enabled: this.zoomEnabled
      },
      legend: {
        show: this.data.legendShow
      },
      data: {
        columns: this.data.columns,
        type: bar()
      },
      axis: {
        x: {
          clipPath: false,
          type: "category",
          categories: this.data.categories,
          tick: {
            width: 80,
            multiline: false,
            culling: false,
            rotate : 40,
            autorotate: true
          }
        },
        y: {
          tick: {
            format: function(y: any) {
              return d3.format(",")(y);
            }
          }
        },
        rotated: this.isHorizontal
      },
      render: {
        lazy: true,
        observe: true
      },
      onrendered: onrendered,
      onresize: onresize,
      size: this.size,
      bindto: this.bindTo,
      bar: {
        width: {
          ratio: 0.9
        }
      }
    });
    this.onStartRender.emit();

    //delay render chart to have time for memory cleanup
    let timedelay = 100;
    if(this.data.columns.length>0){
      let dataCount = this.data.columns[0].length * this.data.columns.length;
      if(timedelay < dataCount*this.TIME_DELAY) timedelay= dataCount*this.TIME_DELAY;
      if(timedelay > 10000) timedelay= 10000;
    }
    setTimeout(renderChart, timedelay);
  }
}
