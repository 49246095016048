<pivot-single-pannel-template [titlePage]="wigetTitle" id="widgetList">
    <div pannel-left-action>
        <div class="add-folder">
            <pivot-text-button class="btn-add-folder" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.FOLDER_CREATE"
                (btnClick)="openDialogAddFolder()">
            </pivot-text-button>
        </div>
    </div>

    <div class="widget-pannel-left-content" pannel-left-content>
        <pivot-search-bar class="search-folder" [searchParams]="searchFolder"
            (onFilterData)="onFilterFolderData($event)">
        </pivot-search-bar>
        <div class="widget-btn-grp">
            <pivot-text-button [iconType]="iconType.EDIT" (btnClick)="openDialogEditFolder()" *ngIf="dspBtnEditFolder"
                [buttonType]="buttonType.INFO" [buttonText]="BUTTON.EDIT" [disabled]="folderActionButtonDisabled">
            </pivot-text-button>
            <pivot-text-button class="btn-delete" *ngIf="dspBtnDelFolder" [iconType]="iconType.DELETE"
                (btnClick)="onDeleteFolder()" [buttonType]="buttonType.DANGER" [buttonText]="BUTTON.DELETE" [disabled]="folderActionButtonDisabled">
            </pivot-text-button>
        </div>
        <div class="table-folder-format">
            <pivot-table [data]="folderData" [selectedRow]="folderSelected"
                (clickRow)="onFilterWidget($event)" [selectedRows]="folderSelecteds"
                (checkRows)="checkRowsFolderTable($event)"></pivot-table>
        </div>
    </div>
    <div class="pannel-right-content" pannel-right-content>
        <div class="widget-list-content">
            <div class="widget-list-filter">
                <pivot-search-bar [searchParams]="searchWidget" (onFilterData)="onFilterwidgetData($event)">
                </pivot-search-bar>
                <div class="btn-widget-grp">
                    <pivot-text-button class="btn-add-widget" [buttonType]="buttonType.SECONDARY"
                        [buttonText]="addWidgetStr" (btnClick)="createWidgetBtnClick()" [disabled]="widgetActionButtonDisabled || !dspBtnAddWidget">
                    </pivot-text-button>
                </div>
            </div>
            <div class="table-right">
                <pivot-table class="table" [isLoading]="isLoadingWidgetTable" [data]="widgetTableData" [selectedRows]="widgetSelecteds"
                (checkRows)="checkRowswidgetTable($event)"  (clickRow)="movetoWidgetViewScreen($event)"
                [checkAllMode]="checkAllMode.ALL_PAGE">
                    <div action-buttons class="table-button-wrapper">
                        <pivot-text-button class="btn-delete" *ngIf="dspBtnDelWidget" [iconType]="iconType.DELETE"
                            (btnClick)="onDeletewidget()" [buttonType]="buttonType.DANGER" [buttonText]="BUTTON.DELETE" [disabled]="widgetActionButtonDisabled">
                        </pivot-text-button>
                    </div>
                </pivot-table>
            </div>
        </div>
    </div>
</pivot-single-pannel-template>

<pivot-dialog-folder-editor [isTemplate]="isWidgetTemplate" [data]="folderEditData" [isDisplayModal]="isDisplayFolderModal" [modalData]="modalFolder" [isShowAssign]="!isWidgetTemplate"
    (onSubmitData)="onChangeDataFolder($event)" [folderType]="folderType.Widget" [assignPermission]="assignPermission">
</pivot-dialog-folder-editor>
<pivot-confirm-del-selected-dialog [inputData]="delTxt" [isDisplayModal]="isDisplayModalDelete" [modalData]="modalDelete" [isCustomDialogForWidgetList]="isDeleteWidget"
    [deleteItems]="deleteArray" (onSubmitData)="onShowConfirmDeleteModel($event)" [renderArray]="dashboardName">
</pivot-confirm-del-selected-dialog >
