import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProcessLoadingService } from '../../services/loading.service';
import { HELP_LIST } from '../../../app/const/text-common';
import { HelpListService } from '../../services/modules/help-list.service';
import * as moment from 'moment';
import { DateFormat, SearchType } from '../../enum/common-enum';
import { HelpListHelper } from './../../_helper/help-list'
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageKey } from '../../_helper/local-storage.helper';
import { SearchParams } from 'src/app/models/common-model';
import { CONSOLE_CATEGORIES, CONSOLE_CATEGORY_CODES, CONSOLE_CATEGORY_VALUES } from 'src/app/const/const';
//import { environment } from "@environments/environment";


@Component({
  selector: 'pivot-help-list',
  templateUrl: './help-list.component.html',
  styleUrls: ['./help-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpListComponent implements OnInit {

  HELP_LIST = HELP_LIST;
  lstHelp :any[]= [];
  heplItem: any ={};
  showDialog= false;
  userID = 0;
  role = ''

  categoryParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    comboDisplayText: 'name',
    defaultValue: CONSOLE_CATEGORY_VALUES.ALL,
    dataSource: CONSOLE_CATEGORIES
  };
  selectedCode: number = CONSOLE_CATEGORY_CODES.ALL;
  
  constructor(
    private loadingService: ProcessLoadingService,
    private helpListService: HelpListService, 
    private authenService: AuthenticationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userID = this.authenService.getData(LocalStorageKey.USERID) || undefined
    let role = (this.authenService.getData(LocalStorageKey.CURRENT_USER_ROLE) || []);
    if(this.userID){
      if(role.length > 0){
        this.role = role[0].roleCd
      }
      this.loadingService.isLoading.emit(true);
      let request = {
        user_id:this.userID,
        is_admin: (this.role == 'RL00000001') || (this.role == 'RL00000099')
      }
      const helpService = await this.helpListService.getHelpListPost(request);
      if(helpService.statuscode === 200){
        this.lstHelp =  helpService.data;
        this.lstHelp.map((x:any)=>{
          x.displayContent = HelpListHelper.getDisplayContent(x);
        });
      }
      this.loadingService.isLoading.emit(false);
    }
  }

  onClickItem(item:any){
    this.heplItem = item;
    this.showDialog = true;
  }
  async onCloseDialog(item: any){
    var itemHelp = this.lstHelp.find((x) => {
      return (x.id == item.id)
    });
    this.showDialog = false;
    if (itemHelp.unread != 0) {
      itemHelp.unread = 0;
      let lstheplunread = this.lstHelp.filter((x:any) => {
        return (x.unread == '1')
      })
      this.helpListService.setCountUnRead(lstheplunread.length);
      await this.updateUnRead(item);
    }

  }

  getInsertdate(item:any){
    if(item.insdate){
     return moment(new Date(item.insdate)).format(DateFormat.FULL_SHORT_DATE);
    }
    return "";
  }
  
  async updateUnRead(item:any){
    let request = {
      user_id:this.userID,
      is_admin: (this.role == 'RL00000001') || (this.role == 'RL00000099'),
      help_id:item.id
    }
    const helpService = await this.helpListService.updateUnread(request);
  }

  onSelectCategory(data: any[]) {
    this.selectedCode = (data && data.length == 1) ? data[0].code : CONSOLE_CATEGORY_CODES.ALL;
  }

  filterByCategory(items: any[] = [], code: number): any[] {
    return  (code == CONSOLE_CATEGORY_CODES.ALL) ? items : items.filter(item => item.category == code);
  }
}
