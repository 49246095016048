import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, findIndex, orderBy } from 'lodash';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { DATASOURCE, FOLDER } from '../../const/table-col-define';
import { BUTTON, COMMON_TEXT, DATASOURCE_LIST, DATASOURCE_TEMPLATE_LIST, FOLDER_DIALOG } from '../../../app/const/text-common';
import { ConfirmDialogComponent } from '../../component/common/confirm-dialog/confirm-dialog.component';
import { ROUTE_PATH } from '../../const/route-path';
import { ButtonIconType, ButtonType, CheckAllMode, DataType, DateFormat, DialogType, SearchType, UserAction } from '../../enum/common-enum';
import { ModalTemplate, SearchParams } from '../../models/common-model';
import { FolderAddDTO } from '../../models/request/folder.dto';
import { DataSourceList } from '../../models/response/datasource.ro';
import { Folder, FOLDER_TYPE } from '../../models/response/folder.ro';
import { Office } from '../../models/response/office.ro';
import { TableData } from '../../models/table-model';
import { AuthenticationService } from '../../services/authentication.service';
import { ProcessLoadingService } from '../../services/loading.service';
import { DataSourceService } from '../../services/modules/data-source.service';
import { FolderService } from '../../services/modules/folder.service';
import Utils from '../../util/utils';
import { LocalStorageKey } from './../../_helper/local-storage.helper';
import {WidgetService} from 'src/app/services/modules/widget.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';

@Component({
  selector: 'pivot-data-source-list',
  templateUrl: './data-source-list.component.html',
  styleUrls: ['./data-source-list.component.scss']
})
export class DataSourceListComponent implements OnInit, AfterViewInit, OnDestroy {
  isDataSourceTemplate: boolean;
  // title form
  titlePage: string;
  // folder data edit
  folderEditData: any = null;
  // folder data edit 
  dataSourceEditData: any = null;
  // display modal variables
  isDspFolderEditorModal: boolean = false;
  isDspOfficeModal: boolean = false;
  // delete folder flag
  deleteFolderFlg: boolean = false;
  // delete datasource flag
  deleteDatasourceFlg: boolean = false;
  isDisplayModalDelete: boolean = false;
  // disable button
  dsbBtnEditFolder: boolean = false;
  dsbBtnDelFolder: boolean = false;
  dsbBtnEditDts: boolean = false;
  dsbBtnDelDts: boolean = false;
  dsbBtnAddDts: boolean = false;
  // permission assign
  permsAssign: boolean = false;
  // modal templete
  modalFolder: ModalTemplate = new ModalTemplate();
  modalDataSourceEditor: ModalTemplate = new ModalTemplate();
  modalDelete: ModalTemplate = new ModalTemplate();
  BUTTON = BUTTON;
  buttonType = ButtonType;
  // disable button
  dspBtnEditFolder: boolean = false;
  dspBtnDeleteFolder: boolean = false;
  dspBtnDeleteDataSource: boolean = false;
  // data source delete content
  deleteContent: string[] = [];
  renderTable: any[] = [];
  // btn add datasource
  addDataSourceStr: string = BUTTON.DATASOURCE_CREATE;
  // icon button
  iconType = ButtonIconType;
  // loading table datasource
  isloadingDataSource: boolean = false;
  // search Folder Value
  searchFolderValue: string = '';
  // search datasource value
  searchDataSourceValue: string = '';
  // list current folder 
  foldeCurrents: any[] = [];
  // list  current datasource
  dataSourceCurrents: any[] = [];
  // list staff
  staffs: any[] = [];
  //  array folder selected
  folderSelecteds: any[] = [];
  // array datasource selected
  dataSourceSelecteds: any[] = [];
  //relationship of each datasource with widget and dashbord
  DatasourceRelationalTree: any[] = [];
  // search folder params
  searchFolder: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '10px 5px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // search datasource params
  searchDataSource: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '11px', width: '550px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  // folder list 
  folders: Folder[] = [];
  folderData: TableData;
  // folder row selected
  folderSelectedRow: any = {};
  officeSelecteds: Array<Office> = [];
  // list datasource
  dataSources: DataSourceList[] = [];
  // table dataSource params
  dataSourceData: TableData;
  delTxt: string = '';
  selectedFolderCd: string = '';

  checkAllMode = CheckAllMode;
  isDeleteDS: boolean = false;
  isSupporterAdmin: boolean;

  constructor(private modalService: DialogService, private folderService: FolderService,
    private router: Router, private dataSourceService: DataSourceService,
    private loading: ProcessLoadingService,
    private authenticationService: AuthenticationService,
    private widgetService: WidgetService,
    private errorHandleService: ErrorHandleService) { 
      this.isDataSourceTemplate = this.router.url.includes("datasource-template") ? true : false;
      this.titlePage = this.isDataSourceTemplate ? DATASOURCE_TEMPLATE_LIST.TITLE : DATASOURCE_LIST.TITLE;
    }

  ngAfterViewInit(): void {
    // set permission for folder
    this.checkPermsDataSource(this.folderSelectedRow);
    this.selectedFolderCd = this.folderSelectedRow?.folderCd || '';
  }

  async ngOnInit() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.loading.isLoading.emit(true);
    this.selectedFolderCd = '';
    this.setDatasourceTable();
    this.configTableFolder();

    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();

    await this.getFolderData(this.isSupporterAdmin);


    this.staffs = this.authenticationService.getData(LocalStorageKey.ALL_STAFF) || []
    let folderData = cloneDeep(this.folderData);
    folderData.body = this.folders;
    this.folderData = folderData;
    // redirect url after create datasource
    let currentFolderSelect = this.folderService.getFolderCd();
    if (!Utils.isNullOrEmpty(currentFolderSelect)) {
      let findFolder = this.folders.find((f: any) => f.folderCd === currentFolderSelect);
      this.folderSelectedRow = findFolder;
      this.checkPermsDataSource(findFolder);
      this.selectedFolderCd = findFolder?.folderCd || '';
      this.dataSources = this.dataSourceCurrents?.filter((dts: any) => dts.folderCd === currentFolderSelect);
      await this.configDataSourceTable(this.folderSelectedRow, false);
    }
    this.loading.isLoading.emit(false);
  }


  ngOnDestroy(): void {
    // clear localStorage
    let pathname = window.location.pathname;
    if (this.isDataSourceTemplate) {
      if (!pathname?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE) && !pathname?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE_CREATE) && !pathname?.includes(ROUTE_PATH.DATASOURCE_TEMPLATE_EDIT)) {
        this.folderService.setFolderCd('');
      }
    } else {
      if (!pathname?.includes(ROUTE_PATH.DATASOURCE) && !pathname?.includes(ROUTE_PATH.DATASOURCE_CREATE) && !pathname?.includes(ROUTE_PATH.DATASOURCE_EDIT)) {
        this.folderService.setFolderCd('');
      }
    }
  }

  // get list folder
  async getFolderData(isSupporterAdmin: boolean) {
    await this.getListFolderData(isSupporterAdmin);
  }

  // on search folder
  onSearchFolDer() {
    if (this.searchFolderValue == '') {
      this.bindingFolderTable(this.foldeCurrents);
    }
    else {
      this.bindingFolderTable(this.foldeCurrents.filter((s: any) => s.name?.toUpperCase()?.trim().includes(this.searchFolderValue?.toUpperCase()?.trim())));
    }
  }

  // onfilter data folder
  onFilterFolderData(folderName: any) {
    this.searchFolderValue = folderName;
    this.onSearchFolDer();
  }

  // filter Datasource data
  onFilterDatasource(dataSourceName: any) {
    this.searchDataSourceValue = dataSourceName;
    if (dataSourceName == '') {
      this.bindingDataSourceTable(this.dataSourceCurrents);
    }
    else {
      this.bindingDataSourceTable(this.dataSourceCurrents.filter((s: any) => s.name?.toUpperCase()?.trim().includes(dataSourceName?.toUpperCase()?.trim())));
    }

    this.dataSourceSelecteds = this.dataSourceData.body.filter(d => d.checked);
    this.dspBtnDeleteDataSource = this.dataSourceSelecteds.length >= 1;
  }

  // get folder data
  async getListFolderData(isSupporterAdmin: boolean) {
    let code: string = FOLDER_TYPE.Datasource.toString();
    let data =  this.isDataSourceTemplate ? await this.folderService.getFolderTemplateByType(code): await this.folderService.getByType(code, isSupporterAdmin);
    if (data.statuscode == 200) { 
      let folderData =  data.data;
      this.folders = folderData || [];
      this.foldeCurrents = folderData || [];
      if (this.searchFolderValue != '') {
        this.folders = this.foldeCurrents?.filter((s: any) => s.name?.toUpperCase()?.trim().includes(this.searchFolderValue?.toUpperCase()?.trim()));
      }
      else {
        this.folders = this.foldeCurrents;
      }
      if(this.folders?.length > 0) this.folders = orderBy(this.folders, ["name"]);
    }
  }

  // get list data source 
  async getListDataSource(folder: Folder, isLoading: boolean = false) {
    this.isloadingDataSource = isLoading;
    let folderCd = folder?.folderCd || '';
    if (!Utils.isNullOrEmpty(folderCd)) {
      let _dataSource = await this.dataSourceService.getByFolder(folderCd);
      if (_dataSource.statuscode == 200) {
        this.dataSources = _dataSource.data || [];
        this.dataSourceCurrents = _dataSource.data || [];
        // filter datasource for DataSource Value
        if (this.searchDataSourceValue != '') {
          this.dataSources = this.dataSourceCurrents?.filter((s: any) => s.name?.toUpperCase()?.trim().includes(this.searchDataSourceValue?.toUpperCase()?.trim()));
        }
        else {
          this.dataSources = this.dataSourceCurrents;
        } 
        let insStaff, updStaff;
        this.dataSources?.map((d: any) => {
          insStaff = this.staffs?.find((stf: any) => stf.staffCd == d.insstfcd);
          updStaff = this.staffs?.find((stf: any) => stf.staffCd == d.updstfcd);
          d.insstfnm = insStaff ? insStaff.fullName : '';
          d.updstfnm = updStaff ? updStaff.fullName : '';
          d.displayNm = Utils.isNullOrEmpty(d.updstfcd) ? d.insstfnm : d.updstfnm;
          d.upddate = moment(d.upddate).format(DateFormat.FULL_SHORT_DATE);
          d.insdate = moment(d.insdate).format(DateFormat.FULL_SHORT_DATE);
          d.lastSync = moment(d.lastSync).format(DateFormat.FULL_SHORT_DATE);
        });
      }
    }
    this.dataSources?.map((s: any) => {
      s.folderName = folder?.name;
    });
    this.isloadingDataSource = false;
  }

  // open dialog add new folder
  openDialogAddFolder() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.ADD_DATA_FAIL);
    this.permsAssign = false;
    this.folderEditData = null;
    this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
    this.modalFolder.style = { 'width': '393px' };
    this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDspFolderEditorModal = true;
  }

  // open dialog edit folder
  openDialogEditFolder() {
    if (!this.dsbBtnEditFolder) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.UPDATE_DATA_FAIL);
      this.permsAssign = (this.folderSelecteds[0]?.act != UserAction.VIEW && this.folderSelecteds[0]?.act != UserAction.EDIT) ? true : false;
      this.delTxt
      this.modalFolder.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
      this.modalFolder.style = { 'width': '393px' };
      this.modalFolder.breakpoints = { '960px': '75vw', '640px': '100vw' };
      this.isDspFolderEditorModal = true;
      if (this.folderSelecteds?.length === 1) {
        this.folderEditData = cloneDeep(this.folderSelecteds[0]);
      }
    }
  }

  // open dialog add new datasource
  openDialogOffice() {
    if (!this.dsbBtnAddDts) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
      this.isDspOfficeModal = true;
    }
  }

  // event delete folder
  onDeleteFolder() {
    this.isDeleteDS = false;
    if (!this.dsbBtnDelFolder) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
      this.deleteContent = [];
      this.folderSelecteds?.forEach(fd => {
        this.deleteContent.push(fd.name);
      });
      this.deleteFolderFlg = true;
      this.isDisplayModalDelete = true;
      this.deleteDatasourceFlg = false;
      this.delTxt = COMMON_TEXT.FOLDER;
    }
  }

  // event show confirm modal
  async onShowConfirmDeleteModel(type: boolean) {
    if (!type) {
      this.isDisplayModalDelete = false;
      return;
    }
    this.loading.isLoading.emit(true);
    if (this.deleteFolderFlg) {
      let folders: FolderAddDTO[] = [];
      // delete folder
      if (this.folderSelecteds.length > 0) {
        this.folderSelecteds?.forEach((f: any) => {
          let index = findIndex(this.folders, (item) => {
            return item.name == f?.name;
          }, 0);
          if (index != -1) {
            f.assigncd = f.assign?.assignCd;
            folders.push(f);
          }
        });
        let deleteFDResult = await this.folderService.delete(folders);
        if (deleteFDResult.statuscode == 200) {
          await this.getFolderData(this.isSupporterAdmin);
          this.folderEditData = null;
          this.bindingFolderTable(this.folders);
          this.openConfirmDialog(DialogType.delete);
        }
        this.setVsbBtnDeleteAndEditFolder();
      }
      this.folderSelecteds = [];
      this.deleteFolderFlg = false;
    }
    else if (this.deleteDatasourceFlg) {
      // delete datasource
      let datasources: string[] = [];
      if (this.dataSourceSelecteds.length > 0) {
        this.dataSourceSelecteds?.forEach((d: any) => {
          let index = findIndex(this.dataSources, (item) => {
            return item.name == d?.name;
          }, 0);
          if (index != -1) {
            datasources.push(d.dsstructCd);
          }
        });
        let deleteResult = await this.dataSourceService.delete(datasources);
        if (deleteResult.statuscode == 200) {
          this.dspBtnDeleteDataSource = false;
          this.dataSources = this.dataSources?.filter(d => !this.dataSourceSelecteds.some(ds => ds.dsstructCd === d.dsstructCd));
          this.openConfirmDialog(DialogType.delete);
        }
        else
          this.isDisplayModalDelete = false;
      }
      this.dataSourceSelecteds = [];
      this.deleteDatasourceFlg = false;
    }
    this.isDisplayModalDelete = false;
    await this.configDataSourceTable(this.folderSelectedRow);
    this.loading.isLoading.emit(false);
  }

  // event delete datasource
  async onDeleteDataSource() {
    this.isDeleteDS = true;
    if (!this.dsbBtnDelDts) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
      this.deleteContent = [];
      this.renderTable = [];
      this.dataSourceSelecteds?.forEach(dtSource => {
        this.deleteContent.push(dtSource?.name);
      });
      await this.generateRelationshipMap(this.dataSourceSelecteds);
      this.generateWarningDetails();
      this.deleteDatasourceFlg = true;
      this.isDisplayModalDelete = true;
      this.deleteFolderFlg = false;
      this.delTxt = COMMON_TEXT.DATASOURCE;
    }
  }

  //generate warning details
  generateWarningDetails() {
    let data = this.DatasourceRelationalTree[0];
    if(data){
      let widgetNames = {
        widgetNames: data.widgetNames
      }
      let dashboardNames = {
        dashboardNames: data.dashboardNames.filter( (e: any) => e != "")
      }

      this.renderTable.push(widgetNames);
      this.renderTable.push(dashboardNames);
    }
  }

  // open confirm dialog
  openConfirmDialog(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
  }

  // change data for folder
  async onChangeDataFolder(data: any) {
    this.isDspFolderEditorModal = false;
    if(data != false) {
      if (!Utils.isNullOrEmpty(data)) {
        // update folder
        if (!Utils.isNullOrEmpty(this.folderEditData)) {
          if (!Utils.isNullOrEmpty(data))
            await this.getListDataSource(data, true);
          this.openConfirmDialog(DialogType.update);
        }
        // insert folder
        else if (!Utils.isNullOrEmpty(data)) {
          this.openConfirmDialog(DialogType.save);
        }
        this.setVsbBtnDeleteAndEditFolder();
        await this.getFolderData(this.isSupporterAdmin);
        this.bindingDataSourceTable(this.dataSources);
        this.folderSelecteds = [];
        this.bindingFolderTable(this.folders);
      }
    }
  }

  setVsbBtnDeleteAndEditFolder() {
    this.dspBtnEditFolder = false;
    this.dspBtnDeleteFolder = false;
  }

  // on change data for datasource
  selectedOffice(office: Office[]) {
    localStorage.setItem(LocalStorageKey.SELECTED_OFFICES, JSON.stringify(office));
    if (this.isDataSourceTemplate) {
      this.router.navigate([ROUTE_PATH.DATASOURCE_TEMPLATE_CREATE + '/' + this.selectedFolderCd]);
    } else {
      this.router.navigate([ROUTE_PATH.DATASOURCE_CREATE + '/' + this.selectedFolderCd]);
    }
  }

  // event show dialog edit datasource

  clickDataSourceRow(row: any) {
    if (row) {
      this.dataSourceEditData = row;
      if (!this.dsbBtnEditDts) {
        // edit datasource detail
        if (this.isDataSourceTemplate) {
          this.router.navigate([ROUTE_PATH.DATASOURCE_TEMPLATE_EDIT + '/' + this.dataSourceEditData.dsstructCd + '/' + 2]);
        } else {
          this.router.navigate([ROUTE_PATH.DATASOURCE_EDIT + '/' + this.dataSourceEditData.dsstructCd + '/' + 2]);
        }
      }
      else {
        // view datasource detail
        if (this.isDataSourceTemplate) {
          this.router.navigate([ROUTE_PATH.DATASOURCE_TEMPLATE_EDIT + '/' + this.dataSourceEditData.dsstructCd + '/' + 3]);
        } else {
          this.router.navigate([ROUTE_PATH.DATASOURCE_EDIT + '/' + this.dataSourceEditData.dsstructCd + '/' + 3]);
        }
      }
    }
  }

  // checked row for folder table
  async checkRowsFolderTable(data: any) {
    this.folderSelecteds = data?.data;
    this.checkVisibleBtnFolder();
    this.checkPermsFolder();
    if (this.folderSelecteds.length > 0) {
      this.checkPermsDataSource(this.folderSelecteds[this.folderSelecteds.length - 1]);
    }

    this.mapSelectedRowToSource(data);
  }

  mapSelectedRowToSource(data: any) {
    if (data.data?.length >= 1) {
      this.foldeCurrents.forEach(d => {
        const checkedRow = data.data?.find((item: any) => item.id === d.id);
        d.checked = Boolean(checkedRow);
      });
    }
    else {
      this.foldeCurrents.forEach(d => d.checked = false);
    }
  }

  // check vissible button delete and edit folder
  checkVisibleBtnFolder() {
    if (this.folderSelecteds?.length >= 1) {
      this.dspBtnDeleteFolder = true;
      if (this.folderSelecteds.length === 1) {
        this.dspBtnEditFolder = true;
        this.folderEditData = cloneDeep(this.folderSelecteds[0]);
      }
      else {
        this.dspBtnEditFolder = false;
        this.folderEditData = null;
      }
    }
    else {
      this.dspBtnDeleteFolder = false;
      this.dspBtnEditFolder = false;
      this.folderEditData = null;
    }
  }

  checkRowsDataSourceTable(data: any) {
    if (data.data?.length >= 1) {
      this.dspBtnDeleteDataSource = true;

      this.dataSourceCurrents.forEach(d => {
        const currentRow = this.dataSourceData.body.find(b => b.id === d.id);
        if (currentRow) {
          d.checked = currentRow.checked;
        }
      });
      this.dataSourceSelecteds = data?.data;
    }
    else {
      this.dspBtnDeleteDataSource = false;
      const outsideSelecteds = this.dataSourceCurrents.filter(d => this.dataSourceSelecteds.every(data => data.id !== d.id));
      this.dataSourceCurrents.forEach(d => { 
        if (outsideSelecteds.every(o => o.id !== d.id)) {
          d.checked = false;
        } 
      });
      this.dataSourceSelecteds = outsideSelecteds;
    }
    
  }

  // binding data for table datasource
  bindingFolderTable(data: any[] = []) {
    let folderTable = cloneDeep(this.folderData);
    folderTable.body = data;
    this.folderData = cloneDeep(folderTable);
  }

  // binding data for table folder
  bindingDataSourceTable(data: any[] = []) {
    let dataSourceTable = cloneDeep(this.dataSourceData);
    dataSourceTable.body = data;
    this.dataSourceData = cloneDeep(dataSourceTable);
  }

  // close dialog office
  closeOfficeModal() {
    this.isDspOfficeModal = false;
  }

  // get list data source for table
  async configDataSourceTable(folder: any, isLoading: boolean = false) {
    await this.getListDataSource(folder, isLoading);
    this.setDatasourceTable();
    this.dataSourceData.body = this.dataSources;
    this.dataSourceSelecteds = [];
  }

  // event click row in table folder
  async filterListDataSource(data: any) {
    this.dspBtnDeleteDataSource = false;
    this.folderService.setFolderCd(data.folderCd);
    let currentFolder = cloneDeep(this.folderSelectedRow);
    this.folderSelectedRow = data;
    if (data) {
      this.selectedFolderCd = data?.folderCd;
    }
    // check change action folder
    if (currentFolder?.folderCd != this.folderSelectedRow?.folderCd) {
      this.DatasourceRelationalTree = [];
      await this.configDataSourceTable(data, true);
    }

    this.checkPermsDataSource(data);
  }

  async generateRelationshipMap(arr: any[]) {
    this.loading.isLoading.emit(true);
    let queryStr = this.generatateQueryStr(arr);
    if(queryStr != "")
    {
      let result = await this.widgetService.getWidgetList(queryStr);
      if(result.data != null && result.data.length > 0)
        this.DatasourceRelationalTree = result.data;
    }
    this.loading.isLoading.emit(false);
  }

  generatateQueryStr(dataArray: any[]) {
    let queryStr = "";
    dataArray.forEach((ele: any) => {
      queryStr += ele.dsstructCd;
      queryStr += "_";
    })
   return queryStr.slice(0, -1);
  } 

  // config data table datasource
  setDatasourceTable() {
    this.dataSourceData = {
      config: {
        id: 'dat-source-1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: true,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%' }
      },
      header: [
        {
          field: 'name',
          title: DATASOURCE.NAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '50%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'folderName',
          title: DATASOURCE.FOLDERNAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'folderId',
          title: DATASOURCE.FOLDERID,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '0%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'displayNm',
          title: DATASOURCE.DISPLAYNM,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {},
            rowClass: 'table-datasource-row-center'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'displayDate',
          title: DATASOURCE.DISPLAYDATE,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {},
            rowClass: 'table-datasource-row-center'
          },
          dataType: DataType.TEXT,
          visible: true
        },
        {
          field: 'id',
          title: 'id',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'dsstructCd',
          title: 'dsstructCd',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        }
      ],
      body: []
    };
  }

  checkPermsFolder() {
    this.dsbBtnDelFolder = false;
    this.dsbBtnEditFolder = false;
    if (this.folderSelecteds.length > 0) {
      // check permission edit folder
      let act = this.folderSelecteds[0]?.act;
      this.dsbBtnEditFolder = act == UserAction.VIEW ? true : false;
      this.permsAssign = (act != UserAction.VIEW && act != UserAction.EDIT) ? true : false;
      // check permission delete folder
      this.folderSelecteds.forEach((f: any) => {
        if (!this.dsbBtnDelFolder) {
          this.dsbBtnDelFolder = f?.act != UserAction.VIEW ? false : true;
        }
      });
    }
  }

  checkPermsDataSource(folder: any) {
    this.dsbBtnDelDts = false;
    this.dsbBtnEditDts = false;
    this.dsbBtnAddDts = false;
    // check permission edit
    if (!Utils.isNullOrEmpty(folder)) {
      let act: number = folder?.act;
      if (act == UserAction.VIEW) {
        this.dsbBtnAddDts = true;
        this.dsbBtnDelDts = true;
        this.dsbBtnEditDts = true;
      }
      else {
        this.dsbBtnAddDts = false;
        this.dsbBtnDelDts = false;
        this.dsbBtnEditDts = false;
      }

    }
  }

// config data table folder
  configTableFolder() {
    // table folder params
    this.folderData = {
      config: {
        id: '1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'border-bottom': 'solid 1px #969696' }
      },
      header: [
        {
          field: 'name',
          title: FOLDER.NAME,
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: true,
          isHasSubText: true
        },
        {
          field: 'id',
          title: 'id',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'folderType',
          title: 'folderType',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'act',
          title: 'act',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'delflg',
          title: 'delflg',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '100%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'folderCd',
          title: 'folderCd',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '0%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        },
        {
          field: 'assign',
          title: 'assign',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '0%' },
            row: {}
          },
          dataType: DataType.TEXT,
          visible: false
        }
      ],
      body: []
    };
  }
}
