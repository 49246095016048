import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTE_PATH } from './const/route-path';
import { BasicInfoComponent } from './module/basic-info/basic-info.component';
import { DashboardListComponent } from './module/dashboard-list/dashboard-list.component';
import { DashboardSettingComponent } from './module/dashboard/dashboard-setting/dashboard-setting.component';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { DataSourceListComponent } from './module/data-source-list/data-source-list.component';
import { DataSourceSettingComponent } from './module/data-source-setting/data-source-setting.component';
import { NotFoundComponent } from './module/error-page/not-found/not-found.component';
import { RoleSettingComponent } from './module/role-setting/role-setting.component';
import { WidgetListComponent } from './module/widget-list/widget-list.component';
import { AuthGuard } from './_helper/auth.guard';
import { WidgetSettingComponent } from './module/widget-setting/widget-setting.component';
import { ResetPasswordComponent } from './module/reset-password/reset-password.component';
import { LoginComponent } from './module/login/login.component';
import { CanDeactivateDashboard } from './services/can-deactivate/can-deactivate-dashboard.service';
import { CanDeactivateWidget } from './services/can-deactivate/can-deactivate-widget.service';
import { CanDeactivateDataSource } from './services/can-deactivate/can-deactivate-datasource.service';
import { WidgetDetailComponent } from './module/widget-detail/widget-detail.component';
import { CkReportComponent } from './module/ck_report/ck-report.component';
import { HelpListComponent } from './module/help-list/help-list.component';
import { DashboardTemplateSettingComponent } from './module/dashboard-template-setting/dashboard-template-setting.component';
import { CorpSelectionComponent } from './module/login/corp-selection/corp-selection.component';
import { LockAccountComponent } from './module/lock-account/lock-account.component';
import { CanDeactivateDataSourceTemplate } from './services/can-deactivate/can-deavtivate-template.service';
import { CanActivateSystemSettings } from './services/can-deactivate/can-deactivate-admin.service';
import { CanActivatePivotListsService } from './services/can-deactivate/can-activate-pivot-lists.service';
import {CanDeactivateCorpSelectionService} from './services/can-deactivate/can-deactivate-corp-selection.service';

export const routes = [
  { path: ROUTE_PATH.AUTH, component: LoginComponent },
  { path: ROUTE_PATH.CONSOLE, component: LoginComponent },
  { path: ROUTE_PATH.RESET_PASSWORD, component: ResetPasswordComponent },
  { path: ROUTE_PATH.PAGE_NOT_FOUND, component: NotFoundComponent },
  { path: ROUTE_PATH.PAGE_ERROR, component: NotFoundComponent },
  { path: ROUTE_PATH.LOCK_ACCOUNT, component: LockAccountComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: ROUTE_PATH.CHOOSE_CORP,
        component: CorpSelectionComponent,
        canDeactivate: [ CanDeactivateCorpSelectionService ]
      },
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: ROUTE_PATH.HOME,
        component: DashboardComponent,
      },
      {
        path: ROUTE_PATH.DATASOURCE,
        component: DataSourceListComponent,
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DATASOURCE_CREATE +'/:folderCd',
        component: DataSourceSettingComponent,
        canDeactivate: [ CanDeactivateDataSource ],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DATASOURCE_EDIT +'/:dsstructCd/:mode',
        component: DataSourceSettingComponent,
        canDeactivate: [ CanDeactivateDataSource ],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DASHBOARD_LIST,
        component: DashboardListComponent,
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.BASIC_INFO,
        component: BasicInfoComponent
      },
      {
        path: ROUTE_PATH.DASHBOARD_CREATE + '/:dashboardId/:type',
        component: DashboardSettingComponent,
        canDeactivate: [ CanDeactivateDashboard ],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DASHBOARD_DETAIL + '/:dashboardId/:type',
        component: DashboardSettingComponent,
        canDeactivate: [ CanDeactivateDashboard ],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.SYSTEM_SETTING + '/:page',
        component: RoleSettingComponent,
        canActivate: [ CanActivateSystemSettings ]
      },
      {
        path: ROUTE_PATH.WIDGET_CREATE,
        component: WidgetSettingComponent,
        canDeactivate: [ CanDeactivateWidget],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.WIDGET_DETAIL,
        component: WidgetSettingComponent,
        canDeactivate: [ CanDeactivateWidget],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.WIDGET_LIST,
        component: WidgetListComponent,
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DB_WD_DETAIL + '/:widgetId',
        component: WidgetDetailComponent
      },
      {
        path: ROUTE_PATH.CK_REPORT,
        component: CkReportComponent
      },
      {
        path: ROUTE_PATH.HELP,
        component: HelpListComponent
      },
      {
        path: ROUTE_PATH.DATASOURCE_TEMPLATE,
        component: DataSourceListComponent,
        canDeactivate: [ CanDeactivateDataSourceTemplate ]
      },
      {
        path: ROUTE_PATH.DATASOURCE_TEMPLATE_CREATE +'/:folderCd',
        component: DataSourceSettingComponent,
        canDeactivate: [ CanDeactivateDataSource ]
      },
      {
        path: ROUTE_PATH.DATASOURCE_TEMPLATE_EDIT +'/:dsstructCd/:mode',
        component: DataSourceSettingComponent,
        canDeactivate: [ CanDeactivateDataSource ]
      },
      {
        path: ROUTE_PATH.DASHBOARD_TEMPLATE,
        component: DashboardListComponent,
      },
      {
        path: ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL + '/:dashboardId/:type',
        component: DashboardTemplateSettingComponent,
        canDeactivate: [ CanDeactivateDashboard ]
      },
      {
        path: ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE +'/:type',
        component: DashboardTemplateSettingComponent,
        canDeactivate: [ CanDeactivateDashboard ],
        canActivate: [ CanActivatePivotListsService ]
      },
      {
        path: ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE + '/:dashboardId/:type',
        component: DashboardTemplateSettingComponent,
        canDeactivate: [ CanDeactivateDashboard ]
      },
      {
        path: ROUTE_PATH.WIDGET_TEMPLATE_CREATE,
        component: WidgetSettingComponent,
        canDeactivate: [ CanDeactivateWidget]
      },
      {
        path: ROUTE_PATH.WIDGET_TEMPLATE_DETAIL,
        component: WidgetSettingComponent,
        canDeactivate: [ CanDeactivateWidget]
      },
      {
        path: ROUTE_PATH.WIDGET_TEMPLATE_LIST,
        component: WidgetListComponent
      },
    ]
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
