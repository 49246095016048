import { FooterOption, ScreenMode } from "../../../app/enum/common-enum";
import { ActivityLog } from "../api-model";
import { operator } from "../common-model";
import { WidgetDetail } from "../request/widget.dto";

export class Widget extends ActivityLog {
    id?: string;
    widgetCd?: string;
    name: string; // ウィジェット名
    description?: string; // 説明
    folderId: string; // フォルダ名
    folderCd?: string; // フォルダ名
    folderName?: string;
    chartType: CHART_TYPE; // 表示形式
    dataTableConfig: Array<WidgetColumn>; // 絞込み
    pivotTableConfig: PivotTableConfig;
    datasources?: Array<string>; // Array of datasourceId
    delflg?: boolean;
    publicDate?: string;
    sortcoltype?: string;
    position?: any; // position of widget on dashboard
}

export class WidgetList extends ActivityLog {
    id?: string;
    widgetCd?: string;
    name: string; // ウィジェット名
    publicDate?: string;
    isPublish?: boolean;
    description?: string; // 説明
    folderId: string; // フォルダ名
    folderCd?: string; // フォルダ名
    folderName?: string;
    chartType: CHART_TYPE; // 表示形式
    datasources?: Array<string>; // Array of datasourceId
    delflg?: boolean;
    sortcoltype?: string;
}


export class DataWidgetsList extends ActivityLog {
    dashboardcd?: string;
    dashboardname?: string;
    dsname?:string;
    dsstructcd?: string;
    widgetcd?: string;
    widgetname?:string;
    staffupdate?:string;
    override upddate?: Date | undefined;
    proccess?:string;
    status?:string;
    id?: string;
    data: any;
  }

export class PivotTableConfig {
    rows: Array<WidgetDetail>; // グループ - 行
    columns: Array<WidgetDetail>; // グループ - 列
    values: Array<WidgetDetail>; // グループ - 値
    footers: Array<WidgetDetail>
    summaryColumns: Array<WidgetDetail>
    hiddens: Array<WidgetDetail>
    type?: number = 0;
}

export class WidgetColumn {
    columnId: string;
    columnName: string;
    label?: string;
    datasourceCd?: string;
    orderNo?: number;
    filterType?: FILTER_CONFIG;
    filterValue?: string;
    operator?: operator[]; // 条件
    operatorDisplay?: string; // 条件
    columnType: COLUMN_TYPE;
}

export enum FILTER_CONFIG {
    //■文字列
    Equals = 1,   //・入力文字と一致する
    DoesNotEqual = 2,   //・入力文字と一致しない
    Contains = 3,   //・入力文字を含む
    DoesNotContain = 4,   //・入力文字を含まない
    BeginsWith = 5,   //・入力文字で始まる
    EndsWith = 6,   //・入力文字で終わる
    //■数値
    Equals_Numeric = 7,   //・入力値と一致する
    DoesNotEqual_Numeric = 8,   //・入力値と一致しない
    Contains_Numeric = 9,   //・入力値を含む
    DoesNotContain_Numeric = 10,   //・入力値を含まない
    GreaterThan_Numeric = 11,   //・入力値以上
    LessThan_Numeric = 12,   //・入力値以下
    //■日付
    Equals_Date = 13,   //・入力値と一致する
    Before_Date = 14,   //・入力値より以前
    After_Date = 15,   //・入力値より以後
    Today = 16,   //・今日
    Yesterday = 17,   //・昨日
    ThisWeek = 18,   //・今週
    LastWeek = 19,   //・先週
    ThisMonth = 20,   //・今月
    LastMonth = 21,   //・先月
    ThisYear = 22,   //・今年
    LastYear = 23,   //・去年
    AllDates = 24,   //・全日程
}

export enum CHART_TYPE {
    LINE_CHART = 1,
    NUMBER_CHART = 2,
    DOUGHNUT_CHART = 3,
    PIE_CHART = 4,
    GAUGE_CHART = 5,
    LINE_BAR_COMBINE_CHART = 6,
    BAR_CHART_VERTICAL = 7,
    BAR_CHART_HORIZONTAL = 8,
    PIVOT_TABLE = 9,
}

export enum COLUMN_TYPE {
    DatasourceColumn = 1,
    Footer = 2,
    UserDefinedColumn = 3
}

export type widget = {
    widgetCd?: string
    folderCd: string
    mode: ScreenMode
}