import { DataType } from "../enum/common-enum";

export class TableData {
    config: TableConfig = new TableConfig();
    header: Array<HeaderItem> = new Array<HeaderItem>();
    body: Array<any> = [];
}

export class TableConfig {
    id: string = '';
    caption: string = '';
    emptyMessage: string = '';
    showIndex: boolean = false;
    showCheckbox: boolean = false;
    hoverShowCheckbox: boolean = false;
    hoverBoderNone?: boolean = false;
    showPagination: boolean = true;
    isResponsive?: boolean = false;
    tableStyle?: any = {};
    isSelectedRow?: boolean = false;
    inlineTableStyle?: any = {};
    isAllowCheckedAllInRow?: boolean = false;
    disableDeleteBtnCondition?: string[] = []; //list value that disable button delete
    disableEditBtnCondition?: string[] = []; //list value that disable button edit
}

export class HeaderItem {
    field: string = '';
    title: string = '';
    sortable: boolean = false;
    filterable: boolean = false;
    attribute: TableAttribute = new TableAttribute();
    dataType: DataType = DataType.TEXT;
    visible: boolean = true;
    isHasSubText?: boolean = false;
    data?: any;
    sortno?: number;
    checkall?: boolean =false;
    isloadicon?: boolean =false;
    ischecked?: any[];
    proccess?: any[] = [];
    isUseTooltips?: boolean = false;
}

export class TableAttribute {
    header: any = {};
    row: any = {};
    rowClass?: any = '';
    isMergeCells? : boolean = false;
    childrenCols? : any[] = [];
    dataCombobox? : any[] = [];
}